import { useMemo } from 'react';
import type { KeyedMutator } from 'swr';

import {
  type ActiveControls,
  SCHEDULE_TIMESERIES_COLUMNS,
  toEnergyManagement,
  toManualControl,
  toTempControl,
} from '~constants/schedule';

import { createLatestQuery, useTimeseriesLatest } from '~hooks/timeseries/queries';
import { useESU } from '~hooks/useESUList';

const MAX_OVERRIDE_DURATION = { hours: 24 };
const ACTIVE_CONTROLS_REFRESH_INTERVAL = 15_000; // ms

export function useActiveControls(esuId: string): {
  data: ActiveControls | undefined;
  error: any;
  isLoading: boolean;
  mutate: KeyedMutator<unknown>;
} {
  const { data: esu, error: esuError, isLoading: isLoadingEsu } = useESU(esuId);
  const { data, mutate, error, isLoading } = useTimeseriesLatest(
    createLatestQuery({
      resources: esu ? [esu.resource_id] : [],
      columns: {
        schedule_override_temperature_emergency: ['duration', 'max_power'],
        schedule_override_fcrn: [
          'duration',
          'aem_active',
          'f_ref',
          'nem_power',
          'nem_multiplier',
          'remaining_endurance',
        ],
        schedule_override_manual: ['duration', ...SCHEDULE_TIMESERIES_COLUMNS],
      },
      start: MAX_OVERRIDE_DURATION,
      atTime: 'now',
    }),
    { refreshInterval: ACTIVE_CONTROLS_REFRESH_INTERVAL, revalidateOnFocus: false },
  );
  const activeControls = useMemo(() => {
    if (esu == null || data == null) {
      return undefined;
    }
    const activeControls: ActiveControls = {};
    const tempControlRow = data[esu.resource_id]?.schedule_override_temperature_emergency;
    if (tempControlRow) {
      activeControls.temp = toTempControl(tempControlRow);
    }
    const fcrnEnergyManagementRow = data[esu.resource_id]?.schedule_override_fcrn;
    if (fcrnEnergyManagementRow) {
      activeControls.energyManagement = toEnergyManagement(fcrnEnergyManagementRow);
    }
    const manualControlRow = data[esu.resource_id]?.schedule_override_manual;
    if (manualControlRow) {
      activeControls.manual = toManualControl(manualControlRow);
    }
    return activeControls;
  }, [esu, data]);

  return {
    data: activeControls,
    error: esuError || error,
    isLoading: isLoadingEsu || isLoading,
    mutate,
  };
}
