import type { UserRole } from '~types';

import { ROOT_ORGANIZATION } from '~constants/auth';

import { useAuth } from '~hooks/useAuth';

export function useUserHasRootRole(role: UserRole) {
  const { user } = useAuth();

  return user?.roles[ROOT_ORGANIZATION]?.includes(role) ?? false;
}
