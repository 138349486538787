import { useCallback, useEffect } from 'react';
import { useMatch, useNavigate, useParams } from 'react-router-dom';

import { useLocalStorage } from '~hooks/useLocalStorage';

import type { Tab } from '~pages/v2/controllers/types';
import { ROUTER_MATCH_PATH } from '~pages/v2/controllers/constants';
import { createControllerPath } from '~pages/v2/controllers/utils/createControllerPath';

const DEFAULT_TAB: Tab = 'diagnostics';

export function useControllersNavigation() {
  const navigate = useNavigate();
  const { edgeControllerId = '' } = useParams();
  const params = useMatch(ROUTER_MATCH_PATH)?.params;

  const [tabFromStorage, setTabFromStorage] = useLocalStorage<Tab>('controllers.tab', DEFAULT_TAB);
  const activeTab = params?.tab as Tab;
  const tab: Tab = activeTab || tabFromStorage;

  const navigateToController = useCallback(
    (edgeControllerId = '', tab: Tab = '') => {
      navigate(createControllerPath(edgeControllerId, tab));
    },
    [navigate],
  );

  useEffect(() => {
    setTabFromStorage(tab);
  }, [tab, setTabFromStorage]);

  return {
    edgeControllerId,
    activeTab,
    tab,
    navigateToController,
  };
}
