import { useCallback, useMemo, useState } from 'react';
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Form, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import { asCactosError, http } from '~http';

import { USER_ROLES } from '~constants/auth';

import { useAbortController } from '~hooks/useAbortController';
import { useUserHasRootRole } from '~hooks/useUserHasRootRole';

import { Iconify } from '~components/Iconify';

export function ChangePassword() {
  const [showPassword, setShowPassword] = useState(false);
  const getSignal = useAbortController();

  const userIsPrivileged = useUserHasRootRole(USER_ROLES.EDIT_EMS_SCHEDULE);
  const minPasswordLength = userIsPrivileged ? 32 : 12;

  const validationSchema = useMemo(
    () =>
      yup.object({
        oldPassword: yup.string().required('Current password is required'),
        newPassword: yup
          .string()
          .min(minPasswordLength, `Password must be at least ${minPasswordLength} characters long`)
          .required('New password is required'),
        confirmPassword: yup
          .string()
          .required('Password confirmation is required')
          .when('newPassword', {
            is: (val: string) => val && val.length > 0,
            then: () => yup.string().oneOf([yup.ref('newPassword')], 'Must match new password'),
          }),
      }),
    [minPasswordLength],
  );

  const formik = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
      logoutOthers: false,
    },
    validationSchema,
    onSubmit: () => {
      handleSubmitPassword();
    },
  });

  const handleSubmitPassword = useCallback(async () => {
    let status;

    try {
      await http.post(
        '/v1/me/password',
        {
          current_password: formik.values.oldPassword,
          new_password: formik.values.newPassword,
          logout_others: formik.values.logoutOthers,
        },
        { signal: getSignal(), responseType: 'text' },
      );
      status = 'Password changed!';
      formik.resetForm();
    } catch (error) {
      status = asCactosError(error).message;
    }

    formik.setSubmitting(false);
    formik.setStatus(status);
  }, [formik, getSignal]);

  const handleShowPassword = useCallback(() => {
    setShowPassword((show) => !show);
  }, []);

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off">
        <Stack alignItems="left" spacing={3} mb={5} mt={5}>
          <TextField
            margin="dense"
            id="oldPassword"
            name="oldPassword"
            type="password"
            label="Your current password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.oldPassword}
            error={formik.touched.oldPassword && Boolean(formik.errors.oldPassword)}
            helperText={(formik.touched.oldPassword && formik.errors.oldPassword) || ' '}
          />
          <TextField
            margin="dense"
            id="newPassword"
            name="newPassword"
            type={showPassword ? 'text' : 'password'}
            label="New password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.newPassword}
            error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
            helperText={(formik.touched.newPassword && formik.errors.newPassword) || ' '}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            margin="dense"
            id="confirmPassword"
            name="confirmPassword"
            type={showPassword ? 'text' : 'password'}
            label="Confirm new password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.confirmPassword}
            error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
            helperText={(formik.touched.confirmPassword && formik.errors.confirmPassword) || ' '}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Tooltip title="Tick the box if you suspect your password has leaked">
            <FormControlLabel
              control={
                <Checkbox
                  {...formik.getFieldProps('logoutOthers')}
                  checked={formik.values.logoutOthers}
                />
              }
              label="Log out other sessions"
            />
          </Tooltip>
          <LoadingButton
            size="large"
            type="submit"
            variant="contained"
            loading={formik.isSubmitting}
            disabled={!formik.dirty || !formik.isValid}
          >
            Change password
          </LoadingButton>
          <Typography>{formik.status || '\u00A0'}</Typography>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
