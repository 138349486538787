import { useMemo } from 'react';
import { Tab } from '@mui/material';
import { TabContext, TabList } from '@mui/lab';
import { NavLink } from 'react-router-dom';

import { isRoutePermitted } from '~utils/isRoutePermitted';

import { useAuth } from '~hooks/useAuth';

type Props = {
  tabs: Record<string, string>;
  tab: string;
  pathname: string;
  allowedTabs?: Record<string, boolean>;
};

// Returns a tab component for each allowed tab
export function Tabs({ tabs, tab, pathname, allowedTabs }: Props) {
  const { user } = useAuth();

  const tabNodes = useMemo(
    () =>
      Object.entries(tabs)
        .filter(
          ([tab]) =>
            isRoutePermitted(user, `${pathname}/${tab}`) &&
            (typeof allowedTabs?.[tab] !== 'boolean' || allowedTabs[tab]),
        )
        .map(([tab, label]) => (
          <Tab key={tab} value={tab} label={label} component={NavLink} to={tab} />
        )),
    [tabs, pathname, allowedTabs, user],
  );

  // If 0 or 1 tab there is no need for tabs
  if (tabNodes.length < 2) return null;

  return (
    <TabContext value={tab}>
      <TabList variant="scrollable">{tabNodes}</TabList>
    </TabContext>
  );
}
