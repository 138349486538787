import { Box, Popper } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import { toString } from 'lodash-es';
import { useEffect, useState } from 'react';

import { parseTimedValue } from '~optimizer/TimedValue';

const indexToDay = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

function matrixToRows(matrix) {
  // Array-of-row-arrays to array-of-row-objects
  const rows = [];
  matrix.forEach((arrayRow, i) => {
    const objectRow = { id: i };
    objectRow.day = indexToDay[i];
    arrayRow.forEach((value, j) => {
      objectRow[toString(j)] = value;
    });
    rows.push(objectRow);
  });
  return rows;
}

export function TimedValuePreview(props) {
  const { timedValue, anchorEl } = props;
  const [timedValueRows, setTimedValueRows] = useState([]);
  function createHourColumns() {
    const columns = [{ field: 'day', headerName: 'Day', width: 20 }];
    for (let i = 0; i < 24; i += 1) {
      const colHeader = `${toString(i).padStart(2, '0')}\n${toString(i + 1).padStart(2, '0')}`;
      columns.push({ field: toString(i), headerName: colHeader, width: 5 });
    }
    return columns;
  }

  useEffect(() => {
    try {
      const rows = matrixToRows(parseTimedValue(timedValue));
      setTimedValueRows(rows);
    } catch (error) {
      // Unable to parse timed value, let's wait for the user to enter something pareable
    }
  }, [timedValue, setTimedValueRows]);
  const timedValueColumns = createHourColumns();

  return (
    <Popper
      id="inner-timed-value-preview"
      anchorEl={anchorEl}
      open={timedValue != null}
      sx={{ 'z-index': 1500 }}
    >
      <Box sx={{ marginTop: '4rem', height: 270, width: 1350, bgcolor: 'background.paper' }}>
        <DataGrid
          headerHeight={50}
          getRowHeight={() => 'auto'}
          hideFooter
          columns={timedValueColumns}
          rows={timedValueRows}
          sx={{
            '& .MuiDataGrid-columnHeaderTitle': {
              textOverflow: 'clip',
              whiteSpace: 'break-spaces',
              lineHeight: 1,
            },
          }}
        />
      </Box>
    </Popper>
  );
}
TimedValuePreview.propTypes = {
  timedValue: PropTypes.string,
  anchorEl: PropTypes.object,
};
