const NEM_REQUIRED_CAPACITY_MULTIPLIER = 0.34;

type FcrnPowerConfig = {
  max_fcrn_power: string | null;
  max_fcrn_discharge_power: string | null;
};

/** FCR-N capacity per device, in kW.
 * This is the average of the charge and discharge power minus the NEM headroom.
 */
export function calculateDeviceFcrnCapacity(
  emsOptimizerConfiguration: FcrnPowerConfig | undefined,
): number {
  if (!emsOptimizerConfiguration) {
    return 0;
  }
  const charge = parseInt(emsOptimizerConfiguration.max_fcrn_power ?? 'NaN', 10);
  const discharge = parseInt(emsOptimizerConfiguration.max_fcrn_discharge_power ?? 'NaN', 10);
  if (!Number.isFinite(charge) || !Number.isFinite(discharge)) {
    return 0;
  }
  const maxSymmetricPower = Math.floor((charge + discharge) / 2);
  const capacity = Math.floor(maxSymmetricPower / (1 + NEM_REQUIRED_CAPACITY_MULTIPLIER));
  return capacity;
}

export function calculateTotalFcrnCapacity(
  data: (FcrnPowerConfig | undefined)[],
): string | undefined {
  const totalFcrnCapacity = data.reduce((acc, row) => acc + calculateDeviceFcrnCapacity(row), 0);
  const totalFcrnCapacityMW = totalFcrnCapacity / 1000;
  return totalFcrnCapacityMW.toFixed(3);
}
