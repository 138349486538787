import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Card,
  CircularProgress,
  Tooltip,
  Typography,
} from '@mui/material';
import { ArrowDropDown as ArrowDropDownIcon, ErrorOutline } from '@mui/icons-material';
import { DataGrid, type GridColDef, type GridValueFormatterParams } from '@mui/x-data-grid';
import useSWRImmutable from 'swr/immutable';

import { fetcher } from '../../../http';
import { formatDateTime } from '../../../utils/time';
import { type DataFrame, type Field, type Schema } from '../../../utils/timeseries';
import { checkIntegralDecimal } from '../../../utils/parsing';

type FCRNResult = {
  region_id: string;
  warnings: string[];
  allocations_df: DataFrame;
};

export type FCRNDetailsProps = {
  runID: string;
  esusByID: Map<string, { name: string }>;
};

export function FCRNDetails(props: FCRNDetailsProps) {
  const { runID, esusByID } = props;

  const key = `/v1/optimizer/run/${runID}/fcr-allocations.json`;
  const { data, error, isLoading } = useSWRImmutable<{ results: { [region: string]: FCRNResult } }>(
    key,
    { fetcher },
  );

  const fcrAllocations = data?.results ?? {};

  function nameFor(column: string): string {
    if (column in NAMES) return NAMES[column];
    return esusByID.get(column)?.name ?? column;
  }

  function columnsFor(schema: Schema): GridColDef[] {
    return schema.fields.map((field: Field) => ({
      field: field.name,
      headerName: nameFor(field.name),
      sortable: false,
      headerClassName: esusByID.has(field.name) ? 'text-vertical' : 'text-horizontal-bottom',
      width: WIDTHS[field.name] ?? 65, // TODO Base on something
      valueFormatter: formatterFor(field.name),
    }));
  }

  return (
    <Box>
      <Card sx={{ p: 2, mt: 2, borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}>
        <Typography variant="h4" color="primary">
          FCR-N Allocations
          {isLoading && <CircularProgress size={15} sx={{ ml: 1.5 }} />}
          {error != null && (
            <Tooltip title="Error loading FCR-N Allocations." placement="right">
              <ErrorOutline
                color="error"
                fontSize="inherit"
                sx={{ ml: 1.2, position: 'relative', top: 2 }}
              />
            </Tooltip>
          )}
        </Typography>
      </Card>
      {Object.entries(fcrAllocations).map(([region, allocation]) => (
        <Accordion key={region}>
          <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
            <Typography variant="data">{region}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box
              sx={{
                '& .text-vertical': {
                  writingMode: 'vertical-lr',
                  transform: 'rotate(-180deg)',
                  height: 200,
                },
                '& .text-horizontal-bottom .MuiDataGrid-columnHeaderTitleContainer': {
                  flexFlow: 'column-reverse',
                },
              }}
            >
              <DataGrid
                columnHeaderHeight={200}
                rows={allocation.allocations_df.data}
                columns={columnsFor(allocation.allocations_df.schema)}
                getRowId={(row) => row[allocation.allocations_df.schema.primaryKey]}
                autoHeight
                initialState={{ pagination: { paginationModel: { page: 1, pageSize: 48 } } }}
                pageSizeOptions={[48]}
                sx={{
                  '& .MuiDataGrid-columnHeaderTitle': {
                    textOverflow: 'clip',
                    whiteSpace: 'break-spaces',
                    lineHeight: 1,
                  },
                }}
              />
            </Box>

            {allocation.warnings.length !== 0 && (
              <Box paddingTop={2}>
                <Typography variant="subtitle2">Warnings</Typography>
                {allocation.warnings.map((warning) => (
                  <Box key={warning} paddingTop={1}>
                    <Alert severity="warning">{warning}</Alert>
                  </Box>
                ))}
              </Box>
            )}
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
}

const WIDTHS: { [column: string]: number } = {
  index: 235,
  time: 235,
  hourly_price: 100,
  duration: 100,
  allocation: 100,
  fcr_allocated: 100,
  fcr_pending: 100,
  assigned_allocation: 100,
};
const NAMES: { [column: string]: string } = {
  index: 'Time',
  time: 'Time',
  hourly_price: 'Price (€ / kW)',
  duration: 'Duration (s)',
  allocation: 'Allocation (kW)',
  fcr_allocated: 'FCR-N allocated',
  fcr_pending: 'FCR-N pending',
  assigned_allocation: 'Assigned allocation (kW)',
};

const FORMATTERS: Record<string, (p: GridValueFormatterParams) => string> = {
  index: (p: GridValueFormatterParams) => formatDateTime(p.value),
  time: (p: GridValueFormatterParams) => formatDateTime(p.value),
  duration: (p: GridValueFormatterParams) => Math.floor(p.value).toString(),
};

function formatterFor(column: string): (p: GridValueFormatterParams) => string {
  const defaultFormatter = (p: GridValueFormatterParams) =>
    checkIntegralDecimal(p.value) ?? p.value;
  return FORMATTERS[column] ?? defaultFormatter;
}
