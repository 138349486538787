import { type RouteObject, createBrowserRouter } from 'react-router-dom';

import { USER_ROLES } from '~constants/auth';

import { preloadEdgeControllerList } from '~hooks/useEdgeControllerList';
import { preloadESUList } from '~hooks/useESUList';
import { preloadMeteringGroupList } from '~hooks/useMeteringGroupList';
import { preloadOrganizationList } from '~hooks/useOrganizationList';
import { preloadReserveObjects } from '~hooks/useReserveObjects';

import { DashboardLayout } from '~layouts/dashboard';
import { EMSDashboardLayout } from '~layouts/dashboard/EMSDashboardLayout';
import { LogoOnlyLayout } from '~layouts/LogoOnlyLayout';
import { ForceMfaBouncer } from '~layouts/ForceMfaBouncer';
import { AppLayout } from '~layouts/AppLayout';
import { AuthenticationBouncer } from '~layouts/AuthenticationBouncer';
import { RoleBouncer } from '~layouts/RoleBouncer';

import { CustomerSchedule } from '~pages/CustomerSchedule';
import { DashboardApp } from '~pages/dashboard/DashboardApp';
import { EdgeControllerDetailPage } from '~pages/fleet/controllers/EdgeControllerDetailPage';
import { EdgeControllerDetails } from '~pages/fleet/controllers/EdgeControllerDetails';
import { EdgeControllerDiagnostics } from '~pages/fleet/controllers/EdgeControllerDiagnostics';
import { EdgeControllerList } from '~pages/fleet/controllers/EdgeControllerList';
import { ESUControl } from '~pages/fleet/esus/control/ESUControl';
import { ESUDetails } from '~pages/fleet/esus/ESUDetails';
import { ESUDiagnostics } from '~pages/fleet/esus/ESUDiagnostics';
import { ESUList } from '~pages/fleet/esus/ESUList';
import { Events } from '~pages/fleet/esus/Events';
import { Schedule } from '~pages/fleet/esus/schedule/Schedule';
import { FCR } from '~pages/fleet/fcr';
import { FleetOverview } from '~pages/fleet/FleetOverview';
import { Optimizer } from '~pages/fleet/optimizer/Optimizer';
import { Login } from '~pages/login';
import { NotFound } from '~pages/NotFound';
import { Reports } from '~pages/Reports';
import { EMSOptimizerConfiguration } from '~pages/settings/esus/EMSOptimizerConfiguration';
import { ESUSettings } from '~pages/settings/esus/ESUSettings';
import { ESUSettingsList } from '~pages/settings/esus/ESUSettingsList';
import { GroupMemberList } from '~pages/settings/groups/GroupMemberList';
import { GroupOptimizerConfiguration } from '~pages/settings/groups/GroupOptimizerConfiguration';
import { GroupSettings } from '~pages/settings/groups/GroupSettings';
import { GroupSettingsList } from '~pages/settings/groups/GroupSettingsList';
import { Pricing } from '~pages/settings/groups/pricing/Pricing';
import { TopologyEditor } from '~pages/settings/groups/TopologyEditor';
import { OrganizationSettings } from '~pages/settings/organizations/OrganizationSettings';
import { OrganizationSettingsList } from '~pages/settings/organizations/OrganizationSettingsList';
import { Signup } from '~pages/Signup';
import { UserSettings } from '~pages/user';
import { TimeSeriesExplorer } from '~pages/experimental/explorer/TimeSeriesExplorer';
import { RootRedirect } from '~pages/v2/RootRedirect';
import { Fleets } from '~pages/v2/fleets/Fleets';
import { FleetRedirect } from '~pages/v2/fleets/components/FleetRedirect';
import { FleetDashboard } from '~pages/v2/fleets/components/FleetDashboard';
import { FleetsMarket } from '~pages/v2/fleets/components/FleetMarket';
import { FleetsDiagnostics } from '~pages/v2/fleets/components/FleetDiagnostics';
import { Organizations } from '~pages/v2/organizations/Organizations';
import { Organization } from '~pages/v2/organizations/Organization';
import { OrganizationRedirect } from '~pages/v2/organizations/components/OrganizationRedirect';
import { OrganizationSettings as OrganizationSettingsV2 } from '~pages/v2/organizations/components/OrganizationSettings';
import { Sites } from '~pages/v2/sites/Sites';
import { Site } from '~pages/v2/sites/Site';
import { SiteRedirect } from '~pages/v2/sites/components/SiteRedirect';
import { SiteDashboard } from '~pages/v2/sites/components/SiteDashboard';
import { SiteConfig } from '~pages/v2/sites/components/SiteConfig';
import { SiteESUs } from '~pages/v2/sites/components/SiteESUs';
import { SitePricing } from '~pages/v2/sites/components/SitePricing';
import { SiteTopology } from '~pages/v2/sites/components/SiteTopology';
import { Controllers } from '~pages/v2/controllers/Controllers';
import { Controller } from '~pages/v2/controllers/Controller';
import { ControllerRedirect } from '~pages/v2/controllers/components/ControllerRedirect';
import { ControllerDiagnostics } from '~pages/v2/controllers/components/ControllerDiagnostics';
import { ControllerSettings } from '~pages/v2/controllers/components/ControllerSettings';
import { ControllerEvents } from '~pages/v2/controllers/components/ControllerEvents';
import { ESUs } from '~pages/v2/esus/ESUs';
import { ESU } from '~pages/v2/esus/ESU';
import { ESURedirect } from '~pages/v2/esus/components/ESURedirect';
import { ESUDashboard } from '~pages/v2/esus/components/ESUDashboard';
import { ESUDiagnostics as ESUDiagnosticsV2 } from '~pages/v2/esus/components/ESUDiagnostics';
import { ESUControl as ESUControlV2 } from '~pages/v2/esus/components/ESUControl';
import { ESUSchedule } from '~pages/v2/esus/components/ESUSchedule';
import { ESUReports } from '~pages/v2/esus/components/ESUReports';
import { ESUSettings as ESUSettingsV2 } from '~pages/v2/esus/components/ESUSettings';
import { AppRedirect } from '~pages/v2/AppRedirect';

import { App } from './App';

export const routerConfiguration: RouteObject[] = [
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: '/dashboard',
        element: (
          <AuthenticationBouncer>
            <ForceMfaBouncer>
              <DashboardLayout />
            </ForceMfaBouncer>
          </AuthenticationBouncer>
        ),
        children: [
          {
            path: '',
            element: <EMSDashboardLayout />,
            children: [
              { path: '', element: <DashboardApp /> },
              { path: 'app', element: <DashboardApp /> },
              { path: 'schedule', element: <CustomerSchedule /> },
              { path: 'reports', element: <Reports /> },
              { path: ':esuId/app', element: <DashboardApp /> },
              { path: ':esuId/reports', element: <Reports /> },
              { path: ':esuId/schedule', element: <CustomerSchedule /> },
            ],
          },
          // Fleet
          {
            path: 'overview',
            element: <FleetOverview />,
            loader: preloadESUList,
          },
          {
            path: 'esus',
            element: <ESUList />,
            loader: preloadESUList,
            children: [
              {
                path: ':esuId',
                element: <ESUDetails />,
                children: [
                  { path: 'diagnostics', element: <ESUDiagnostics /> },
                  { path: 'control', element: <ESUControl /> },
                  { path: 'schedule', element: <Schedule /> },
                  { path: 'events', element: <Events /> },
                ],
              },
            ],
          },
          {
            path: 'controllers',
            element: <EdgeControllerList />,
            loader: preloadEdgeControllerList,
            children: [
              {
                path: ':edgeControllerId',
                element: <EdgeControllerDetails />,
                children: [
                  { path: 'details', element: <EdgeControllerDetailPage /> },
                  { path: 'diagnostics', element: <EdgeControllerDiagnostics /> },
                  { path: 'events', element: <Events /> },
                ],
              },
            ],
          },
          {
            path: 'optimizer',
            element: <Optimizer />,
            children: [{ path: ':optimizerRunId', element: <Optimizer /> }],
          },
          { path: 'fcr', element: <FCR /> },
          // User
          { path: 'user', element: <UserSettings /> },
          {
            path: 'experimental',
            children: [
              {
                path: 'timeseries_explorer',
                element: <TimeSeriesExplorer />,
              },
            ],
          },
        ],
      },
      // Settings
      {
        path: '/settings',
        element: (
          <AuthenticationBouncer>
            <ForceMfaBouncer>
              <DashboardLayout />
            </ForceMfaBouncer>
          </AuthenticationBouncer>
        ),
        children: [
          {
            path: 'groups',
            element: <GroupSettingsList />,
            loader: preloadMeteringGroupList,
            children: [
              {
                path: ':groupId',
                element: <GroupSettings />,
                children: [
                  {
                    path: 'config',
                    element: <GroupOptimizerConfiguration />,
                  },
                  {
                    path: 'topology',
                    element: <TopologyEditor />,
                  },
                  {
                    path: 'members',
                    element: <GroupMemberList />,
                  },
                  {
                    path: 'pricing',
                    element: <Pricing />,
                  },
                ],
              },
            ],
          },
          {
            path: 'esus',
            element: <ESUSettingsList />,
            loader: preloadESUList,
            children: [
              {
                path: ':esuId',
                element: <ESUSettings />,
                children: [
                  {
                    path: 'config',
                    element: <EMSOptimizerConfiguration />,
                  },
                ],
              },
            ],
          },
          {
            path: 'organizations',
            loader: preloadOrganizationList,
            element: <OrganizationSettingsList />,
            children: [
              {
                path: ':organizationId',
                element: <OrganizationSettings />,
              },
            ],
          },
        ],
      },
      {
        path: 'v2',
        element: (
          <AuthenticationBouncer>
            <RoleBouncer>
              <ForceMfaBouncer>
                <AppLayout />
              </ForceMfaBouncer>
            </RoleBouncer>
          </AuthenticationBouncer>
        ),
        children: [
          {
            index: true,
            element: <RootRedirect />,
          },
          {
            path: 'user',
            element: <UserSettings />,
          },
          {
            path: 'fleets',
            element: <Fleets />,
            handle: {
              permission: {
                rootRoleRequired: [USER_ROLES.OPERATOR_VIEW, USER_ROLES.FCR_BIDDING],
              },
            },
            children: [
              {
                index: true,
                element: <FleetRedirect />,
              },
              {
                path: 'dashboard',
                element: <FleetDashboard />,
                loader: preloadReserveObjects,
                handle: {
                  permission: {
                    rootRoleRequired: USER_ROLES.OPERATOR_VIEW,
                  },
                },
              },
              {
                path: 'market',
                element: <FleetsMarket />,
                handle: {
                  permission: {
                    rootRoleRequired: USER_ROLES.FCR_BIDDING,
                  },
                },
              },
              {
                path: 'diagnostics',
                element: <FleetsDiagnostics />,
                handle: {
                  permissition: {
                    rootRoleRequired: USER_ROLES.OPERATOR_VIEW,
                  },
                },
              },
            ],
          },
          {
            path: 'organizations',
            loader: preloadOrganizationList,
            handle: {
              permission: {
                rootRoleRequired: USER_ROLES.VIEW_ORGANIZATION,
              },
            },
            element: <Organizations />,
            children: [
              {
                path: ':organizationId',
                element: <Organization />,
                children: [
                  {
                    index: true,
                    element: <OrganizationRedirect />,
                  },
                  {
                    path: 'settings',
                    element: <OrganizationSettingsV2 />,
                  },
                ],
              },
            ],
          },
          {
            path: 'sites',
            loader: preloadMeteringGroupList,
            element: <Sites />,
            children: [
              {
                path: ':meteringGroupId',
                element: <Site />,
                children: [
                  {
                    index: true,
                    element: <SiteRedirect />,
                  },
                  {
                    path: 'dashboard',
                    element: <SiteDashboard />,
                  },
                  {
                    path: 'config',
                    element: <SiteConfig />,
                    handle: {
                      permission: {
                        rootRoleRequired: USER_ROLES.OPERATOR_VIEW,
                      },
                    },
                  },
                  {
                    path: 'esus',
                    element: <SiteESUs />,
                    handle: {
                      permission: {
                        rootRoleRequired: USER_ROLES.OPERATOR_VIEW,
                      },
                    },
                  },
                  {
                    path: 'topology',
                    element: <SiteTopology />,
                    handle: {
                      permission: {
                        rootRoleRequired: USER_ROLES.VIEW_TOPOLOGY,
                      },
                    },
                  },
                  {
                    path: 'pricing',
                    element: <SitePricing />,
                    handle: {
                      permission: {
                        rootRoleRequired: USER_ROLES.VIEW_PRICING,
                      },
                    },
                  },
                ],
              },
            ],
          },
          {
            path: 'controllers',
            loader: preloadEdgeControllerList,
            element: <Controllers />,
            handle: {
              permission: {
                roleRequired: [
                  USER_ROLES.EMS_DIAGNOSTIC_VIEW,
                  USER_ROLES.DEBUG_EMS,
                  USER_ROLES.OPERATOR_VIEW,
                ],
              },
            },
            children: [
              {
                path: ':edgeControllerId',
                element: <Controller />,
                children: [
                  {
                    index: true,
                    element: <ControllerRedirect />,
                  },
                  {
                    path: 'diagnostics',
                    element: <ControllerDiagnostics />,
                    handle: {
                      permission: {
                        roleRequired: USER_ROLES.EMS_DIAGNOSTIC_VIEW,
                      },
                    },
                  },
                  {
                    path: 'settings',
                    element: <ControllerSettings />,
                    handle: {
                      permission: {
                        roleRequired: USER_ROLES.OPERATOR_VIEW,
                      },
                    },
                  },
                  {
                    path: 'events',
                    element: <ControllerEvents />,
                    handle: {
                      permission: {
                        roleRequired: USER_ROLES.DEBUG_EMS,
                      },
                    },
                  },
                ],
              },
            ],
          },
          {
            path: 'esus',
            loader: preloadESUList,
            element: <ESUs />,
            children: [
              {
                path: ':esuId',
                element: <ESU />,
                children: [
                  {
                    index: true,
                    element: <ESURedirect />,
                  },
                  {
                    path: 'dashboard',
                    element: <ESUDashboard />,
                  },
                  {
                    path: 'diagnostics',
                    element: <ESUDiagnosticsV2 />,
                    handle: {
                      permission: {
                        roleRequired: USER_ROLES.EMS_DIAGNOSTIC_VIEW,
                      },
                    },
                  },
                  {
                    path: 'control',
                    element: <ESUControlV2 />,
                    handle: {
                      permission: {
                        roleRequired: [USER_ROLES.EDIT_EMS_SCHEDULE, USER_ROLES.EMS_DIAGNOSTIC_RUN],
                      },
                    },
                  },
                  {
                    path: 'schedule',
                    element: <ESUSchedule />,
                    handle: {
                      permission: {
                        rootRoleRequired: USER_ROLES.OPERATOR_VIEW,
                      },
                    },
                  },
                  {
                    path: 'reports',
                    element: <ESUReports />,
                    handle: {
                      permission: {
                        roleRequired: USER_ROLES.VIEW_REPORT,
                      },
                    },
                  },
                  {
                    path: 'settings',
                    element: <ESUSettingsV2 />,
                    handle: {
                      permission: {
                        rootRoleRequired: USER_ROLES.OPERATOR_VIEW,
                      },
                    },
                  },
                ],
              },
            ],
          },
          {
            path: 'optimizer',
            element: <Optimizer />,
            handle: {
              permission: {
                rootRoleRequired: USER_ROLES.OPERATOR_VIEW,
              },
            },
            children: [
              {
                path: ':optimizerRunId',
                element: <Optimizer />,
              },
            ],
          },
          {
            path: 'explorer',
            handle: {
              permission: {
                rootRoleRequired: USER_ROLES.OPERATOR_VIEW,
              },
            },
            element: <TimeSeriesExplorer />,
          },
        ],
      },
      {
        path: '/',
        element: <LogoOnlyLayout />,
        children: [
          { path: '/', element: <AppRedirect /> },
          { path: 'login', element: <Login /> },
          { path: '404', element: <NotFound /> },
          { path: 'signup/:inviteId/:confirmationToken', element: <Login /> },
          { path: 'signup/:inviteId', element: <Signup /> },
          { path: '*', element: <NotFound /> },
        ],
      },
    ],
  },
];

export const router = createBrowserRouter(routerConfiguration);
