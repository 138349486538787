import { Navigate } from 'react-router-dom';
import { useCallback } from 'react';

import { isRoutePermitted } from '~utils/isRoutePermitted';

import { useAuth } from '~hooks/useAuth';

import { NotFound } from '~pages/NotFound';

type Props<T extends string> = {
  tabs: Record<string, string>;
  currentTab: T;
  allowedTabs?: Record<string, boolean>;
  createPath: (tab: T) => string;
};

// Redirect to the first allowed tab
export function TabsRedirection<T extends string>({
  tabs,
  currentTab,
  allowedTabs,
  createPath,
}: Props<T>) {
  const { user } = useAuth();
  const tabKeys = Object.keys(tabs) as T[];

  const isAllowed = useCallback(
    (tabKey: T) => {
      return (
        isRoutePermitted(user, createPath(tabKey)) &&
        (typeof allowedTabs?.[tabKey] !== 'boolean' || allowedTabs[tabKey])
      );
    },
    [createPath, allowedTabs, user],
  );

  // If the current tab is allowed, redirect to it
  for (const tabKey of tabKeys) {
    if (currentTab === tabKey && isAllowed(tabKey)) {
      return <Navigate replace to={tabKey} />;
    }
  }

  // Otherwise redirect to the first allowed tab
  for (const tabKey of tabKeys) {
    if (isAllowed(tabKey)) {
      return <Navigate replace to={tabKey} />;
    }
  }

  return <NotFound />;
}
