import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';

import { configureDayjs } from '~utils/configureDayjs';
import { configureSentry } from '~utils/configureSentry';

import { router } from './router';

configureDayjs();
configureSentry();

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(<RouterProvider router={router} />);
