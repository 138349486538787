import { useCallback, useRef, useState } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { IconButton, InputAdornment, Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { http } from '~http';

import { getMe } from '~utils/user';

import { useAuth } from '~hooks/useAuth';
import { useAbortController } from '~hooks/useAbortController';

import { Iconify } from '~components/Iconify';

import { Terms } from './Terms';

const LoginSchema = Yup.object().shape({
  email: Yup.string().email('Email must be a valid email address').required('Email is required'),
  password: Yup.string().required('Password is required'),
  mfaToken: Yup.string().matches(/^[0-9]{6}$/, {
    message: 'Token must be 6 digits long',
  }),
});

export function LoginForm() {
  const [showPassword, setShowPassword] = useState(false);
  const mfaRequired = useRef(false);
  const [termsNotAccepted, setTermsNotAccepted] = useState(false);
  const [userlessToken, setUserlessToken] = useState();
  const { login, logout } = useAuth();
  const getSignal = useAbortController();

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      mfaToken: '',
      acceptTerms: '',
    },
    validationSchema: LoginSchema,
    onSubmit: () => postLogin(),
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setErrors } = formik;

  const acceptTerms = useCallback(
    (termsId) => {
      values.acceptTerms = termsId;
      setTermsNotAccepted(false);
    },
    [values],
  );

  const postLogin = useCallback(async () => {
    const postData = {
      email: values.email,
      password: values.password,
    };
    if (mfaRequired.current) {
      postData.mfa_token = values.mfaToken;
    }
    if (values.acceptTerms) {
      postData.accept_terms = values.acceptTerms;
    }
    try {
      await http.post('/v1/login', postData);
      const userData = await getMe(getSignal());
      login(userData);
    } catch (e) {
      const response = e.response ?? {};
      if (response.status === 403) {
        const reason = response.data?.reason;
        if (reason === 'mfa_token_required') {
          setErrors({ mfaToken: 'Two-factor authentication required' });
          mfaRequired.current = true;
          return;
        }
        if (reason === 'mfa_token_invalid') {
          setErrors({ mfaToken: 'Incorrect token supplied' });
          mfaRequired.current = true;
          return;
        }
        if (reason === 'terms_not_accepted') {
          setTermsNotAccepted(true);
          setUserlessToken(e.response.data.token);
          return;
        }
        setErrors({ password: 'Something went wrong, please try again' });
      } else if (response.status === 401) {
        setErrors({
          password:
            'Invalid username or password. If you have just registered, make sure you have opened the confirmation link we sent you by email.',
        });
      } else {
        setErrors({ password: 'Something went wrong, please try again' });
      }
      logout();
    }
  }, [mfaRequired, values, setErrors, getSignal, login, logout]);

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" onSubmit={handleSubmit}>
        <Stack spacing={3}>
          {!mfaRequired.current && !termsNotAccepted && (
            <>
              <TextField
                margin="dense"
                fullWidth
                autoComplete="username"
                type="email"
                label="Email address"
                {...getFieldProps('email')}
                error={Boolean(touched.email && errors.email)}
                helperText={(touched.email && errors.email) || ' '}
                autoFocus
              />

              <TextField
                margin="dense"
                fullWidth
                autoComplete="current-password"
                type={showPassword ? 'text' : 'password'}
                label="Password"
                {...getFieldProps('password')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleShowPassword} edge="end">
                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={Boolean(touched.password && errors.password)}
                helperText={(touched.password && errors.password) || ' '}
              />
            </>
          )}
          {mfaRequired.current && (
            <TextField
              margin="dense"
              fullWidth
              type="text"
              label="Two-factor authentication token"
              {...getFieldProps('mfaToken')}
              error={touched.mfaToken && Boolean(errors.mfaToken)}
              helperText={(touched.mfaToken && errors.mfaToken) || ' '}
              autoFocus
              inputProps={{
                pattern: '[0-9]*',
                maxLength: 6,
              }}
            />
          )}
          {termsNotAccepted && <Terms acceptTerms={acceptTerms} token={userlessToken} />}
          {!termsNotAccepted && (
            <LoadingButton
              margin="dense"
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              disabled={!formik.isValid}
            >
              Login
            </LoadingButton>
          )}
        </Stack>
      </Form>
    </FormikProvider>
  );
}
