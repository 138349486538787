import { useRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { alpha } from '@mui/material/styles';
import {
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';

import type { User } from '~types';

import { http } from '~http';

import { useAuth } from '~hooks/useAuth';
import { useLocalStorage } from '~hooks/useLocalStorage';

import { Iconify } from '~components/Iconify';

const MENU_OPTIONS = [
  {
    label: 'Settings',
    icon: 'eva:settings-2-fill',
    linkTo: '/dashboard/user',
  },
];

export function AccountPopover(props: {
  popoverOpen: boolean;
  onTogglePopover: () => void;
  onClosePopover: () => void;
}) {
  const anchorRef = useRef(null);
  const { popoverOpen } = props;
  const { logout } = useAuth();
  const [userData] = useLocalStorage<User | null>('user', null);

  const handleLogout = async () => {
    await http.get('/v1/logout', { responseType: 'text' });
    logout();
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        sx={{
          padding: 0,
          ...(popoverOpen && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
            },
          }),
        }}
        onClick={props.onTogglePopover}
      >
        <Avatar />
      </IconButton>

      <Menu
        open={popoverOpen}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
        onClose={props.onClosePopover}
        onClick={props.onClosePopover}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {userData?.email || ''}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        {MENU_OPTIONS.map((option) => (
          <MenuItem
            key={option.label}
            to={option.linkTo}
            component={RouterLink}
            sx={{ typography: 'body2', py: 1, px: 2.5 }}
          >
            <Iconify
              icon={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24,
              }}
            />

            {option.label}
          </MenuItem>
        ))}

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button fullWidth color="inherit" variant="outlined" onClick={handleLogout}>
            Logout
          </Button>
        </Box>
      </Menu>
    </>
  );
}
