import { useRegisterBreadcrumbs } from '~hooks/useBreadcrumbs';

import { PageLayout } from '~layouts/PageLayout';

import { ControllersList } from '~pages/v2/controllers/components/ControllersList';
import { createControllerPath } from '~pages/v2/controllers/utils/createControllerPath';

export function Controllers() {
  useRegisterBreadcrumbs('controllers', 'Controllers', createControllerPath());

  return (
    <PageLayout title="Controllers">
      <ControllersList />
    </PageLayout>
  );
}
