import { createContext, useContext } from 'react';

const CurrrentEdgeControllerIdContext = createContext<string | null>(null);

export const CurrentEdgeControllerIdContextProvider = CurrrentEdgeControllerIdContext.Provider;

export function useCurrentEdgeControllerId(): string {
  const id = useContext(CurrrentEdgeControllerIdContext);

  if (id == null) {
    throw new Error(
      'useCurrentEdgeControllerId must be used within a EdgeControllerContextProvider',
    );
  }

  return id;
}
