import { Schedule } from '~pages/fleet/esus/schedule/Schedule';

import { ScrollView } from '~components/ScrollView';

export function ESUSchedule() {
  return (
    <ScrollView>
      <Schedule />
    </ScrollView>
  );
}
