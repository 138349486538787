import { type MouseEvent, useCallback } from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { Forward10, Replay10 } from '@mui/icons-material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { Box, IconButton, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { addSeconds, differenceInSeconds, subSeconds } from 'date-fns';

import { type DateTimeRangeWithNow } from '~utils/time';

const MIN_DATE = new Date('2022');
const MAX_DATE = new Date('2030'); // Sorry, future bug fixer. But hey, you still have a job!
const PRESET_OPTIONS = [
  [3600, '1h'],
  [2 * 3600, '2h'],
  [6 * 3600, '6h'],
  [12 * 3600, '12h'],
  [24 * 3600, '1d'],
  [2 * 24 * 3600, '2d'],
  [3 * 24 * 3600, '3d'],
  [7 * 24 * 3600, '1w'],
];
const PRESET_VALUES = new Set(PRESET_OPTIONS.map(([seconds]) => seconds));

const SKIP_SECONDS = 10;

export type DateTimeRangePickerProps = {
  value: DateTimeRangeWithNow;
  onChange: (value: DateTimeRangeWithNow) => void;
  showSkipButtons?: boolean;
};

export function DateTimeRangePicker(props: DateTimeRangePickerProps) {
  const { value, onChange, showSkipButtons = false } = props;
  const duration = differenceInSeconds(value.end, value.start);
  const preset = PRESET_VALUES.has(duration) ? duration : '';

  const onPresetSelect = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      const element = event.currentTarget as HTMLButtonElement;
      const duration = parseInt(element.value, 10);
      onChange({ start: subSeconds(value.end, duration), end: value.end, now: value.now });
    },
    [onChange, value.end, value.now],
  );

  return (
    <Box display="flex" gap={1} flexWrap="wrap">
      <ToggleButton
        size="small"
        value="now"
        color="primary"
        selected={value.now}
        onChange={() => {
          const current = new Date();
          onChange({ start: subSeconds(current, duration), end: current, now: true });
        }}
      >
        Now
      </ToggleButton>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateTimePicker
          label="At time"
          value={value.end}
          onChange={(date: Date | null): void => {
            if (date) {
              const newDate = new Date(date);
              newDate.setMilliseconds(0);
              if (!showSkipButtons) newDate.setSeconds(0);
              onChange({ start: subSeconds(newDate, duration), end: newDate, now: false });
            }
          }}
          minDateTime={MIN_DATE}
          maxDateTime={MAX_DATE}
          ampm={false}
          disabled={false}
          format={showSkipButtons ? 'yyyy-MM-dd HH:mm:ss' : 'yyyy-MM-dd HH:mm'}
          viewRenderers={{
            hours: null,
            minutes: null,
            seconds: null,
          }}
          slotProps={{
            textField: {
              size: 'small',
            },
          }}
        />
      </LocalizationProvider>
      {showSkipButtons && (
        <>
          <IconButton
            onClick={() =>
              onChange({
                start: subSeconds(value.start, SKIP_SECONDS),
                end: subSeconds(value.end, SKIP_SECONDS),
                now: false,
              })
            }
          >
            <Replay10 />
          </IconButton>
          <IconButton
            onClick={() =>
              onChange({
                start: addSeconds(value.start, SKIP_SECONDS),
                end: addSeconds(value.end, SKIP_SECONDS),
                now: false,
              })
            }
          >
            <Forward10 />
          </IconButton>
        </>
      )}
      <ToggleButtonGroup
        color="primary"
        value={preset}
        onChange={onPresetSelect}
        exclusive
        size="small"
      >
        {PRESET_OPTIONS.map(([seconds, label]) => (
          <ToggleButton key={seconds} value={seconds}>
            {label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </Box>
  );
}
