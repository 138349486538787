import { useMatch, useNavigate, useParams } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  type MRT_ColumnDef,
  type MRT_ColumnSizingState,
  type MRT_TableInstance,
  MaterialReactTable,
  useMRT_Rows,
  useMaterialReactTable,
} from 'material-react-table';
import { Box, Button } from '@mui/material';

import { type MeteringGroup } from '~types';

import { USER_ROLES } from '~constants/auth';

import { collator } from '~utils/localization';

import { useLocalStorage } from '~hooks/useLocalStorage';
import { useMeteringGroupList } from '~hooks/useMeteringGroupList';
import { useAuth } from '~hooks/useAuth';
import { MeteringGroupContextProvider } from '~hooks/useMeteringGroupContext';
import { ResultListContextProvider } from '~hooks/useResultListContext';

import { GroupCreateModal } from '~pages/settings/groups/GroupCreateModal';
import { GroupNameHeader } from '~pages/settings/groups/GroupNameHeader';

import { SplitLayout } from '~components/SplitLayout';

function GroupNameFooter({ table }: { table: MRT_TableInstance<MeteringGroup> }) {
  const rows = useMRT_Rows(table);

  return <>{rows.length} groups</>;
}

const columns: MRT_ColumnDef<MeteringGroup>[] = [
  {
    header: 'Name',
    id: 'name',
    accessorFn: (row) => row.name,
    sortingFn: (a, b, columnId) => collator.compare(a.getValue(columnId), b.getValue(columnId)),
    Footer: GroupNameFooter,
  },
  {
    header: 'Organization',
    id: 'organization_name',
    accessorFn: (row) => row.organization_name,
    sortingFn: (a, b, columnId) => collator.compare(a.getValue(columnId), b.getValue(columnId)),
  },
];

export function GroupSettingsList() {
  const { groupId: currentGroupId } = useParams();
  const match = useMatch('/settings/groups/:groupId/:tab');
  const { user } = useAuth();
  const roles = user?.organizationsByRole || {};
  const defaultTab = USER_ROLES.OPERATOR_VIEW in roles ? 'config' : 'pricing';
  const canCreateNewGroup = USER_ROLES.EDIT_EMS in roles;
  const currentTab = match?.params.tab || defaultTab;
  const { data } = useMeteringGroupList();
  const meteringGroup = data?.find((group) => group.id === currentGroupId);
  const navigate = useNavigate();
  const rowSelection = useMemo(
    () => (currentGroupId ? { [currentGroupId]: true } : {}),
    [currentGroupId],
  );
  const [columnSizing, setColumnSizing] = useLocalStorage<MRT_ColumnSizingState>(
    'settings.groups.columnSizing',
    {},
  );
  const [isCreateGroupModalOpen, setIsCreateGroupModalOpen] = useState(false);

  const meteringGroupContextValue = useMemo(
    () =>
      currentGroupId
        ? {
            id: currentGroupId,
            name: meteringGroup?.name ?? '',
          }
        : null,
    [currentGroupId, meteringGroup],
  );

  const table = useMaterialReactTable<MeteringGroup>({
    data: data ?? [],
    columns,
    initialState: {
      density: 'compact',
    },
    state: {
      rowSelection,
      columnSizing: columnSizing ?? {},
    },
    onColumnSizingChange: setColumnSizing,
    enablePagination: false,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableColumnResizing: true,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableBottomToolbar: false,
    positionToolbarAlertBanner: 'none',
    getRowId: (row) => row.id,
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => {
        const groupId = row.id;
        if (groupId) {
          navigate(`/settings/groups/${groupId}/${currentTab}`);
        } else {
          navigate('/settings/groups');
        }
      },
      sx: { cursor: 'pointer' },
    }),
    muiTableContainerProps: { sx: { maxHeight: 'calc(100vh - 189px)' } },
    muiTablePaperProps: { sx: { borderRadius: 0 } },
    muiTableFooterProps: { sx: { minHeight: 60 } },
  });
  const rows = useMRT_Rows(table);
  const resultListContext = useMemo(() => {
    const currentRowIndex = currentGroupId
      ? rows.findIndex((row) => row.id === currentGroupId)
      : -1;
    const totalRowCount = rows.length;
    return {
      currentRowIndex,
      totalRowCount,
      goToNextResult: () => {
        if (currentRowIndex + 1 < totalRowCount) {
          const groupId = rows[currentRowIndex + 1].id;
          navigate(`/settings/groups/${groupId}/${currentTab}`);
        }
      },
      goToPreviousResult: () => {
        if (currentRowIndex > 0) {
          const groupId = rows[currentRowIndex - 1].id;
          navigate(`/settings/groups/${groupId}/${currentTab}`);
        }
      },
      goToResultList: () => navigate('/settings/groups'),
    };
  }, [rows, navigate, currentTab, currentGroupId]);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      // Ignore event if focus in within an input or textarea.
      if (['INPUT', 'TEXTAREA'].includes((event.target as Element).tagName)) {
        return;
      }
      if (event.key === 'Escape') {
        navigate('/settings/groups');
      } else if (event.key === 'j') {
        const nextIndex = rows.findIndex((row) => row.id === currentGroupId) + 1;
        if (nextIndex < rows.length) {
          const groupId = rows[nextIndex].id;
          navigate(`/settings/groups/${groupId}/${currentTab}`);
        }
      } else if (event.key === 'k') {
        const prevIndex = rows.findIndex((row) => row.id === currentGroupId) - 1;
        if (prevIndex >= 0) {
          const groupId = rows[prevIndex].id;
          navigate(`/settings/groups/${groupId}/${currentTab}`);
        }
      }
    },
    [rows, currentGroupId, navigate, currentTab],
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <>
      <MeteringGroupContextProvider value={meteringGroupContextValue}>
        <ResultListContextProvider value={resultListContext}>
          <SplitLayout showDetails={!!currentGroupId} renderTitle={() => <GroupNameHeader />}>
            {canCreateNewGroup && (
              <Box display="flex" justifyContent="flex-end" marginBottom={2} marginRight={2}>
                <Button onClick={() => setIsCreateGroupModalOpen(true)}>
                  Create metering group
                </Button>
              </Box>
            )}
            <MaterialReactTable table={table} />
          </SplitLayout>
        </ResultListContextProvider>
      </MeteringGroupContextProvider>
      <GroupCreateModal
        open={isCreateGroupModalOpen}
        onClose={() => setIsCreateGroupModalOpen(false)}
      />
    </>
  );
}
