import dayjs from 'dayjs';

import type { DateTimeRange } from '~utils/time';

import { ALLOCATION_TZ } from '~pages/v2/fleets/constants';

export function getTimeRange(): DateTimeRange {
  const start = dayjs().tz(ALLOCATION_TZ).startOf('day');
  let end = start.utc().add(48, 'hours').tz(ALLOCATION_TZ);
  const startUTCOffset = start.utcOffset();
  const endUTCOffset = end.utcOffset();

  if (startUTCOffset !== endUTCOffset) {
    // If the there's a DST change between start and end, we need to adjust the end time.
    // When DST starts, the day is 23 hours long, so we need to subtract an hour.
    // When DST ends, the day is 25 hours long, so we need to add an extra hour.
    end = end.add(startUTCOffset - endUTCOffset, 'minutes');
  }

  return {
    start: start.toDate(),
    end: end.toDate(),
  };
}
