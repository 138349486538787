import { OrganizationSettings as OrganizationSettingsV1 } from '~pages/settings/organizations/OrganizationSettings';

import { ScrollView } from '~components/ScrollView';

export function OrganizationSettings() {
  return (
    <ScrollView>
      <OrganizationSettingsV1 />
    </ScrollView>
  );
}
