import { type ReactNode } from 'react';
import { type NavLinkProps, NavLink as RouterLink, matchPath, useLocation } from 'react-router-dom';
import { alpha, styled, useTheme } from '@mui/material/styles';
import {
  Box,
  List,
  ListItemButton,
  type ListItemButtonProps,
  ListItemIcon,
  ListItemText,
} from '@mui/material';

import type { User } from '~types';

import { useAuth } from '~hooks/useAuth';

import { type SidebarConfigItem } from '~layouts/dashboard/sidebarConfig';

function hasRequiredRole(user: User | null, item: SidebarConfigItem): boolean {
  if (!user) {
    return false;
  }
  if (!item.roleRequired) {
    if (item.children) {
      return item.children.some((child: SidebarConfigItem) => hasRequiredRole(user, child));
    } else {
      return true;
    }
  }
  if (typeof item.roleRequired === 'function') {
    return item.roleRequired(user);
  }
  return !!user.organizationsByRole[item.roleRequired];
}

const ListItemStyle = styled((props: ListItemButtonProps & NavLinkProps) => (
  <ListItemButton disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  padding: theme.spacing(0, 3),
  color: theme.palette.text.secondary,
}));

const ListHeaderStyle = styled('div')(({ theme }) => ({
  ...theme.typography.body2,
  height: theme.spacing(3),
  lineHeight: theme.spacing(3),
  padding: theme.spacing(0, 3),
  margin: theme.spacing(1, 0),
  color: theme.palette.text.secondary,
  fontWeight: theme.typography.fontWeightBold,
}));

const Group = styled('div')(() => ({}));

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

type NavItemProps = {
  item: {
    title: string;
    path?: string;
    icon?: ReactNode;
    children?: any[];
  };
  active: (path: string) => boolean;
};

function NavItem({ item, active }: NavItemProps) {
  const theme = useTheme();
  const { user } = useAuth();

  const isActiveRoot = item.path ? active(item.path) : false;
  const { title, path, icon, children } = item;

  const activeRootStyle = {
    color: 'primary.main',
    fontWeight: 'fontWeightMedium',
    bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
    '&:before': { display: 'block' },
  };

  const activeSubStyle = {
    color: 'text.primary',
    fontWeight: 'fontWeightMedium',
  };

  if (children) {
    return (
      <Group>
        <ListHeaderStyle>{title}</ListHeaderStyle>

        <List component="div" disablePadding>
          {children
            .filter((item) => hasRequiredRole(user, item))
            .map((item) => {
              const { title, path, icon } = item;
              const isActiveSub = active(path);

              return (
                <ListItemStyle
                  key={title}
                  component={RouterLink}
                  to={path as string}
                  sx={{
                    ...(isActiveSub && activeSubStyle),
                  }}
                >
                  <ListItemIconStyle>{icon}</ListItemIconStyle>
                  <ListItemText disableTypography primary={title} />
                </ListItemStyle>
              );
            })}
        </List>
      </Group>
    );
  }

  return (
    <ListItemStyle
      component={RouterLink}
      to={path as string}
      sx={{
        ...(isActiveRoot && activeRootStyle),
      }}
    >
      <ListItemIconStyle>{icon}</ListItemIconStyle>
      <ListItemText disableTypography primary={title} />
    </ListItemStyle>
  );
}

export function NavSection({ navConfig, ...other }: { navConfig: SidebarConfigItem[] }) {
  const { pathname } = useLocation();
  const { user } = useAuth();

  const match = (path: string) => (path ? !!matchPath({ path, end: false }, pathname) : false);

  return (
    <Box {...other}>
      <List disablePadding>
        {navConfig.map((item) => {
          if (hasRequiredRole(user, item)) {
            return <NavItem key={item.title} item={item} active={match} />;
          }
          return null;
        })}
      </List>
    </Box>
  );
}
