import { type AxiosResponse } from 'axios';

import { BASE_URL, http } from '~http';

import type {
  Attachment,
  AttachmentContentMetadata,
  Pricing,
  PricingItem,
  SpotItem,
} from '~pages/settings/groups/pricing/types';

export function savePricing(
  pricingId: string | null,
  currency: string,
  meterGroupId: string,
  startTime: string | Date,
  importPricingItems: PricingItem[],
  exportPricingItems: PricingItem[],
  importSpotItems: SpotItem[],
  exportSpotItems: SpotItem[],
): Promise<AxiosResponse<Pricing>> {
  const energyItems = [...exportPricingItems, ...importPricingItems].map((item) => ({
    amount: item.amount,
    direction: item.direction.toLowerCase(),
    category: item.category.toLowerCase(),
    effective_period: item.effective_period || null,
    includes_vat: item.includes_vat,
    name: item.name,
  }));

  const spotItems = [...exportSpotItems, ...importSpotItems].map((item) => ({
    direction: item.direction.toLowerCase(),
    factor: item.factor,
    includes_vat: item.includes_vat,
  }));

  const request = {
    metering_group_id: meterGroupId,
    currency,
    energy_items: energyItems,
    spot_items: spotItems,
    start_time: new Date(startTime),
  };

  if (pricingId) {
    return http.put(`/v1/pricing/${pricingId}`, request);
  } else {
    return http.post('/v1/pricing', request);
  }
}

export function deletePricing(pricingId: string): Promise<AxiosResponse<Pricing>> {
  return http.delete(`/v1/pricing/${pricingId}`);
}

export function deleteAttachment(attachmentId: string): Promise<AxiosResponse<Attachment>> {
  return http.delete(`/v1/pricing/attachment/${attachmentId}`);
}

export function updateAttachment(
  attachmentId: string,
  metadata: AttachmentContentMetadata,
): Promise<AxiosResponse<Attachment>> {
  return http.put(`/v1/pricing/attachment/${attachmentId}`, {
    ...metadata,
    direction: metadata.direction.map((direction) => direction.toLowerCase()),
    category: metadata.category.map((category) => category.toLowerCase()),
  });
}

export async function savePricingAttachments(pricingId: string, files: Attachment[]) {
  const formData = new FormData();

  files.forEach((f) => {
    if (!f.content_metadata) {
      throw new Error('content_metadata not defined');
    }

    formData.append(
      `metadata/${f.content_metadata.name}`,
      JSON.stringify({
        ...f.content_metadata,
        direction: f.content_metadata.direction.map((direction) => direction.toLowerCase()),
        category: f.content_metadata.category.map((category) => category.toLowerCase()),
      } as AttachmentContentMetadata),
    );

    if (f.file) {
      formData.append(`file/${f.content_metadata.name}`, f.file);
    }
  });

  await http.post(`/v1/pricing/${pricingId}/attachment`, formData);
}

export async function downloadingPricingAttachment(attachmentId: string, attachmentName: string) {
  const response = await fetch(new URL(`v1/pricing/attachment/${attachmentId}`, BASE_URL));
  const downloadLink = document.createElement('a');
  const objectURL = URL.createObjectURL(await response.blob());
  try {
    downloadLink.href = objectURL;
    downloadLink.download = attachmentName;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  } finally {
    URL.revokeObjectURL(objectURL);
  }
}
