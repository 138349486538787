import { useCallback, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { ErrorMessage, Form, Formik } from 'formik';

import type { UserRole } from '~types';

import { asCactosError, http } from '~http';

import { useOrganization } from '~hooks/useOrganization';
import { useCurrentOrganizationId } from '~hooks/useCurrentOrganizationId';
import { useOrganizationRoles } from '~hooks/useOrganizationRoles';

import { printRole } from '~pages/settings/organizations/utils';

import { ErrorContainer } from '~components/ErrorContainer';

type Props = {
  open: boolean;
  onClose: () => void;
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export function OrganizationUserInviteModal({ open, onClose }: Props) {
  const organizationId = useCurrentOrganizationId();
  const { mutate } = useOrganization(organizationId);
  const { data: roles } = useOrganizationRoles(organizationId);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleClose = useCallback(() => {
    setError(null);
    onClose();
  }, [onClose]);

  const handleInvite = useCallback(
    async (email: string, roles: UserRole[]) => {
      if (isLoading) return;

      setIsLoading(true);
      setError(null);

      try {
        await http.post(`/v1/organization/${organizationId}/invite`, { email, roles });
        await mutate();

        handleClose();
      } catch (error: any) {
        setError(asCactosError(error).message ?? 'An error occurred while sending the invitation');
      }

      setIsLoading(false);
    },
    [isLoading, organizationId, mutate, handleClose],
  );

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Invite user</DialogTitle>
      <DialogContent>
        <DialogContentText>Invite a new user to your organization</DialogContentText>
        <Formik
          initialValues={{ email: '', roles: [] as UserRole[] }}
          onSubmit={async (values, { setSubmitting }) => {
            await handleInvite(values.email, values.roles);
            setSubmitting(false);
          }}
        >
          {({ values, handleChange, handleBlur }) => (
            <Form id="invite-user-form">
              <TextField
                fullWidth
                autoFocus
                required
                margin="dense"
                id="name"
                name="email"
                label="Email Address"
                type="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
              />
              <ErrorMessage name="email" component={ErrorContainer} />
              <Box marginTop={2}>
                <FormControl fullWidth>
                  <InputLabel>Roles</InputLabel>
                  <Select
                    multiple
                    fullWidth
                    required
                    margin="dense"
                    id="roles"
                    name="roles"
                    label="Roles"
                    value={values.roles}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    input={<OutlinedInput label="Tag" />}
                    renderValue={(selected) => selected.map(printRole).join(', ')}
                    MenuProps={MenuProps}
                  >
                    {(roles ?? []).map((value) => (
                      <MenuItem key={value} value={value}>
                        <Checkbox checked={values.roles.includes(value)} />
                        <ListItemText primary={printRole(value)} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <ErrorMessage name="roles" component={ErrorContainer} />
            </Form>
          )}
        </Formik>
        {!!error && (
          <Box marginTop={2}>
            <ErrorContainer>{error}</ErrorContainer>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <LoadingButton type="submit" form="invite-user-form" loading={isLoading}>
          Invite
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
