import { useMemo } from 'react';

import { calculateTotalFcrnCapacity } from '~utils/esu';

import { useTimeseriesLatest } from '~hooks/timeseries';
import { createLatestQuery } from '~hooks/timeseries/queries';
import { useESUList } from '~hooks/useESUList';

export function useTotalFcrnCapacity(): {
  data: string | undefined;
  error: any;
  isLoading: boolean;
} {
  const { data: esus } = useESUList('fleet');
  const query = createLatestQuery({
    resources: (esus ?? []).map((esu) => esu.resource_id),
    columns: {
      ems_optimizer_configuration: ['max_fcrn_power', 'max_fcrn_discharge_power'],
    },
  });
  const { data, error, isLoading } = useTimeseriesLatest(query, {
    revalidateOnFocus: false,
  });
  const totalFcrnCapacity = useMemo(() => {
    if (!esus || !data) {
      return undefined;
    }
    const rows = esus.map((esu) => data?.[esu.resource_id]?.ems_optimizer_configuration);
    return calculateTotalFcrnCapacity(rows);
  }, [esus, data]);

  return { data: totalFcrnCapacity, error, isLoading };
}
