import type { FormikProps } from 'formik';

import type { PricingWizardSchemaType } from '~pages/settings/groups/pricing/wizard/PricingWizardSchema';

import { ErrorContainer } from '~components/ErrorContainer';

type Props = {
  formik: FormikProps<PricingWizardSchemaType>;
  name: keyof PricingWizardSchemaType;
};

export function ErrorMessage({ formik, name }: Props) {
  const error = formik.errors[name];

  if (!error) return null;

  if (typeof error === 'string') {
    return <ErrorContainer>{error}</ErrorContainer>;
  }

  return (
    <ErrorContainer>
      {Object.entries(error).map(([key, value]) => (
        <div key={key}>{Object.values(value)}</div>
      ))}
    </ErrorContainer>
  );
}
