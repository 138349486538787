import { useCallback, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import * as _ from 'lodash-es';
import useSWR from 'swr';

import { asCactosError, fetcher } from '~http';

import {
  CUSTOMER_SCHEDULE_COLUMNS,
  type ScheduleRowWithTime,
  TIME_COLUMN,
  toScheduleRowWithTime,
} from '~constants/schedule';

import { useCurrentESUId } from '~hooks/useCurrentESUId';
import { useESU } from '~hooks/useESUList';
import { useScheduleHistory } from '~hooks/useScheduleHistory';

import { Scrollbar } from '~components/Scrollbar';
import { Page } from '~components/Page';

export function CustomerSchedule() {
  const esuId = useCurrentESUId();
  const esuName = useESU(esuId).data?.name;
  const [showEarlyHistory, setShowEarlyHistory] = useState<boolean>(false);

  const {
    data: scheduleHistory,
    error: scheduleHistoryError,
    isLoading: isLoadingScheduleHistory,
  } = useScheduleHistory(esuId);

  const {
    data: computedScheduleResponse,
    error: computedScheduleError,
    isLoading: isLoadingComputedSchedule,
  } = useSWR(`/v1/ems/${esuId}/computed_schedule`, {
    fetcher,
    refreshInterval: 60_000,
    revalidateOnFocus: false,
  });
  const computedSchedule: ScheduleRowWithTime[] = useMemo(
    () => computedScheduleResponse?.data?.map(toScheduleRowWithTime) ?? [],
    [computedScheduleResponse],
  );

  const isLoading = isLoadingScheduleHistory || isLoadingComputedSchedule;

  const RECENT_HISTORY_SIZE = 10;
  const earlyHistory = (history: ScheduleRowWithTime[]) =>
    _.dropRight(history, RECENT_HISTORY_SIZE);
  const recentHistory = (history: ScheduleRowWithTime[]) =>
    _.takeRight(history, RECENT_HISTORY_SIZE);
  const allColumns = CUSTOMER_SCHEDULE_COLUMNS.length + 1;

  const scheduleRows = useCallback(
    (schedule: ScheduleRowWithTime[], loadError?: unknown, replaceFirstTime?: string) => {
      if (!loadError) {
        return schedule.map((row, idx) => (
          <TableRow key={idx} tabIndex={-1}>
            <TableCell key="time" align="left">
              {(idx === 0 && replaceFirstTime) || TIME_COLUMN.formatter(row.time, false)}
            </TableCell>
            {CUSTOMER_SCHEDULE_COLUMNS.map((column) => {
              const value = row[column.id];
              return (
                <TableCell key={column.id} align="left">
                  {column.formatter ? column.formatter(value) : value}
                </TableCell>
              );
            })}
          </TableRow>
        ));
      }
      return (
        <TableRow>
          <TableCell colSpan={allColumns}>
            <Typography>{asCactosError(loadError).message}</Typography>
          </TableCell>
        </TableRow>
      );
    },
    [allColumns],
  );

  return (
    <Page title={esuName != null ? `${esuName} Schedule` : 'Schedule'}>
      <Container maxWidth="xl">
        <Typography variant="h3">{esuName != null ? `${esuName} Schedule` : 'Schedule'}</Typography>
        {isLoading && (
          <Box display="flex" flexDirection="row" justifyContent="center">
            <CircularProgress size={36} />
          </Box>
        )}

        <Card>
          <Scrollbar sx={{}}>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell key="time" align="left" width={250}>
                      {TIME_COLUMN.label}
                    </TableCell>
                    {CUSTOMER_SCHEDULE_COLUMNS.map((column) => (
                      <TableCell key={column.id} align="left">
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={allColumns}>
                      <Box display="flex" flexDirection="row" justifyContent="center">
                        <Button onClick={() => setShowEarlyHistory(!showEarlyHistory)}>
                          Show {showEarlyHistory ? 'less' : 'more'} history
                        </Button>
                      </Box>
                    </TableCell>
                  </TableRow>
                  {showEarlyHistory && scheduleRows(earlyHistory(scheduleHistory), undefined)}
                  {scheduleRows(recentHistory(scheduleHistory), scheduleHistoryError)}
                  <TableRow>
                    <TableCell colSpan={allColumns}>
                      <Typography>Planned schedule</Typography>
                    </TableCell>
                  </TableRow>

                  {scheduleRows(computedSchedule, computedScheduleError, 'Current')}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
        </Card>
      </Container>
    </Page>
  );
}
