import { useGroupedFleetDiagnostics } from '~hooks/useGroupedFleetDiagnostics';

import { FleetTemperatureHistogram } from '~components/charts/FleetTemperatureHistogram';

export function AmbientTemperatures() {
  const {
    data: fleetDiagnosticsData,
    isLoading: fleetDiagnosticsLoading,
    error: fleetDiagnosticsError,
  } = useGroupedFleetDiagnostics();

  return (
    <FleetTemperatureHistogram
      title="Ambient temperatures"
      data={fleetDiagnosticsData.byMaxAmbientTemperature}
      isLoading={fleetDiagnosticsLoading}
      error={fleetDiagnosticsError}
      height={250}
    />
  );
}
