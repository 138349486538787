import { Box, useTheme } from '@mui/material';

import { DIAGNOSTICS_SELECTOR_BAR_Z_INDEX } from '~theme/index';

import { useCurrentEdgeControllerId } from '~hooks/useCurrentEdgeControllerId';
import { useEdgeController } from '~hooks/useEdgeControllerList';
import { useURLRange } from '~hooks/useURLRange';
import { useResponsive } from '~hooks/useResponsive';

import { DiagnosticsPanel } from '~pages/fleet/diagnostics/DiagnosticsPanel';
import { useEdgeControllerDiagnosticsData } from '~pages/fleet/diagnostics/useDiagnosticsData';

import { DateTimeRangePicker } from '~components/DateTimeRangePicker';
import { Page } from '~components/Page';
import { CopyLinkButton } from '~components/CopyLinkButton';
import { CopyableID } from '~components/CopyableID';

export function EdgeControllerDiagnostics() {
  const id = useCurrentEdgeControllerId();
  const name = useEdgeController(id)?.data?.name ?? '';
  const theme = useTheme();

  const stickySelectorBar = useResponsive('up', 'sm');

  const [range, setRange] = useURLRange(2 * 3600);
  const atTime: Date | 'now' = range.now ? 'now' : range.end;

  const { data, isLoading, isValidating, error } = useEdgeControllerDiagnosticsData(id, atTime);

  return (
    <Page title={`${name} Diagnostics`}>
      <Box paddingX={3}>
        <Box
          position={stickySelectorBar ? 'sticky' : 'static'}
          top={0}
          paddingTop={2}
          paddingBottom={2}
          zIndex={DIAGNOSTICS_SELECTOR_BAR_Z_INDEX}
          bgcolor={theme.palette.background.default}
          sx={{ opacity: 0.9 }}
        >
          <DateTimeRangePicker value={range} onChange={setRange} showSkipButtons={true} />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          flexWrap="wrap"
          alignItems="flex-start"
          justifyContent="space-between"
          marginBottom={1}
        >
          <CopyableID id={id} />
          <CopyLinkButton
            link={
              `${window.location.origin}${window.location.pathname}` +
              `?start=${range.start.toISOString()}&end=${range.end.toISOString()}`
            }
          />
        </Box>
        <DiagnosticsPanel
          data={data}
          isLoading={isLoading}
          isValidating={isValidating}
          error={error}
          viewingHistoricalData={atTime !== 'now'}
        />
      </Box>
    </Page>
  );
}
