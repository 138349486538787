import { ESUDiagnostics as ESUDiagnosticsContent } from '~pages/fleet/esus/ESUDiagnostics';

import { ScrollView } from '~components/ScrollView';

export function ESUDiagnostics() {
  return (
    <ScrollView>
      <ESUDiagnosticsContent />
    </ScrollView>
  );
}
