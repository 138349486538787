import { useCallback, useEffect, useState } from 'react';
import { Alert, AlertTitle } from '@mui/material';
import dayjs from 'dayjs';

import { type CactosError, asCactosError, http } from '../../../http';

const TEST_CYCLE_REFRESH_INTERVAL = 10_000; // ms

type TestCycleData = {
  id: string;
  start: string;
  end: string;
  mode: 'NO_OP' | 'STOP' | 'CACTOS_POWERSTEP' | 'ENTSOE_NSA_SINE' | 'ENTSOE_NSA_FIXED_FREQUENCY';
  parameter: number;
};

export function TestCyclePanel({ esuID }: { esuID: string }) {
  const { data } = useTestCycleData(esuID);

  if (data == null || data.end != null) {
    return null;
  }

  return (
    <Alert severity="warning" sx={{ marginBottom: 1 }}>
      <AlertTitle>Prequalification test cycle in progress</AlertTitle>
      {formatCycle(data)} since{' '}
      <span style={{ whiteSpace: 'nowrap' }}>
        {dayjs(data.start).format('YYYY-MM-DD HH:mm:ss')}
      </span>
    </Alert>
  );
}

function formatCycle(data: TestCycleData) {
  switch (data.mode) {
    case 'NO_OP':
      return 'No-op';
    case 'STOP':
      return 'Stop';
    case 'CACTOS_POWERSTEP':
      return 'Power step test';
    case 'ENTSOE_NSA_SINE':
      return `Sine test (${data.parameter} s period)`;
    case 'ENTSOE_NSA_FIXED_FREQUENCY':
      return `Fixed frequency (${data.parameter / 1000} Hz)`;
    default:
      return data.mode;
  }
}

function useTestCycleData(emsId: string | null) {
  const [status, setStatus] = useState<'idle' | 'loading' | 'error'>('idle');
  const [data, setData] = useState<TestCycleData | null>(null);
  const [error, setError] = useState<CactosError | null>(null);

  const fetchData = useCallback(async () => {
    if (!emsId) {
      return;
    }
    try {
      setStatus('loading');

      const response = await http.get(`/v1/fcr_prequalification_testing/list/${emsId}?limit=1`);
      setData(response.data[0]);
      setError(null);
      setStatus('idle');
    } catch (e) {
      const error = asCactosError(e);
      console.error('Error fetching run data', e);
      setError(error);
    }
  }, [emsId]);

  useEffect(() => {
    fetchData();
    const intervalId = setInterval(() => fetchData(), TEST_CYCLE_REFRESH_INTERVAL);
    return () => {
      clearInterval(intervalId);
    };
  }, [fetchData]);

  return { data, status, error, forceFetch: fetchData };
}
