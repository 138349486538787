import { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Container,
  Grid,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import axios from 'axios';

import { http } from '~http';

import { useAbortController } from '~hooks/useAbortController';
import { useCurrentESUId } from '~hooks/useCurrentESUId';
import { useESU } from '~hooks/useESUList';

import { Page } from '~components/Page';

export function Reports() {
  const esuId = useCurrentESUId();
  const esuName = useESU(esuId).data?.name;
  const [emsReports, setEmsReports] = useState([]);
  const getSignal = useAbortController();

  const fetchReports = useCallback(async () => {
    if (!esuId) {
      return;
    }
    try {
      const response = await http.get(`/v1/reports/ems/${esuId}`, { signal: getSignal() });
      setEmsReports(response?.data ?? []);
    } catch (e) {
      if (!axios.isCancel(e)) {
        console.log(`Error fetching reports: ${e}`);
      }
    }
  }, [esuId, getSignal]);

  useEffect(() => {
    fetchReports();
  }, [esuId, fetchReports]);

  return (
    <Page title={esuName != null ? `${esuName} Reports` : 'Reports'}>
      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h3" gutterBottom>
            {esuName != null ? `${esuName} Reports` : 'Reports'}
          </Typography>
        </Stack>
        <Grid container spacing={3}>
          <Grid item lg={10} sx={{ pl: 3 }}>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell key="report" align="left">
                      Report
                    </TableCell>
                    <TableCell key="download" align="left">
                      Download
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody sx={{ minWidth: 800 }}>
                  {emsReports.map((row) => (
                    <TableRow key={row} tabIndex={-1}>
                      <TableCell key="report" align="left">
                        {row}
                      </TableCell>
                      <TableCell key="download" align="left">
                        <Button
                          component={Link}
                          variant="contained"
                          sx={{ marginRight: '1rem' }}
                          href={`/api/v1/reports/ems/${esuId}/${row}`}
                        >
                          Download
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
