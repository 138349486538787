import { useCallback, useEffect } from 'react';
import { useMatch, useNavigate, useParams } from 'react-router-dom';

import { useLocalStorage } from '~hooks/useLocalStorage';

import type { Tab } from '~pages/v2/esus/types';
import { ROUTER_MATCH_PATH } from '~pages/v2/esus/constants';
import { createESUPath } from '~pages/v2/esus/utils/createESUPath';

const DEFAULT_TAB: Tab = 'dashboard';

export function useESUsNavigation() {
  const navigate = useNavigate();
  const { esuId = '' } = useParams();
  const params = useMatch(ROUTER_MATCH_PATH)?.params;

  const [tabFromStorage, setTabFromStorage] = useLocalStorage<Tab>('esus.tab', DEFAULT_TAB);
  const tab: Tab = (params?.tab as Tab) || tabFromStorage;

  const navigateToESU = useCallback(
    (esuId = '', tab: Tab = '') => {
      navigate(createESUPath(esuId, tab));
    },
    [navigate],
  );

  useEffect(() => {
    setTabFromStorage(tab);
  }, [tab, setTabFromStorage]);

  return {
    esuId,
    tab,
    navigateToESU,
  };
}
