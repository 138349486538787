import { Box } from '@mui/material';

import { useOrganization } from '~hooks/useOrganization';
import { useCurrentOrganizationId } from '~hooks/useCurrentOrganizationId';

import { OrganizationUserList } from '~pages/settings/organizations/OrganizationUserList';

import { Page } from '~components/Page';

export function OrganizationSettings() {
  const organizationId = useCurrentOrganizationId();
  const { data: organization } = useOrganization(organizationId);

  if (!organization) return null;

  return (
    <Page title={`${organization.human_name} Config`}>
      <Box paddingX={3} paddingY={2} overflow="auto">
        <OrganizationUserList />
      </Box>
    </Page>
  );
}
