import type { Tab } from '~pages/v2/fleets/types';
import { TABS } from '~pages/v2/fleets/constants';
import { useFleetsNavigation } from '~pages/v2/fleets/hooks/useFleetsNavigation';
import { createFleetPath } from '~pages/v2/fleets/utils/createFleetPath';

import { TabsRedirection } from '~components/TabsRedirection';

// Smart redirection for fleets index route
export function FleetRedirect() {
  const { tab } = useFleetsNavigation();

  return (
    <TabsRedirection tabs={TABS} currentTab={tab} createPath={(tab: Tab) => createFleetPath(tab)} />
  );
}
