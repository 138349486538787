export type ErrorDetails = {
  type: string;
  loc: (string | number)[];
  msg: string;
  input: unknown;
};

export type ErrorWithDetails = {
  message: string;
  errors: ErrorDetails[];
};

export function isErrorDetails(data: unknown): data is ErrorDetails {
  return (
    typeof data === 'object' &&
    data !== null &&
    'type' in data &&
    'loc' in data &&
    Array.isArray(data.loc) &&
    data.loc.every((loc) => typeof loc === 'string' || typeof loc === 'number') &&
    'msg' in data &&
    typeof data.msg === 'string'
  );
}

export function isErrorWithDetails(data: unknown): data is ErrorWithDetails {
  return (
    typeof data === 'object' &&
    data !== null &&
    'message' in data &&
    'errors' in data &&
    Array.isArray(data.errors) &&
    data.errors.every((error) => isErrorDetails(error))
  );
}
