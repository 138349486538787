import { createContext, useContext } from 'react';

const CurrentESUIdContext = createContext<string | null>(null);

export const CurrentESUIdContextProvider = CurrentESUIdContext.Provider;

export function useCurrentESUId(): string {
  const id = useContext(CurrentESUIdContext);
  if (id == null) {
    throw new Error('useCurrentESUId must be used within a ESUContextProvider');
  }
  return id;
}
