import { EdgeControllerDiagnostics } from '~pages/fleet/controllers/EdgeControllerDiagnostics';

import { ScrollView } from '~components/ScrollView';

export function ControllerDiagnostics() {
  return (
    <ScrollView>
      <EdgeControllerDiagnostics />
    </ScrollView>
  );
}
