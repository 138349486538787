import { useMemo } from 'react';
import {
  type MRT_ColumnDef,
  type MRT_ColumnSizingState,
  type MRT_TableInstance,
  MaterialReactTable,
  useMRT_Rows,
  useMaterialReactTable,
} from 'material-react-table';
import { Box } from '@mui/material';

import type { Organization } from '~types';

import { collator } from '~utils/localization';

import { useLocalStorage } from '~hooks/useLocalStorage';
import { useOrganizationList } from '~hooks/useOrganizationList';
import { ResultListContextProvider } from '~hooks/useResultListContext';
import { CurrentOrganizationIdProvider } from '~hooks/useCurrentOrganizationId';

import { SIDEBAR_USER_HEIGHT } from '~layouts/AppLayout/Sidebar';

import { useOrganizationsNavigation } from '~pages/v2/organizations/hooks/useOrganizationsNavigation';

import { BreadcrumbsProvider } from '~components/BreadcrumbsProvider';
import { Breadcrumbs } from '~components/Breadcrumbs';
import { SplitLayout } from '~components/SplitLayout';

const columns: MRT_ColumnDef<Organization>[] = [
  {
    header: 'Name',
    id: 'human_name',
    accessorFn: (row) => row.human_name,
    sortingFn: (a, b, columnId) => collator.compare(a.getValue(columnId), b.getValue(columnId)),
    size: 400,
    Footer: TableFooter,
  },
];

export function OrganizationsList() {
  const { data } = useOrganizationList();
  const { organizationId, tab, navigateToOrganization } = useOrganizationsNavigation();

  const organization = useMemo(
    () => data?.find((org) => org.id === organizationId) ?? null,
    [data, organizationId],
  );

  const rowSelection = useMemo(
    () => (organizationId ? { [organizationId]: true } : {}),
    [organizationId],
  );
  const [columnSizing, setColumnSizing] = useLocalStorage<MRT_ColumnSizingState>(
    'organizations.settings.columnSizing',
    {},
  );

  const table = useMaterialReactTable<Organization>({
    data: data ?? [],
    columns,
    initialState: {
      density: 'compact',
    },
    state: {
      rowSelection,
      columnSizing: columnSizing ?? {},
    },
    onColumnSizingChange: setColumnSizing,
    enablePagination: false,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableColumnResizing: true,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableTableFooter: true,
    enableBottomToolbar: false,
    positionToolbarAlertBanner: 'none',
    getRowId: (row) => row.id,
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => {
        navigateToOrganization(row.id, tab);
      },
      sx: { cursor: 'pointer' },
    }),
    muiTableContainerProps: {
      sx: { maxHeight: 'calc(100vh - 128px)' },
    },
    muiTablePaperProps: {
      sx: { borderRadius: 0 },
    },
    muiTableFooterCellProps: {
      sx: { height: SIDEBAR_USER_HEIGHT + 1 },
    },
  });
  const rows = useMRT_Rows(table);
  const resultListContext = useMemo(() => {
    const currentRowIndex = organizationId
      ? rows.findIndex((row) => row.id === organizationId)
      : -1;
    const totalRowCount = rows.length;

    return {
      currentRowIndex,
      totalRowCount,
      goToNextResult: () => {
        if (currentRowIndex + 1 >= totalRowCount) return;

        navigateToOrganization(rows[currentRowIndex + 1].id, tab);
      },
      goToPreviousResult: () => {
        if (currentRowIndex <= 0) return;

        navigateToOrganization(rows[currentRowIndex - 1].id, tab);
      },
      goToResultList: () => navigateToOrganization(),
    };
  }, [rows, organizationId, tab, navigateToOrganization]);

  return (
    <CurrentOrganizationIdProvider value={organizationId ?? null}>
      <ResultListContextProvider value={resultListContext}>
        <BreadcrumbsProvider>
          <SplitLayout showDetails={!!organization} renderTitle={() => <Breadcrumbs hasIcon />}>
            <MaterialReactTable table={table} />
          </SplitLayout>
        </BreadcrumbsProvider>
      </ResultListContextProvider>
    </CurrentOrganizationIdProvider>
  );
}

function TableFooter({ table }: { table: MRT_TableInstance<Organization> }) {
  const rows = useMRT_Rows(table);

  return (
    <Box height="100%" display="flex" alignItems="center">
      {rows.length} organizations
    </Box>
  );
}
