import { type ResourceID, useTimeseriesLatest } from '../../../../hooks/timeseries';
import { createLatestQuery } from '../../../../hooks/timeseries/queries';
import { useMeteringGroupContext } from '../../../../hooks/useMeteringGroupContext';

export const DEFAULT_TIMEZONE = 'Europe/Helsinki';

export function useMeteringGroupTimezone(): string | null | undefined {
  const { id: meteringGroupId } = useMeteringGroupContext();
  const resourceId: ResourceID = `metering_group:${meteringGroupId}`;
  const { data: config } = useTimeseriesLatest(
    createLatestQuery({
      resources: [resourceId],
      columns: {
        group_configuration: ['time_zone'],
      },
    }),
  );
  if (config === undefined) {
    return undefined;
  }
  return config?.[resourceId]?.group_configuration?.time_zone ?? null;
}
