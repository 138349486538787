import { Outlet } from 'react-router-dom';
import { AppBar, Toolbar } from '@mui/material';

import { Logo } from '~components/Logo';

export function LogoOnlyLayout() {
  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <Logo />
        </Toolbar>
      </AppBar>

      <Outlet />
    </>
  );
}
