import {
  HOURS_EXTENDED,
  formatEffectivePeriodHours,
  printEffectivePeriodHours,
  validateEffectivePeriodHours,
} from '~pages/settings/groups/pricing/utils/effectivePeriod';
import { BaseRangePicker } from '~pages/settings/groups/pricing/effectivePeriod/BaseRangePicker';

type Props = {
  values: number[];
  onChange: (values: number[]) => void;
};

export function HourRangePicker({ values, onChange }: Props) {
  return (
    <BaseRangePicker
      values={values}
      onChange={onChange}
      label="Hours"
      options={
        Object.entries(HOURS_EXTENDED)
          .filter(([, value]) => value < 24)
          .reduce((acc, [label, value]) => ({ ...acc, [label]: value }), {}) as Record<
          string,
          number
        >
      }
      renderValue={printEffectivePeriodHours}
      error={!validateEffectivePeriodHours(formatEffectivePeriodHours(values))}
    />
  );
}
