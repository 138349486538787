import { Box, Grid, useTheme } from '@mui/material';
import { useCallback, useMemo } from 'react';

import { formatRange } from '~utils/time';
import { getAggregageQueryTimeStepSeconds } from '~utils/timeseries';

import { useURLRange } from '~hooks/useURLRange';
import { useMeteringGroupContext } from '~hooks/useMeteringGroupContext';
import { useMeteringGroupList } from '~hooks/useMeteringGroupList';

import { useSiteChartData } from '~pages/v2/sites/hooks/useSiteChartData';
import { useSitePeakshavingThreshold } from '~pages/v2/sites/hooks/useSitePeakshavingThreshold';
import { useSiteGridMeterData } from '~pages/v2/sites/hooks/useSiteGridMeterData';

import { CopyLinkButton } from '~components/CopyLinkButton';
import { TimeSeriesMultiLineChart } from '~components/charts';
import { DateTimeRangePicker } from '~components/DateTimeRangePicker';

export function SiteDashboardCharts() {
  const theme = useTheme();
  const { id: meteringGroupId } = useMeteringGroupContext();
  const { data: meteringGroups } = useMeteringGroupList();

  const meteringGroup = useMemo(
    () => meteringGroups.find((group) => group.id === meteringGroupId),
    [meteringGroups, meteringGroupId],
  );

  const [range, setRange] = useURLRange(6 * 3600);

  const {
    data: chartData,
    isLoading: isLoadingChartData,
    error: chartDataError,
  } = useSiteChartData(meteringGroupId, range);
  const isAggregatedOverTime = chartData.isAggregatedOverTime;
  const {
    data: scheduleData,
    extendedData: extendedScheduleData,
    isLoading: isLoadingSchedule,
    error: scheduleError,
  } = useSitePeakshavingThreshold(meteringGroupId, range);
  const {
    data: gridMeterData,
    isLoading: isLoadingGridMeter,
    error: gridMeterError,
  } = useSiteGridMeterData(meteringGroupId, range);

  const chartExportFilename = useCallback(
    (startTime: Date, endTime: Date, chartTitle: string) =>
      `${chartTitle} ${meteringGroup?.name} at ${startTime.toISOString()}`,
    [meteringGroup?.name],
  );

  const rangeString = formatRange(range);

  // don't draw over gap when 5 pings dropped
  const rawDataLongestGapMs = 60_000;
  // don't draw over gap when 5 data points are missing
  const aggregateLongestGapMs = getAggregageQueryTimeStepSeconds(range) * 5_000;

  const annotationLineColor = theme.palette.grey[500];
  const annotationStrokeDasharray = '4,2';

  const timeAxis = {
    clipMin: range.start,
    include: [range.start, range.end],
    clipMax: range.end,
  };

  return (
    <Grid container spacing={3} paddingBottom={3}>
      <Grid item xs={12}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <DateTimeRangePicker value={range} onChange={setRange} />
          <CopyLinkButton link={window.location.href} />
        </Box>
      </Grid>

      <Grid item xs={12}>
        <TimeSeriesMultiLineChart
          title="Total grid power"
          subtitle={rangeString}
          height={280}
          data={{ grid_meter: gridMeterData }}
          isLoading={isLoadingGridMeter}
          error={gridMeterError}
          units="kW"
          timeAxis={timeAxis}
          valueAxis={{ include: [-1, 1] }}
          onZoom={setRange}
          exportFilename={chartExportFilename}
          variableSources={{
            grid_meter: {
              variables: [
                {
                  name: 'Power (max)',
                  sourceColumn: 'grid_active_power:max',
                  denominator: 1000,
                  color: theme.palette.chart.aggregate.max,
                },
                {
                  name: 'Power (mean)',
                  sourceColumn: 'grid_active_power:mean',
                  denominator: 1000,
                  color: theme.palette.chart.aggregate.mean,
                },
                {
                  name: 'Power (min)',
                  sourceColumn: 'grid_active_power:min',
                  denominator: 1000,
                  color: theme.palette.chart.aggregate.min,
                  lowerBoundOfArea: {
                    upperBoundSeriesName: 'Power (max)',
                    areaColor: theme.palette.chart.aggregate.area,
                    areaOpacity: 0.2,
                  },
                },
              ],
              longestDrawnGapMs: aggregateLongestGapMs,
              snapTooltip: true,
            },
          }}
          chartAnnotations={[
            {
              type: 'line',
              height: 0,
              color: annotationLineColor,
              strokeDasharray: annotationStrokeDasharray,
            },
          ]}
        />
      </Grid>

      <Grid item xs={12}>
        <TimeSeriesMultiLineChart
          title="Grid frequency"
          subtitle={rangeString}
          height={400}
          data={{ esu_power_control: chartData.esu_power_control }}
          isLoading={isLoadingChartData}
          error={chartDataError}
          units="Hz"
          tooltipDecimalDigits={3}
          timeAxis={timeAxis}
          valueAxis={{
            clipMin: 49.7,
            include: [49.8, 50.2],
            clipMax: 50.3,
          }}
          onZoom={setRange}
          exportFilename={chartExportFilename}
          variableSources={{
            esu_power_control: {
              variables: isAggregatedOverTime
                ? [
                    {
                      name: 'Frequency (max)',
                      sourceColumn: 'control_grid_f:max',
                      denominator: 1000,
                      color: theme.palette.chart.aggregate.max,
                    },
                    {
                      name: 'Frequency (mean)',
                      sourceColumn: 'control_grid_f:mean',
                      denominator: 1000,
                      color: theme.palette.chart.aggregate.mean,
                    },
                    {
                      name: 'Frequency (min)',
                      sourceColumn: 'control_grid_f:min',
                      denominator: 1000,
                      color: theme.palette.chart.aggregate.min,
                      lowerBoundOfArea: {
                        upperBoundSeriesName: 'Frequency (max)',
                        areaColor: theme.palette.chart.aggregate.area,
                        areaOpacity: 0.2,
                      },
                    },
                  ]
                : [
                    {
                      name: 'Frequency',
                      sourceColumn: 'control_grid_f',
                      denominator: 1000,
                      color: theme.palette.chart.single,
                    },
                  ],
              longestDrawnGapMs: isAggregatedOverTime ? aggregateLongestGapMs : rawDataLongestGapMs,
              snapTooltip: true,
            },
          }}
          chartAnnotations={[
            {
              type: 'area',
              y: { from: 49.9, to: 50.1 },
              color: theme.palette.chart.validity.good,
              opacity: 0.15,
            },
            {
              type: 'line',
              height: 50,
              color: annotationLineColor,
              strokeDasharray: annotationStrokeDasharray,
            },
          ]}
        />
      </Grid>

      <Grid item xs={12}>
        <TimeSeriesMultiLineChart
          title="Grid current"
          subtitle={rangeString}
          height={280}
          data={{
            grid_meter: gridMeterData,
            schedule_history: extendedScheduleData,
            'schedule_history#export': scheduleData,
          }}
          isLoading={isLoadingGridMeter || isLoadingSchedule}
          error={gridMeterError ?? scheduleError}
          units="A"
          tooltipDecimalDigits={0}
          timeAxis={timeAxis}
          valueAxis={{ include: [0, 100] }}
          onZoom={setRange}
          exportFilename={chartExportFilename}
          variableSources={{
            grid_meter: {
              variables: [
                {
                  name: 'L1 (mean)',
                  sourceColumn: 'current_a:mean',
                  transform: transformCurrent,
                  color: theme.palette.chart.categorical3[0],
                },
                {
                  name: 'L2 (mean)',
                  sourceColumn: 'current_b:mean',
                  transform: transformCurrent,
                  color: theme.palette.chart.categorical3[1],
                },
                {
                  name: 'L3 (mean)',
                  sourceColumn: 'current_c:mean',
                  transform: transformCurrent,
                  color: theme.palette.chart.categorical3[2],
                },
              ],
              longestDrawnGapMs: aggregateLongestGapMs,
              snapTooltip: true,
            },
            schedule_history: {
              variables: [
                {
                  name: 'Peak shaving threshold',
                  sourceColumn: 'peak_shaving_threshold',
                  color: theme.palette.chart.gray,
                  interpolation: 'step-after',
                  strokeDasharray: '6,3',
                },
              ],
            },
          }}
        />
      </Grid>
    </Grid>
  );
}

function transformCurrent(value: unknown): number | null {
  return typeof value === 'number' ? Math.abs(value) : null;
}
