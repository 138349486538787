import { range } from 'lodash-es';

export function constantArrayIByJ(_i, _j, constant) {
  const array = Array(_i);
  for (let i = 0; i < _i; i += 1) {
    array[i] = Array(_j);
    for (let j = 0; j < _j; j += 1) {
      array[i][j] = constant;
    }
  }
  return array;
}

function parseTimeSpecWithDays(timeSpec) {
  const [daysSpec, hourSpec] = timeSpec.split(';', 2);
  const [startDay, endDay] = daysSpec.split('-', 2).map((x) => parseInt(x, 10));
  if (startDay < 1 || endDay > 7) {
    throw Error(`Days must be in range 1 .. 7, was '${daysSpec}'`);
  }
  const dayRange = range(startDay, endDay + 1);
  return [dayRange, parseHourSpec(hourSpec)];
}

function parseHourSpec(hourSpec) {
  const [startHour, endHour] = hourSpec.split('-', 2).map((x) => parseInt(x, 10));
  if (startHour < 0 || endHour > 24) {
    throw Error(`Hours must be in range 0 .. 24, time specification was '${hourSpec}'`);
  }
  return range(startHour, endHour);
}

function parseTimeSpec(timeSpec) {
  if (timeSpec.includes(';')) {
    return parseTimeSpecWithDays(timeSpec);
  }
  const dayRange = range(1, 8);
  return [dayRange, parseHourSpec(timeSpec)];
}

function assignRectangle(timedValueArray, dayRange, hourRange, value) {
  dayRange.forEach((day) => {
    hourRange.forEach((hour) => {
      // day-1 because teh array is 0-indexed and day ranges are 1-indexed
      timedValueArray[day - 1][hour] = value;
    });
  });
}

export function parseTimedValue(timedValue) {
  // Parse into array-of-arrays, first indexably by day index and then hour index
  timedValue = timedValue.trim();
  const timedValueFormat =
    /^-?[0-9.]+(\s*,\s*([1-7]-[1-7]\s*;\s*)?[0-9]{2}-[0-9]{2}\s*=\s*-?[0-9.]+)*$/;
  const timeFormat = /^([1-7]-[1-7]\s*;\s*)?[0-9]{2}-[0-9]{2}$/;
  const valueFormat = /^-?[0-9.]+$/;
  const passesFormatCheck = timedValueFormat.test(timedValue);
  let result;
  let defaultApplied = false;
  let currentRule = timedValue;
  try {
    const rules = timedValue.split(',').map((s) => s.trim());
    rules.forEach((rule) => {
      currentRule = rule;
      if (!rule.includes('=')) {
        if (defaultApplied) {
          throw new Error(`Exactly one default rule allowed, second default rule: '${rule}'`);
        }
        if (!valueFormat.test(rule)) {
          throw new Error(`Unable to parse value '${rule}'`);
        }
        result = constantArrayIByJ(7, 24, parseFloat(rule));
        defaultApplied = true;
      } else {
        // Add some extra validation through regular expressions. JS parseFloat doesn't fal when input is garbage.
        const [timeSpec, value] = rule.split('=', 2).map((s) => s.trim());
        const timePassesFormatCheck = timeFormat.test(timeSpec);
        if (!valueFormat.test(value)) {
          throw new Error(`Unable to parse value '${value}'`);
        }
        const [dayRange, hourRange] = parseTimeSpec(timeSpec);
        if (!timePassesFormatCheck) {
          throw new Error(`Unable to parse time specification '${timeSpec}'`);
        }
        assignRectangle(result, dayRange, hourRange, parseFloat(value));
      }
    });
  } catch (error) {
    throw new Error(`Unable to parse input '${timedValue}', failed at ${currentRule}, ${error}`);
  }
  if (!passesFormatCheck) {
    throw new Error(
      `Input '${timedValue}' failed format check, but we couldn't produce more specific error message`,
    );
  }
  return result;
}
