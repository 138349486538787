export const splitPascalCase = (str: string): string[] => {
  // new part starts at index 0 and at each capital letter preceded or followed by a lowercase letter
  const partStartIndices: number[] = Array.from(str)
    .map((character) => character !== character.toLowerCase())
    .flatMap((thisIndexIsUppercase, index, isUppercase) =>
      index === 0 ||
      (thisIndexIsUppercase &&
        (isUppercase[index - 1] === false || isUppercase[index + 1] === false))
        ? [index]
        : [],
    );
  return partStartIndices.map((start, index, allStarts) =>
    str.slice(start, allStarts[index + 1] ?? str.length),
  );
};

export const hash = (str: string): number => {
  let hash = 0x55555555;
  for (let i = 0, chr; i < str.length; i++) {
    chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0;
  }
  return hash;
};
