import {
  type ImperativePanelHandle,
  Panel,
  PanelGroup,
  PanelResizeHandle,
} from 'react-resizable-panels';
import { css } from '@emotion/css';
import { Outlet } from 'react-router-dom';
import type React from 'react';
import { type ReactNode, useCallback, useRef, useState } from 'react';
import { Box, IconButton, Typography } from '@mui/material';

import { useResponsive } from '~hooks/useResponsive';
import { useResultListContext } from '~hooks/useResultListContext';
import { useResultListKeyboardShortcuts } from '~hooks/useResultListKeyboardShortcuts';

import { Iconify } from '~components/Iconify';

const panelStyles = css({
  display: 'flex',
  flexDirection: 'column',
  containerName: 'panel',
  containerType: 'size',
});

const panelResizeHandleStyles = css({
  width: '3px',
  transition: '250ms linear background-color',
  backgroundColor: '#515151', // The border color of the table component
  outline: 'none',
  ':hover': {
    backgroundColor: '#72d34c',
  },
  '@media (pointer: coarse)': {
    display: 'none',
  },
});

const hiddenResizeHandleStyles = css({
  display: 'none',
});

export function SplitLayout(props: {
  children: ReactNode;
  showDetails: boolean;
  renderTitle: () => ReactNode;
}) {
  const leftPanelRef = useRef<ImperativePanelHandle>(null);
  const [leftPanelSize, setLeftPanelSize] = useState(20);
  const isMobile = useResponsive('down', 'md');
  const onResize = useCallback((size: number) => {
    setLeftPanelSize(size);
  }, []);
  const { currentRowIndex, totalRowCount, goToNextResult, goToPreviousResult, goToResultList } =
    useResultListContext();
  useResultListKeyboardShortcuts();
  return (
    <PanelGroup autoSaveId="persistence" direction="horizontal">
      {(!isMobile || !props.showDetails) && (
        <Panel
          id="left-panel"
          order={0}
          ref={leftPanelRef}
          className={panelStyles}
          collapsible
          collapsedSize={0}
          minSize={10}
          onResize={onResize}
        >
          {props.children}
        </Panel>
      )}
      {props.showDetails && (
        <>
          <PanelResizeHandle
            className={
              leftPanelSize > 0 && !isMobile ? panelResizeHandleStyles : hiddenResizeHandleStyles
            }
          />
          <Panel id="right-panel" minSize={25} order={1} className={panelStyles}>
            <Box
              sx={{
                display: 'flex',
                paddingLeft: 3,
                alignItems: 'center',
                borderBottomWidth: 1,
                borderBottomStyle: 'solid',
                borderBottomColor: 'divider',
              }}
            >
              {props.renderTitle()}
              <Box flexGrow={1} />
              {currentRowIndex !== -1 && (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    borderRight: '1px solid #333',
                    paddingRight: 1,
                    marginRight: 1,
                  }}
                >
                  <Typography variant="body2" sx={{ paddingLeft: 2, paddingRight: 1 }}>
                    {currentRowIndex !== -1 ? (
                      <>
                        {currentRowIndex + 1} of {totalRowCount}
                      </>
                    ) : undefined}
                  </Typography>
                  <IconButton
                    title="Previous (k)"
                    onClick={goToPreviousResult}
                    disabled={currentRowIndex === -1 || currentRowIndex === 0}
                  >
                    <Iconify icon="mdi:navigate-before" width={20} height={20} />
                  </IconButton>
                  <IconButton
                    title="Next (j)"
                    onClick={goToNextResult}
                    disabled={currentRowIndex === -1 || currentRowIndex >= totalRowCount - 1}
                  >
                    <Iconify icon="mdi:navigate-next" width={20} height={20} />
                  </IconButton>
                </Box>
              )}
              <PanelToolbar leftPanelSize={leftPanelSize} leftPanelRef={leftPanelRef} />
              <IconButton onClick={goToResultList} title="Close">
                <Iconify icon="mdi:arrow-collapse-right" width={20} height={20} />
              </IconButton>
            </Box>
            <Outlet />
          </Panel>
        </>
      )}
    </PanelGroup>
  );
}

function PanelToolbar({
  leftPanelSize,
  leftPanelRef,
}: {
  leftPanelSize: number;
  leftPanelRef: React.RefObject<ImperativePanelHandle>;
}) {
  const isMobile = useResponsive('down', 'md');
  if (isMobile) {
    return null;
  }
  return leftPanelSize > 0 ? (
    <IconButton
      onClick={() => {
        leftPanelRef.current?.collapse();
      }}
      title="Full screen"
    >
      <Iconify icon="mdi:arrow-expand" width={20} height={20} />
    </IconButton>
  ) : (
    <IconButton
      onClick={() => {
        leftPanelRef.current?.expand();
      }}
      title="Collapse panel"
    >
      <Iconify icon="mdi:arrow-collapse" width={20} height={20} />
    </IconButton>
  );
}
