import { useCallback, useState } from 'react';

export const useLocalStorage = <T>(
  keyName: string,
  defaultValue: T,
): [T, (value: T | ((old: T) => T)) => void] => {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const value = window.localStorage.getItem(keyName);
      if (value) {
        return JSON.parse(value) as T;
      }
      window.localStorage.setItem(keyName, JSON.stringify(defaultValue));
      return defaultValue;
    } catch (err) {
      return defaultValue;
    }
  });
  const setValue = useCallback(
    (value: T | ((prevValue: T) => T)) => {
      const newValue = value instanceof Function ? value(storedValue) : value;
      try {
        window.localStorage.setItem(keyName, JSON.stringify(newValue));
      } catch (err) {
        // ignore
      }
      setStoredValue(newValue);
    },
    [keyName, storedValue],
  );
  return [storedValue, setValue];
};
