import useSWR from 'swr';

import { type FetchHookData, type OrganizationDetails } from '~types';

import { fetcher } from '~http';

export function useOrganization(organizationId: string): FetchHookData<OrganizationDetails> {
  const { data, error, isLoading, mutate } = useSWR<OrganizationDetails>(
    `/v1/organization/${organizationId}`,
    {
      refreshInterval: 60_000,
      revalidateOnFocus: false,
      fetcher,
    },
  );

  return {
    data: data ?? null,
    error,
    isLoading,
    mutate,
  };
}
