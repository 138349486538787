export const TIME_ZONE = 'Europe/Helsinki';
export const CURRENCY = 'EUR';

export const IMPORT_TRANSFER_HIGHER_NAME = 'Transfer higher';
export const IMPORT_TRANSFER_LOWER_NAME = 'Transfer lower';
export const IMPORT_TRANSFER_FIXED_NAME = 'Transfer fixed';
export const IMPORT_SPOT_MARGIN_NAME = 'Spot margin';
export const EXPORT_TRANSFER_NAME = 'Fixed price';
export const EXPORT_SPOT_MARGIN_NAME = 'Sales margin';
export const ENERGY_TAX_NAME = 'Energy taxes';
export const STRATEGIC_TAX_NAME = 'Strategic stockpile fee';

export const DEFAULT_ENERGY_TAX = '0.0224';
export const DEFAULT_STOCKPILE_FEE = '0.00013';

export const PURCHASE_MODEL_OPTIONS = {
  fixed: 'Fixed',
  spotMargin: 'Spot + margin',
};

export const PURCHASE_TRANSFER_MODEL_OPTIONS = {
  fixed: 'Fixed price',
  variable: 'Variable Price',
};

export const SALES_MODEL_OPTIONS = {
  fixed: 'Fixed',
  spotMargin: 'Spot + margin',
};

export const SALES_TRANSFER_MODEL_OPTIONS = {
  noTransfer: 'No transfer fee',
  fixed: 'Fixed price',
};
