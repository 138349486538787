import { Link, useMatch, useNavigate, useParams } from 'react-router-dom';
import type React from 'react';
import { useCallback, useMemo, useState } from 'react';
import {
  type MRT_ColumnDef,
  type MRT_ColumnSizingState,
  type MRT_TableInstance,
  type MRT_VisibilityState,
  MaterialReactTable,
  useMRT_Rows,
  useMaterialReactTable,
} from 'material-react-table';
import {
  Box,
  IconButton,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material';

import { type FleetFilter } from '~types';

import { collator } from '~utils/localization';
import { calculateDeviceFcrnCapacity, calculateTotalFcrnCapacity } from '~utils/esu';
import { formatDateTime } from '~utils/time';

import { useLocalStorage } from '~hooks/useLocalStorage';
import { type ESUSettingsDataRow, useESUSettingsData } from '~hooks/useESUSettingsData';
import { CurrentESUIdContextProvider, useCurrentESUId } from '~hooks/useCurrentESUId';
import { useESU } from '~hooks/useESUList';
import { ResultListContextProvider } from '~hooks/useResultListContext';

import { SplitLayout } from '~components/SplitLayout';
import { Iconify } from '~components/Iconify';

function NameFooter({ table }: { table: MRT_TableInstance<ESUSettingsDataRow> }) {
  const rows = useMRT_Rows(table);

  return <>ESUs: {rows.length}</>;
}

function FCRNCapacityFooter({ table }: { table: MRT_TableInstance<ESUSettingsDataRow> }) {
  const rows = useMRT_Rows(table);
  const totalFcrnCapacity = useMemo(
    () => calculateTotalFcrnCapacity(rows.map((row) => row.original.ems_optimizer_configuration)),
    [rows],
  );
  return (
    <Stack>
      <Box>Total:</Box>
      <Box>{totalFcrnCapacity} MW</Box>
    </Stack>
  );
}

function DeviceEnergyCapacityFooter({ table }: { table: MRT_TableInstance<ESUSettingsDataRow> }) {
  const rows = useMRT_Rows(table);
  const totalDeviceEnergyCapacity = useMemo(
    () => calculateTotalDeviceEnergyCapacity(rows.map((row) => row.original)),
    [rows],
  );
  return (
    <Stack>
      <Box>Total:</Box>
      <Box>{totalDeviceEnergyCapacity} kWh</Box>
    </Stack>
  );
}

function getColumns(): MRT_ColumnDef<ESUSettingsDataRow>[] {
  return [
    {
      header: 'ESU',
      columns: [
        {
          header: 'Name',
          id: 'name',
          accessorFn: (row) => row.name,
          sortingFn: (a, b, columnId) =>
            collator.compare(a.getValue(columnId), b.getValue(columnId)),
          Footer: NameFooter,
        },
        {
          header: 'Metering Group',
          id: 'metering_group_name',
          accessorFn: (row) => row.edge_controller.metering_group.name,
          sortingFn: (a, b, columnId) =>
            collator.compare(a.getValue(columnId), b.getValue(columnId)),
        },
        {
          header: 'In Metering Group Since',
          id: 'metering_group_since',
          accessorFn: (row) => formatDateTime(row.group_membership_start),
          sortingFn: (a, b, columnId) =>
            collator.compare(a.getValue(columnId), b.getValue(columnId)),
        },
        {
          header: 'Organization',
          id: 'organization_name',
          accessorFn: (row) => row.edge_controller.organization.human_name,
          sortingFn: (a, b, columnId) =>
            collator.compare(a.getValue(columnId), b.getValue(columnId)),
        },
      ],
    },

    // Optimizer
    {
      header: 'Energy transfer (kWh)',
      columns: [
        {
          header: 'Max (24h)',
          id: 'ems_optimizer_configuration.energy_transfer_max',
          accessorFn: (row) => row.ems_optimizer_configuration?.energy_transfer_max,
          size: 170,
        },
      ],
    },
    {
      header: 'Idle SoC',
      columns: [
        {
          header: 'Min',
          id: 'ems_optimizer_configuration.idle_low_soc',
          accessorFn: (row) => row.ems_optimizer_configuration?.idle_low_soc,
          size: 80,
        },
        {
          header: 'Max',
          id: 'ems_optimizer_configuration.idle_high_soc',
          accessorFn: (row) => row.ems_optimizer_configuration?.idle_high_soc,
          size: 80,
        },
      ],
    },
    {
      header: 'Optimizer',
      columns: [
        {
          header: 'Include',
          id: 'ems_optimizer_configuration.run_optimizer',
          accessorFn: (row) => {
            if (!row.ems_optimizer_configuration) {
              return '';
            }
            return row.ems_optimizer_configuration.run_optimizer ? 'Yes' : 'No';
          },
          size: 100,
        },
      ],
    },
    // Installation
    {
      header: 'Max power (kW)',
      columns: [
        {
          header: 'Import',
          id: 'ems_optimizer_configuration.default_esu_import_power_max',
          accessorFn: (row) => row.ems_optimizer_configuration?.default_esu_import_power_max,
          size: 90,
        },
        {
          header: 'Export',
          id: 'ems_optimizer_configuration.default_esu_export_power_max',
          accessorFn: (row) => row.ems_optimizer_configuration?.default_esu_export_power_max,
          size: 90,
        },
      ],
    },
    // FCR-N
    {
      header: 'FCR-N power (kW)',
      columns: [
        {
          header: 'Charge',
          id: 'ems_optimizer_configuration.max_fcrn_power',
          accessorFn: (row) => row.ems_optimizer_configuration?.max_fcrn_power,
          size: 100,
        },
        {
          header: 'Discharge',
          id: 'ems_optimizer_configuration.max_fcrn_discharge_power',
          accessorFn: (row) => row.ems_optimizer_configuration?.max_fcrn_discharge_power,
          size: 120,
        },
        {
          header: 'Capacity',
          id: '@calculated/fcrn_cap_max',
          accessorFn: (row) => calculateDeviceFcrnCapacity(row.ems_optimizer_configuration),
          Footer: FCRNCapacityFooter,
        },
        {
          header: 'Offset',
          id: 'ems_optimizer_configuration.desired_fcrn_power_offset',
          accessorFn: (row) => row.ems_optimizer_configuration?.desired_fcrn_power_offset,
          size: 100,
        },
      ],
    },
    {
      header: 'FCR-N SoC',
      columns: [
        {
          header: 'Min',
          id: 'ems_optimizer_configuration.fcrn_low_soc',
          accessorFn: (row) => row.ems_optimizer_configuration?.fcrn_low_soc,
          size: 80,
        },
        {
          header: 'Max',
          id: 'ems_optimizer_configuration.fcrn_high_soc',
          accessorFn: (row) => row.ems_optimizer_configuration?.fcrn_high_soc,
          size: 80,
        },
      ],
    },
    {
      header: 'FCR-N',
      columns: [
        {
          header: 'Priority',
          id: 'ems_optimizer_configuration.fcrn_priority_group',
          accessorFn: (row) => row.ems_optimizer_configuration?.fcrn_priority_group,
          size: 100,
        },
      ],
    },
    // Peak shaving
    {
      header: 'Peak shaving',
      columns: [
        {
          header: 'Threshold (A)',
          id: 'ems_optimizer_configuration.peak_shaving_threshold_override',
          accessorFn: (row) => row.ems_optimizer_configuration?.peak_shaving_threshold_override,
          size: 140,
        },
      ],
    },
    // Device
    {
      header: 'Energy (kWh)',
      columns: [
        {
          header: 'Capacity',
          id: 'ems_optimizer_configuration.device_energy_capacity',
          accessorFn: (row) => row.ems_optimizer_configuration?.device_energy_capacity,
          size: 110,
          Footer: DeviceEnergyCapacityFooter,
        },
      ],
    },
    {
      header: 'Efficiency',
      columns: [
        {
          header: 'Import',
          id: 'ems_optimizer_configuration.import_efficiency',
          accessorFn: (row) => row.ems_optimizer_configuration?.import_efficiency,
          size: 90,
        },
        {
          header: 'Export',
          id: 'ems_optimizer_configuration.export_efficiency',
          accessorFn: (row) => row.ems_optimizer_configuration?.export_efficiency,
          size: 90,
        },
      ],
    },
  ];
}

const columns = getColumns();

function calculateTotalDeviceEnergyCapacity(
  data: ESUSettingsDataRow[] | undefined,
): string | undefined {
  if (!data) {
    return undefined;
  }
  const total = data.reduce((acc, row) => {
    if (!row.ems_optimizer_configuration?.device_energy_capacity) {
      return acc;
    }
    const capacity = parseInt(row.ems_optimizer_configuration.device_energy_capacity, 10);
    return acc + capacity;
  }, 0);
  return total.toString();
}

const defaultColumnVisibility = {};

export function ESUSettingsList() {
  const { esuId } = useParams();
  const match = useMatch('/settings/esus/:esuId/:tab');
  const currentTab = match?.params.tab || 'config';
  const navigate = useNavigate();
  const rowSelection = useMemo(() => (esuId ? { [esuId]: true } : {}), [esuId]);
  const [columnSizing, setColumnSizing] = useLocalStorage<MRT_ColumnSizingState>(
    'settings.esus.columnSizing',
    {},
  );
  const [columnVisibility, setColumnVisibility] =
    useState<MRT_VisibilityState>(defaultColumnVisibility);
  const [fleetFilter, setFleetFilter] = useLocalStorage<FleetFilter>(
    'settings.esus.fleetFilter',
    'all',
  );
  const onFleetFilterChange = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      const { value } = event.currentTarget as HTMLButtonElement;
      setFleetFilter(value as FleetFilter);
    },
    [setFleetFilter],
  );
  const { data } = useESUSettingsData(fleetFilter);

  const table = useMaterialReactTable<ESUSettingsDataRow>({
    data: data ?? [],
    columns,
    initialState: {
      density: 'compact',
    },
    state: {
      rowSelection,
      columnSizing: columnSizing ?? {},
      columnVisibility: columnVisibility ?? {},
      columnPinning: {
        left: ['name'],
      },
    },
    onColumnSizingChange: setColumnSizing,
    onColumnVisibilityChange: setColumnVisibility,
    enablePagination: false,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableColumnResizing: true,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableColumnActions: false,
    enableTableFooter: true,
    enableBottomToolbar: false,
    positionToolbarAlertBanner: 'none',
    getRowId: (row) => row.id,
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => {
        const esuId = row.id;
        if (esuId) {
          navigate(`/settings/esus/${esuId}/${currentTab}`);
        } else {
          navigate('/settings/esus');
        }
      },
      sx: { cursor: 'pointer' },
    }),
    muiTableContainerProps: { sx: { maxHeight: 'calc(100vh - 120px)' } },
    muiTablePaperProps: { sx: { borderRadius: 0 } },
    muiTableFooterProps: { sx: { minHeight: 120 } },
    renderTopToolbarCustomActions: () => (
      <ToggleButtonGroup
        color="primary"
        value={fleetFilter}
        onChange={onFleetFilterChange}
        size="small"
        exclusive
      >
        <ToggleButton value="all">All</ToggleButton>
        <ToggleButton value="fleet">Fleet</ToggleButton>
      </ToggleButtonGroup>
    ),
  });
  const rows = useMRT_Rows(table);
  const resultListContext = useMemo(() => {
    const currentRowIndex = esuId ? rows.findIndex((row) => row.id === esuId) : -1;
    const totalRowCount = rows.length;
    return {
      currentRowIndex,
      totalRowCount,
      goToNextResult: () => {
        if (currentRowIndex + 1 < totalRowCount) {
          navigate(`/settings/esus/${rows[currentRowIndex + 1].id}/${currentTab}`);
        }
      },
      goToPreviousResult: () => {
        if (currentRowIndex > 0) {
          navigate(`/settings/esus/${rows[currentRowIndex - 1].id}/${currentTab}`);
        }
      },
      goToResultList: () => navigate('/settings/esus'),
    };
  }, [rows, navigate, currentTab, esuId]);
  return (
    <CurrentESUIdContextProvider value={esuId ?? null}>
      <ResultListContextProvider value={resultListContext}>
        <SplitLayout showDetails={!!esuId} renderTitle={() => <Title />}>
          <MaterialReactTable table={table} />
        </SplitLayout>
      </ResultListContextProvider>
    </CurrentESUIdContextProvider>
  );
}

function Title() {
  const id = useCurrentESUId();
  const name = useESU(id).data?.name;
  return (
    <>
      <Typography variant="subtitle1">{name}</Typography>
      <Tooltip title="View diagnostics" placement="right">
        <IconButton component={Link} to={`/dashboard/esus/${id}/diagnostics`}>
          <Iconify icon="mdi:clipboard-pulse-outline" />
        </IconButton>
      </Tooltip>
    </>
  );
}
