import dayjs from 'dayjs';
import dayJsAdvancedFormatPlugin from 'dayjs/plugin/advancedFormat';
import dayJsDurationPlugin from 'dayjs/plugin/duration';
import dayJsIsoWeekPlugin from 'dayjs/plugin/isoWeek';
import dayJsIsSameOrBeforePlugin from 'dayjs/plugin/isSameOrBefore';
import dayJsRelativeTimePlugin from 'dayjs/plugin/relativeTime';
import dayJsTimezonePlugin from 'dayjs/plugin/timezone';
import dayJsUtcPlugin from 'dayjs/plugin/utc';
import dayJsMinMaxPlugin from 'dayjs/plugin/minMax';

export function configureDayjs() {
  dayjs.extend(dayJsAdvancedFormatPlugin);
  dayjs.extend(dayJsDurationPlugin);
  dayjs.extend(dayJsIsoWeekPlugin);
  dayjs.extend(dayJsIsSameOrBeforePlugin);
  dayjs.extend(dayJsRelativeTimePlugin);
  dayjs.extend(dayJsTimezonePlugin);
  dayjs.extend(dayJsUtcPlugin);
  dayjs.extend(dayJsMinMaxPlugin);
}
