import { Navigate } from 'react-router-dom';

import { useLocalStorage } from '~hooks/useLocalStorage';

export function AppRedirect() {
  const [isUiv2Enabled] = useLocalStorage<boolean>('ui-v2-enabled', false);

  if (isUiv2Enabled) {
    return <Navigate replace to="/v2" />;
  }

  return <Navigate to="/dashboard/app" />;
}
