import { Box, Card, CircularProgress, Tooltip } from '@mui/material';
import { ErrorOutline } from '@mui/icons-material';
import { ParentSize } from '@visx/responsive';
import { scaleSequential } from 'd3-scale';
import { interpolateRdYlGn } from 'd3-scale-chromatic';

import { DashboardCardHeader } from '../DashboardCard';
import { HistogramBarChart, type UnitInfo } from './HistogramBarChart';

type FleetCellVoltageDiffHistogramProps = {
  data: Map<number, UnitInfo[]>; // units grouped by 'v_max - v_min', in centivolts, floored to integers
  isLoading?: boolean;
  error?: any;
  height?: number;
};

export function FleetCellVoltageDiffHistogram({
  data,
  isLoading = false,
  error = null,
  height = 300,
}: FleetCellVoltageDiffHistogramProps) {
  // green at 0V, most red at 0.3V and beyond
  const barColorScale = scaleSequential(interpolateRdYlGn).domain([30, 0]).clamp(true);

  return (
    <Card sx={{ padding: 2 }}>
      <DashboardCardHeader>
        Cell voltage difference
        {isLoading && <CircularProgress size={15} sx={{ ml: 1.5, mb: 0.35 }} />}
        {error != null && (
          <Tooltip title="Error loading fleet voltage data." placement="right">
            <ErrorOutline color="error" fontSize="small" sx={{ ml: 1.2 }} />
          </Tooltip>
        )}
      </DashboardCardHeader>
      <Box sx={{ p: 1, pb: 1, alignSelf: 'stretch' }}>
        <ParentSize debounceTime={100}>
          {({ width }) => (
            <HistogramBarChart
              width={width}
              height={height}
              data={data}
              barColoring={(key) => barColorScale(key)}
              formatTooltipLabel={(key) => `${key * 10}\u2013${key * 10 + 9} mV`}
              formatBottomAxisLabel={(key) => `${key * 10}mV`}
              minBottomAxisTickGap={50}
            />
          )}
        </ParentSize>
      </Box>
    </Card>
  );
}
