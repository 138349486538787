import { Outlet } from 'react-router-dom';

import { ScrollView } from '~components/ScrollView';

export function ESUSettings() {
  return (
    <ScrollView>
      <Outlet />
    </ScrollView>
  );
}
