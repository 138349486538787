import { Reports } from '~pages/Reports';

import { ScrollView } from '~components/ScrollView';

export function ESUReports() {
  return (
    <ScrollView>
      <Reports />
    </ScrollView>
  );
}
