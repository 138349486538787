import type { PropsWithChildren, ReactNode } from 'react';
import { Box, Divider, styled, useMediaQuery, useTheme } from '@mui/material';

import { useDocumentTitle } from '~hooks/useDocumentTitle';

import {
  SIDEBAR_LOGO_HEIGHT,
  SIDEBAR_MOBILE_HEIGHT,
  SIDEBAR_WIDTH,
} from '~layouts/AppLayout/Sidebar';

import { Breadcrumbs } from '~components/Breadcrumbs';

type Props = PropsWithChildren<{
  title: string;
  actions?: ReactNode;
}>;

const Container = styled('div')(({ theme }) => ({
  maxWidth: '100vw',
  height: '100vh',
  overflowY: 'auto',
  [theme.breakpoints.up('lg')]: {
    maxWidth: `calc(100vw - ${SIDEBAR_WIDTH}px)`,
  },
}));

export function PageLayout({ title, actions = null, children }: Props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const headerHeight = (isMobile ? SIDEBAR_MOBILE_HEIGHT : SIDEBAR_LOGO_HEIGHT) + 1; // 1px for the divider

  useDocumentTitle(title);

  return (
    <Container>
      <Box
        p={2}
        minHeight={SIDEBAR_LOGO_HEIGHT}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        gap={2}
      >
        <Breadcrumbs />
        {actions}
      </Box>
      <Divider />
      <Box height={`calc(100vh - ${headerHeight}px)`}>{children}</Box>
    </Container>
  );
}
