import type { Organization } from '~types';

export function filterValidControllerTransferOrganizations(
  organizations: Organization[] | null,
): Organization[] {
  return (
    organizations?.filter(
      (organization) =>
        organization.name === '/cactos/factory/' ||
        organization.name === '/cactos/development/' ||
        organization.name.startsWith('/cactos/installation/'),
    ) ?? []
  );
}
