import { Outlet } from 'react-router-dom';

import { useRegisterBreadcrumbs } from '~hooks/useBreadcrumbs';
import { useMeteringGroupContext } from '~hooks/useMeteringGroupContext';

import { TABS } from '~pages/v2/sites/constants';
import { useSitesNavigation } from '~pages/v2/sites/hooks/useSitesNavigation';
import { useSitesHierarchyBreadcrumbs } from '~pages/v2/sites/hooks/useSitesHierarchyBreadcrumbs';
import { createSitePath } from '~pages/v2/sites/utils/createSitePath';

import { Tabs } from '~components/Tabs';

export function Site() {
  const { meteringGroupId, tab } = useSitesNavigation();
  const { name } = useMeteringGroupContext();

  useSitesHierarchyBreadcrumbs();
  useRegisterBreadcrumbs('sites', name, createSitePath(meteringGroupId));

  return (
    <>
      <Tabs tabs={TABS} tab={tab} pathname={createSitePath(meteringGroupId)} />
      <Outlet />
    </>
  );
}
