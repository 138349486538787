import {
  type MRT_ColumnDef,
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import { Box, CardHeader, CircularProgress, Stack, Tooltip, Typography } from '@mui/material';
import { ErrorOutline } from '@mui/icons-material';
import { useMemo } from 'react';
import { differenceInSeconds, formatDistanceToNowStrict } from 'date-fns';

import { type FaultEvent } from '../../../types';
import {
  type FaultEventWithESU,
  useESUFaults,
  useSingleESUFaults,
} from '../../../hooks/useESUFaults';
import { DashboardCardHeader } from '../../../components/DashboardCard';
import {
  type DateTimeRangeWithNow,
  formatClockTime,
  formatDurationShort,
  formatRange,
  formatRelativeTimeShort,
} from '../../../utils/time';
import { asCactosError } from '../../../http';

export function ESUFaultEventDetails({ fault }: { fault: FaultEvent }) {
  const { code, description, params, extras } = fault;
  return (
    <Box>
      <Typography variant="data" sx={{ fontWeight: 'bold' }}>
        {code} {JSON.stringify(params)}
      </Typography>
      <Typography variant="body1">{description}</Typography>
      <pre>
        {extras != null && Object.keys(extras).length ? JSON.stringify(extras, null, 2) : ''}
      </pre>
    </Box>
  );
}

export function ESUFaultsCard() {
  const { data } = useESUFaults(60, 'fleet');
  const resultStartTime = data?.start;
  const esuNames = useMemo<string[]>(() => {
    if (!data) return [];
    const names = data.faults
      .map((fault) => fault.esu?.name)
      .filter((name: string | undefined): name is string => name != null);
    return Array.from(new Set(names));
  }, [data]);

  const columns = useMemo<MRT_ColumnDef<FaultEventWithESU>[]>(
    () => [
      {
        header: 'ESU',
        id: 'esu.name',
        accessorFn: (row) => row.esu?.name,
        filterVariant: 'multi-select',
        filterSelectOptions: esuNames,
      },
      {
        header: 'Code',
        id: 'code',
        accessorFn: (row) => row.code,
      },
      {
        header: 'Last seen',
        id: 'last_seen',
        accessorFn: (row) => row.last_seen,
        enableColumnFilter: false,
        Cell: ({ cell }) => (
          <Typography variant="body2">
            {formatRelativeTimeShort(cell.getValue<string>())}
          </Typography>
        ),
      },
      {
        header: 'Start',
        id: 'start',
        accessorFn: (row) => row.start,
        enableColumnFilter: false,
        Cell: ({ cell }) => (
          <Typography variant="body2">{formatClockTime(cell.getValue<string>())}</Typography>
        ),
      },
      {
        header: 'Duration',
        id: 'duration',
        accessorFn: ({ start, end }) => {
          if (start && end) {
            return differenceInSeconds(end, start);
          }
          return '';
        },
        filterVariant: 'range',
        Cell: ({ cell }) => (
          <Typography variant="body2">{formatDurationShort(cell.getValue<number>())}</Typography>
        ),
      },
    ],
    [esuNames],
  );

  const table = useMaterialReactTable<FaultEvent>({
    data: data?.faults ?? [],
    columns,
    initialState: {
      density: 'compact',
      sorting: [{ id: 'last_seen', desc: true }],
    },
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableColumnActions: false,
    positionToolbarAlertBanner: 'none',
    muiTableProps: {
      sx: {
        caption: {
          captionSide: 'top',
        },
      },
    },
    renderTopToolbarCustomActions: () => (
      <Stack marginX={1} marginY={1}>
        <DashboardCardHeader>Faults</DashboardCardHeader>
        {resultStartTime ? (
          <>Faults since {formatDistanceToNowStrict(resultStartTime, { addSuffix: true })}</>
        ) : (
          'Loading...'
        )}
      </Stack>
    ),
    muiDetailPanelProps: () => ({
      sx: (theme) => ({
        backgroundColor: theme.palette.grey[800],
      }),
    }),
    renderDetailPanel: ({ row }) => <ESUFaultEventDetails fault={row.original} />,
  });

  return <MaterialReactTable table={table} />;
}

export function SingleESUFaultsCard({
  esuID,
  range,
}: {
  esuID: string;
  range: DateTimeRangeWithNow;
}) {
  const { data, isLoading, error } = useSingleESUFaults(esuID, range.start, range.end);
  const resultRange =
    data != null ? { start: new Date(data.start_time), end: new Date(data.end_time) } : null;

  const columns = useMemo<MRT_ColumnDef<FaultEvent>[]>(
    () => [
      {
        header: 'Code',
        id: 'code',
        accessorFn: (row) => row.code,
      },
      {
        header: 'Last seen',
        id: 'last_seen',
        accessorFn: (row) => row.last_seen,
        enableColumnFilter: false,
        Cell: ({ cell }) => (
          <Typography variant="body2">
            {range.now
              ? formatRelativeTimeShort(cell.getValue<string>())
              : formatClockTime(cell.getValue<string>())}
          </Typography>
        ),
      },
      {
        header: 'Start',
        id: 'start',
        accessorFn: (row) => row.start,
        enableColumnFilter: false,
        Cell: ({ cell }) => (
          <Typography variant="body2">{formatClockTime(cell.getValue<string>())}</Typography>
        ),
      },
      {
        header: 'Duration',
        id: 'duration',
        accessorFn: ({ start, end }) => {
          if (start && end) {
            return differenceInSeconds(end, start);
          }
          return '';
        },
        filterVariant: 'range',
        Cell: ({ cell }) => (
          <Typography variant="body2">{formatDurationShort(cell.getValue<number>())}</Typography>
        ),
      },
    ],
    [range.now],
  );

  const table = useMaterialReactTable<FaultEvent>({
    data: data?.faults ?? [],
    columns,
    initialState: {
      density: 'compact',
      sorting: [{ id: 'last_seen', desc: true }],
    },
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableColumnActions: false,
    positionToolbarAlertBanner: 'none',
    muiTableProps: {
      sx: {
        caption: {
          captionSide: 'top',
        },
      },
    },
    renderTopToolbarCustomActions: () => (
      <CardHeader
        title={
          <>
            Faults {isLoading && <CircularProgress size={12} sx={{ mt: 1, ml: 1 }} />}
            {error != null && (
              <Tooltip
                title={'Error loading ESU faults: ' + asCactosError(error).message}
                placement="right"
              >
                <ErrorOutline
                  color="error"
                  fontSize="small"
                  sx={{ ml: 1, position: 'relative', top: '3px' }}
                />
              </Tooltip>
            )}
          </>
        }
        subheader={resultRange ? formatRange(resultRange) : 'Loading...'}
        sx={{ p: 1 }}
      />
    ),
    muiDetailPanelProps: () => ({
      sx: (theme) => ({
        backgroundColor: theme.palette.grey[800],
      }),
    }),
    renderDetailPanel: ({ row }) => <ESUFaultEventDetails fault={row.original} />,
  });

  return <MaterialReactTable table={table} />;
}
