import { EdgeControllerDetailPage } from '~pages/fleet/controllers/EdgeControllerDetailPage';

import { ScrollView } from '~components/ScrollView';

export function ControllerSettings() {
  return (
    <ScrollView>
      <EdgeControllerDetailPage />
    </ScrollView>
  );
}
