import { Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';

import { DashboardCard, DashboardCardHeader } from '~components/DashboardCard';

const getVoltageFromMillivolts = (value: number | undefined | null) => {
  if (value == null) {
    return 'N/A';
  }
  return `${(value / 1000).toFixed(2)} V`;
};

const getVoltage = (value: number | undefined | null) => {
  if (value == null) {
    return 'N/A';
  }
  return `${value.toFixed(2)} V`;
};

export type EMSBatteryCardProps = {
  data?: {
    bms_cell_voltages?: {
      'max(*)': number | null;
      'min(*)': number | null;
    };
    bms_temperatures?: {
      'max(*)': number | null;
      'min(*)': number | null;
    };
    esu_diagnostics_power?: {
      bms_battery_pack_voltage: number | null;
    };
  };
  isLoading: boolean;
};

export function EMSBatteryCard(props: EMSBatteryCardProps) {
  const { data, isLoading } = props;
  if (isLoading && data?.bms_temperatures == null) {
    return (
      <DashboardCard size="medium">
        <DashboardCardHeader>Battery</DashboardCardHeader>
      </DashboardCard>
    );
  }

  const minVoltage = getVoltageFromMillivolts(data?.bms_cell_voltages?.['min(*)']);
  const maxVoltage = getVoltageFromMillivolts(data?.bms_cell_voltages?.['max(*)']);
  const voltage = getVoltage(data?.esu_diagnostics_power?.bms_battery_pack_voltage);
  const temperature = data?.bms_temperatures?.['max(*)'];

  return (
    <DashboardCard size="medium">
      <DashboardCardHeader>Battery</DashboardCardHeader>
      <Table
        size="small"
        sx={{
          '& td, & th': { lineHeight: '1.5rem' },
          '& td:first-of-type, & th:first-of-type': { paddingLeft: 0 },
          '& tr:last-child td': { borderBottom: 'none' },
        }}
      >
        <TableBody>
          <TableRow>
            <TableCell>Pack Voltage</TableCell>
            <TableCell align="right">
              <Typography variant="data">{voltage}</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Cell Voltage Min</TableCell>
            <TableCell align="right">
              <Typography variant="data">{minVoltage}</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Cell Voltage Max</TableCell>
            <TableCell align="right">
              <Typography variant="data">{maxVoltage}</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Cell Temperature</TableCell>
            <TableCell align="right">
              <Typography variant="data">
                {temperature != null ? `${temperature} °C` : 'N/A'}
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </DashboardCard>
  );
}
