import { useCallback, useState } from 'react';
import { Box, IconButton, Tooltip, useTheme } from '@mui/material';

import { Iconify } from '~components/Iconify';

export function CopyableID({ id }: { id: string }) {
  const [copied, setCopied] = useState(false);
  const [hovered, setHovered] = useState(false);
  const theme = useTheme();

  const copy = useCallback(() => {
    navigator.clipboard.writeText(id);

    if (!copied) {
      setCopied(true);
      setTimeout(() => setCopied(false), 2_000);
    }
  }, [id, copied]);

  return (
    <Box
      px={1}
      py={0.5}
      width="fit-content"
      display="flex"
      alignItems="center"
      gap={1}
      color="white"
      bgcolor={theme.palette.grey[800]}
      borderRadius={1}
      fontSize={14}
    >
      {id}
      <Tooltip
        title={copied ? 'ID copied!' : 'Copy ID'}
        placement="top"
        open={hovered || copied}
        onOpen={() => setHovered(true)}
        onClose={() => setHovered(false)}
      >
        <IconButton onClick={copy}>
          {copied ? (
            <Iconify icon="mdi:check" color={theme.palette.success.main} fontSize="medium" />
          ) : (
            <Iconify icon="mdi:content-copy" fontSize="medium" />
          )}
        </IconButton>
      </Tooltip>
    </Box>
  );
}
