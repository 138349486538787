import { Outlet } from 'react-router-dom';

import { useRegisterBreadcrumbs } from '~hooks/useBreadcrumbs';
import { useOrganization } from '~hooks/useOrganization';

import { useOrganizationsNavigation } from '~pages/v2/organizations/hooks/useOrganizationsNavigation';
import { createOrganizationPath } from '~pages/v2/organizations/utils/createOrganizationPath';

export function Organization() {
  const { organizationId } = useOrganizationsNavigation();
  const { data: organization } = useOrganization(organizationId);

  useRegisterBreadcrumbs(
    'organizations',
    organization?.human_name ?? '',
    createOrganizationPath(organizationId),
  );

  return <Outlet />;
}
