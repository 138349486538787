import { useCallback, useEffect } from 'react';
import { useMatch, useNavigate, useParams } from 'react-router-dom';

import { useLocalStorage } from '~hooks/useLocalStorage';

import type { Tab } from '~pages/v2/organizations/types';
import { createOrganizationPath } from '~pages/v2/organizations/utils/createOrganizationPath';

const ROUTER_MATCH_PATH = '/v2/organizations/:organizationId/:tab';
const DEFAULT_TAB: Tab = 'settings';

export function useOrganizationsNavigation() {
  const navigate = useNavigate();
  const { organizationId = '' } = useParams();
  const params = useMatch(ROUTER_MATCH_PATH)?.params;

  const [tabFromStorage, setTabFromStorage] = useLocalStorage<Tab>(
    'organizations.tab',
    DEFAULT_TAB,
  );
  const tab: Tab = (params?.tab as Tab) || tabFromStorage;

  const navigateToOrganization = useCallback(
    (organizationId = '', tab: Tab = '') => {
      navigate(createOrganizationPath(organizationId, tab));
    },
    [navigate],
  );

  useEffect(() => {
    setTabFromStorage(tab);
  }, [tab, setTabFromStorage]);

  return {
    organizationId,
    tab,
    navigateToOrganization,
  };
}
