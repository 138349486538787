import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  IconButton,
  LinearProgress,
  NativeSelect,
  Tooltip,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { type ChangeEvent, useCallback, useEffect, useState } from 'react';
import useSWR from 'swr';

import { type CactosError, asCactosError, fetcher, http } from '~http';

import { useCurrentESUId } from '~hooks/useCurrentESUId';

import { Iconify } from '~components/Iconify';

import OptimizerIcon from '~assets/icons/cactos-logo-optimizer.svg?react';

const TEST_RUN_REFRESH_INTERVAL = 10_000; // ms

type UIAlert = {
  severity: 'success' | 'info' | 'warning' | 'error';
  message: string;
};

type TestRunData =
  | {
      test_run_id: string;
      start: string | null;
      end: string | null;
    }
  | {
      test_run_id: null;
    };

type ProgramListItem = {
  id: string;
  display_name: string;
};

export function ProgramPanel() {
  const [selectedProgramId, setSelectedProgramId] = useState<string>('');
  const [alerts, setAlerts] = useState<UIAlert[]>([]);

  const esuId = useCurrentESUId();

  const { data: availablePrograms, error: availableProgramsError } = useSWR<ProgramListItem[]>(
    `/v1/esu/${esuId}/available_programs`,
    { fetcher, revalidateOnFocus: false },
  );

  const { data, status, error, forceFetch } = useRunData(esuId);
  const currentProgramId = data?.test_run_id ?? null;
  const programStartTime = data?.test_run_id ? data.start : null;
  const ProgramEndTime = data?.test_run_id ? data.end : null;

  const onStart = useCallback(async () => {
    setAlerts([]);
    try {
      await http.post(`/v1/ems/${esuId}/diagnostic_run`, { test_run_id: selectedProgramId });
      await forceFetch();
      setSelectedProgramId('');
    } catch (e) {
      const message = `Starting test run failed: ${asCactosError(e).message}`;
      console.error(e);
      setAlerts([{ severity: 'error', message }]);
    }
  }, [esuId, selectedProgramId, forceFetch]);

  const onStop = useCallback(async () => {
    setAlerts([]);
    try {
      await http.post(`/v1/ems/${esuId}/diagnostic_run`, { test_run_id: 'idle' });
      await forceFetch();
    } catch (e) {
      const message = `Setting unit to idle failed: ${asCactosError(e).message}`;
      console.error(e);
      setAlerts([{ severity: 'error', message }]);
    }
  }, [esuId, forceFetch]);

  const onSelectProgram = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedProgramId(e.target.value);
    setAlerts([]);
  }, []);

  return (
    <Card sx={{ marginBottom: '1rem' }}>
      <CardHeader title="Program" sx={{ padding: '16px 16px 0' }} />
      <CardContent sx={{ padding: '16px 16px' }}>
        {currentProgramId != null && (
          <Box
            sx={{
              display: 'flex',
              gap: '1em',
              alignItems: 'center',
              flexWrap: 'wrap',
              borderRadius: 1,
              backgroundColor: 'rgba(0, 0, 0, 0.04)',
              padding: '0.5em 1em',
              minHeight: 48,
            }}
          >
            {currentProgramId !== 'idle' && currentProgramId !== 'optimizer' && (
              <Tooltip title="Set to idle">
                <IconButton size="small" onClick={onStop}>
                  <Iconify icon="material-symbols:stop" />
                </IconButton>
              </Tooltip>
            )}
            {currentProgramId === 'optimizer' ? (
              <Chip
                label="In Optimizer"
                color="primary"
                icon={
                  <OptimizerIcon
                    style={{ color: 'black', height: '1rem', width: '1rem', marginLeft: '10px' }}
                  />
                }
              />
            ) : (
              <Typography variant="body1">
                <em>
                  {availablePrograms?.find((spec) => spec.id === currentProgramId)?.display_name ??
                    currentProgramId}
                </em>
              </Typography>
            )}
            {currentProgramId !== 'idle' && currentProgramId !== 'optimizer' && (
              <ProgramProgress startTime={programStartTime} endTime={ProgramEndTime} />
            )}
          </Box>
        )}
        {availableProgramsError != null && (
          <Alert key="available-programs-error" severity="error" sx={{ marginTop: '1em' }}>
            Error getting available programs: {asCactosError(availableProgramsError).message}
          </Alert>
        )}
        {alerts.map((alert, i) => (
          <Alert key={i} severity={alert.severity} sx={{ marginTop: '1em' }}>
            {alert.message}
          </Alert>
        ))}
        {status === 'error' && (
          <Alert key="error" severity="error" sx={{ marginTop: '1em' }}>
            {error?.message}
          </Alert>
        )}
        <Box sx={{ marginTop: '1em' }}>
          <NativeSelect
            value={selectedProgramId}
            onChange={onSelectProgram}
            sx={{ marginRight: '1rem', marginBottom: '1rem' }}
          >
            <option value="">
              {availablePrograms != null ? 'Select program...' : 'Loading programs...'}
            </option>
            {(availablePrograms ?? []).map((program) => (
              <option key={program.id} value={program.id}>
                {program.display_name}
              </option>
            ))}
          </NativeSelect>
          <Button
            variant="contained"
            onClick={onStart}
            disabled={!selectedProgramId}
            startIcon={<Iconify icon="material-symbols:play-arrow" />}
          >
            Run
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
}

export type ProgramProgressProps = {
  startTime: string | null;
  endTime: string | null;
};

export function ProgramProgress({ startTime, endTime }: ProgramProgressProps) {
  const [now, updateNow] = useState<Date>(new Date());

  // Update every second
  useEffect(() => {
    const intervalId = setInterval(() => {
      updateNow(new Date());
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  if (startTime == null) {
    return null;
  }

  const elapsed = dayjs.duration(dayjs(now).diff(startTime));
  const remaining = endTime ? dayjs.duration(dayjs(endTime).diff(now)) : null;
  const percentage = remaining
    ? Math.floor(
        (elapsed.asMilliseconds() /
          dayjs.duration(dayjs(endTime).diff(startTime)).asMilliseconds()) *
          100,
      )
    : 0;

  return (
    <Box sx={{ flex: 1, display: 'flex', gap: '1em', alignItems: 'center' }}>
      <Typography variant={'data' as any}>
        {elapsed.minutes().toString().padStart(2, '0')}:
        {elapsed.seconds().toString().padStart(2, '0')}
      </Typography>

      <LinearProgress variant="determinate" value={percentage} sx={{ flex: 1, minWidth: 100 }} />
      {remaining && (
        <Typography variant={'data' as any}>
          {remaining.minutes().toString().padStart(2, '0')}:
          {remaining.seconds().toString().padStart(2, '0')}
        </Typography>
      )}
    </Box>
  );
}

function useRunData(emsId: string | null) {
  const [status, setStatus] = useState<'idle' | 'loading' | 'error'>('idle');
  const [data, setData] = useState<TestRunData | null>(null);
  const [error, setError] = useState<CactosError | null>(null);

  const fetchData = useCallback(async () => {
    if (!emsId) {
      return;
    }
    try {
      setStatus('loading');

      const response = await http.get(`/v1/ems/${emsId}/diagnostic_run`);
      setData(response.data);
      setError(null);
      setStatus('idle');
    } catch (e) {
      const error = asCactosError(e);
      console.error('Error fetching run data', e);
      setError(error);
    }
  }, [emsId]);

  useEffect(() => {
    fetchData();
    const intervalId = setInterval(() => fetchData(), TEST_RUN_REFRESH_INTERVAL);
    return () => {
      clearInterval(intervalId);
    };
  }, [fetchData]);

  return { data, status, error, forceFetch: fetchData };
}
