import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { Link } from 'react-router-dom';

type Props = {
  open: boolean;
  onClose: () => void;
};

export function MfaAlertModal({ open, onClose }: Props) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>You cannot invite new users yet</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Your account needs 2FA enabled before you can invite new users.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} autoFocus>
          Close
        </Button>
        <Link to="/dashboard/user">
          <Button>Go to settings</Button>
        </Link>
      </DialogActions>
    </Dialog>
  );
}
