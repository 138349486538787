import type React from 'react';
import { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Alert,
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  LinearProgress,
  NativeSelect,
  Tooltip,
} from '@mui/material';
import { ErrorOutline } from '@mui/icons-material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { endOfDay, startOfYear, subMinutes, subYears } from 'date-fns';
import { useSWRConfig } from 'swr';
import useSWRImmutable from 'swr/immutable';

import { asCactosError, http } from '~http';

import { type DateTimeRange, formatDateTime } from '~utils/time';

import { Page } from '~components/Page';

import { RunView } from './RunView';
import { type RunDetails } from './types';

const ALGORITHM_ID = 'base';

export function Optimizer() {
  const navigate = useNavigate();
  const { optimizerRunId } = useParams();

  const [endTime, setEndTime] = useState<Date>(() => new Date());
  const range = useMemo<DateTimeRange>(
    () => ({ start: subMinutes(endTime, 30), end: endTime }),
    [endTime],
  );

  const {
    data: selectedRun,
    isLoading: loadingSelectedRun,
    error: selectedRunError,
  } = useSingleOptimizerResult(optimizerRunId ?? null);

  const {
    data: optimizerResults,
    isLoading: loadingOptimizerResults,
    error: optimizerResultError,
  } = useOptimizerResults(ALGORITHM_ID, range.start, range.end);

  const resultsByRunID = new Map((optimizerResults ?? []).map((run) => [run.id, run]));

  const handleSelectionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const id = event.target.value;
    const run = resultsByRunID.get(id);
    navigate(`./${run?.id ?? ''}`);
  };

  const now = new Date();

  return (
    <Page title="Optimizer">
      <Box paddingX={3} paddingY={2}>
        <Box
          marginBottom={2}
          display="flex"
          flexDirection="row"
          flexWrap="wrap"
          alignItems="center"
        >
          <Box paddingRight={2}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                label="End time"
                value={endTime}
                onChange={(value: Date | null) => {
                  if (value !== null) {
                    const date = new Date(value);
                    date.setSeconds(0);
                    date.setMilliseconds(0);
                    setEndTime(date);
                  }
                }}
                minDateTime={startOfYear(subYears(now, 10))}
                maxDateTime={endOfDay(now)}
                ampm={false}
                format="yyyy-MM-dd HH:mm"
                viewRenderers={{
                  hours: null,
                  minutes: null,
                  seconds: null,
                }}
                slotProps={{
                  textField: {
                    size: 'small',
                  },
                }}
              />
            </LocalizationProvider>
          </Box>
          <FormControl>
            <InputLabel variant="standard" htmlFor="select-run">
              {loadingOptimizerResults ? 'Loading runs...' : 'Select Run'}
            </InputLabel>
            <NativeSelect
              value={resultsByRunID.has(optimizerRunId ?? '') ? optimizerRunId : 'none'}
              defaultChecked={false}
              inputProps={{ name: 'select run', id: 'select-run', style: { minWidth: '240px' } }}
              onChange={handleSelectionChange}
            >
              <option value="none" disabled>
                -
              </option>
              {(optimizerResults ?? []).map((run) => (
                <option value={run.id} key={run.id}>
                  {formatDateTime(run.run_time)}
                </option>
              ))}
            </NativeSelect>
          </FormControl>
          {loadingOptimizerResults && <CircularProgress size={18} sx={{ ml: 1.5 }} />}
          {optimizerResultError != null && (
            <Tooltip
              title={
                'Error fetching optimizer runs: ' + asCactosError(optimizerResultError).message
              }
              placement="right"
            >
              <ErrorOutline color="error" fontSize="medium" sx={{ ml: 2 }} />
            </Tooltip>
          )}
        </Box>
        {loadingSelectedRun && <LinearProgress sx={{ mb: 2 }} />}
        {selectedRunError != null && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {'Error loading run: ' + asCactosError(selectedRunError).message}
          </Alert>
        )}
        {selectedRun != null && <RunView run={selectedRun} />}
      </Box>
    </Page>
  );
}

const assertIsRunDetails = (run: any): void => {
  const keys = new Set(Object.keys(run));
  if (
    !['groups', 'units', 'id', 'run_time', 'group_results'].every((key) => keys.has(key)) ||
    run.group_results == null
  ) {
    throw new Error('Invalid response');
  }
};

async function fetchRuns(url: string) {
  const response = await http.get(url);
  const runs = response.data.details;
  for (const run of runs) {
    assertIsRunDetails(run);
  }
  return runs;
}

function useOptimizerResults(algorithmId: string, startTime: Date, endTime: Date) {
  const { mutate } = useSWRConfig();
  const key = `/v1/optimizer/runs/${algorithmId}?start=${startTime.toISOString()}&end=${endTime.toISOString()}`;
  const { data, error, isLoading } = useSWRImmutable<RunDetails[]>(key, {
    fetcher: fetchRuns,
    onSuccess: (data) => {
      data.forEach((run) => {
        // since we have the run data from the API that probably
        // shouldn't return the full JSON for each run, we might as well use it
        const key = `/v1/optimizer/run/${run.id}/details.json`;
        mutate(key, run, { revalidate: false });
      });
    },
  });

  return {
    data: data ?? null,
    error,
    isLoading,
  };
}

async function fetchSingleRun(url: string) {
  const response = await http.get(url);
  const run = response.data;
  assertIsRunDetails(run);
  return run;
}

function useSingleOptimizerResult(runID: string | null) {
  const key = runID ? `/v1/optimizer/run/${runID}/details.json` : null;
  const { data, error, isLoading } = useSWRImmutable<RunDetails>(key, { fetcher: fetchSingleRun });
  return {
    data: data ?? null,
    error,
    isLoading,
  };
}
