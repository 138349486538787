import { Alert, Box } from '@mui/material';

import { asCactosError } from '~http';

import { useReserveObjects } from '~hooks/useReserveObjects';
import { useRegisterBreadcrumbs } from '~hooks/useBreadcrumbs';

import { FingridStatusCard } from '~pages/fleet/cards/FingridStatusCard';
import { CurrentStatusCard, MonthlyAllocationsCard } from '~pages/fleet/fcr/FCR';
import { BIDDING_DOMAIN } from '~pages/v2/fleets/constants';
import { createFleetPath } from '~pages/v2/fleets/utils/createFleetPath';

import { FCRGraph } from '~components/charts/FCRGraph';

export function FleetDashboard() {
  const { data: reserveObjects, error: reserveObjectsError } = useReserveObjects();

  useRegisterBreadcrumbs('fleets', 'Dashboard', createFleetPath('dashboard'));

  return (
    <>
      {!!reserveObjectsError && (
        <Alert severity="error">
          Failed to load list of reserve objects: {asCactosError(reserveObjectsError).message}
        </Alert>
      )}
      <Box display="flex">
        <FingridStatusCard />
      </Box>
      <FCRGraph biddingDomain={BIDDING_DOMAIN} reserveObjects={reserveObjects} />
      <CurrentStatusCard reserveObjects={reserveObjects} />
      <MonthlyAllocationsCard />
    </>
  );
}
