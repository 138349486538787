import { useCallback, useMemo } from 'react';
import useSWR, { preload } from 'swr';

import type { ESUWithController, FleetFilter, ListEdgeController } from '~types';

import { fetcher } from '~http';

import { collator } from '~utils/localization';

export async function preloadESUList() {
  try {
    return await preload('/v1/edge_controller', fetcher);
  } catch {
    return null;
  }
}

export function useESUList(fleetFilter?: FleetFilter): {
  data: ESUWithController[] | undefined;
  error: Error | undefined;
  isLoading: boolean;
  refetch: () => void;
} {
  const {
    data: controllers,
    error,
    isLoading,
    mutate,
  } = useSWR<ListEdgeController[]>('/v1/edge_controller', {
    refreshInterval: 60_000,
    revalidateOnFocus: false,
    fetcher,
  });

  const esus = useMemo(() => {
    if (controllers == null) return undefined;
    const filteredControllers =
      fleetFilter === 'fleet' ? controllers.filter(isInFleet) : controllers;
    const esus = filteredControllers.flatMap((ec) =>
      ec.esus.map((esu) => ({ ...esu, edge_controller: ec })),
    );
    return esus.sort((a, b) => collator.compare(a.name, b.name));
  }, [controllers, fleetFilter]);

  const refetch = useCallback(() => mutate(), [mutate]);

  return { data: esus, error, isLoading, refetch };
}

export function useESU(esuId: string | null) {
  const {
    data: controllers,
    error,
    isLoading,
  } = useSWR<ListEdgeController[]>('/v1/edge_controller', {
    refreshInterval: 60_000,
    revalidateOnFocus: false,
    fetcher,
  });

  const esus: ESUWithController[] | undefined = useMemo(
    () => controllers?.flatMap((ec) => ec.esus.map((esu) => ({ ...esu, edge_controller: ec }))),
    [controllers],
  );

  const esu = useMemo(() => {
    if (esus == null) return undefined;
    if (esuId == null) return null;
    return esus.find((esu) => esu.id === esuId) ?? null;
  }, [esus, esuId]);

  return { data: esu, error, isLoading };
}

function isInFleet(edgeController: ListEdgeController) {
  if (
    edgeController.organization.name === '/cactos/development/' ||
    edgeController.organization.name === '/cactos/factory/' ||
    edgeController.organization.name.startsWith('/cactos/installation/')
  ) {
    // Edge controllers in the development, factory, and installation organizations
    // are considered to be not in fleet, when it comes to the UI.
    // This check can be removed when the concept of a fleet is added
    // and we start filtering units based on it.
    return false;
  }
  return edgeController.metering_group?.id != null;
}
