import { useMemo } from 'react';

import { useRegisterBreadcrumbs } from '~hooks/useBreadcrumbs';
import { useMeteringGroupList } from '~hooks/useMeteringGroupList';

import { createOrganizationPath } from '~pages/v2/organizations/utils/createOrganizationPath';
import { useSitesNavigation } from '~pages/v2/sites/hooks/useSitesNavigation';

export function useSitesHierarchyBreadcrumbs() {
  const { meteringGroupId } = useSitesNavigation();
  const { data: meteringGroups } = useMeteringGroupList();

  const meteringGroup = useMemo(
    () => meteringGroups.find((meteringGroup) => meteringGroup.id === meteringGroupId),
    [meteringGroupId, meteringGroups],
  );

  useRegisterBreadcrumbs(
    'organizations',
    meteringGroup?.organization_name ?? null,
    createOrganizationPath(meteringGroup?.organization_id ?? ''),
  );
}
