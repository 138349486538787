import { useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { AppBar, Container, MenuItem, TextField, type Theme, useTheme } from '@mui/material';

import { useLocalStorage } from '~hooks/useLocalStorage';
import { useResponsive } from '~hooks/useResponsive';
import { CurrentESUIdContextProvider } from '~hooks/useCurrentESUId';
import { useESUList } from '~hooks/useESUList';

import { APPBAR_DESKTOP, APPBAR_MOBILE } from '~layouts/dashboard/DashboardNavbar';

const Navbar = styled(AppBar)(({ theme }: { theme: Theme }) => ({
  backgroundColor: 'transparent',
  boxShadow: 'none',
  paddingLeft: theme.spacing(3),
  minHeight: APPBAR_MOBILE,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
  },
  left: '200px',
}));

export function EMSDashboardLayout() {
  const theme = useTheme();
  const isDesktop = useResponsive('up', 'lg');
  const { esuId: esuIdFromUrl } = useParams();
  const [storedEsuId, setStoredEsuId] = useLocalStorage<string | null>('currentEms', null);

  const { data: esus } = useESUList();

  const [currentEsuId, setCurrentEsuId] = useState<string | null>(
    esuIdFromUrl ?? storedEsuId ?? esus?.[0]?.id ?? null,
  );

  useEffect(() => {
    if (currentEsuId != null && currentEsuId !== storedEsuId) {
      setStoredEsuId(currentEsuId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentEsuId]);

  if (currentEsuId == null) {
    const newId = esuIdFromUrl ?? storedEsuId ?? esus?.[0]?.id ?? null;
    if (newId) setCurrentEsuId(newId);
  }

  if (currentEsuId == null) {
    return null;
  }

  const selector = (
    <TextField
      select
      size="small"
      label="ESU"
      value={currentEsuId}
      onChange={(e) => setCurrentEsuId(e.target.value)}
      sx={{
        minWidth: 200,
      }}
    >
      {(esus ?? []).map((esu) => (
        <MenuItem key={esu.id} value={esu.id}>
          {esu.name ?? esu.id}
        </MenuItem>
      ))}
    </TextField>
  );

  return (
    <CurrentESUIdContextProvider value={currentEsuId}>
      {(esus ?? []).length > 1 &&
        (isDesktop ? (
          <Navbar theme={theme} sx={{ left: 200, width: 200 }}>
            {selector}
          </Navbar>
        ) : (
          <Container sx={{ paddingTop: 1 }}>{selector}</Container>
        ))}
      <Outlet />
    </CurrentESUIdContextProvider>
  );
}
