import type { Tab } from '~pages/v2/organizations/types';
import { TABS } from '~pages/v2/organizations/constants';
import { useOrganizationsNavigation } from '~pages/v2/organizations/hooks/useOrganizationsNavigation';
import { createOrganizationPath } from '~pages/v2/organizations/utils/createOrganizationPath';

import { TabsRedirection } from '~components/TabsRedirection';

// Smart redirection for orgnanization index route
export function OrganizationRedirect() {
  const { organizationId, tab } = useOrganizationsNavigation();

  return (
    <TabsRedirection
      tabs={TABS}
      currentTab={tab}
      createPath={(tab: Tab) => createOrganizationPath(organizationId, tab)}
    />
  );
}
