import { useMemo } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';

import { formatLocalTime } from '~utils/time';

import {
  attachmentListSx,
  fileIcon,
  formatSize,
  isValidAttachmentURL,
} from '~pages/settings/groups/pricing/AttachmentForm';
import type {
  Attachment,
  Pricing,
  PricingItem,
  SpotItem,
} from '~pages/settings/groups/pricing/types';
import { downloadingPricingAttachment } from '~pages/settings/groups/pricing/utils/pricing';

import { Iconify } from '~components/Iconify';

type PricingCancelConfirmationDialogProps = {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

type PricingSaveConfirmationDialogProps = {
  open: boolean;
  isNewPricing: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

type PricingSaveErrorDialogProps = {
  open: boolean;
  error: string;
  onClose: () => void;
};

type PricingDeleteConfirmationDialogProps = {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

type PricingHistoryDialogProps = {
  open: boolean;
  onClose: () => void;
  pricings: Pricing[];
  activePricing: Pricing | null;
  setPricing: (pricing: Pricing | null) => void;
};

type AttachmentListItemProps = {
  attachement: Attachment;
};

type PricingAttachmentsDialogProps = {
  open: boolean;
  attachments: Attachment[];
  onClose: () => void;
};

export function PricingCancelConfirmationDialog({
  open,
  onClose,
  onConfirm,
}: PricingCancelConfirmationDialogProps) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Confirm cancel</DialogTitle>

      <DialogContent>
        <DialogContentText>
          Are you sure you want to cancel? Changes will not be be saved.
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="primary">
          Keep editing
        </Button>
        <Button onClick={onConfirm} variant="outlined" color="primary">
          Discard
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export function PricingSaveConfirmationDialog({
  open,
  onClose,
  onConfirm,
  isNewPricing,
}: PricingSaveConfirmationDialogProps) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Confirm save</DialogTitle>

      <DialogContent>
        <DialogContentText>
          {`Are you sure you want to ${isNewPricing ? 'create' : 'update'} the pricing?`}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="primary">
          Keep editing
        </Button>
        <Button onClick={onConfirm} variant="outlined" color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export function PricingSaveErrorDialog({ open, onClose, error }: PricingSaveErrorDialogProps) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Typography fontWeight="bold">Saving failed</Typography>
      </DialogTitle>

      <DialogContent>
        <FormControl>
          <FormLabel>
            <Typography fontWeight="medium">Error while saving:</Typography>
          </FormLabel>

          <DialogContentText>{error}</DialogContentText>
        </FormControl>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} variant="outlined" color="error">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export function PricingDeleteConfirmationDialog({
  open,
  onClose,
  onConfirm,
}: PricingDeleteConfirmationDialogProps) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Confirm Delete</DialogTitle>

      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete? This action cannot be undone.
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={onConfirm} variant="outlined" color="error">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export function PricingHistoryDialog({
  open,
  onClose,
  pricings,
  activePricing,
  setPricing,
}: PricingHistoryDialogProps) {
  const renderItems = (energyItems: PricingItem[], spotItems: SpotItem[], currency: string) => {
    if (energyItems.length === 1 && spotItems.length === 1) {
      const [spotItem] = spotItems;
      const [energyItem] = energyItems;

      return (
        <Typography>
          {spotItem.factor} x SPOT {Number(energyItem.amount) < 0 ? '' : '+'}
          {energyItem.amount} {currency}
          /kWh
        </Typography>
      );
    }

    if (energyItems.length === 1 && spotItems.length === 0) {
      const [energyItem] = energyItems;

      return (
        <Typography>
          {energyItem.amount} {currency}/kWh fixed
        </Typography>
      );
    }

    if (energyItems.length === 0 && spotItems.length === 1) {
      const [spotItem] = spotItems;
      return <Typography>{spotItem.factor} SPOT</Typography>;
    }

    return (
      <Typography>{`${spotItems.length} spot items and ${energyItems.length} energy items`}</Typography>
    );
  };

  const renderListItem = (
    direction: string,
    energyItems: PricingItem[],
    spotItems: SpotItem[],
    currency: string,
  ) => (
    <li>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 0.5,
        }}
      >
        <Typography fontWeight="medium">{direction}:</Typography>
        {renderItems(energyItems, spotItems, currency)}
      </Box>
    </li>
  );

  const theme = useTheme();

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Pricing history</DialogTitle>

      <DialogContent>
        <List>
          {pricings?.map((pricing) => {
            const importEnergyItems = pricing.items.filter(
              (item) => item.category === 'ENERGY' && item.direction === 'IMPORT',
            );
            const importSpotItems = pricing.spot_items.filter(
              (item) => item.direction === 'IMPORT',
            );
            const exportEnergyItems = pricing.items.filter(
              (item) => item.category === 'ENERGY' && item.direction === 'EXPORT',
            );
            const exportSpotItems = pricing.spot_items.filter(
              (item) => item.direction === 'EXPORT',
            );

            return (
              <ListItem key={pricing.id}>
                <ListItemButton
                  selected={pricing.id === activePricing?.id}
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    gap: 2,
                  }}
                  onClick={() => {
                    setPricing(pricing);
                    onClose();
                  }}
                >
                  <Box>
                    <Typography fontWeight="medium">
                      Start time: {formatLocalTime(pricing.start_time)}
                    </Typography>

                    <ul
                      style={{
                        marginRight: '2rem',
                        marginLeft: '1rem',
                        color: theme.palette.grey[400],
                      }}
                    >
                      {renderListItem(
                        'IMPORT',
                        importEnergyItems,
                        importSpotItems,
                        pricing.currency,
                      )}

                      {renderListItem(
                        'EXPORT',
                        exportEnergyItems,
                        exportSpotItems,
                        pricing.currency,
                      )}
                    </ul>
                  </Box>

                  {pricing.current ? (
                    <Chip
                      label={<Typography>Active</Typography>}
                      size="medium"
                      color="success"
                      variant="outlined"
                    />
                  ) : (
                    <Chip
                      label={<Typography>Inactive</Typography>}
                      size="medium"
                      color="error"
                      variant="outlined"
                    />
                  )}
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </DialogContent>

      <DialogActions>
        <Button color="primary" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function AttachmentListItem({ attachement }: AttachmentListItemProps) {
  return (
    <ListItem
      sx={{
        display: 'flex',
        flexDirection: 'row',
        borderRadius: 1,
        p: 2,
        gap: 1,
      }}
    >
      <IconButton disabled sx={{ width: '5rem' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'center' }}>
          {attachement.content_metadata.file ? (
            <>
              <Iconify icon={fileIcon(attachement.content_metadata.name)} />
              <Typography>{formatSize(attachement.content_length || 0)}</Typography>
            </>
          ) : (
            <>
              <Iconify icon="mdi:open-in-new" />
              <Typography>URL</Typography>
            </>
          )}
        </Box>
      </IconButton>

      <Grid container spacing={4} justifyContent="space-between" alignItems="center">
        <Grid item xs={6}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <TextField
              fullWidth
              label="Name"
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              value={attachement.content_metadata.name}
              size="small"
            />

            {!attachement.content_metadata.file && (
              <TextField
                label="URL"
                size="small"
                InputProps={{
                  readOnly: true,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                value={attachement.content_metadata.url}
              />
            )}

            <TextField
              size="small"
              label="Description"
              multiline
              value={attachement.content_metadata.description}
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>
        </Grid>

        <Grid item xs={6}>
          <FormLabel>Direction</FormLabel>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <FormControlLabel
              label="Import"
              sx={{ cursor: 'auto' }}
              control={
                <Checkbox
                  readOnly
                  disableRipple
                  checked={attachement.content_metadata.direction.includes('IMPORT')}
                  sx={{ cursor: 'auto' }}
                />
              }
            />
            <FormControlLabel
              label="Export"
              sx={{ cursor: 'auto' }}
              control={
                <Checkbox
                  readOnly
                  disableRipple
                  checked={attachement.content_metadata.direction.includes('EXPORT')}
                  sx={{ cursor: 'auto' }}
                />
              }
            />
          </Box>

          <FormLabel>Category</FormLabel>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <FormControlLabel
              label="Transfer"
              sx={{ cursor: 'auto' }}
              control={
                <Checkbox
                  readOnly
                  disableRipple
                  checked={attachement.content_metadata.category.includes('TRANSFER')}
                  sx={{ cursor: 'auto' }}
                />
              }
            />
            <FormControlLabel
              label="Energy"
              sx={{ cursor: 'auto' }}
              control={
                <Checkbox
                  readOnly
                  disableRipple
                  checked={attachement.content_metadata.category.includes('ENERGY')}
                  sx={{ cursor: 'auto' }}
                />
              }
            />
            <FormControlLabel
              label="Fee"
              sx={{ cursor: 'auto' }}
              control={
                <Checkbox
                  readOnly
                  disableRipple
                  checked={attachement.content_metadata.category.includes('FEE')}
                  sx={{ cursor: 'auto' }}
                />
              }
            />
            <FormControlLabel
              label="Tax"
              sx={{ cursor: 'auto' }}
              control={
                <Checkbox
                  readOnly
                  disableRipple
                  checked={attachement.content_metadata.category.includes('TAX')}
                  sx={{ cursor: 'auto' }}
                />
              }
            />
          </Box>
        </Grid>
      </Grid>

      {attachement.id && (
        <Box>
          <Button
            startIcon={<Iconify icon="mdi:open-in-new" />}
            color="info"
            variant="outlined"
            onClick={() => {
              if (attachement.content_metadata.url) {
                const { url } = attachement.content_metadata;

                if (url && isValidAttachmentURL(url)) {
                  window.open(url, '_blank');
                }
              } else if (
                attachement.content_metadata.file &&
                attachement.id &&
                attachement.content_metadata.name
              ) {
                downloadingPricingAttachment(attachement.id, attachement.content_metadata.name);
              }
            }}
          >
            {attachement.content_metadata.url ? 'Open' : 'Download'}
          </Button>
        </Box>
      )}
    </ListItem>
  );
}

export function PricingAttachmentsDialog({
  open,
  attachments,
  onClose,
}: PricingAttachmentsDialogProps) {
  const attachmentsMemo = useMemo(
    () =>
      attachments
        .filter((attachement) => attachement.id)
        .map((attachement) => (
          <AttachmentListItem key={attachement.id} attachement={attachement} />
        )),
    [attachments],
  );

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle>Pricing attachments</DialogTitle>

      <DialogContent>
        <FormLabel>Attachments</FormLabel>
        {attachmentsMemo.length > 0 && <List sx={attachmentListSx}>{attachmentsMemo}</List>}
      </DialogContent>

      <DialogActions>
        <Box>
          <Button onClick={onClose} color="primary">
            Close
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
