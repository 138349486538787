import { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  type MRT_ColumnDef,
  type MRT_ColumnSizingState,
  type MRT_TableInstance,
  MaterialReactTable,
  useMRT_Rows,
  useMaterialReactTable,
} from 'material-react-table';
import { Box, Button, Typography } from '@mui/material';

import type { Organization } from '~types';

import { USER_ROLES } from '~constants/auth';

import { collator } from '~utils/localization';

import { useLocalStorage } from '~hooks/useLocalStorage';
import { useOrganizationList } from '~hooks/useOrganizationList';
import { ResultListContextProvider } from '~hooks/useResultListContext';
import {
  CurrentOrganizationIdProvider,
  useCurrentOrganizationId,
} from '~hooks/useCurrentOrganizationId';
import { useOrganization } from '~hooks/useOrganization';
import { useUserHasRootRole } from '~hooks/useUserHasRootRole';

import { OrganizationCreateModal } from '~pages/settings/organizations/OrganizationCreateModal';

import { SplitLayout } from '~components/SplitLayout';
import { StyledToaster } from '~components/StyledToaster';

function TableFooter({ table }: { table: MRT_TableInstance<Organization> }) {
  const rows = useMRT_Rows(table);

  return <>{rows.length} organizations</>;
}

const columns: MRT_ColumnDef<Organization>[] = [
  {
    header: 'Name',
    id: 'human_name',
    accessorFn: (row) => row.human_name,
    sortingFn: (a, b, columnId) => collator.compare(a.getValue(columnId), b.getValue(columnId)),
    size: 400,
    Footer: TableFooter,
  },
];

export function OrganizationSettingsList() {
  const { data } = useOrganizationList();
  const { organizationId } = useParams();
  const navigate = useNavigate();

  const userCanCreateOrganization = useUserHasRootRole(USER_ROLES.CREATE_ORGANIZATION);

  const [isCreateOrganizationModalOpen, setIsCreateOrganizationModalOpen] = useState(false);

  const organization = useMemo(
    () => data?.find((org) => org.id === organizationId) ?? null,
    [data, organizationId],
  );

  const rowSelection = useMemo(
    () => (organizationId ? { [organizationId]: true } : {}),
    [organizationId],
  );
  const [columnSizing, setColumnSizing] = useLocalStorage<MRT_ColumnSizingState>(
    'settings.organizations.columnSizing',
    {},
  );

  const table = useMaterialReactTable<Organization>({
    data: data ?? [],
    columns,
    initialState: {
      density: 'compact',
    },
    state: {
      rowSelection,
      columnSizing: columnSizing ?? {},
    },
    onColumnSizingChange: setColumnSizing,
    enablePagination: false,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableColumnResizing: true,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableTableFooter: true,
    enableBottomToolbar: false,
    positionToolbarAlertBanner: 'none',
    getRowId: (row) => row.id,
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => {
        if (row.id) {
          navigate(`/settings/organizations/${row.id}`);
        } else {
          navigate('/settings/organizations');
        }
      },
      sx: { cursor: 'pointer' },
    }),
    muiTableContainerProps: { sx: { maxHeight: 'calc(100vh - 189px)' } },
    muiTablePaperProps: { sx: { borderRadius: 0 } },
    muiTableFooterProps: { sx: { minHeight: 60 } },
  });
  const rows = useMRT_Rows(table);
  const resultListContext = useMemo(() => {
    const currentRowIndex = organizationId
      ? rows.findIndex((row) => row.id === organizationId)
      : -1;
    const totalRowCount = rows.length;
    return {
      currentRowIndex,
      totalRowCount,
      goToNextResult: () => {
        if (currentRowIndex + 1 < totalRowCount) {
          navigate(`/settings/organizations/${rows[currentRowIndex + 1].id}`);
        }
      },
      goToPreviousResult: () => {
        if (currentRowIndex > 0) {
          navigate(`/settings/organizations/${rows[currentRowIndex - 1].id}`);
        }
      },
      goToResultList: () => navigate('/settings/organizations'),
    };
  }, [rows, navigate, organizationId]);

  return (
    <>
      <CurrentOrganizationIdProvider value={organizationId ?? null}>
        <ResultListContextProvider value={resultListContext}>
          <SplitLayout showDetails={!!organization} renderTitle={() => <Title />}>
            {userCanCreateOrganization && (
              <Box display="flex" justifyContent="flex-end" marginBottom={2} marginRight={2}>
                <Button onClick={() => setIsCreateOrganizationModalOpen(true)}>
                  Create organization
                </Button>
              </Box>
            )}
            <MaterialReactTable table={table} />
          </SplitLayout>
        </ResultListContextProvider>
      </CurrentOrganizationIdProvider>
      <StyledToaster />
      <OrganizationCreateModal
        open={isCreateOrganizationModalOpen}
        onClose={() => setIsCreateOrganizationModalOpen(false)}
      />
    </>
  );
}

function Title() {
  const organizationId = useCurrentOrganizationId();
  const { data: organization } = useOrganization(organizationId);

  if (!organization) return null;

  return <Typography variant="subtitle1">{organization.human_name}</Typography>;
}
