export const ROUTER_MATCH_PATH = '/v2/esus/:esuId/:tab';

export const TABS = {
  dashboard: 'Dashboard',
  diagnostics: 'Diagnostics',
  control: 'Control',
  schedule: 'Schedule',
  reports: 'Reports',
  settings: 'Settings',
};
