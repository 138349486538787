import {
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from '@mui/material';

type Props = {
  values: number[];
  onChange: (values: number[]) => void;
  label: string;
  options: Record<string, number>;
  renderValue: (selected: number[]) => string;
  error: boolean;
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export function BaseRangePicker({ values, onChange, label, options, renderValue, error }: Props) {
  const optionValues = Object.values(options);
  const areAllOptionsSelected =
    optionValues.every((value) => values.includes(value)) && optionValues.length === values.length;

  return (
    <FormControl fullWidth error={error}>
      <InputLabel id="base-range-picker" size="small">
        {label}
      </InputLabel>
      <Select
        labelId="base-range-picker"
        multiple
        value={values}
        onChange={(event) => onChange(event.target.value as number[])}
        input={<OutlinedInput label={label} />}
        renderValue={renderValue}
        MenuProps={MenuProps}
        size="small"
      >
        {Object.entries(options).map(([name, value]) => (
          <MenuItem key={value} value={value}>
            <Checkbox checked={values.includes(value)} />
            <ListItemText primary={name} />
          </MenuItem>
        ))}
      </Select>
      <Box display="flex" justifyContent="flex-end">
        <Typography
          variant="body2"
          sx={{ cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }}
          color="primary"
          onClick={() => onChange(areAllOptionsSelected ? [] : optionValues)}
        >
          {areAllOptionsSelected ? 'Deselect all' : 'Select all'}
        </Typography>
      </Box>
    </FormControl>
  );
}
