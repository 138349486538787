import { useGroupedFleetDiagnostics } from '~hooks/useGroupedFleetDiagnostics';

import { FleetCellVoltageDiffHistogram } from '~components/charts/FleetCellVoltageDiffHistogram';

export function CellVoltageDifference() {
  const {
    data: fleetDiagnosticsData,
    isLoading: fleetDiagnosticsLoading,
    error: fleetDiagnosticsError,
  } = useGroupedFleetDiagnostics();

  return (
    <FleetCellVoltageDiffHistogram
      data={fleetDiagnosticsData.byVoltageDifference}
      isLoading={fleetDiagnosticsLoading}
      error={fleetDiagnosticsError}
      height={250}
    />
  );
}
