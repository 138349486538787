import {
  WEEKDAYS,
  formatEffectivePeriodWeekdays,
  printEffectivePeriodWeekdays,
  validateEffectivePeriodWeekdays,
} from '~pages/settings/groups/pricing/utils/effectivePeriod';
import { BaseRangePicker } from '~pages/settings/groups/pricing/effectivePeriod/BaseRangePicker';

type Props = {
  values: number[];
  onChange: (values: number[]) => void;
};

export function WeekdayRangePicker({ values, onChange }: Props) {
  return (
    <BaseRangePicker
      values={values}
      onChange={onChange}
      label="Weekdays"
      options={WEEKDAYS}
      renderValue={printEffectivePeriodWeekdays}
      error={!validateEffectivePeriodWeekdays(formatEffectivePeriodWeekdays(values))}
    />
  );
}
