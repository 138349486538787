import { type Dispatch, type SetStateAction, useCallback, useEffect, useState } from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  type SelectChangeEvent,
} from '@mui/material';

import type { User, UserRole } from '~types';

import { ROOT_ORGANIZATION, USER_ROLES } from '~constants/auth';

import { SIDEBAR_WIDTH } from '~layouts/AppLayout/Sidebar';

const ALL_ROLE_VALUES = Object.values(USER_ROLES).sort((a, b) => a.localeCompare(b)) as UserRole[];

type DevRoleSelectorProps = {
  user: User | null;
  setUser: Dispatch<SetStateAction<User | null>>;
};
export function DevRoleSelector({ user, setUser }: DevRoleSelectorProps) {
  const [visible, setVisible] = useState(false);

  const roles = Object.values(user?.roles ?? {})
    .flat()
    .filter((role) => ALL_ROLE_VALUES.includes(role));

  const handleRolesChange = useCallback(
    (event: SelectChangeEvent<typeof roles>) => {
      const value = event.target.value;
      const nextRoles = typeof value === 'string' ? (value.split(',') as UserRole[]) : value;

      setUser((user) => {
        if (!user) return user;

        const nextUser: typeof user = {
          ...user,
          roles: { [ROOT_ORGANIZATION]: nextRoles },
          organizationsByRole: Object.fromEntries(
            nextRoles.map((role) => [role, [ROOT_ORGANIZATION]]),
          ),
        };

        return nextUser;
      });
    },
    [setUser],
  );

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'k' && event.ctrlKey) {
        setVisible((visible) => !visible);
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  if (!user || !visible) return null;

  return (
    <Box
      position="fixed"
      top={0}
      left={0}
      width={SIDEBAR_WIDTH - 1}
      bgcolor="Background"
      zIndex={9999}
      p={1}
    >
      <FormControl fullWidth>
        <InputLabel id="DevRoleSelector-label">User roles</InputLabel>
        <Select
          multiple
          value={roles}
          onChange={handleRolesChange}
          labelId="DevRoleSelector-label"
          input={<OutlinedInput label="User roles" />}
          renderValue={(roles) => `${roles.length} role${roles.length > 1 ? 's' : ''}`}
        >
          {ALL_ROLE_VALUES.map((role) => (
            <MenuItem key={role} value={role}>
              {role}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
