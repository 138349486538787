import type { PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';

import { useAuth } from '~hooks/useAuth';

export function AuthenticationBouncer({ children }: PropsWithChildren) {
  const { user } = useAuth();

  if (!user) return <Navigate replace to="/login" />;

  return children;
}
