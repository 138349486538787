import { useState } from 'react';
import {
  Alert,
  Box,
  Card,
  CardHeader,
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { format } from 'date-fns';
import { color, rgb } from 'd3-color';

import { asCactosError } from '~http';

import {
  type DateTimeRangeWithNow,
  formatDateTime,
  formatRange,
  localTimeZoneOffset,
} from '~utils/time';
import { hash } from '~utils/string';

import { useESUEvents } from '~hooks/useESUEvents';

import { Scrollbar } from '~components/Scrollbar';

// generally use lighter color for "good/ok/connected/closed" in pair of events
const COLORS: { [eventName: string]: string } = {
  TCP_KEEPALIVE_REUSE: '#ffd7d9',
  TCP_CONNECTED: '#a6cee3',
  TCP_DISCONNECTED: '#1f78b4',
  TCP_CONNECTION_FAILED: '#d02670',
  INVERTER_FAULT: '#e31a1c',
  INVERTER_RECOVERY_STARTED: '#fb9a99',
  INVERTER_FAULT_CODE: '#c6c6c6',
  INVERTER_WARNING: '#f1c21b',
  GRID_UP_D7_OK: '#bdee63',
  GRID_DOWN_D7_NOT_OK: '#ad7f58',
  KA1_FEEDBACK_CLOSED: '#cab2d6',
  KA1_FEEDBACK_OPENED: '#6a3d9a',
  INV_D7_SYNC: '#12a594',
  INV_D7_NOT_SYNC: '#005d5d',
  INV_DC_VOLTAGE_MATCHES_BMS: '#cec378',
  INV_DC_VOLTAGE_DIFFERS_BMS: '#60580e',
  KA2_FEEDBACK_CLOSED: '#fdbf6f',
  KA2_FEEDBACK_OPENED: '#ff7f00',
  ETHERNET_CONNECTED: '#b2df8a',
  ETHERNET_DISCONNECTED: '#33a02c',
};

const getEventStyle = (eventName: string): { background: string; color: string } => {
  const background = COLORS[eventName] ?? stringToColor(eventName);
  const { r, g, b } = rgb(background);
  const fontColor = r * 0.299 + g * 0.587 + b * 0.114 > 145 ? '#000000' : '#FFFFFF';
  return { background, color: fontColor };
};

export function EventLog({ esuID, range }: { esuID: string; range: DateTimeRangeWithNow }) {
  const theme = useTheme();

  const [selectedEvents, setSelectedEvents] = useState(new Set(['TCP_KEEPALIVE_REUSE']));
  const [allSeenEvents, setAllSeenEvents] = useState(new Set(selectedEvents));

  const {
    data: eventData,
    isLoading: loadingEventData,
    error: eventDataError,
  } = useESUEvents(esuID, range, { ignore: Array.from(selectedEvents) });

  // remember event names so the ignore selector doesn't flash when loading
  const newEvents = (eventData?.events ?? []).filter((event) => !allSeenEvents.has(event.name));
  if (newEvents.length > 0) {
    setAllSeenEvents(new Set([...allSeenEvents, ...newEvents.map((event) => event.name)]));
  }

  return (
    <Card>
      <CardHeader
        title={
          <>Event Log {loadingEventData && <CircularProgress size={12} sx={{ mt: 1, ml: 1 }} />}</>
        }
        subheader={formatRange(range)}
      />

      <Box padding={2}>
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel id="mutiple-select-label" size="small">
            Ignore events
          </InputLabel>
          <Select
            labelId="mutiple-select-label"
            label="Ignore events"
            multiple
            variant="outlined"
            size="small"
            value={Array.from(selectedEvents).sort()}
            onChange={(event) => {
              const value = event.target.value as string[];
              setSelectedEvents(new Set(value));
            }}
            renderValue={(selected) => selected.join(', ')}
            fullWidth
            autoWidth
          >
            {Array.from(allSeenEvents)
              .sort()
              .map((name) => (
                <MenuItem key={name} value={name}>
                  <ListItemIcon>
                    <Checkbox checked={selectedEvents.has(name)} />
                  </ListItemIcon>
                  <ListItemText primary={name}>{name}</ListItemText>
                </MenuItem>
              ))}
          </Select>
        </FormControl>

        {eventDataError != null && (
          <Alert severity="error" sx={{ mt: 1, mb: 1 }}>
            {asCactosError(eventDataError)?.message}
          </Alert>
        )}

        {eventData?.resultIsIncomplete === true && (
          <Alert severity="info" sx={{ mt: 1, mb: 1 }}>
            Queried range had many events, only showing events up to{' '}
            {formatDateTime(eventData.resultsRangeEnd)}.
          </Alert>
        )}

        <Scrollbar sx={{}}>
          <TableContainer sx={{ minWidth: '450px' }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell width="30%" sx={{ whiteSpace: 'nowrap' }}>
                    Time {localTimeZoneOffset()}
                  </TableCell>
                  <TableCell width="20%" sx={{ whiteSpace: 'nowrap' }}>
                    Event name
                  </TableCell>
                  <TableCell width="50%" sx={{ whiteSpace: 'nowrap' }}>
                    Param
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={{ minWidth: '450px' }}>
                {eventData?.events == null ? (
                  <TableRow>
                    <TableCell colSpan={4} sx={{ textAlign: 'center' }}>
                      <i>Loading events...</i>
                    </TableCell>
                  </TableRow>
                ) : eventData.events.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={4} sx={{ textAlign: 'center' }}>
                      No events found for the selected range and filters.
                    </TableCell>
                  </TableRow>
                ) : (
                  eventData.events.map((event) => (
                    <TableRow
                      key={`event-${event.time.toISOString()}-${event.name}-${event.param}`}
                      sx={{ '&:nth-of-type(odd)': { backgroundColor: theme.palette.action.hover } }}
                    >
                      <TableCell sx={{ whiteSpace: 'nowrap' }}>
                        <Typography variant="data">
                          {format(event.time, 'yyyy-MM-dd HH:mm:ss.SSS')}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Chip
                          label={<Typography variant="data">{event.name}</Typography>}
                          size="small"
                          sx={getEventStyle(event.name)}
                        />
                      </TableCell>
                      <TableCell>
                        <Typography variant="data">{event.param}</Typography>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
      </Box>
    </Card>
  );
}

const stringToColor = (str: string): string => {
  const value = Math.abs(hash(str));
  const h = (value & 0x0fff) % 360;
  const s = (((value >> 12) & 0x03ff) % 60) + 20;
  const l = (((value >> 22) & 0x03ff) % 50) + 30;
  return color(`hsl(${h}, ${s}%, ${l}%)`)?.formatHex() ?? '#707070';
};
