import { useRegisterBreadcrumbs } from '~hooks/useBreadcrumbs';

import { BidsAndAllocations } from '~pages/v2/fleets/components/BidsAndAllocations';
import { UploadBids } from '~pages/v2/fleets/components/UploadBids';
import { BIDDING_DOMAIN } from '~pages/v2/fleets/constants';
import { createFleetPath } from '~pages/v2/fleets/utils/createFleetPath';

export function FleetsMarket() {
  useRegisterBreadcrumbs('fleets', 'Market', createFleetPath('market'));

  return (
    <>
      <BidsAndAllocations />
      <UploadBids biddingDomain={BIDDING_DOMAIN} />
    </>
  );
}
