import useSWR from 'swr';

import { type EdgeController, type FetchHookData } from '~types';

import { fetcher } from '~http';

export function useEdgeController(id: string): FetchHookData<EdgeController> {
  const { data, error, isLoading, mutate } = useSWR<EdgeController>(`/v1/edge_controller/${id}`, {
    refreshInterval: 60_000,
    revalidateOnFocus: false,
    fetcher,
  });

  return {
    data: data ?? null,
    error,
    isLoading,
    mutate,
  };
}
