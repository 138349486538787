/// <reference types="vite-plugin-svgr/client" />
import { type ReactNode } from 'react';

import type { User, UserRoleRequired } from '~types';

import { USER_ROLES } from '~constants/auth';

import { Iconify } from '~components/Iconify';

import ESUSettingsIcon from '~assets/icons/esu-settings.svg?react';

export type SidebarConfigItem = {
  title: string;
  path?: string;
  icon?: ReactNode;
  roleRequired?: UserRoleRequired;
  children?: SidebarConfigItem[];
};

const getIcon = (icon: string) => <Iconify icon={icon} width={22} height={22} />;

export const sidebarConfig: SidebarConfigItem[] = [
  {
    title: 'Dashboard',
    path: '/dashboard/app',
    icon: getIcon('mdi:chart-box'),
  },
  {
    title: 'Schedule',
    path: '/dashboard/schedule',
    icon: getIcon('mdi:clock'),
  },
  {
    title: 'Reports',
    path: '/dashboard/reports',
    icon: getIcon('mdi:file-table-outline'),
    roleRequired: USER_ROLES.VIEW_REPORT,
  },
  {
    title: 'Fleet',
    children: [
      {
        title: 'Overview',
        path: '/dashboard/overview',
        icon: getIcon('mdi:chart-bell-curve'),
        roleRequired: USER_ROLES.OPERATOR_VIEW,
      },
      {
        title: 'ESUs',
        path: '/dashboard/esus',
        icon: getIcon('mdi:battery-charging-50'),
        roleRequired: USER_ROLES.EMS_DIAGNOSTIC_VIEW,
      },
      {
        title: 'Controllers',
        path: '/dashboard/controllers',
        icon: getIcon('mdi:server-network-outline'),
        roleRequired: USER_ROLES.EMS_DIAGNOSTIC_VIEW,
      },
      {
        title: 'FCR',
        path: '/dashboard/fcr',
        icon: getIcon('mdi:sine-wave'),
        roleRequired: USER_ROLES.FCR_BIDDING,
      },
      {
        title: 'Optimizer',
        path: '/dashboard/optimizer',
        icon: getIcon('mdi:math-integral-box'),
        roleRequired: USER_ROLES.VIEW_OPTIMIZER_RESULT,
      },
    ],
  },
  {
    title: 'Settings',
    children: [
      {
        title: 'Group Settings',
        path: '/settings/groups',
        icon: getIcon('mdi:home-lightning-bolt-outline'),
        // Requires either OPERATOR_VIEW or VIEW_PRICING role
        roleRequired: (user: User) =>
          !!(
            user.organizationsByRole[USER_ROLES.OPERATOR_VIEW] ||
            user.organizationsByRole[USER_ROLES.VIEW_PRICING]
          ),
      },
      {
        title: 'ESU Settings',
        path: '/settings/esus',
        icon: <ESUSettingsIcon />,
        roleRequired: USER_ROLES.OPERATOR_VIEW,
      },
      {
        title: 'Organizations',
        path: '/settings/organizations',
        icon: getIcon('mdi:office-building-outline'),
        roleRequired: USER_ROLES.VIEW_ORGANIZATION,
      },
    ],
  },
  {
    title: 'Experimental',
    children: [
      {
        title: 'Explorer',
        path: '/dashboard/experimental/timeseries_explorer',
        icon: getIcon('mdi:toy-brick-search'),
        roleRequired: USER_ROLES.OPERATOR_VIEW,
      },
    ],
  },
];
