import { useMemo } from 'react';

import { isRoutePermitted } from '~utils/isRoutePermitted';

import { useAuth } from '~hooks/useAuth';

export function useIsRoutePermitted(pathname: string) {
  const { user } = useAuth();

  return useMemo(() => isRoutePermitted(user, pathname), [user, pathname]);
}
