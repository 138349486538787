import { createContext, useContext } from 'react';

type ResultListContextType = {
  currentRowIndex: number;
  totalRowCount: number;
  goToNextResult: () => void;
  goToPreviousResult: () => void;
  goToResultList: () => void;
};

const ResultListContext = createContext<ResultListContextType>({
  currentRowIndex: -1,
  totalRowCount: 0,
  goToNextResult: () => {},
  goToPreviousResult: () => {},
  goToResultList: () => {},
});

export const ResultListContextProvider = ResultListContext.Provider;

export function useResultListContext() {
  return useContext(ResultListContext);
}
