import { createContext, useContext } from 'react';

type MeteringGroupContextType = { id: string; name: string };

const MeteringGroupContext = createContext<MeteringGroupContextType | null>(null);

export const MeteringGroupContextProvider = MeteringGroupContext.Provider;

export function useMeteringGroupContext(): MeteringGroupContextType {
  const context = useContext(MeteringGroupContext);

  if (!context) {
    throw new Error('useMeteringGroupContext must be used within a MeteringGroupContextProvider');
  }

  return context;
}
