import { Alert, Box, useTheme } from '@mui/material';

import { DIAGNOSTICS_SELECTOR_BAR_Z_INDEX } from '~theme/index';

import { useCurrentEdgeControllerId } from '~hooks/useCurrentEdgeControllerId';
import { useEdgeController } from '~hooks/useEdgeControllerList';
import { useResponsive } from '~hooks/useResponsive';
import { useURLRange } from '~hooks/useURLRange';

import { DateTimeRangePicker } from '~components/DateTimeRangePicker';
import { Page } from '~components/Page';
import { CopyLinkButton } from '~components/CopyLinkButton';

import { EventLog } from '../cards/EventLog';
import { SingleESUFaultsCard } from '../cards/ESUFaultsCard';

export function Events() {
  const theme = useTheme();
  const id = useCurrentEdgeControllerId();
  const edgeController = useEdgeController(id);
  const name = edgeController?.data?.name;
  const edgeControllerType = edgeController?.data?.type;

  const [range, setRange] = useURLRange(2 * 3600);
  const stickySelectorBar = useResponsive('up', 'sm');

  return (
    <Page title={name != null ? `${name} Events` : 'Events'}>
      <Box paddingX={3}>
        <Box
          position={stickySelectorBar ? 'sticky' : 'static'}
          top={0}
          paddingTop={2}
          paddingBottom={2}
          zIndex={DIAGNOSTICS_SELECTOR_BAR_Z_INDEX}
          bgcolor={theme.palette.background.default}
          sx={{ opacity: 0.9 }}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box>
            <DateTimeRangePicker value={range} onChange={setRange} showSkipButtons />
          </Box>

          <Box>
            <CopyLinkButton
              link={
                `${window.location.origin}${window.location.pathname}` +
                `?start=${range.start.toISOString()}&end=${range.end.toISOString()}`
              }
            />
          </Box>
        </Box>

        <Box display="flex" flexDirection="column" gap={2}>
          {!range.now && <Alert severity="info">Viewing historical data.</Alert>}

          {edgeControllerType === 'ems' && <SingleESUFaultsCard esuID={id} range={range} />}

          <EventLog esuID={id} range={range} />
        </Box>
      </Box>
    </Page>
  );
}
