import { alpha, styled } from '@mui/material/styles';
import { AppBar, Box, IconButton, Stack, Toolbar } from '@mui/material';
import { useCallback, useState } from 'react';

import { useResponsive } from '~hooks/useResponsive';

import { AccountPopover } from '~layouts/dashboard/AccountPopover';

import { Iconify } from '~components/Iconify';
import { Logo } from '~components/Logo';

export const APPBAR_MOBILE = 64;
export const APPBAR_DESKTOP = 80;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 3),
  },
}));

export function DashboardNavbar({ onToggleSidebar }: { onToggleSidebar: () => void }) {
  const isDesktop = useResponsive('up', 'lg');

  const [popoverOpen, setPopoverOpen] = useState(false);
  const onTogglePopover = useCallback(() => {
    setPopoverOpen((open) => !open);
  }, [setPopoverOpen]);
  const onClosePopover = useCallback(() => {
    setPopoverOpen(false);
  }, [setPopoverOpen]);

  return (
    <RootStyle position="fixed">
      <ToolbarStyle sx={{ gap: 1 }}>
        {isDesktop ? (
          <>
            <Logo />
            <Box sx={{ flexGrow: 1 }} />
          </>
        ) : (
          <>
            <IconButton
              onClick={onToggleSidebar}
              sx={{
                mr: 1,
                color: 'text.primary',
              }}
            >
              <Iconify icon="mdi:menu" width={30} height={30} color="grey.600" />
            </IconButton>
            <Box sx={{ flexGrow: 1 }} />
            <Logo />
            <Box sx={{ flexGrow: 1 }} />
          </>
        )}
        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          <AccountPopover
            popoverOpen={popoverOpen}
            onTogglePopover={onTogglePopover}
            onClosePopover={onClosePopover}
          />
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
