import { type CSSProperties, useEffect, useMemo, useState } from 'react';
import {
  Alert,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from '@mui/material';
import { addHours } from 'date-fns';
import dayjs from 'dayjs';

import { asCactosError } from '~http';

import { type DateTimeRange, currentTimeZone, formatDateTimeNoTimeZone } from '~utils/time';

import { useCurrentBidsAndAllocations } from '~hooks/useCurrentBidsAndAllocations';

import { getTimeRange } from '~pages/v2/fleets/utils/getTimeRange';
import { parseFiniteFloat } from '~pages/v2/fleets/utils/parseFiniteFloat';
import { ALLOCATION_TZ, BIDDING_DOMAIN } from '~pages/v2/fleets/constants';

import { Scrollbar } from '~components/Scrollbar';

export function BidsAndAllocations() {
  const theme = useTheme();

  const [now, setNow] = useState<Date>(new Date());
  const [range, setRange] = useState<DateTimeRange>(getTimeRange());

  const rangeHours: Date[] = useMemo(() => {
    const result = [];
    let hour = range.start;

    while (hour < range.end) {
      result.push(hour);
      hour = addHours(hour, 1);
    }

    return result;
  }, [range.start, range.end]);

  const activeHourMs = rangeHours
    .filter((hour) => hour <= now)
    .at(-1)
    ?.getTime();

  const {
    data: rangeQueryResult,
    isLoading: loadingRangeQuery,
    error: rangeQueryError,
  } = useCurrentBidsAndAllocations(BIDDING_DOMAIN, range);

  const allocationAndBidRows = useMemo(() => {
    const r = rangeQueryResult?.[`region:${BIDDING_DOMAIN}`];
    const allocationsByTime = new Map(
      (r?.fcr_allocation ?? []).map((row) => [new Date(row.time).toISOString(), row]),
    );
    const bidsByTime = new Map(
      (r?.fcr_bid ?? []).map((row) => [new Date(row.time).toISOString(), row]),
    );
    const marketPricesByTime = new Map(
      (r?.fingrid_fcr_n_market_price ?? []).map((row) => [new Date(row.time).toISOString(), row]),
    );

    return rangeHours.map((hour) => {
      const timestamp = hour.toISOString();
      const bid = bidsByTime.get(timestamp);
      const allocation = allocationsByTime.get(timestamp);
      const allocationQuantity = parseFiniteFloat(allocation?.quantity);
      const marketPrice = parseFiniteFloat(marketPricesByTime.get(timestamp)?.value);

      let allocationPrice: number | null = null;
      let allocationCurrency: string | null = null;
      if (allocationQuantity != null && allocationQuantity !== 0) {
        allocationPrice = parseFiniteFloat(allocation?.price);
        allocationCurrency = allocation?.currency ?? null;
      } else if (marketPrice != null) {
        allocationPrice = marketPrice;
        allocationCurrency = 'EUR';
      }

      return {
        time: hour,
        bidQuantity: parseFiniteFloat(bid?.quantity),
        bidPrice: parseFiniteFloat(bid?.price),
        bidCurrency: bid?.currency ?? null,
        bidAcknowledgedAt: bid?.acknowledged_at ?? null,
        allocationQuantity,
        allocationPrice,
        allocationCurrency,
        allocationReceivedAt: allocation?.received_timestamp ?? null,
      };
    });
  }, [rangeHours, rangeQueryResult]);

  const totalAllocationsByDay = allocationAndBidRows.reduce(
    (acc: { [day: string]: number }, allocation) => {
      const value = (allocation.allocationPrice ?? NaN) * (allocation.allocationQuantity ?? NaN);
      if (!Number.isFinite(value) || value === 0) return acc;
      const day = dayjs(allocation.time).tz(ALLOCATION_TZ).format('MMMM Do');
      return { ...acc, [day]: (acc[day] ?? 0) + value };
    },
    {},
  );

  const tableCellRightBorderStyle: CSSProperties = {
    borderRightWidth: '1px',
    borderRightStyle: 'solid',
    borderRightColor: theme.palette.grey[800],
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setNow(new Date());
      setRange(getTimeRange());
    }, 60_000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Card>
      <CardHeader
        title={
          <>
            Bids and allocations from {dayjs(range.start).tz(ALLOCATION_TZ).format('MMMM Do')} to{' '}
            {dayjs(range.end).tz(ALLOCATION_TZ).subtract(1, 'minute').format('MMMM Do')} (
            {ALLOCATION_TZ}){loadingRangeQuery && <CircularProgress size={16} sx={{ ml: 1 }} />}
          </>
        }
      />
      <CardContent>
        {!!rangeQueryError && (
          <Alert severity="error" sx={{ mb: 2 }}>
            Error loading data: {asCactosError(rangeQueryError).message}
          </Alert>
        )}
        {Object.entries(totalAllocationsByDay).map(([day, value]) => (
          <div key={day}>
            {day} ({ALLOCATION_TZ}) allocations total value: {value.toFixed(2)} EUR
          </div>
        ))}
        <div>Times below are shown in timezone "{currentTimeZone()}".</div>
        <Scrollbar>
          <TableContainer sx={{ minWidth: 1100, pt: 2 }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell sx={tableCellRightBorderStyle} />
                  <TableCell sx={tableCellRightBorderStyle} align="center" colSpan={3}>
                    Bids
                  </TableCell>
                  <TableCell align="center" colSpan={3}>
                    Allocations
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={tableCellRightBorderStyle}>Start time</TableCell>
                  {/* bids */}
                  <TableCell>Quantity</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell sx={tableCellRightBorderStyle}>Acknowledged at</TableCell>
                  {/* allocations */}
                  <TableCell>Quantity</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell>Received at</TableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={{ minWidth: 1100 }}>
                {allocationAndBidRows.map((row) => (
                  <TableRow
                    key={row.time.toISOString()}
                    tabIndex={-1}
                    sx={
                      row.time.getTime() === activeHourMs
                        ? { backgroundColor: theme.palette.action.hover }
                        : undefined
                    }
                  >
                    <TableCell>{formatDateTimeNoTimeZone(row.time)}</TableCell>
                    <TableCell>{row.bidQuantity}</TableCell>
                    <TableCell>
                      {row.bidPrice} {row.bidCurrency}
                    </TableCell>
                    <TableCell>
                      {row.bidAcknowledgedAt != null ? (
                        formatDateTimeNoTimeZone(row.bidAcknowledgedAt)
                      ) : row.bidQuantity != null ? (
                        <span style={{ color: theme.palette.warning.light }}>N/A</span>
                      ) : null}
                    </TableCell>
                    <TableCell>{row.allocationQuantity}</TableCell>

                    <TableCell>
                      {row.allocationPrice} {row.allocationCurrency}
                    </TableCell>
                    <TableCell>
                      {formatDateTimeNoTimeZone(row.allocationReceivedAt ?? undefined)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
      </CardContent>
    </Card>
  );
}
