import useSWR from 'swr';

import type { FetchHookData, UserRole } from '~types';

import { fetcher } from '~http';

export function useOrganizationRoles(organizationId: string): FetchHookData<UserRole[], false> {
  const { data, error, isLoading, mutate } = useSWR<UserRole[]>(
    `/v1/organization/${organizationId}/assignable_roles`,
    {
      refreshInterval: 60_000,
      revalidateOnFocus: false,
      fetcher,
    },
  );

  return {
    data: data ?? [],
    error,
    isLoading,
    mutate,
  };
}
