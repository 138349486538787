import useSWR, { preload } from 'swr';
import { useMemo } from 'react';

import { type FetchHookData, type MeteringGroup } from '~types';

import { fetcher } from '~http';

import { collator } from '~utils/localization';

export async function preloadMeteringGroupList() {
  try {
    return await preload('/v1/metering_group', fetcher);
  } catch {
    return null;
  }
}

export function useMeteringGroupList(): FetchHookData<MeteringGroup[], false> {
  const {
    data = [],
    error,
    isLoading,
    mutate,
  } = useSWR<MeteringGroup[]>('/v1/metering_group', {
    refreshInterval: 60_000,
    revalidateOnFocus: false,
    fetcher,
  });

  const sortedData = useMemo(
    () => [...data].sort((a, b) => collator.compare(a.name, b.name)),
    [data],
  );

  return {
    data: sortedData,
    error,
    isLoading,
    mutate,
  };
}
