import { Box } from '@mui/material';

import { type ESUWithController } from '~types';

import { USER_ROLES } from '~constants/auth';

import { useAuth } from '~hooks/useAuth';
import { useCurrentESUId } from '~hooks/useCurrentESUId';
import { useESU } from '~hooks/useESUList';

import { Page } from '~components/Page';

import { OverrideForm } from './OverrideForm';
import { ProgramPanel } from './ProgramPanel';
import { ScheduleUpload } from './ScheduleUpload';

export function ESUControl() {
  const { user } = useAuth();
  const esuId = useCurrentESUId();
  const esu = useESU(esuId).data ?? null;
  const organizationsByRole = user?.organizationsByRole ?? {};
  return (
    <Page title={esu != null ? `${esu.name} Control` : 'Control'}>
      <Box paddingX={3} paddingY={2}>
        {USER_ROLES.EMS_DIAGNOSTIC_RUN in organizationsByRole && <ProgramPanel />}
        {canEditEsuSchedule(esu, organizationsByRole) && (
          <>
            <OverrideForm />
            <ScheduleUpload />
          </>
        )}
      </Box>
    </Page>
  );
}

function canEditEsuSchedule(
  esu: ESUWithController | null,
  organizationsByRole: Record<string, string[]>,
) {
  if (esu == null) {
    return false;
  }
  const organizations = organizationsByRole[USER_ROLES.EDIT_EMS_SCHEDULE] ?? [];
  return organizations.some((organizationName) =>
    esu.edge_controller.organization.name.startsWith(organizationName),
  );
}
