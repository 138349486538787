import { EMSOptimizerConfiguration } from '~pages/settings/esus/EMSOptimizerConfiguration';

import { ScrollView } from '~components/ScrollView';

export function ESUSettings() {
  return (
    <ScrollView>
      <EMSOptimizerConfiguration />
    </ScrollView>
  );
}
