import { useRegisterBreadcrumbs } from '~hooks/useBreadcrumbs';

import { ESUFaultsCard } from '~pages/fleet/cards/ESUFaultsCard';
import { FleetIssuesCard } from '~pages/fleet/cards/FleetIssuesCard';
import { BatteryMaxTemperatures } from '~pages/v2/fleets/components/BatteryMaxTemperatures';
import { AmbientTemperatures } from '~pages/v2/fleets/components/AmbientTemperatures';
import { CellVoltageDifference } from '~pages/v2/fleets/components/CellVoltageDifference';
import { createFleetPath } from '~pages/v2/fleets/utils/createFleetPath';

export function FleetsDiagnostics() {
  useRegisterBreadcrumbs('fleets', 'Diagnostics', createFleetPath('diagnostics'));

  return (
    <>
      <FleetIssuesCard />
      <ESUFaultsCard />
      <BatteryMaxTemperatures />
      <AmbientTemperatures />
      <CellVoltageDifference />
    </>
  );
}
