import { type Components, type Theme } from '@mui/material/styles';

export const components: Components<Theme> = {
  MuiCssBaseline: {
    styleOverrides: {
      ':root': {
        colorScheme: 'dark',
      },
    },
  },
  MuiAppBar: {
    styleOverrides: {
      root: {
        backgroundImage: 'none',
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      input: ({ theme }) => ({
        '&:-webkit-autofill': {
          WebkitBoxShadow: `0 0 0 100px ${theme.palette.secondary.main} inset`,
        },
      }),
    },
  },
  MuiListItemIcon: {
    styleOverrides: {
      root: ({ theme }) => ({
        color: 'inherit',
        minWidth: 'auto',
        marginRight: theme.spacing(2),
      }),
    },
  },
  MuiListItemAvatar: {
    styleOverrides: {
      root: ({ theme }) => ({
        minWidth: 'auto',
        marginRight: theme.spacing(2),
      }),
    },
  },
  MuiLink: {
    styleOverrides: {
      root: {
        cursor: 'pointer',
      },
    },
  },
  MuiDialog: {
    styleOverrides: {
      paper: ({ theme }) => ({
        [theme.breakpoints.down('sm')]: {
          minWidth: 'min(512px, 100%)',
        },
      }),
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: ({ theme }) => ({
        minWidth: 512,
        [theme.breakpoints.down('sm')]: {
          minWidth: '100%',
        },
      }),
    },
  },
};
