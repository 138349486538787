import { matchRoutes } from 'react-router-dom';

import type { User, UserRole } from '~types';

import { ROOT_ORGANIZATION } from '~constants/auth';

import { routerConfiguration } from '../router';

type Permission = {
  roleRequired?: UserRole | UserRole[];
  rootRoleRequired?: UserRole | UserRole[];
};

export function isRoutePermitted(user: User | null, pathname: string) {
  const routes = matchRoutes(routerConfiguration, { pathname });

  if (!user || !routes) return false;

  return routes.every((route) => hasPermission(user, route.route.handle?.permission));
}

export function hasPermission(user: User, permission?: Permission): boolean {
  if (!permission?.roleRequired && !permission?.rootRoleRequired) return true;

  if (Array.isArray(permission.rootRoleRequired)) {
    return permission.rootRoleRequired.some((role) => hasRole(user, role, true));
  }

  if (Array.isArray(permission.roleRequired)) {
    return permission.roleRequired.some((role) => hasRole(user, role, false));
  }

  if (permission.rootRoleRequired) {
    return hasRole(user, permission.rootRoleRequired, true);
  }

  if (permission.roleRequired) {
    return hasRole(user, permission.roleRequired, false);
  }

  return true;
}

function hasRole(user: User, role: UserRole, isRootRole?: boolean) {
  if (isRootRole) return user.roles[ROOT_ORGANIZATION]?.includes(role) ?? false;

  return (user.organizationsByRole[role]?.length ?? 0) > 0;
}
