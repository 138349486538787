import dayJs from 'dayjs';

export const MONTHS = Array.from({ length: 12 }, (_, i) => ({
  label: dayJs().month(i).format('MMMM'),
  value: i + 1,
})).reduce((acc, { label, value }) => ({ ...acc, [label]: value }), {}) as Record<string, number>;

export const WEEKDAYS = Array.from({ length: 7 }, (_, i) => ({
  label: dayJs()
    .day(i + 1)
    .format('dddd'),
  value: i + 1,
})).reduce((acc, { label, value }) => ({ ...acc, [label]: value }), {}) as Record<string, number>;

export const HOURS: Record<string, number> = {};
export const HOURS_EXTENDED: Record<string, number> = {};

for (let i = 0; i <= 24; i++) {
  HOURS[`${i.toString().padStart(2, '0')}:00`] = i;
  HOURS_EXTENDED[`${i.toString().padStart(2, '0')}:00-${(i + 1).toString().padStart(2, '0')}:00`] =
    i;
}

function parseEffectivePeriodPart(
  partString: string,
  minValue: number,
  maxValue: number,
  maxInclusive: boolean,
) {
  const values: number[] = [];

  if (!partString) return values;

  const finalMaxValue = maxInclusive ? maxValue + 1 : maxValue;
  const parts = partString.split(',');

  for (const part of parts) {
    if (part.includes('-')) {
      const [start, end] = part.split('-').map(Number);

      if (
        start !== start ||
        end !== end ||
        start < minValue ||
        start > finalMaxValue ||
        end < minValue ||
        end > finalMaxValue
      ) {
        continue;
      }

      for (let i = start; i <= end + (maxInclusive ? -1 : 0); i++) {
        values.push(i);
      }
    } else {
      const n = Number(part);

      if (n !== n) continue;
      if (n < minValue || n > finalMaxValue) continue;

      values.push(n);
    }
  }

  return values.sort((a, b) => a - b);
}

function formatEffectivePeriodPart(
  values: number[],
  minValue: number,
  maxValue: number,
  maxInclusive: boolean,
) {
  if (values.length === 0) return '';

  const workingValues = values.filter((x) => x >= minValue && x <= maxValue).sort((a, b) => a - b);

  const ranges: string[] = [];
  let start = workingValues[0];
  let end = workingValues[0];

  for (let i = 1; i < workingValues.length; i++) {
    if (workingValues[i] !== workingValues[i]) continue;

    if (workingValues[i] === end + 1) {
      end = workingValues[i];
    } else {
      ranges.push(start === end ? `${start}` : `${start}-${end + (maxInclusive ? 1 : 0)}`);
      start = workingValues[i];
      end = workingValues[i];
    }
  }

  ranges.push(start === end ? `${start}` : `${start}-${end + (maxInclusive ? 1 : 0)}`);

  return ranges.join(',');
}

function printEffectivePeriodPart(
  value: number[],
  minValue: number,
  maxValue: number,
  maxInclusive: boolean,
  stringValues: Record<string, number>,
) {
  const valueString = formatEffectivePeriodPart(value, minValue, maxValue, maxInclusive);
  const stringValuesEntries = Object.entries(stringValues);

  return valueString
    .replace(/\d+/g, (match) => {
      return stringValuesEntries.find(([, value]) => value === Number(match))?.[0] || match;
    })
    .replace(/,/g, ', ');
}

function validateEffectivePeriodPart(
  partString: string,
  minValue: number,
  maxValue: number,
  maxInclusive: boolean,
) {
  if (!partString) return false;

  const finalMaxValue = maxInclusive ? maxValue + 1 : maxValue;
  const parts = partString.split(',');
  const valueRegex = /^(\d{1,2})(-(\d{1,2}))?$/;

  for (const part of parts) {
    const match = part.match(valueRegex);

    if (!match) {
      return false;
    }

    const start = Number(match[1]);
    const end = match[3] ? Number(match[3]) : start;

    if (
      start < minValue ||
      start > finalMaxValue ||
      end < minValue ||
      end > finalMaxValue ||
      start > end
    ) {
      return false;
    }
  }

  return true;
}

export function parseEffectivePeriod(effectivePeriod: string) {
  const parts = effectivePeriod.split(';');

  return {
    weekdays: parseEffectivePeriodWeekdays(parts[0]),
    hours: parseEffectivePeriodHours(parts[1]),
    months: parseEffectivePeriodMonths(parts[2]),
  };
}

export function parseEffectivePeriodWeekdays(weekdays: string) {
  return parseEffectivePeriodPart(weekdays, 1, 7, false);
}

export function parseEffectivePeriodHours(hours: string) {
  return parseEffectivePeriodPart(hours, 0, 23, true);
}

export function parseEffectivePeriodMonths(value: string) {
  return parseEffectivePeriodPart(value, 1, 12, false);
}

export function formatEffectivePeriodWeekdays(weekdays: number[]) {
  return formatEffectivePeriodPart(weekdays, 1, 7, false);
}

export function formatEffectivePeriodHours(hours: number[]) {
  return formatEffectivePeriodPart(hours, 0, 23, true);
}

export function formatEffectivePeriodMonths(months: number[]) {
  return formatEffectivePeriodPart(months, 1, 12, false);
}

export function printEffectivePeriodWeekdays(weekdays: number[]) {
  return printEffectivePeriodPart(weekdays, 1, 7, false, WEEKDAYS);
}

export function printEffectivePeriodHours(hours: number[]) {
  return printEffectivePeriodPart(hours, 0, 23, true, HOURS);
}

export function printEffectivePeriodMonths(months: number[]) {
  return printEffectivePeriodPart(months, 1, 12, false, MONTHS);
}

export function validateEffectivePeriodWeekdays(weekdays: string) {
  return validateEffectivePeriodPart(weekdays, 1, 7, false);
}

export function validateEffectivePeriodHours(hours: string) {
  return validateEffectivePeriodPart(hours, 0, 23, true);
}

export function validateEffectivePeriodMonths(months: string) {
  return validateEffectivePeriodPart(months, 1, 12, false);
}

export function validateEffectivePeriod(effectivePeriod: string) {
  const parts = effectivePeriod.split(';');

  if (parts.length !== 3) return false;

  return (
    validateEffectivePeriodWeekdays(parts[0]) &&
    validateEffectivePeriodHours(parts[1]) &&
    validateEffectivePeriodMonths(parts[2])
  );
}

export const DEFAULT_EFFECTIVE_PERIOD_WEEKDAYS = '1-7';
export const DEFAULT_EFFECTIVE_PERIOD_HOURS = '0-24';
export const DEFAULT_EFFECTIVE_PERIOD_MONTHS = '1-12';
export const DEFAULT_EFFECTIVE_PERIOD = `${DEFAULT_EFFECTIVE_PERIOD_WEEKDAYS};${DEFAULT_EFFECTIVE_PERIOD_HOURS};${DEFAULT_EFFECTIVE_PERIOD_MONTHS}`;
