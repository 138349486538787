import { Pricing } from '~pages/settings/groups/pricing/Pricing';

import { ScrollView } from '~components/ScrollView';

export function SitePricing() {
  return (
    <ScrollView>
      <Pricing />
    </ScrollView>
  );
}
