import { addMinutes, differenceInMinutes, subMinutes } from 'date-fns';
import { useMemo } from 'react';

import { type DateTimeRange } from '~utils/time';
import { getAggregageQueryTimeStepSeconds } from '~utils/timeseries';

import {
  createAggregateQuery,
  createRangeQuery,
  useTimeseriesAggregate,
  useTimeseriesRange,
} from '~hooks/timeseries/queries';
import { type ResourceID } from '~hooks/timeseries/types';
import { useMeteringGroupList } from '~hooks/useMeteringGroupList';

export function useSiteChartData(meteringGroupId: string, range: DateTimeRange) {
  const { data: meteringGroups } = useMeteringGroupList();

  const meteringGroup = useMemo(
    () => meteringGroups.find((group) => group.id === meteringGroupId),
    [meteringGroups, meteringGroupId],
  );

  // TODO: There's currently no time series to get the grid frequency by metering group
  // so we pick an arbitrary ESU and fetch its control_grid_f instead.
  const esuResourceId: ResourceID | null = meteringGroup?.esus[0]?.resource_id ?? null;

  const shouldAggregateOverTime = differenceInMinutes(range.end, range.start) > 120;
  const aggregateTimeStepSeconds = getAggregageQueryTimeStepSeconds(range);
  const resources = esuResourceId != null ? [esuResourceId] : [];
  const {
    data: aggregateData,
    isLoading: loadingAggregateData,
    error: aggregateDataError,
  } = useTimeseriesAggregate(
    createAggregateQuery({
      resources: shouldAggregateOverTime ? resources : [],
      columns: {
        esu_power_control: ['control_grid_f:mean', 'control_grid_f:min', 'control_grid_f:max'],
      },
      start: range.start,
      end: range.end,
      step: `${aggregateTimeStepSeconds}s`,
    }),
  );

  const {
    data: rawData,
    isLoading: loadingRawData,
    error: rawDataError,
  } = useTimeseriesRange(
    createRangeQuery({
      resources: shouldAggregateOverTime ? [] : resources,
      columns: {
        esu_power_control: ['control_grid_f'],
      },
      start: range.start,
      end: range.end,
      fillStart: subMinutes(range.start, 1),
      fillEnd: addMinutes(range.end, 1),
    }),
  );

  if (shouldAggregateOverTime) {
    const data = esuResourceId != null ? aggregateData?.[esuResourceId] : null;
    return {
      data: {
        isAggregatedOverTime: true,
        esu_power_control: data?.esu_power_control ?? null,
      },
      isLoading: loadingAggregateData,
      error: aggregateDataError,
    };
  } else {
    const data = esuResourceId != null ? rawData?.[esuResourceId] : null;
    return {
      data: {
        isAggregatedOverTime: false,
        esu_power_control: data?.esu_power_control ?? null,
      },
      isLoading: loadingRawData,
      error: rawDataError,
    };
  }
}
