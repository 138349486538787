import { type ReactNode } from 'react';

import { useDocumentTitle } from '~hooks/useDocumentTitle';

type PageProps = { children: ReactNode; title: string };

export function Page(props: PageProps) {
  useDocumentTitle(props.title);
  return props.children;
}
