import { Box, Switch, Typography } from '@mui/material';

import { USER_ROLES } from '~constants/auth';

import { useLocalStorage } from '~hooks/useLocalStorage';
import { useUserHasRootRole } from '~hooks/useUserHasRootRole';

export function UiV2Settings() {
  const [isUiv2Enabled, setIsUiv2Enabled] = useLocalStorage<boolean>('ui-v2-enabled', false);

  const canViewUiV2Settings = useUserHasRootRole(USER_ROLES.VIEW_EMS);

  if (!canViewUiV2Settings) return null;

  return (
    <>
      <Typography variant="h5" gutterBottom>
        UI v2
      </Typography>
      <Box display="flex" alignItems="center">
        <Switch checked={isUiv2Enabled} onChange={(e) => setIsUiv2Enabled(e.target.checked)} />
        <Typography>Enable UI v2</Typography>
      </Box>
    </>
  );
}
