import { Container, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { UiV2Settings } from '~pages/user/UiV2Settings';

import { Page } from '~components/Page';

import { ChangePassword } from './ChangePassword';
import { SetupMFA } from './SetupMFA';

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0),
}));

export function UserSettings() {
  return (
    <Page title="User Settings">
      <ContentStyle>
        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5} mt={5}>
            <Typography variant="h3" gutterBottom>
              User settings
            </Typography>
          </Stack>
          <Typography variant="h5" gutterBottom>
            Change password
          </Typography>
          <ChangePassword />
          <Typography variant="h5" gutterBottom>
            Setup two-factor authentication
          </Typography>
          <SetupMFA />
          <UiV2Settings />
        </Container>
      </ContentStyle>
    </Page>
  );
}
