import {
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  Card,
  Chip,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { type HTMLAttributes, useEffect, useMemo, useState } from 'react';
import useSWR from 'swr';

import { fetcher } from '~http';

import { collator } from '~utils/localization';

import type { QueryResultItem, Row } from '~hooks/timeseries/types';

import { TimeSeriesMultiLineChart } from '~components/charts';
import type { ChartVariable } from '~components/charts/TimeSeriesMultiLineChart';
import { Iconify } from '~components/Iconify';

import type { TimeseriesSchemas } from './TimeSeriesExplorer';

type Variable = {
  key: string;
  resource: string;
  series: string;
  column: string;
  color: string;
  multiplier: number;
};

export type EditableChartProps = {
  results: QueryResultItem[];
  displayNameForResource: Map<string, string>;
  onRemove: () => void;
};

export const EditableChart = ({
  results,
  displayNameForResource,
  onRemove,
}: EditableChartProps) => {
  const [variables, setVariables] = useState<Variable[]>([]);
  const [showControls, setShowControls] = useState(true);

  const theme = useTheme();
  const presetColors: string[] = theme.palette.chart.categorical14;

  const addVariable = () =>
    setVariables((prev) => {
      const key = `variable-${new Date().getTime()}`;
      const existingColors = new Set(prev.map((v) => v.color));
      const color = presetColors.filter((c) => !existingColors.has(c))[0] ?? '#ffffff';
      const newVariable =
        prev.length > 0
          ? { ...prev.at(-1)!, color, key }
          : { resource: '', series: '', column: '', color, multiplier: 1, key };
      return [...prev, newVariable];
    });

  const chartData = useMemo(
    () =>
      Object.fromEntries(
        results.map((result) => [`(${result.resource})(${result.series})`, result.data as Row[]]),
      ),
    [results],
  );

  const variableSources = useMemo(() => {
    const result: { [source: string]: { variables: ChartVariable[] } } = {};
    const previousNames = new Set<string>();

    variables.forEach((variable) => {
      const source = `(${variable.resource})(${variable.series})`;
      if (!(source in result)) result[source] = { variables: [] };

      const resourceName = displayNameForResource.get(variable.resource) ?? variable.resource;
      const name = `${resourceName} ${variable.series} ${variable.column}`;
      if (previousNames.has(name)) return;
      previousNames.add(name);

      result[source].variables.push({
        sourceColumn: variable.column,
        name,
        color: variable.color,
        denominator: variable.multiplier !== 1 ? 1 / variable.multiplier : undefined,
      });
    });

    return result;
  }, [variables, displayNameForResource]);

  const knownResources = useMemo(
    () =>
      Array.from(new Set(results.map((result) => result.resource))).sort((a, b) =>
        collator.compare(displayNameForResource.get(a) ?? '', displayNameForResource.get(b) ?? ''),
      ),
    [results, displayNameForResource],
  );

  return (
    <Card>
      <Stack direction="column" padding={2} spacing={1}>
        <Box display="flex" justifyContent="flex-end">
          <ButtonGroup variant="text">
            <Button onClick={() => setShowControls((prev) => !prev)}>
              {showControls ? 'Hide controls' : 'Show controls'}
            </Button>
            <Button color="error" onClick={onRemove}>
              Remove chart
            </Button>
          </ButtonGroup>
        </Box>

        <Box sx={{ padding: '0px 10px 0px 10px' }}>
          <TimeSeriesMultiLineChart
            title="custom chart"
            units=""
            frameless
            variableSources={variableSources}
            data={chartData}
          />
        </Box>

        {showControls && (
          <Box display="flex" flexDirection="column" gap={1}>
            {variables.map((variable) => (
              <VariableView
                key={variable.key}
                variable={variable}
                onChange={(variable) => {
                  setVariables((prev) => {
                    const index = prev.findIndex((v) => v.key === variable.key);
                    const newVariables = [...prev];
                    newVariables[index] = variable;
                    return newVariables;
                  });
                }}
                onRemove={() => {
                  setVariables((prev) => prev.filter((v) => v.key !== variable.key));
                }}
                knownResources={knownResources}
                displayNameForResource={displayNameForResource}
              />
            ))}
            <Box display="flex" justifyContent="flex-end">
              <Button onClick={addVariable}>Add variable</Button>
            </Box>
          </Box>
        )}
      </Stack>
    </Card>
  );
};

type VariableViewProps = {
  variable: Variable;
  onChange: (variable: Variable) => void;
  onRemove: () => void;
  knownResources: string[];
  displayNameForResource: Map<string, string>;
};

const VariableView = ({
  variable,
  onChange: updateVariable,
  onRemove,
  knownResources,
  displayNameForResource,
}: VariableViewProps) => {
  const { data: schemas = {} } = useSWR<TimeseriesSchemas>('/v2/timeseries/schemas', {
    fetcher,
    revalidateOnFocus: false,
  });

  const resourceType = variable.resource.split(':')[0];
  const knownSeries = Object.keys(schemas[resourceType] ?? {});
  const columns = Object.keys(schemas[resourceType]?.[variable.series]?.columns ?? {});
  const knownColumns = [
    ...columns,
    ...columns.flatMap((column) => [
      `${column}:mean`,
      `${column}:min`,
      `${column}:max`,
      `${column}:sum`,
    ]),
  ];

  // hooking up the color picker directly to updateVariable causes
  // hunders of renders per second if the user is dragging in the color picker
  const [color, setColor] = useState<string>(variable.color);
  useEffect(() => {
    const timeout = setTimeout(() => updateVariable({ ...variable, color }), 20);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [color]);

  return (
    <Box display="flex" gap={1} alignItems="center">
      <Autocomplete
        freeSolo
        options={knownResources}
        value={variable.resource}
        onChange={(_, value) => updateVariable({ ...variable, resource: value ?? '' })}
        renderInput={(params) => <TextField {...params} label="Resource" size="small" />}
        style={{ flexGrow: 1, flexBasis: '10px' }}
        componentsProps={{ popper: { style: { width: 'fit-content' } } }}
        filterOptions={(options, state) => {
          const input = state.inputValue.toLowerCase();
          return options.filter(
            (option) =>
              option.includes(input) ||
              displayNameForResource.get(option)?.toLowerCase().includes(input) === true,
          );
        }}
        renderOption={(props, option) => {
          const { key, ...optionProps } = props as HTMLAttributes<HTMLLIElement> & {
            key: string;
          };
          return (
            <Box key={key} component="li" {...optionProps}>
              <Typography variant="data">{option}</Typography>{' '}
              <Chip label={displayNameForResource.get(option)} sx={{ ml: 2 }} />
            </Box>
          );
        }}
      />

      <Autocomplete
        freeSolo
        options={knownSeries}
        value={variable.series}
        onChange={(_, value) => updateVariable({ ...variable, series: value ?? '' })}
        renderInput={(params) => <TextField {...params} label="Series" size="small" />}
        style={{ flexGrow: 1, flexBasis: '10px' }}
        componentsProps={{ popper: { style: { width: 'fit-content' } } }}
      />

      <Autocomplete
        freeSolo
        options={knownColumns}
        value={variable.column}
        onChange={(_, value) => updateVariable({ ...variable, column: value ?? '' })}
        renderInput={(params) => <TextField {...params} label="Column" size="small" />}
        style={{ flexGrow: 1, flexBasis: '10px' }}
        componentsProps={{ popper: { style: { width: 'fit-content' } } }}
      />

      <TextField
        label="Multiplier"
        defaultValue={variable.multiplier !== 1 ? variable.multiplier : ''}
        onChange={(event) => {
          const float = parseFloat(event.target.value);
          if (Number.isFinite(float)) {
            updateVariable({ ...variable, multiplier: float });
          }
        }}
        size="small"
        style={{ flexGrow: 1, flexBasis: '10px' }}
      />

      <input
        type="color"
        style={{ height: '35px' }}
        value={variable.color}
        onChange={(event) => setColor(event.target.value)}
      />

      <Tooltip title="Remove variable" placement="top">
        <IconButton color="error" onClick={onRemove}>
          <Iconify icon="mdi:remove-circle-outline" />
        </IconButton>
      </Tooltip>
    </Box>
  );
};
