import { GroupMemberList } from '~pages/settings/groups/GroupMemberList';

import { ScrollView } from '~components/ScrollView';

export function SiteESUs() {
  return (
    <ScrollView>
      <GroupMemberList />
    </ScrollView>
  );
}
