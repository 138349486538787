import { useCallback, useEffect, useState } from 'react';
import { Document, Page as PdfPage, pdfjs } from 'react-pdf';
import { Button, Container, Pagination, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import { BASE_URL, plainHttp } from '~http';

import { useAbortController } from '~hooks/useAbortController';

// Use external CDN because I can't figure out how to include it in the build
// see e.g. https://github.com/wojtekmaj/react-pdf/issues/782
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

export function Terms({ acceptTerms, token }) {
  const [chosenTerms, setChosenTerms] = useState();
  const [doc, setDoc] = useState(null);
  const [pageNum, setPageNum] = useState(1);
  const [numPages, setNumPages] = useState(1);
  const [allTerms, setAllTerms] = useState([]);

  const onDocumentLoadSuccess = ({ numPages: nextNumPages }) => {
    setNumPages(nextNumPages);
  };

  const getSignal = useAbortController();

  const updateDoc = useCallback(async () => {
    if (!chosenTerms) {
      return;
    }
    try {
      const response = await fetch(new URL(`v1/terms/${chosenTerms}`, BASE_URL), {
        signal: getSignal(),
        headers: {
          Authorization: `Bearer-Userless ${token}`,
        },
      });
      setDoc(await response.arrayBuffer());
    } catch (e) {
      setDoc(null);
    }
  }, [chosenTerms, getSignal, token]);

  useEffect(() => {
    updateDoc();
  }, [updateDoc]);

  const updateAllTerms = useCallback(async () => {
    const response = await plainHttp.get(`/v1/terms`, {
      signal: getSignal(),
      headers: {
        Authorization: `Bearer-Userless ${token}`,
      },
    });
    const terms = response.data ?? [];
    if (terms.length === 1) {
      setChosenTerms(terms[0].terms_id);
    }
    setAllTerms(terms);
  }, [getSignal, token]);

  useEffect(() => {
    updateAllTerms();
  }, [updateAllTerms]);

  const handlePageChange = (e, page) => {
    setPageNum(page);
  };

  const handleDownload = () => {
    const blob = new Blob([doc], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    const fileLink = document.createElement('a');
    fileLink.href = url;
    fileLink.download = 'Cactos_terms_of_use.pdf';
    fileLink.click();
  };

  return (
    <Container maxWidth="xl">
      <Stack alignItems="center">
        <Typography sx={{ mt: 5, mb: 5 }}>
          You must accept the Cactos Spine terms of use before logging in.
        </Typography>
        {allTerms.length > 0 && !chosenTerms && (
          <>
            <Typography sx={{ mt: 5, mb: 5 }}>Choose language:</Typography>
            {allTerms.map((terms) => (
              <Button
                sx={{ mb: 3 }}
                component="label"
                variant="contained"
                onClick={() => setChosenTerms(terms.terms_id)}
                key={terms.terms_id}
              >
                {terms.language}
              </Button>
            ))}
          </>
        )}
        {chosenTerms && (
          <>
            <Pagination
              count={numPages}
              defaultPage={1}
              page={pageNum}
              onChange={handlePageChange}
            />
            <Document file={doc} onLoadSuccess={onDocumentLoadSuccess} width={600}>
              <PdfPage
                key={`page_${pageNum}`}
                pageNumber={pageNum}
                renderTextLayer={false}
                renderAnnotationLayer={false}
              />
            </Document>
            <Pagination
              count={numPages}
              defaultPage={1}
              page={pageNum}
              onChange={handlePageChange}
            />
            <Button sx={{ mb: 3 }} component="label" variant="contained" onClick={handleDownload}>
              Download PDF
            </Button>
            <Button
              sx={{ mt: 3 }}
              component="label"
              variant="contained"
              onClick={() => acceptTerms(chosenTerms)}
            >
              Accept terms of use
            </Button>
          </>
        )}
      </Stack>
    </Container>
  );
}

Terms.propTypes = {
  acceptTerms: PropTypes.func,
  token: PropTypes.string,
};
