import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Container, Stack, Typography } from '@mui/material';

import { plainHttp } from '~http';

import { useAbortController } from '~hooks/useAbortController';

import { Page } from '~components/Page';

import { LoginForm } from './LoginForm';

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: 'calc(100vh - 64px)',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0),
}));

export function Login() {
  const { inviteId, confirmationToken } = useParams();
  const [isConfirmation, setConfirmation] = useState(true);
  const [isConfirmed, setConfirmed] = useState(false);
  const getSignal = useAbortController();

  const confirmSignup = useCallback(async () => {
    try {
      await plainHttp.get(`/v1/invite/${inviteId}/${confirmationToken}`, {
        signal: getSignal(),
        responseType: 'text',
      });
      setConfirmed(true);
    } catch (e) {
      window.location.href = '/404';
    }
  }, [inviteId, confirmationToken, getSignal]);

  useEffect(() => {
    if (inviteId) {
      if (!confirmationToken) {
        window.location.href = '/404';
      }
      confirmSignup();
    } else {
      setConfirmation(false);
    }
  }, [inviteId, confirmationToken, confirmSignup]);

  if (isConfirmation && !isConfirmed) {
    return null;
  }

  return (
    <RootStyle title="Login | Cactos Spine">
      <Container maxWidth="sm">
        <ContentStyle>
          <Stack sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
              {(isConfirmed && 'Account confirmed! You may now sign in.') || 'Sign in to Cactos'}
            </Typography>
          </Stack>
          <LoginForm />
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
