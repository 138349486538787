import { useCallback } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Link, Breadcrumbs as MuiBreadcrumbs, Typography, styled } from '@mui/material';

import { type Breadcrumb, type BreadcrumbType, useBreadcrumbs } from '~hooks/useBreadcrumbs';

import { Iconify } from '~components/Iconify';

type BreadcrumbsProps = {
  hasIcon?: boolean;
};

type BreadcrumbIconProps = {
  breadcrumbType: BreadcrumbType;
};

const StyledBreadcrumbs = styled(MuiBreadcrumbs)({
  '.MuiBreadcrumbs-ol': {
    flexWrap: 'nowrap',
  },
  '.MuiBreadcrumbs-separator': {
    flexShrink: 0,
  },
  '.MuiBreadcrumbs-li': {
    flexShrink: 0,
  },
});

export function Breadcrumbs({ hasIcon = false }: BreadcrumbsProps) {
  const { breadcrumbs } = useBreadcrumbs();

  const renderBreadcrumb = useCallback(
    (breadcrumb: Breadcrumb, index: number) => {
      const icon = hasIcon ? <BreadcrumbIcon breadcrumbType={breadcrumb.type} /> : null;

      // The last breadcrumb should not be a link
      if (index === breadcrumbs.length - 1) {
        return <Typography key={breadcrumb.path}>{breadcrumb.label || '...'}</Typography>;
      }

      return (
        <Box key={breadcrumb.path} display="flex" alignItems="center" gap={1}>
          {icon}
          <Link
            to={breadcrumb.path}
            component={RouterLink}
            sx={{
              textDecoration: 'none',
              ':hover': {
                textDecoration: 'underline',
              },
            }}
          >
            {breadcrumb.label || '...'}
          </Link>
        </Box>
      );
    },
    [hasIcon, breadcrumbs],
  );

  return (
    <Box
      sx={{
        overflowX: 'auto',
        overflowY: 'hidden',
      }}
    >
      <StyledBreadcrumbs>{breadcrumbs.map(renderBreadcrumb)}</StyledBreadcrumbs>
    </Box>
  );
}

function BreadcrumbIcon({ breadcrumbType }: BreadcrumbIconProps) {
  switch (breadcrumbType) {
    case 'fleets':
      return <Iconify icon="mdi:sitemap" fontSize={16} />;
    case 'organizations':
      return <Iconify icon="mdi:office-building" fontSize={16} />;
    case 'sites':
      return <Iconify icon="mdi:home-lightning-bolt-outline" fontSize={16} />;
    case 'controllers':
      return <Iconify icon="mdi:server-network-outline" fontSize={16} />;
    case 'esus':
      return <Iconify icon="mdi:battery-charging-50" fontSize={16} />;
    default:
      return null;
  }
}
