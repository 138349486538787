import { type ReactNode } from 'react';
import { CssBaseline } from '@mui/material';
import { StyledEngineProvider, ThemeProvider, createTheme } from '@mui/material/styles';

import { palette } from '~theme/palette';
import { typography } from '~theme/typography';
import { components } from '~theme/components';

export const DIAGNOSTICS_SELECTOR_BAR_Z_INDEX = 100;
const DRAWER_Z_INDEX = 500;
const APP_BAR_Z_INDEX = 600;
export const DASHBOARD_SIDEBAR_Z_INDEX = 700;
export const LOADING_BAR_Z_INDEX = 800;

const theme = createTheme({
  palette,
  shape: { borderRadius: 8 },
  typography,
  zIndex: {
    appBar: APP_BAR_Z_INDEX,
    drawer: DRAWER_Z_INDEX,
  },
  components,
});

export function ThemeConfig({ children }: { children: ReactNode }) {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
