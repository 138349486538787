import type React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Card, CardHeader, CircularProgress, Stack, Tooltip, Typography } from '@mui/material';
import { ErrorOutline } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { ParentSize } from '@visx/responsive';
import { Group } from '@visx/group';
import { AreaStack, Line, LinePath } from '@visx/shape';
import { scaleLinear, scaleOrdinal, scaleTime } from '@visx/scale';
import { Grid } from '@visx/grid';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { curveStepAfter } from '@visx/curve';
import { TooltipWithBounds, useTooltip } from '@visx/tooltip';
import { localPoint } from '@visx/event';
import { bisector } from 'd3-array';
import dayjs from 'dayjs';

import { type Region } from '~types';

import { asCactosError } from '~http';

import { formatRange } from '~utils/time';
import { joinTimeseriesData } from '~utils/timeseries';

import { type ResourceID, useTimeseriesAggregate, useTimeseriesRange } from '~hooks/timeseries';
import { createAggregateQuery, createRangeQuery } from '~hooks/timeseries/queries';

import {
  ChartLegend,
  TOOLTIP_STYLES,
  formatAxisDate,
  formatTooltipDate,
  getContinuousAxisNumTicks,
  useCommonChartStyles,
} from './utils';

const CHART_RANGE_CHECK_INTERVAL = 10_000;
const CHART_NOW_LINE_UPDATE_INTERVAL = 20_000;

export type FCRGraphProps = {
  biddingDomain: string;
  reserveObjects: Region[];
};

const getRangeEndTimeStamp = (): number => dayjs.utc().add(1, 'hour').startOf('hour').valueOf();

export const FCRGraph = (props: FCRGraphProps) => {
  const { biddingDomain, reserveObjects } = props;
  const theme = useTheme();
  const [highlightedChartElementId, setHighlightedChartElementId] = useState<string | null>(null);
  const [rangeEndTimeStamp, setRangeEndTimeStamp] = useState<number>(getRangeEndTimeStamp());

  useEffect(() => {
    const interval = setInterval(() => {
      // because rangeEndTimeStamp is a number, this will only trigger a re-render
      // if the end time actually changes (once every hour)
      setRangeEndTimeStamp(getRangeEndTimeStamp());
    }, CHART_RANGE_CHECK_INTERVAL);
    return () => clearInterval(interval);
  }, []);
  const range = useMemo(() => {
    const end = new Date(rangeEndTimeStamp);
    return { start: dayjs.utc(end).subtract(6, 'hours').toDate(), end };
  }, [rangeEndTimeStamp]);

  const {
    data: allocationData,
    error: allocationError,
    isLoading: loadingAllocation,
  } = useTimeseriesRange(
    createRangeQuery({
      resources: [`region:${biddingDomain}`],
      columns: {
        fcr_allocation: ['quantity', 'duration'],
      },
      start: range.start,
      end: range.end,
    }),
  );

  const {
    data: activationData,
    error: activationError,
    isLoading: loadingActivation,
  } = useTimeseriesAggregate(
    createAggregateQuery({
      resources: reserveObjects.map((r) => `region:${r.id}` as ResourceID),
      columns: {
        fcr_reporting_region: ['maintained_capacity:min'],
      },
      start: range.start,
      end: range.end,
      step: '60s',
    }),
  );

  const allocations = (allocationData?.[`region:${biddingDomain}`]?.fcr_allocation ?? []).map(
    (row) => ({
      time: new Date(row.time),
      quantity: parseFloat(row.quantity as string),
      duration: row.duration as number,
    }),
  );
  if (allocations.length > 0) {
    const last = allocations[allocations.length - 1];
    allocations.push({
      time: dayjs
        .min(dayjs.utc(range.end), dayjs.utc(last.time).add(last.duration, 'seconds'))
        .toDate(),
      quantity: last.quantity,
      duration: 0,
    });
  }

  // combine different results such that they share the same index, convert units from W to MW
  const activations: { time: Date; [id: string]: number | Date }[] = useMemo(
    () =>
      reserveObjects
        .map((reserveObject) => {
          const data = activationData?.[`region:${reserveObject.id}`].fcr_reporting_region ?? [];
          return {
            columns: [reserveObject.id],
            data: data.map((row) => ({
              time: row.time,
              [reserveObject.id]: (row['maintained_capacity:min'] ?? NaN) / 1_000_000,
            })),
          };
        })
        .reduce(
          (acc, current) => ({
            columns: [...acc.columns, ...current.columns],
            data: joinTimeseriesData(acc.data, acc.columns, current.data, current.columns) as any[],
          }),
          { columns: [], data: [] },
        )
        .data.map((row) => ({
          ...row,
          time: new Date(row.time),
        })),
    [activationData, reserveObjects],
  );

  const reserveObjectIds = reserveObjects.map((r) => r.id);

  const fcrAllocationLineColor = '#FFFFFF';
  const colorScale = scaleOrdinal<string, string>({
    domain: reserveObjectIds,
    range: theme.palette.chart.categorical14,
  });

  const getColor = (key: string): string => colorScale(key);

  const legendItems = [
    {
      id: 'fcr-allocation-line',
      displayName: 'FCR Allocation',
      color: fcrAllocationLineColor,
    },
    ...reserveObjects.map((reserveObject) => ({
      id: reserveObject.id,
      displayName: `${reserveObject.id} (${reserveObject.name})`,
      color: getColor(reserveObject.id),
    })),
  ];

  const loading = loadingAllocation || loadingActivation;
  const error = allocationError ?? activationError;
  const cactosError = error != null ? asCactosError(error) : null;

  return (
    <Stack>
      <Card>
        <CardHeader
          title={
            <Stack direction="row" alignItems="center" gap={1}>
              <Typography gutterBottom variant="h6" component="h4">
                FCR Status
              </Typography>
              {loading && <CircularProgress size={12} sx={{ mb: 0.8 }} />}
              {cactosError != null && (
                <Tooltip title={`Error loading data: ${cactosError.message}`} placement="right">
                  <ErrorOutline color="error" fontSize="small" sx={{ mb: 0.8 }} />
                </Tooltip>
              )}
            </Stack>
          }
          subheader={formatRange(range)}
        />
        <Box sx={{ p: 3, pb: 1 }} dir="ltr">
          <ParentSize debounceTime={100}>
            {({ width }) => (
              <FCRStackedAreaChart
                width={width}
                height={380}
                reserveObjectIds={reserveObjectIds}
                highlightedChartElementId={highlightedChartElementId}
                fcrAllocationLineColor={fcrAllocationLineColor}
                getColor={getColor}
                activationData={activations}
                allocationData={allocations}
                timeRangeMin={range.start}
                timeRangeMax={range.end}
              />
            )}
          </ParentSize>
          <ChartLegend
            items={legendItems}
            selectedItemID={highlightedChartElementId}
            onSelectedItemChange={setHighlightedChartElementId}
          />
        </Box>
      </Card>
    </Stack>
  );
};

type FCRStackedAreaChartProps = {
  width: number;
  height: number;
  reserveObjectIds: string[];
  // either a reserve object id, 'fcr-allocation-line' or null
  highlightedChartElementId: string | null;
  fcrAllocationLineColor: string;
  getColor: (key: string) => string;
  activationData: { time: Date; [id: string]: number | Date }[];
  allocationData: { time: Date; quantity: number }[];
  timeRangeMin: Date;
  timeRangeMax: Date;
};

type TooltipData = {
  time: Date;
  fcrAllocation: number;
  values: { [id: string]: number };
};

const CHART_MARGIN = { top: 20, left: 40, right: 20, bottom: 20 };

const FCRStackedAreaChart = (props: FCRStackedAreaChartProps) => {
  const {
    width,
    height,
    reserveObjectIds,
    highlightedChartElementId,
    fcrAllocationLineColor,
    getColor,
    activationData,
    allocationData,
    timeRangeMin,
    timeRangeMax,
  } = props;

  const {
    tooltipData,
    tooltipLeft = 0,
    tooltipTop = 0,
    tooltipOpen,
    showTooltip,
    hideTooltip,
  } = useTooltip<TooltipData>();

  const [now, setNow] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      // triggers a re-render, updating the 'now' line on the chart
      setNow(new Date());
    }, CHART_NOW_LINE_UPDATE_INTERVAL);
    return () => clearInterval(interval);
  }, []);

  const {
    highlightColor,
    axisColor,
    axisBottomTickLabelProps,
    axisLeftTickLabelProps,
    gridColor,
    gridStrokeDasharray,
  } = useCommonChartStyles();

  const yMax = height - CHART_MARGIN.top - CHART_MARGIN.bottom;
  const xMax = width - CHART_MARGIN.left - CHART_MARGIN.right;

  const timeScale = scaleTime<number>({
    range: [0, xMax],
    // we don't want to have the current time at the very right edge of the chart
    domain: [
      timeRangeMin,
      dayjs.max([dayjs.utc(timeRangeMax), dayjs.utc(now).add(5, 'minute')]).toDate(),
    ],
  });

  const yScale = useMemo(() => {
    const totals = [
      ...allocationData.map((row) => row.quantity),
      ...activationData.map((row) =>
        reserveObjectIds
          .map((id) => (Number.isFinite(row[id]) ? (row[id] as number) : 0))
          .reduce((acc, val) => acc + val, 0),
      ),
    ];
    return scaleLinear<number>({
      range: [yMax, 0],
      domain: totals.length > 0 ? [0, Math.max(...totals)] : [0, 1],
      nice: true,
    });
  }, [allocationData, activationData, reserveObjectIds, yMax]);

  const handleTooltip = useCallback(
    (event: React.TouchEvent<SVGRectElement> | React.MouseEvent<SVGRectElement>): void => {
      const { x, y } = localPoint(event) || { x: 0, y: 0 };
      const bisectDate = bisector((d: { time: Date }) => d.time);
      const dateUnderCursor = timeScale.invert(x - CHART_MARGIN.left);
      const allocationIndex = bisectDate.left(allocationData, dateUnderCursor);

      showTooltip({
        tooltipData: {
          time: dateUnderCursor,
          fcrAllocation:
            allocationData.length === 0 || allocationIndex === 0
              ? NaN
              : allocationData[allocationIndex - 1].quantity,
          values: Object.fromEntries(
            reserveObjectIds.map((id) => {
              if (activationData.length === 0) return [id, NaN];
              const index = bisectDate.left(activationData, dateUnderCursor);
              if (index === 0 || index >= activationData.length) return [id, NaN];
              return [id, activationData[index - 1][id] as number];
            }),
          ),
        },
        tooltipLeft: x,
        tooltipTop: y,
      });
    },
    [showTooltip, allocationData, activationData, reserveObjectIds, timeScale],
  );

  return width < 10 ? null : (
    <div style={{ position: 'relative' }}>
      <svg width={width} height={height}>
        <Group left={CHART_MARGIN.left} top={CHART_MARGIN.top}>
          <Grid
            xScale={timeScale}
            numTicksColumns={getContinuousAxisNumTicks(width)}
            yScale={yScale}
            width={xMax}
            height={yMax}
            stroke={gridColor}
            strokeDasharray={gridStrokeDasharray}
          />
          <AxisLeft
            scale={yScale}
            stroke={axisColor}
            tickStroke={axisColor}
            tickLabelProps={axisLeftTickLabelProps}
          />
          <AxisBottom
            hideAxisLine
            hideTicks
            top={yMax}
            scale={timeScale}
            numTicks={getContinuousAxisNumTicks(width)}
            tickFormat={(date) => formatAxisDate(date as Date)}
            stroke={axisColor}
            tickStroke={axisColor}
            tickLabelProps={axisBottomTickLabelProps}
          />

          <AreaStack
            top={0}
            left={0}
            keys={[...reserveObjectIds].reverse()} // same order as in tooltip/legend
            data={activationData}
            x={(d) => timeScale(d.data.time)}
            y0={(d) => yScale(d[0])}
            y1={(d) => yScale(d[1])}
            defined={(d) => Number.isFinite(d[0]) && Number.isFinite(d[1])}
            curve={curveStepAfter}
          >
            {({ stacks, path }) =>
              stacks.map((stack) => (
                <path
                  key={`stack-${stack.key}`}
                  d={path(stack) ?? ''}
                  stroke="transparent"
                  fill={getColor(stack.key)}
                  opacity={
                    highlightedChartElementId != null && highlightedChartElementId !== stack.key
                      ? 0.15
                      : 0.7
                  }
                />
              ))
            }
          </AreaStack>

          <Line
            from={{ x: timeScale(now), y: 0 }}
            to={{ x: timeScale(now), y: yMax }}
            stroke={highlightColor}
            strokeWidth={1}
            pointerEvents="none"
          />
          <Group left={timeScale(now)} top={-8}>
            <rect
              x={-15}
              y={-8}
              width={30}
              height={16}
              strokeWidth={1}
              stroke={highlightColor}
              fill="transparent"
              rx={3.5}
            />
            <text fontSize="10px" dominantBaseline="middle" textAnchor="middle" fill="white">
              Now
            </text>
          </Group>

          <LinePath
            key="fcr-allocation-line"
            stroke={fcrAllocationLineColor}
            strokeWidth={1.5}
            strokeDasharray="1,3"
            data={allocationData}
            curve={curveStepAfter}
            x={(d) => timeScale(d.time)}
            y={(d) => yScale(d.quantity)}
            defined={(d) => Number.isFinite(d.quantity)}
            opacity={
              highlightedChartElementId != null &&
              highlightedChartElementId !== 'fcr-allocation-line'
                ? 0.4
                : 1
            }
          />

          {tooltipData && (
            <Line
              from={{ x: timeScale(tooltipData.time), y: 0 }}
              to={{ x: timeScale(tooltipData.time), y: yMax }}
              stroke={highlightColor}
              strokeWidth={1}
              pointerEvents="none"
              strokeDasharray="5,2"
            />
          )}
        </Group>

        <rect
          x={CHART_MARGIN.left}
          y={CHART_MARGIN.top}
          width={xMax}
          height={yMax}
          fill="transparent"
          onTouchStart={handleTooltip}
          onTouchMove={handleTooltip}
          onMouseMove={handleTooltip}
          onMouseLeave={() => hideTooltip()}
        />
      </svg>

      {tooltipOpen && tooltipData && (
        <TooltipWithBounds
          key={`tooltip-${tooltipTop}-${tooltipLeft}-${tooltipData.time.getTime()}`}
          top={tooltipTop}
          left={tooltipLeft}
          style={TOOLTIP_STYLES}
        >
          <div style={{ color: highlightColor }}>
            <strong>{formatTooltipDate(tooltipData.time)}</strong>
          </div>
          {[
            {
              id: 'fcr-allocation-line',
              displayName: 'FCR Allocation',
              color: fcrAllocationLineColor,
              value: Number.isFinite(tooltipData.fcrAllocation)
                ? `${tooltipData.fcrAllocation.toFixed(3)} MW`
                : '-',
            },
            ...reserveObjectIds.map((id) => ({
              id,
              displayName: `${id}`,
              color: getColor(id),
              value: Number.isFinite(tooltipData.values[id])
                ? `${tooltipData.values[id].toFixed(3)} MW`
                : '-',
            })),
          ].map((item) => (
            <div
              key={item.id}
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
            >
              <div style={{ display: 'flex', alignItems: 'center', marginRight: 5 }}>
                <div style={{ marginRight: 3 }}>
                  <svg width="10" height="10">
                    <g transform="translate(5, 5)">
                      <circle r="5" fill={item.color} />
                    </g>
                  </svg>
                </div>
                {item.displayName}:
              </div>
              <div>{item.value}</div>
            </div>
          ))}
        </TooltipWithBounds>
      )}
    </div>
  );
};
