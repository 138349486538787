import { differenceInSeconds } from 'date-fns';

import { type DateTimeRange } from './time';

export interface Field {
  name: string;
  type: string;
  subtype?: string;
}

export interface Schema {
  fields: Field[];
  primaryKey: string;
}

export interface DataFrame {
  schema: Schema;
  data: Record<string, string | number | boolean>[];
}

export type Row = Record<string, string | number | null> & { time: string };

export function getAggregageQueryTimeStepSeconds(range: DateTimeRange): number {
  const timeDiff = differenceInSeconds(range.end, range.start);
  // the server has minimum time steps for different time ranges, ask for too much and the server will not respect the time step
  // https://github.com/CactosSystem/ems-server/blob/81bc923a5e12573c36b08f46c62efc1f766ac453/ems_server/timeseries/querying.py#L482-L491
  if (timeDiff < 2 * 3600) return 10; // must be '<' not '<=', server limit
  if (timeDiff <= 12 * 3600) return 60;
  if (timeDiff <= 24 * 3600) return 5 * 60;
  if (timeDiff <= 10 * 24 * 3600) return 3600;
  return 24 * 3600;
}

export function getRecommendedRefreshIntervalMs(range: DateTimeRange): number {
  const timeDiff = differenceInSeconds(range.end, range.start);
  if (timeDiff <= 2 * 3600) return 60_000; // range <= 2 hours
  if (timeDiff <= 24 * 3600) return 5 * 60_000; // range <= 24 hours
  return 10 * 60_000; // range > 24 hours
}

export function joinTimeseriesData<
  T1 extends Row,
  K1 extends keyof T1,
  T2 extends Row,
  K2 extends keyof T2,
>(
  series1: T1[],
  series1Columns: K1[],
  series2: T2[],
  series2Columns: K2[],
): (Partial<Pick<T1, K1> & Pick<T2, K2>> & { time: string })[] {
  type ResultType = Partial<Pick<T1, K1> & Pick<T2, K2>> & { time: string };
  const it1 = series1.values();
  const it2 = series2.values();
  const results: ResultType[] = [];

  let next1 = it1.next();
  let next2 = it2.next();

  while (!next1.done || !next2.done) {
    if (next1.done) {
      results.push({
        time: next2.value.time,
        ...Object.fromEntries(series2Columns.map((col) => [col, next2.value[col]])),
      } as ResultType);
      next2 = it2.next();
    } else if (next2.done) {
      results.push({
        time: next1.value.time,
        ...Object.fromEntries(series1Columns.map((col) => [col, next1.value[col]])),
      } as ResultType);
      next1 = it1.next();
    } else {
      const diff = new Date(next1.value.time).getTime() - new Date(next2.value.time).getTime();
      if (diff === 0) {
        results.push({
          time: next1.value.time,
          ...Object.fromEntries(series1Columns.map((col) => [col, next1.value[col]])),
          ...Object.fromEntries(series2Columns.map((col) => [col, next2.value[col]])),
        } as ResultType);
        next1 = it1.next();
        next2 = it2.next();
      } else if (diff < 0) {
        results.push({
          time: next1.value.time,
          ...Object.fromEntries(series1Columns.map((col) => [col, next1.value[col]])),
        } as ResultType);
        next1 = it1.next();
      } else {
        // diff > 0
        results.push({
          time: next2.value.time,
          ...Object.fromEntries(series2Columns.map((col) => [col, next2.value[col]])),
        } as ResultType);
        next2 = it2.next();
      }
    }
  }

  return results;
}

export function truncateTimeseriesDataToWindow<T extends Row>(
  series: T[],
  start: Date | null,
  end: Date | null,
  columnHandling: Record<Exclude<keyof T, 'time'>, 'linear' | 'step-after'>,
): T[] {
  const interpolate = (left: T, mid: Date, right: T): T => {
    const dTimeLeftToRight = new Date(right.time).getTime() - new Date(left.time).getTime();
    const dTimeLeftToMid = mid.getTime() - new Date(left.time).getTime();
    const row: Row = { time: mid.toISOString() };
    for (const [col, method] of Object.entries(columnHandling)) {
      if (
        !(typeof left[col] === 'number' && Number.isFinite(left[col])) ||
        !(typeof right[col] === 'number' && Number.isFinite(right[col]))
      ) {
        row[col] = NaN;
        continue;
      }
      if (method === 'linear') {
        const dValueLeftToRight = (right[col] as number) - (left[col] as number);
        // dValue_[left..mid] / dValue_[left..right] = dTime_[left..mid] / dTime_[left..right]
        const dValueLeftToMid = (dTimeLeftToMid / dTimeLeftToRight) * dValueLeftToRight;
        row[col] = (left[col] as number) + dValueLeftToMid;
      } else {
        row[col] = left[col];
      }
    }
    return row as T;
  };

  const results: T[] = [];
  let index = 0;

  if (start !== null) {
    while (index < series.length && new Date(series[index].time) < start) {
      index += 1;
    }
    if (index > 0 && index < series.length) {
      const prev = series[index - 1];
      const current = series[index];
      results.push(interpolate(prev, start, current));
    }
  }

  if (end === null) {
    while (index < series.length) {
      results.push(series[index]);
      index += 1;
    }
    return results;
  }

  while (index < series.length && new Date(series[index].time) <= end) {
    results.push(series[index]);
    index += 1;
  }

  if (index > 0 && index < series.length) {
    const prev = series[index - 1];
    const current = series[index];
    results.push(interpolate(prev, end, current));
  }

  return results;
}
