import axios from 'axios';

import type { User } from '~types';

import { http } from '~http';

export async function getMe(signal: AbortSignal) {
  let meResponse;
  try {
    meResponse = await http.get('/v1/me', { signal });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      if (e.response?.status === 401) {
        return null;
      }
    }
    throw e;
  }
  const userData = meResponse.data as User;
  const organizationsByRole: Record<string, string[]> = {};
  if ('roles' in userData) {
    Object.keys(userData.roles).forEach((org) => {
      userData.roles[org].forEach((role) => {
        if (!(role in organizationsByRole)) {
          organizationsByRole[role] = [];
        }
        organizationsByRole[role].push(org);
      });
    });
    userData.organizationsByRole = organizationsByRole;
  }
  return userData;
}
