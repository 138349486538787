import { NavLink, Outlet, useLocation, useMatch } from 'react-router-dom';
import { TabContext, TabList } from '@mui/lab';
import { Tab } from '@mui/material';

import { USER_ROLES } from '~constants/auth';

import { useAuth } from '~hooks/useAuth';
import { useCurrentEdgeControllerId } from '~hooks/useCurrentEdgeControllerId';
import { useEdgeController } from '~hooks/useEdgeControllerList';

import { Iconify } from '~components/Iconify';
import { ScrollView } from '~components/ScrollView';

export function EdgeControllerDetails() {
  const match = useMatch('/dashboard/controllers/:controllerId/:tab');
  const queryString = useLocation().search;
  const getUrl = (tab: string) => tab + queryString;
  const currentTab = match?.params.tab || 'details';
  const { user } = useAuth();
  const roles = user?.organizationsByRole || {};

  const { data: edgeController } = useEdgeController(useCurrentEdgeControllerId());
  const linkToESUList = edgeController?.type === 'ems' || edgeController?.type === 'ccu_alpha';

  return (
    <>
      <TabContext value={currentTab}>
        <TabList
          sx={{
            marginLeft: 1,
          }}
        >
          {USER_ROLES.VIEW_EMS in roles && (
            <Tab value="details" label="Details" component={NavLink} to={getUrl('details')} />
          )}
          {USER_ROLES.EMS_DIAGNOSTIC_VIEW in roles && (
            <Tab
              value="diagnostics"
              label={
                linkToESUList ? (
                  <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                    Diagnostics <Iconify icon="mdi:external-link" />
                  </div>
                ) : (
                  'Diagnostics'
                )
              }
              component={NavLink}
              to={
                linkToESUList
                  ? `/dashboard/esus/${match?.params.controllerId}/diagnostics`
                  : getUrl('diagnostics')
              }
            />
          )}
          {USER_ROLES.DEBUG_EMS in roles && (
            <Tab
              value="events"
              label={
                linkToESUList ? (
                  <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                    Events <Iconify icon="mdi:external-link" />
                  </div>
                ) : (
                  'Events'
                )
              }
              component={NavLink}
              to={
                linkToESUList
                  ? `/dashboard/esus/${match?.params.controllerId}/events`
                  : getUrl('events')
              }
            />
          )}
        </TabList>
      </TabContext>
      <ScrollView>
        <Outlet />
      </ScrollView>
    </>
  );
}
