import { Box, Grid } from '@mui/material';

import { useGroupedFleetDiagnostics } from '~hooks/useGroupedFleetDiagnostics';

import { FleetTemperatureHistogram } from '~components/charts/FleetTemperatureHistogram';
import { Page } from '~components/Page';
import { FleetCellVoltageDiffHistogram } from '~components/charts/FleetCellVoltageDiffHistogram';

import { ESUFaultsCard } from './cards/ESUFaultsCard';
import { FingridStatusCard } from './cards/FingridStatusCard';
import { FleetIssuesCard } from './cards/FleetIssuesCard';

export function FleetOverview() {
  const {
    data: fleetDiagnosticsData,
    isLoading: fleetDiagnosticsLoading,
    error: fleetDiagnosticsError,
  } = useGroupedFleetDiagnostics();

  return (
    <Page title="Fleet Overview">
      <Box paddingX={3} paddingY={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={4}>
            <FingridStatusCard />
          </Grid>
          <Grid item xs={12} lg={8}>
            <FleetIssuesCard />
          </Grid>
          <Grid item xs={12} lg={12}>
            <ESUFaultsCard />
          </Grid>
          <Grid item xs={12} lg={12}>
            <FleetTemperatureHistogram
              title="Battery max temperatures"
              data={fleetDiagnosticsData.byMaxCellTemperature}
              isLoading={fleetDiagnosticsLoading}
              error={fleetDiagnosticsError}
              height={250}
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <FleetTemperatureHistogram
              title="Ambient temperatures"
              data={fleetDiagnosticsData.byMaxAmbientTemperature}
              isLoading={fleetDiagnosticsLoading}
              error={fleetDiagnosticsError}
              height={250}
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <FleetCellVoltageDiffHistogram
              data={fleetDiagnosticsData.byVoltageDifference}
              isLoading={fleetDiagnosticsLoading}
              error={fleetDiagnosticsError}
              height={250}
            />
          </Grid>
        </Grid>
      </Box>
    </Page>
  );
}
