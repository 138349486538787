import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { useState } from 'react';
import dayjs from 'dayjs';
import { Decimal } from 'decimal.js';

import { useTimeseriesAggregate } from '~hooks/timeseries';
import { createAggregateQuery } from '~hooks/timeseries/queries';
import type { AggregateRow } from '~hooks/timeseries/types';

import { DashboardCard, DashboardCardHeader } from '~components/DashboardCard';
import { Iconify } from '~components/Iconify';

function aggregateValues(estimate: AggregateRow<'group_fcrn_share_estimate'>[] | undefined) {
  if (!estimate) return undefined;
  return estimate.reduce((prev: Record<string, Decimal>, current) => {
    const month = dayjs(String(current.time)).format('MMMM');
    const prevValue = prev[month] ?? new Decimal(0);
    const rawValue = current['net_value:sum'];
    const value = prevValue.add(new Decimal(typeof rawValue === 'string' ? rawValue : '0'));
    return { ...prev, ...{ [month]: value } };
  }, {});
}

export function FCRNRevenueEstimateCard({ groupId }: { groupId: string | undefined }) {
  const theme = useTheme();

  const [{ now, months }] = useState(() => {
    const now = dayjs.utc().startOf('hour');
    const startOfMonth = now.startOf('month');
    return {
      now: now.toDate(),
      months: [
        startOfMonth.subtract(2, 'month'),
        startOfMonth.subtract(1, 'month'),
        startOfMonth,
      ].map((d) => d.toDate()),
    };
  });

  const { data } = useTimeseriesAggregate(
    createAggregateQuery({
      resources: groupId ? [`metering_group:${groupId}`] : [],
      columns: { group_fcrn_share_estimate: ['net_value:sum'] },
      start: months[0],
      end: now,
      step: '1d',
    }),
  );

  const values = aggregateValues(data?.[`metering_group:${groupId}`].group_fcrn_share_estimate);

  const monthNames = months.map((m) => dayjs(m).format('MMMM'));

  return (
    <DashboardCard size="medium">
      <DashboardCardHeader>FCR-N</DashboardCardHeader>
      <Table
        size="small"
        sx={{
          '& td, & th': { lineHeight: '1.5rem' },
          '& td:first-of-type, & th:first-of-type': { paddingLeft: 0 },
          '& tr:last-child td': { borderBottom: 'none' },
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell colSpan={2}>
              Estimated Revenue{' '}
              <Tooltip
                title="The estimated FCR-N revenue of this ESU before penalties, calculated according to the agreed FCR-N revenue share effective from time to time.
        The current month value is Month to Date. In case multiple ESUs are installed as a group, this is the group total.
        The estimate does not account for FCR-N operation penalties that are incurred when ESUs cannot deliver their allocated capacity."
              >
                <IconButton size="small" sx={{ cursor: 'help' }}>
                  <Iconify icon="mdi:help-circle" color={theme.palette.grey[600]} />
                </IconButton>
              </Tooltip>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {monthNames.map((month, index, all) => (
            <TableRow key={month}>
              <TableCell>{index === all.length - 1 ? `${month} MTD` : month}</TableCell>
              <TableCell align="right">
                <Typography variant="data">
                  {values ? `${(values[month] ?? new Decimal(0)).toFixed(2)} EUR` : 'Loading...'}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </DashboardCard>
  );
}
