import { type ESUWithController, type FleetFilter } from '~types';

import { useTimeseriesLatest } from '~hooks/timeseries';
import { createLatestQuery } from '~hooks/timeseries/queries';
import { useMeteringGroupList } from '~hooks/useMeteringGroupList';
import { useESUList } from '~hooks/useESUList';

export type ESUSettingsDataRow = ESUWithController & {
  ems_optimizer_configuration?: {
    device_energy_capacity: string | null;
    energy_transfer_max: string | null;
    idle_low_soc: string | null;
    idle_high_soc: string | null;
    run_optimizer: number | null;
    default_esu_import_power_max: string | null;
    default_esu_export_power_max: string | null;
    max_fcrn_power: string | null;
    max_fcrn_discharge_power: string | null;
    desired_fcrn_power_offset: string | null;
    fcrn_low_soc: string | null;
    fcrn_high_soc: string | null;
    fcrn_priority_group: number | null;
    import_efficiency: string | null;
    export_efficiency: string | null;
    peak_shaving_threshold_override: number | null;
  };
  group_membership_start?: string;
};

export type ESUSettingsData = {
  data: ESUSettingsDataRow[] | undefined;
  error: any;
  isLoading: boolean;
};

export function useESUSettingsData(fleetFilter: FleetFilter): ESUSettingsData {
  const { data: meteringGroups } = useMeteringGroupList();
  const { data: esus } = useESUList(fleetFilter);

  const query = createLatestQuery({
    resources: (esus ?? []).map((esu) => esu.resource_id),
    columns: {
      ems_optimizer_configuration: [
        'device_energy_capacity',
        'energy_transfer_max',
        'idle_low_soc',
        'idle_high_soc',
        'run_optimizer',
        'default_esu_import_power_max',
        'default_esu_export_power_max',
        'max_fcrn_power',
        'max_fcrn_discharge_power',
        'desired_fcrn_power_offset',
        'fcrn_low_soc',
        'fcrn_high_soc',
        'fcrn_priority_group',
        'import_efficiency',
        'export_efficiency',
        'peak_shaving_threshold_override',
      ],
    },
  });
  const { data, error, isLoading } = useTimeseriesLatest(query, {
    refreshInterval: 60_000,
    revalidateOnFocus: false,
  });

  const mergedData = esus?.map((esu) => {
    const meteringGroupEsuData = meteringGroups
      ?.find((meteringGroup) => meteringGroup.id === esu.edge_controller.metering_group.id)
      ?.esus.find((e) => e.id === esu.id);
    const esuData = data?.[esu.resource_id];

    return {
      ...esu,
      ...meteringGroupEsuData,
      ...esuData,
    };
  });

  return { data: mergedData, error, isLoading };
}
