import { ESUControl as EsuControlV1 } from '~pages/fleet/esus/control/ESUControl';

import { ScrollView } from '~components/ScrollView';

export function ESUControl() {
  return (
    <ScrollView>
      <EsuControlV1 />
    </ScrollView>
  );
}
