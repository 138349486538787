import { type ReactNode, useMemo } from 'react';
import { Alert, CircularProgress, Grid, Tooltip, useTheme } from '@mui/material';
import {
  ErrorOutline as ErrorIcon,
  ErrorOutline,
  PersonOutlined as PersonIcon,
  QuestionMarkOutlined as QuestionMarkIcon,
  ThermostatOutlined as ThermostatIcon,
  WarningAmberOutlined as WarningIcon,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

import { type ESUCurrentMode, type ESUDetails } from '~types';

import { collator } from '~utils/localization';

import { useESUList } from '~hooks/useESUList';
import { useESUCurrentMode } from '~hooks/useESUCurrentMode';
import { type ESUFaultsData, useESUFaults } from '~hooks/useESUFaults';
import { CURRENT_FAULTS_RANGE_MINUTES } from '~hooks/useESUOperationData';

import { DashboardCard, DashboardCardHeader } from '~components/DashboardCard';
import { Iconify } from '~components/Iconify';
import { RelativeTime } from '~components/RelativeTime';

type Issue = {
  id: string;
  severity: 'warning' | 'error';
  text: ReactNode;
  icon: ReactNode;
};

type ESUWithIssues = {
  esu: ESUDetails;
  issues: Issue[];
  severity: 'warning' | 'error';
};

const MAX_SHOWN_ISSUES = 10;

export function FleetIssuesCard() {
  const theme = useTheme();
  const navigate = useNavigate();

  const { data: esus, isLoading: esusLoading, error: esusError } = useESUList('fleet');
  const { data: esuModes, isLoading: esuModesLoading, error: esuModesError } = useESUCurrentMode();
  const {
    data: esuFaultsData,
    isLoading: esuFaultsDataLoading,
    error: esuFaultsDataError,
  } = useESUFaults(CURRENT_FAULTS_RANGE_MINUTES, 'fleet');

  const dataLoading = esusLoading || esuModesLoading || esuFaultsDataLoading;
  const hasError = esusError != null || esuModesError != null || esuFaultsDataError != null;

  const abnormalESUs: ESUWithIssues[] | undefined = useMemo(
    () => createIssueList(esus, esuModes, esuFaultsData),
    [esus, esuModes, esuFaultsData],
  );

  // show only the first n ESUs such that the total number of issues shown is at most MAX_ROWS
  const visibleESUs: ESUWithIssues[] = useMemo(() => {
    if (!abnormalESUs) return [];

    let rows = 0;

    for (const [index, esu] of abnormalESUs.entries()) {
      rows += esu.issues.length;
      if (rows > MAX_SHOWN_ISSUES) {
        // length went over, only show up to the previous ESU
        return abnormalESUs.slice(0, index);
      }
    }

    return abnormalESUs;
  }, [abnormalESUs]);

  return (
    <DashboardCard size="small">
      <DashboardCardHeader>
        Issues
        {dataLoading && <CircularProgress size={15} sx={{ ml: 1.5, mb: 0.35 }} />}
        {hasError && (
          <Tooltip title="Error loading fleet status data." placement="right">
            <ErrorOutline color="error" fontSize="small" sx={{ ml: 1.2 }} />
          </Tooltip>
        )}
      </DashboardCardHeader>
      <Grid container spacing={1}>
        {!abnormalESUs ? (
          dataLoading && (
            <Alert
              severity="info"
              icon={false}
              sx={{
                background: theme.palette.grey[800],
                color: theme.palette.grey[300],
                width: '100%',
              }}
            >
              Loading...
            </Alert>
          )
        ) : abnormalESUs.length === 0 ? (
          <Alert severity="success" sx={{ width: '100%' }}>
            No issues in the fleet.
          </Alert>
        ) : (
          <>
            {visibleESUs.map(({ esu, issues }) => (
              <Grid item key={esu.id} xs={12}>
                {issues.map((issue) => (
                  <Alert
                    key={issue.id}
                    severity={issue.severity}
                    icon={issue.icon}
                    onClick={() => navigate(`/dashboard/esus/${esu.id}/diagnostics`)}
                    sx={{
                      cursor: 'pointer',
                      ':not(:first-of-type)': {
                        borderTopLeftRadius: 0,
                        borderTopRightRadius: 0,
                      },
                      ':not(:last-of-type)': {
                        borderBottomLeftRadius: 0,
                        borderBottomRightRadius: 0,
                      },
                    }}
                  >
                    {issue.text}
                  </Alert>
                ))}
              </Grid>
            ))}
            {visibleESUs.length < abnormalESUs.length && (
              <Grid item xs={12}>
                <Alert
                  severity="info"
                  icon={false}
                  sx={{ background: theme.palette.grey[800], color: theme.palette.grey[300] }}
                >
                  And issues with {abnormalESUs.length - visibleESUs.length} others ESUs...
                </Alert>
              </Grid>
            )}
          </>
        )}
      </Grid>
    </DashboardCard>
  );
}

const createIssueList = (
  esus: ESUDetails[] | undefined,
  esuModes: { [esu_id: string]: ESUCurrentMode } | undefined,
  esuFaultsData: ESUFaultsData | undefined,
): ESUWithIssues[] | undefined => {
  if (esus == null || esuModes == null || esuFaultsData == null) return undefined;

  const result = esus.flatMap((esu) => {
    const mode = esuModes[esu.id];
    const nOfFaults = esuFaultsData.faults.filter((fault) => fault.ems_id === esu.id).length;

    const issues = getIssuesForESU(esu, mode, nOfFaults);
    if (issues.length === 0) return [];

    issues.sort((a, b) => {
      if (a.severity === b.severity) return 0;
      return a.severity === 'error' ? -1 : 1;
    });

    // the severity of the ESU is the highest severity of its issues
    return { esu, issues, severity: issues[0].severity };
  });

  return result.sort((a, b) => {
    if (a.severity === b.severity) return collator.compare(a.esu.name, b.esu.name);
    return a.severity === 'error' ? -1 : 1;
  });
};

const getIssuesForESU = (esu: ESUDetails, mode: ESUCurrentMode, nOfFaults: number): Issue[] => {
  const issues: Issue[] = [];

  if (mode.mode === 'no_recent_data') {
    issues.push({
      id: 'no_recent_data',
      severity: 'error',
      text: (
        <>
          No recent data from {esu.name}
          {mode.last_data_time != null && (
            <>
              , last seen <RelativeTime date={mode.last_data_time} />
            </>
          )}
          .
        </>
      ),
      icon: <Iconify icon="mdi:signal-off" />,
    });
    return issues;
  }

  if (mode.mode === 'invalid_data') {
    issues.push({
      id: 'invalid_data',
      severity: 'error',
      text: `Received invalid status data from ${esu.name}.`,
      icon: <QuestionMarkIcon />,
    });
    return issues;
  }

  if (!mode.grid_connected)
    issues.push({
      id: 'off_grid',
      severity: 'error',
      text: `${esu.name} is off-grid.`,
      icon: <Iconify icon="mdi:transmission-tower-off" />,
    });

  if (mode.mode === 'powered_off') {
    issues.push({
      id: 'powered_off',
      severity: 'error',
      text: `${esu.name} is powered off.`,
      icon: <Iconify icon="mdi:electric-switch" />,
    });
    return issues;
  }

  // at this point mode.mode === 'operating'

  if (mode.temperature_override)
    issues.push({
      id: 'temperature_override',
      severity: 'warning',
      text: `Temperature override active on ${esu.name}.`,
      icon: <ThermostatIcon />,
    });

  if (nOfFaults > 0)
    issues.push({
      id: 'has_faults',
      severity: 'error',
      text:
        `${esu.name} had ${nOfFaults} fault${nOfFaults > 1 ? 's' : ''}` +
        ` during the last ${CURRENT_FAULTS_RANGE_MINUTES} minutes.`,
      icon: <ErrorIcon />,
    });

  if (mode.control_source === 'default_schedule')
    issues.push({
      id: 'running_default_schedule',
      severity: 'error',
      text: `Default schedule active on ${esu.name}.`,
      icon: <WarningIcon />,
    });

  if (mode.control_source === 'optimizer_deactivated')
    issues.push({
      id: 'optimizer_deactivated',
      severity: 'error',
      text: `${esu.name} is removed from optimizer.`,
      icon: <WarningIcon />,
    });

  if (mode.control_source === 'manual_override')
    issues.push({
      id: 'manual_override_active',
      severity: 'error',
      text: `Manual override active on ${esu.name}.`,
      icon: <PersonIcon />,
    });

  if (mode.control_source === 'program') {
    if (mode.program_detail.type === 'program') {
      const displayName = mode.program_detail.display_name;
      // if a program is just about to begin we consider it as running
      const active =
        mode.program_detail.active || new Date() < new Date(mode.program_detail.start_time);
      issues.push({
        id: 'program_active',
        severity: 'warning',
        text: `Program '${displayName}' ${active ? 'active' : 'has ended'} on ${esu.name}.`,
        icon: <PersonIcon />,
      });
    } else {
      // program type ad_hoc
      issues.push({
        id: 'ad_hoc_program_active',
        severity: 'warning',
        text: `Program active on ${esu.name}.`,
        icon: <PersonIcon />,
      });
    }
  }

  return issues;
};
