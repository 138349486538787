import { useMemo } from 'react';
import {
  type MRT_ColumnDef,
  type MRT_ColumnSizingState,
  type MRT_TableInstance,
  MaterialReactTable,
  useMRT_Rows,
  useMaterialReactTable,
} from 'material-react-table';
import { Alert, Box, Typography } from '@mui/material';

import { type EdgeControllerType, type ListEdgeController } from '~types';

import { asCactosError } from '~http';

import { collator } from '~utils/localization';

import { useLocalStorage } from '~hooks/useLocalStorage';
import { ResultListContextProvider } from '~hooks/useResultListContext';
import { useEdgeControllerList } from '~hooks/useEdgeControllerList';
import { CurrentEdgeControllerIdContextProvider } from '~hooks/useCurrentEdgeControllerId';

import { SIDEBAR_USER_HEIGHT } from '~layouts/AppLayout/Sidebar';

import { useControllersNavigation } from '~pages/v2/controllers/hooks/useControllersNavigation';

import { BreadcrumbsProvider } from '~components/BreadcrumbsProvider';
import { Breadcrumbs } from '~components/Breadcrumbs';
import { SplitLayout } from '~components/SplitLayout';

const typeDisplayName: Map<EdgeControllerType, string> = new Map([
  ['ems', 'EMS'],
  ['ccu', 'CCU'],
  ['cmu', 'CMU'],
  ['legacy_cmu', 'Legacy CMU'],
  ['ccu_alpha', 'CCU Alpha'],
]);

const columns: MRT_ColumnDef<ListEdgeController>[] = [
  {
    header: 'Controller',
    id: 'name',
    accessorFn: (row) => row.name,
    sortingFn: (a, b, columnId) => collator.compare(a.getValue(columnId), b.getValue(columnId)),
    size: 150,
    Footer: NameFooter,
  },
  {
    header: 'Type',
    id: 'type',
    accessorFn: (row) => typeDisplayName.get(row.type) ?? row.type,
    sortingFn: (a, b, columnId) => collator.compare(a.getValue(columnId), b.getValue(columnId)),
    size: 120,
    filterVariant: 'autocomplete',
    filterSelectOptions: Array.from(typeDisplayName.values()),
  },
  {
    header: 'Site',
    id: 'metering_group',
    accessorFn: (row) => row.metering_group?.name,
    sortingFn: (a, b, columnId) => collator.compare(a.getValue(columnId), b.getValue(columnId)),
    size: 200,
  },
  {
    header: 'Organization',
    id: 'organization',
    accessorFn: (row) => row.organization?.human_name,
    sortingFn: (a, b, columnId) => collator.compare(a.getValue(columnId), b.getValue(columnId)),
    size: 200,
  },
];

export function ControllersList() {
  const { data: edgeControllers, error } = useEdgeControllerList();
  const { edgeControllerId, tab, navigateToController } = useControllersNavigation();

  const edgeController = edgeControllers?.find((row) => row.id === edgeControllerId);

  const rowSelection = useMemo(
    () => (edgeControllerId ? { [edgeControllerId]: true } : {}),
    [edgeControllerId],
  );
  const [columnSizing, setColumnSizing] = useLocalStorage<MRT_ColumnSizingState>(
    'controllers.settings.columnSizing',
    {},
  );

  const table = useMaterialReactTable<ListEdgeController>({
    data: edgeControllers ?? [],
    columns,
    initialState: {
      density: 'compact',
    },
    state: {
      rowSelection,
      columnSizing: columnSizing ?? {},
      columnPinning: {
        left: ['name'],
      },
    },
    onColumnSizingChange: setColumnSizing,
    enablePagination: false,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableColumnResizing: true,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableColumnActions: false,
    enableBottomToolbar: false,
    positionToolbarAlertBanner: 'none',
    getRowId: (row) => row.id,
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => {
        navigateToController(row.id, tab);
      },
      sx: { cursor: 'pointer' },
    }),
    muiTableContainerProps: {
      sx: { maxHeight: 'calc(100vh - 128px)' },
    },
    muiTablePaperProps: {
      sx: { borderRadius: 0 },
    },
    muiTableFooterCellProps: {
      sx: { height: SIDEBAR_USER_HEIGHT + 1 },
    },
    renderEmptyRowsFallback: () =>
      error != null ? (
        <Alert
          severity="error"
          sx={{ borderRadius: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          Error loading data: {asCactosError(error).message}
        </Alert>
      ) : (
        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography variant="body2">
            <i>No controllers to display</i>
          </Typography>
        </Box>
      ),
  });
  const rows = useMRT_Rows(table);
  const resultListContext = useMemo(() => {
    const currentRowIndex = edgeControllerId
      ? rows.findIndex((row) => row.id === edgeControllerId)
      : -1;
    const totalRowCount = rows.length;
    return {
      currentRowIndex,
      totalRowCount,
      goToNextResult: () => {
        if (currentRowIndex + 1 >= totalRowCount) return;

        navigateToController(rows[currentRowIndex + 1].id, tab);
      },
      goToPreviousResult: () => {
        if (currentRowIndex <= 0) return;

        navigateToController(rows[currentRowIndex - 1].id, tab);
      },
      goToResultList: () => navigateToController(),
    };
  }, [rows, edgeControllerId, tab, navigateToController]);

  return (
    <CurrentEdgeControllerIdContextProvider value={edgeControllerId ?? null}>
      <ResultListContextProvider value={resultListContext}>
        <BreadcrumbsProvider>
          <SplitLayout showDetails={!!edgeController} renderTitle={() => <Breadcrumbs hasIcon />}>
            <MaterialReactTable table={table} />
          </SplitLayout>
        </BreadcrumbsProvider>
      </ResultListContextProvider>
    </CurrentEdgeControllerIdContextProvider>
  );
}

function NameFooter({ table }: { table: MRT_TableInstance<ListEdgeController> }) {
  const rows = useMRT_Rows(table);

  return (
    <Box height="100%" display="flex" alignItems="center">
      {rows.length} edge controllers
    </Box>
  );
}
