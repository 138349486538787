import { Navigate } from 'react-router-dom';

import { USER_ROLES } from '~constants/auth';

import { useUserHasRootRole } from '~hooks/useUserHasRootRole';

export function RootRedirect() {
  const canViewFleetsDashboard = useUserHasRootRole(USER_ROLES.OPERATOR_VIEW);
  const canViewFleetsMarket = useUserHasRootRole(USER_ROLES.FCR_BIDDING);

  // Redirect to fleets if user has access to fleets dashboard or fleets market
  if (canViewFleetsDashboard || canViewFleetsMarket) {
    return <Navigate replace to="fleets" />;
  }

  // Sites is always available to users
  return <Navigate replace to="sites" />;
}
