import { useCallback, useState } from 'react';
import { Box, Stack, TextField, Typography } from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import * as yup from 'yup';
import { QRCodeSVG } from 'qrcode.react';

import { http } from '~http';

import { useUserMfa } from '~hooks/useUserMfa';
import { useAbortController } from '~hooks/useAbortController';

const validationSchema = yup.object({
  password: yup.string().required('Current password is required'),
  mfaToken: yup
    .string()
    .matches(/^[0-9]{6}$/, { message: 'Token must be 6 digits long' })
    .required('Token is required'),
});

export function SetupMFA() {
  const { data: mfaData, mutate } = useUserMfa();
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const getSignal = useAbortController();

  const formik = useFormik({
    initialValues: {
      password: '',
      mfaToken: '',
    },
    validationSchema,
    onSubmit: () => {
      postMfa();
    },
  });

  const postMfa = useCallback(async () => {
    let status;

    if (!mfaData || mfaData.new_otp_secret === null) return;

    try {
      await http.post(
        '/v1/me/mfa',
        {
          password: formik.values.password,
          mfa_token: formik.values.mfaToken,
          otp_secret: mfaData.new_otp_secret,
        },
        {
          signal: getSignal(),
          responseType: 'text',
        },
      );
      status = 'Multi-factor authentication setup done!';
      setSubmitSuccess(true);
      mutate();
    } catch (e) {
      const { response } = e;
      if (response?.status === 403 || response?.status === 400) {
        status = response?.data?.message || 'Unexpected failure';
      } else {
        status = 'Unexpected failure';
      }
    }
    formik.setSubmitting(false);
    formik.setStatus(status);
  }, [formik, mfaData, getSignal, mutate]);

  if (submitSuccess === true) {
    return (
      <Stack alignItems="left" spacing={3} mb={5} mt={5}>
        <Typography gutterBottom>Multi-factor authentication setup done!</Typography>
      </Stack>
    );
  }

  if (!mfaData) return null;

  if (mfaData.mfa_enabled) {
    return (
      <Stack alignItems="left" spacing={3} mb={5} mt={5}>
        <Typography gutterBottom>
          Your account already has multi-factor authentication enabled. Please contact Cactos if you
          would like to reset it.
        </Typography>
      </Stack>
    );
  }

  if (!mfaData.new_otp_url) {
    return null;
  }

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off">
        <Stack spacing={3} mb={5} mt={5}>
          <Box
            sx={{
              alignSelf: 'center',
              display: 'flex',
              justifyContent: 'center',
              padding: '16px',
              background: 'white',
            }}
          >
            <QRCodeSVG value={mfaData.new_otp_url} />
          </Box>
          <Typography gutterBottom>
            Download an authenticator application such as Authy or Google Authenticator on your
            phone and use it to read the QR code above. Then insert your password and the 6-digit
            number the application gives below.
          </Typography>
          <TextField
            margin="dense"
            id="password"
            name="password"
            type="password"
            label="Your current password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={(formik.touched.password && formik.errors.password) || ' '}
          />
          <TextField
            margin="dense"
            id="mfaToken"
            name="mfaToken"
            type="text"
            label="Token from authenticator"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.mfaToken}
            error={formik.touched.mfaToken && Boolean(formik.errors.mfaToken)}
            helperText={(formik.touched.mfaToken && formik.errors.mfaToken) || ' '}
          />
          <LoadingButton
            size="large"
            type="submit"
            variant="contained"
            onClick={formik.handleSubmit}
            loading={formik.isSubmitting}
            disabled={!formik.dirty || !formik.isValid}
          >
            Set up MFA
          </LoadingButton>
          <Typography>{formik.status || '\u00A0'}</Typography>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
