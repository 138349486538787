import { useCallback, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { ErrorMessage, Form, Formik } from 'formik';

import type { UserRole } from '~types';

import { asCactosError, http } from '~http';

import { useOrganization } from '~hooks/useOrganization';
import { useCurrentOrganizationId } from '~hooks/useCurrentOrganizationId';
import { useOrganizationRoles } from '~hooks/useOrganizationRoles';
import { useDebounce } from '~hooks/common/useDebounce';

import { printRole } from '~pages/settings/organizations/utils';
import type { OrganizationUser } from '~pages/settings/organizations/types';

type Props = {
  user: OrganizationUser | null;
  onClose: () => void;
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export function OrganizationUserRoleModal({ user, onClose }: Props) {
  const organizationId = useCurrentOrganizationId();
  const { data: organization, mutate } = useOrganization(organizationId);
  const { data: roles } = useOrganizationRoles(organizationId);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  // Setting user to null causes flickering on the modal. This fixes it
  const debouncedEmail = useDebounce(user?.email, 300) || user?.email;

  const currentRoles = useMemo(() => {
    const roles: UserRole[] = [];

    if (!user) return roles;
    if (!organization) return roles;

    organization.roles.forEach((role) => {
      if (role.user.id === user.id) {
        roles.push(role.role_type);
      }
    });

    return roles;
  }, [user, organization]);

  const handleSubmit = useCallback(
    async (roles: UserRole[]) => {
      if (!user) return;
      if (isLoading) return;

      setIsLoading(true);
      setError(null);

      try {
        await http.put(`/v1/organization/${organizationId}/roles/${user.id}`, { roles });
        await mutate();

        onClose();
      } catch (error: any) {
        setError(asCactosError(error).message ?? 'An error occurred while sending the invitation');
      }

      setIsLoading(false);
    },
    [user, organizationId, isLoading, mutate, onClose],
  );

  return (
    <Dialog open={!!user} onClose={onClose}>
      <DialogTitle>Edit user roles</DialogTitle>
      <DialogContent>
        <DialogContentText>Select the roles for {debouncedEmail}</DialogContentText>
        <Formik
          initialValues={{ roles: currentRoles }}
          onSubmit={async (values, { setSubmitting }) => {
            await handleSubmit(values.roles);
            setSubmitting(false);
          }}
        >
          {({ values, handleChange, handleBlur }) => (
            <Form id="user-role-form">
              <Box marginTop={2}>
                <FormControl fullWidth>
                  <InputLabel>Roles</InputLabel>
                  <Select
                    multiple
                    fullWidth
                    required
                    margin="dense"
                    id="roles"
                    name="roles"
                    label="Roles"
                    value={values.roles}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    input={<OutlinedInput label="Tag" />}
                    renderValue={(selected) => selected.map(printRole).join(', ')}
                    MenuProps={MenuProps}
                  >
                    {(roles ?? []).map((value) => (
                      <MenuItem key={value} value={value}>
                        <Checkbox checked={values.roles.includes(value)} />
                        <ListItemText primary={printRole(value)} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <ErrorMessage name="roles" component="div" />
              </Box>
            </Form>
          )}
        </Formik>
        {!!error && (
          <Box marginTop={2} color="red">
            {error}
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <LoadingButton type="submit" form="user-role-form" loading={isLoading}>
          Assign roles
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
