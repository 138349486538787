import useSWR from 'swr';
import { useMemo } from 'react';
import { chunk } from 'lodash-es';
import { subMinutes } from 'date-fns';

import { type ESUDetails, type FaultEvent, type FleetFilter } from '~types';

import { http } from '~http';

import { useESUList } from '~hooks/useESUList';

export type FaultEventWithESU = FaultEvent & { esu?: ESUDetails };

export type ESUFaultsData = {
  start: string;
  end: string;
  faults: FaultEventWithESU[];
};

type FaultsResponse = {
  start_time: string;
  end_time: string;
  faults: FaultEvent[];
};

const MAX_ESUS_PER_REQUEST = 100;

export function useESUFaults(
  rangeDurationMinutes = 60,
  fleetFilter: FleetFilter = 'fleet',
): {
  data: ESUFaultsData | undefined;
  error: Error | undefined;
  isLoading: boolean;
} {
  const { data: esuList, error: esuListError, isLoading: isLoadingESUs } = useESUList(fleetFilter);
  const {
    data,
    error: faultsError,
    isLoading: isLoadingFaults,
  } = useSWR<FaultsResponse>(
    esuList
      ? [
          '/v1/ems/faults', // TODO: Implement new faults API, this request is to an EMS-specific endpoint
          esuList.filter((esu) => esu.edge_controller.type === 'ems').map((esu) => esu.id), // Filter out non-EMS ESUs
          rangeDurationMinutes,
        ]
      : null,
    { refreshInterval: 60_000, fetcher: fetchFaults },
  );

  const esusById = useMemo(() => new Map((esuList ?? []).map((esu) => [esu.id, esu])), [esuList]);

  const faultDataWithESUs = useMemo(() => {
    if (!data) return undefined;
    const faultsWithESUs: FaultEventWithESU[] = data.faults.map((fault) => ({
      ...fault,
      esu: esusById.get(fault.ems_id),
    }));
    return { start: data.start_time, end: data.end_time, faults: faultsWithESUs };
  }, [data, esusById]);

  return {
    data: faultDataWithESUs,
    error: esuListError || faultsError,
    isLoading: isLoadingESUs || isLoadingFaults,
  };
}

async function fetchFaults([url, esuIDs, rangeDurationMinutes]: [
  string,
  string[],
  number,
]): Promise<FaultsResponse> {
  const end = new Date();
  const start = subMinutes(end, rangeDurationMinutes);
  const chunks = chunk(esuIDs, MAX_ESUS_PER_REQUEST);
  const responses = await Promise.all(chunks.map((batch) => fetchChunk(url, batch, start, end)));

  if (
    responses.length > 0 &&
    !responses.every(
      (r) => r.start_time === responses[0].start_time && r.end_time === responses[0].end_time,
    )
  ) {
    throw new Error('Responses have different start or end times');
  }

  return {
    start_time: responses[0].start_time,
    end_time: responses[0].end_time,
    faults: responses.flatMap((r) => r.faults),
  };
}

async function fetchChunk(
  url: string,
  esuIDs: string[],
  start: Date,
  end: Date,
): Promise<FaultsResponse> {
  const response = await http.get(
    `${url}?ems_ids=${esuIDs.join(',')}&start=${start.toISOString()}&end=${end.toISOString()}`,
    { timeout: 20_000 },
  );
  return response.data;
}

export function useSingleESUFaults(
  esuID: string | null,
  start: Date,
  end: Date,
): {
  data: FaultsResponse | undefined;
  error: Error | undefined;
  isLoading: boolean;
} {
  // TODO: Implement new faults API, this request is to an EMS-specific endpoint
  const key = esuID
    ? `/v1/ems/faults?ems_ids=${esuID}&start=${start.toISOString()}&end=${end.toISOString()}`
    : null;

  const { data, error, isLoading } = useSWR<FaultsResponse>(key, { fetcher: fetchSingleESUFaults });

  return { data, error, isLoading };
}

async function fetchSingleESUFaults(url: string) {
  const response = await http.get(url, { timeout: 20_000 });
  return response.data;
}
