import { useEffect, useMemo } from 'react';
import { useMatch, useNavigate, useParams } from 'react-router-dom';
import {
  type MRT_ColumnDef,
  type MRT_ColumnSizingState,
  type MRT_TableInstance,
  MaterialReactTable,
  useMRT_Rows,
  useMaterialReactTable,
} from 'material-react-table';
import { Alert, Box, Stack, Typography } from '@mui/material';

import { type EdgeControllerType, type ListEdgeController } from '~types';

import { asCactosError } from '~http';

import { collator } from '~utils/localization';

import { useLocalStorage } from '~hooks/useLocalStorage';
import { ResultListContextProvider } from '~hooks/useResultListContext';
import { useEdgeControllerList } from '~hooks/useEdgeControllerList';
import { CurrentEdgeControllerIdContextProvider } from '~hooks/useCurrentEdgeControllerId';

import { SplitLayout } from '~components/SplitLayout';

const typeDisplayName: Map<EdgeControllerType, string> = new Map([
  ['ems', 'EMS'],
  ['ccu', 'CCU'],
  ['cmu', 'CMU'],
  ['legacy_cmu', 'Legacy CMU'],
  ['ccu_alpha', 'CCU Alpha'],
]);

function NameFooter({ table }: { table: MRT_TableInstance<ListEdgeController> }) {
  const rows = useMRT_Rows(table);
  return (
    <Stack>
      <Box>{rows.length} edge controllers</Box>
    </Stack>
  );
}

const columns: MRT_ColumnDef<ListEdgeController>[] = [
  {
    header: 'Controller',
    id: 'name',
    accessorFn: (row) => row.name,
    sortingFn: (a, b, columnId) => collator.compare(a.getValue(columnId), b.getValue(columnId)),
    size: 150,
    Footer: NameFooter,
  },
  {
    header: 'Type',
    id: 'type',
    accessorFn: (row) => typeDisplayName.get(row.type) ?? row.type,
    sortingFn: (a, b, columnId) => collator.compare(a.getValue(columnId), b.getValue(columnId)),
    size: 120,
    filterVariant: 'autocomplete',
    filterSelectOptions: Array.from(typeDisplayName.values()),
  },
  {
    header: 'Metering Group',
    id: 'metering_group',
    accessorFn: (row) => row.metering_group?.name,
    sortingFn: (a, b, columnId) => collator.compare(a.getValue(columnId), b.getValue(columnId)),
    size: 200,
  },
  {
    header: 'Organization',
    id: 'organization',
    accessorFn: (row) => row.organization?.human_name,
    sortingFn: (a, b, columnId) => collator.compare(a.getValue(columnId), b.getValue(columnId)),
    size: 200,
  },
];

export function EdgeControllerList() {
  const { edgeControllerId } = useParams();

  const match = useMatch('/dashboard/controllers/:controllerId/:tab');
  const [defaultTab, setDefaultTab] = useLocalStorage<string>(
    'fleet.controllers.defaultTab',
    'details',
  );
  const currentTab = match?.params.tab || defaultTab;
  useEffect(() => {
    if (currentTab !== defaultTab) {
      setDefaultTab(currentTab);
    }
  }, [setDefaultTab, currentTab, defaultTab]);

  const navigate = useNavigate();
  const rowSelection = useMemo(
    () => (edgeControllerId ? { [edgeControllerId]: true } : {}),
    [edgeControllerId],
  );
  const [columnSizing, setColumnSizing] = useLocalStorage<MRT_ColumnSizingState>(
    'fleet.controllers.columnSizing',
    {},
  );
  const { data, error } = useEdgeControllerList();

  const table = useMaterialReactTable<ListEdgeController>({
    data: data ?? [],
    columns,
    initialState: {
      density: 'compact',
    },
    state: {
      rowSelection,
      columnSizing: columnSizing ?? {},
      columnPinning: {
        left: ['name'],
      },
    },
    onColumnSizingChange: setColumnSizing,
    enablePagination: false,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableColumnResizing: true,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableColumnActions: false,
    enableBottomToolbar: false,
    positionToolbarAlertBanner: 'none',
    getRowId: (row) => row.id,
    muiTableContainerProps: { sx: { maxHeight: 'calc(100vh - 120px)' } },
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => {
        const edgeControllerId = row.id;
        if (edgeControllerId) {
          const type = row.original?.type;
          const tab = type === 'ems' || type === 'ccu_alpha' ? 'details' : currentTab;
          navigate(`/dashboard/controllers/${edgeControllerId}/${tab}`);
        } else {
          navigate('/dashboard/controllers');
        }
      },
      sx: { cursor: 'pointer' },
    }),
    muiTablePaperProps: { sx: { borderRadius: 0 } },
    muiTableFooterProps: { sx: { minHeight: 60 } },
    renderEmptyRowsFallback: () =>
      error != null ? (
        <Alert
          severity="error"
          sx={{ borderRadius: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          Error loading data: {asCactosError(error).message}
        </Alert>
      ) : (
        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography variant="body2">
            <i>No controllers to display</i>
          </Typography>
        </Box>
      ),
  });
  const rows = useMRT_Rows(table);
  const resultListContext = useMemo(() => {
    const currentRowIndex = edgeControllerId
      ? rows.findIndex((row) => row.id === edgeControllerId)
      : -1;
    const totalRowCount = rows.length;
    return {
      currentRowIndex,
      totalRowCount,
      goToNextResult: () => {
        if (currentRowIndex + 1 < totalRowCount) {
          const id = rows[currentRowIndex + 1].id;
          const type = rows[currentRowIndex + 1].original.type;
          const tab = type === 'ems' || type === 'ccu_alpha' ? 'details' : currentTab;
          navigate(`/dashboard/controllers/${id}/${tab}`);
        }
      },
      goToPreviousResult: () => {
        if (currentRowIndex > 0) {
          const id = rows[currentRowIndex - 1].id;
          const type = rows[currentRowIndex - 1].original.type;
          const tab = type === 'ems' || type === 'ccu_alpha' ? 'details' : currentTab;
          navigate(`/dashboard/controllers/${id}/${tab}`);
        }
      },
      goToResultList: () => navigate('/dashboard/controllers'),
    };
  }, [rows, navigate, edgeControllerId, currentTab]);

  const selected = edgeControllerId ? data?.find((row) => row.id === edgeControllerId) : undefined;
  return (
    <CurrentEdgeControllerIdContextProvider value={edgeControllerId ?? null}>
      <ResultListContextProvider value={resultListContext}>
        <SplitLayout
          showDetails={!!edgeControllerId}
          renderTitle={() => <Typography variant="subtitle1">{selected?.name ?? ''}</Typography>}
        >
          <MaterialReactTable table={table} />
        </SplitLayout>
      </ResultListContextProvider>
    </CurrentEdgeControllerIdContextProvider>
  );
}
