import { Navigate, Outlet } from 'react-router-dom';

import { useRegisterBreadcrumbs } from '~hooks/useBreadcrumbs';
import { useEdgeController } from '~hooks/useEdgeControllerList';

import { TABS } from '~pages/v2/controllers/constants';
import { useControllersNavigation } from '~pages/v2/controllers/hooks/useControllersNavigation';
import { useControllersAllowedTabs } from '~pages/v2/controllers/hooks/useControllersAllowedTabs';
import { useControllersHierarchyBreadcrumbs } from '~pages/v2/controllers/hooks/useControllersHierarchyBreadcrumbs';
import { createControllerPath } from '~pages/v2/controllers/utils/createControllerPath';

import { Tabs } from '~components/Tabs';

export function Controller() {
  const { edgeControllerId, tab } = useControllersNavigation();
  const { data: edgeController } = useEdgeController(edgeControllerId);
  const { allowedTabs, isTabAllowed } = useControllersAllowedTabs();

  useControllersHierarchyBreadcrumbs();
  useRegisterBreadcrumbs(
    'controllers',
    edgeController?.name ?? '',
    createControllerPath(edgeControllerId),
  );

  if (!isTabAllowed) {
    return <Navigate to="." replace />;
  }

  return (
    <>
      <Tabs
        tabs={TABS}
        tab={tab}
        pathname={createControllerPath(edgeControllerId)}
        allowedTabs={allowedTabs}
      />
      <Outlet />
    </>
  );
}
