import { useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { Check, Link } from '@mui/icons-material';

export function CopyLinkButton({ link }: { link: string }) {
  const [hovered, setHovered] = useState(false);
  const [copied, setCopied] = useState(false);

  const copyLink = () => {
    navigator.clipboard.writeText(link);
    if (!copied) {
      setCopied(true);
      setTimeout(() => setCopied(false), 2_000);
    }
  };

  return (
    <Tooltip
      title={copied ? 'Link copied!' : 'Copy link'}
      open={hovered || copied}
      onOpen={() => setHovered(true)}
      onClose={() => setHovered(false)}
      placement="top"
    >
      <IconButton onClick={copyLink}>{copied ? <Check color="success" /> : <Link />}</IconButton>
    </Tooltip>
  );
}
