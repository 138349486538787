import { useRegisterBreadcrumbs } from '~hooks/useBreadcrumbs';
import { useESU } from '~hooks/useESUList';

import { createControllerPath } from '~pages/v2/controllers/utils/createControllerPath';
import { useESUsNavigation } from '~pages/v2/esus/hooks/useESUsNavigation';
import { createOrganizationPath } from '~pages/v2/organizations/utils/createOrganizationPath';
import { createSitePath } from '~pages/v2/sites/utils/createSitePath';

export function useESUsHierarchyBreadcrumbs() {
  const { esuId } = useESUsNavigation();
  const { data: esu } = useESU(esuId);

  useRegisterBreadcrumbs(
    'organizations',
    esu?.edge_controller.organization.human_name ?? null,
    createOrganizationPath(esu?.edge_controller.organization.id ?? ''),
  );
  useRegisterBreadcrumbs(
    'sites',
    esu?.edge_controller.metering_group.name ?? null,
    createSitePath(esu?.edge_controller.metering_group.id ?? ''),
  );
  useRegisterBreadcrumbs(
    'controllers',
    esu?.edge_controller.name ?? null,
    createControllerPath(esu?.edge_controller.id ?? ''),
  );
}
