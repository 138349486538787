import { Typography } from '@mui/material';

import { DashboardCard, DashboardCardHeader } from '../../../components/DashboardCard';

type EMSGridFrequencyCardProps = {
  frequencyMilliHz: number | undefined | null;
  isLoading: boolean;
};

export function EMSGridFrequencyCard(props: EMSGridFrequencyCardProps) {
  const { frequencyMilliHz, isLoading } = props;

  if (isLoading && frequencyMilliHz == null) {
    return (
      <DashboardCard size="small">
        <DashboardCardHeader>Grid Frequency</DashboardCardHeader>
      </DashboardCard>
    );
  }

  return (
    <DashboardCard size="small">
      <DashboardCardHeader>Grid Frequency</DashboardCardHeader>
      <Typography variant="data" sx={{ fontSize: 24 }}>
        {frequencyMilliHz != null ? `${frequencyMilliHz / 1000} Hz` : 'N/A'}
      </Typography>
    </DashboardCard>
  );
}
