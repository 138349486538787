import { useRegisterBreadcrumbs } from '~hooks/useBreadcrumbs';
import { useEdgeController } from '~hooks/useEdgeControllerList';

import { useControllersNavigation } from '~pages/v2/controllers/hooks/useControllersNavigation';
import { createOrganizationPath } from '~pages/v2/organizations/utils/createOrganizationPath';
import { createSitePath } from '~pages/v2/sites/utils/createSitePath';

export function useControllersHierarchyBreadcrumbs() {
  const { edgeControllerId } = useControllersNavigation();
  const { data: edgeController } = useEdgeController(edgeControllerId);

  useRegisterBreadcrumbs(
    'organizations',
    edgeController?.organization.human_name ?? null,
    createOrganizationPath(edgeController?.organization.id ?? ''),
  );
  useRegisterBreadcrumbs(
    'sites',
    edgeController?.metering_group.name ?? null,
    createSitePath(edgeController?.metering_group.id ?? ''),
  );
}
