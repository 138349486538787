import { type ChangeEvent, useCallback, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { ErrorMessage, Form, Formik } from 'formik';
import * as Yup from 'yup';

import { asCactosError, http } from '~http';

import { useOrganizationList } from '~hooks/useOrganizationList';

import { ErrorContainer } from '~components/ErrorContainer';

type Props = {
  open: boolean;
  onClose: () => void;
};

const CreateOrganizationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Path is too short')
    .max(100, 'Path is too long')
    .matches(
      /^\/[a-z0-9-/]*\/$/,
      'Must start and end with / and may only contain lowercase alphanumeric characters (a-z, 0-9) and hyphens (-)',
    )
    .required('Required'),
  humanName: Yup.string()
    .min(2, 'Name is too short')
    .max(100, 'Name is too long')
    .required('Required'),
});

export function OrganizationCreateModal({ open, onClose }: Props) {
  const { mutate } = useOrganizationList();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleClose = useCallback(() => {
    setError(null);
    onClose();
  }, [onClose]);

  const handleHumanNameChange = useCallback(
    (
      handleChange: (event: ChangeEvent<HTMLInputElement>) => void,
      setFieldValue: (field: string, value: any) => void,
      touched: boolean,
    ) =>
      (event: ChangeEvent<HTMLInputElement>) => {
        handleChange(event);

        if (touched) return;

        // Apply autogenerated name
        const name = event.target.value
          .toLowerCase()
          .replace(/[^a-z0-9/]+/g, '-')
          .replace(/^-|-$/g, '');

        setFieldValue('name', `/${name}/`);
      },
    [],
  );

  const handleInvite = useCallback(
    async (name: string, humanName: string) => {
      if (isLoading) return;

      setIsLoading(true);
      setError(null);

      try {
        await http.post(`/v1/organization`, { name, human_name: humanName });
        await mutate();

        handleClose();
      } catch (error: unknown) {
        setError(asCactosError(error).message);
      }

      setIsLoading(false);
    },
    [isLoading, mutate, handleClose],
  );

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Create Organization</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{ name: '', humanName: '' }}
          onSubmit={async (values, { setSubmitting }) => {
            await handleInvite(values.name, values.humanName);
            setSubmitting(false);
          }}
          validationSchema={CreateOrganizationSchema}
        >
          {({ values, touched, handleChange, handleBlur, setFieldValue }) => (
            <Form id="create-organization-form">
              <TextField
                autoFocus
                fullWidth
                required
                margin="dense"
                id="humanName"
                name="humanName"
                label="Name"
                onChange={handleHumanNameChange(handleChange, setFieldValue, touched.name ?? false)}
                onBlur={handleBlur}
                value={values.humanName}
              />
              <ErrorMessage name="humanName" component={ErrorContainer} />
              <Box marginTop={2}>
                <TextField
                  fullWidth
                  required
                  margin="dense"
                  id="name"
                  name="name"
                  label="Path"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                />
              </Box>
              <ErrorMessage name="name" component={ErrorContainer} />
            </Form>
          )}
        </Formik>
        {!!error && (
          <Box marginTop={2}>
            <ErrorContainer>{error}</ErrorContainer>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <LoadingButton type="submit" form="create-organization-form" loading={isLoading}>
          Create
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
