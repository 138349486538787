import { createContext, useContext } from 'react';

const CurrentOrganizationContext = createContext<string | null>(null);

export const CurrentOrganizationIdProvider = CurrentOrganizationContext.Provider;

export function useCurrentOrganizationId() {
  const id = useContext(CurrentOrganizationContext);

  if (id == null) {
    throw new Error('useCurrentOrganizationId must be used within a CurrentOrganizationIdProvider');
  }

  return id;
}
