import type { DateTimeRange } from '~utils/time';

import { createRangeQuery, useTimeseriesRange } from '~hooks/timeseries/queries';

export function useCurrentBidsAndAllocations(biddingDomain: string, range: DateTimeRange) {
  return useTimeseriesRange(
    createRangeQuery({
      resources: [`region:${biddingDomain}`],
      columns: {
        fcr_bid: ['price', 'quantity', 'currency', 'acknowledged_at'],
        fcr_allocation: ['price', 'quantity', 'currency', 'received_timestamp'],
        fingrid_fcr_n_market_price: ['value'],
        entsoe_day_ahead_price: ['amount'],
      },
      start: range.start,
      end: range.end,
    }),
  );
}
