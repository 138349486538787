import * as Yup from 'yup';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Form, FormikProvider, useFormik } from 'formik';
import { styled } from '@mui/material/styles';
import {
  Box,
  Container,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { plainHttp } from '~http';

import { useAbortController } from '~hooks/useAbortController';

import { Page } from '~components/Page';
import { Iconify } from '~components/Iconify';

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0),
}));

const LoginSchema = Yup.object().shape({
  password: Yup.string()
    .required('Password is required')
    .min(12, 'Password must be at least 12 characters long'),
  confirmPassword: Yup.string()
    .required('Password confirmation is required')
    .when('password', {
      is: (val) => val && val.length > 0,
      then: () => Yup.string().oneOf([Yup.ref('password')], 'Must match new password'),
    }),
});

export function Signup() {
  const { inviteId } = useParams();
  const [inviteData, setInviteData] = useState(undefined);
  const [showPassword, setShowPassword] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const getSignal = useAbortController();

  const fetchInvite = useCallback(async () => {
    try {
      const response = await plainHttp.get(`/v1/invite/${inviteId}`, { signal: getSignal() });
      setInviteData(response?.data ?? {});
    } catch (e) {
      console.log(e.response);
      console.log(e?.response?.data);
      window.location.href = '/404';
    }
  }, [inviteId, getSignal]);

  useEffect(() => {
    fetchInvite();
  }, [fetchInvite]);

  const formik = useFormik({
    initialValues: {
      email: inviteData?.email,
      password: '',
      confirmPassword: '',
    },
    validationSchema: LoginSchema,
    onSubmit: () => submit(values.password),
  });

  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setSubmitting,
    setStatus,
  } = formik;

  const submit = useCallback(
    async (password) => {
      let status = '';
      try {
        const response = await plainHttp.post(`/v1/invite/${inviteId}`, { password });
        status = response?.data?.message || 'Check your email!';
        setSubmitSuccess(true);
      } catch (e) {
        const { response } = e;
        if (response.status === 404 || response.status === 401) {
          status = 'Invite not found. Has it been used already?';
        } else if (response.status === 400) {
          status = response?.data?.message || 'Unexpected failure';
        } else {
          status = 'Unexpected failure';
        }
      }
      setSubmitting(false);
      setStatus(status);
    },
    [inviteId, setSubmitting, setStatus],
  );

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };
  if (!inviteData) {
    return '';
  }
  return (
    <RootStyle title="Signup | Cactos Spine">
      <Container maxWidth="sm">
        <ContentStyle>
          <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
            <Typography variant="h3" paragraph>
              Welcome to Cactos Spine!
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              You have been invited to Cactos Spine. Come up with a password and enter it below so
              that we can create your account!
              <br />
              <br />
              If you would like to change your email, which will also be your username, you need to
              ask to have a new invite sent to the other address.
            </Typography>
          </Box>

          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              {!submitSuccess && (
                <Stack spacing={3} sx={{ mb: 3 }}>
                  <TextField
                    margin="dense"
                    fullWidth
                    type="text"
                    label="Email"
                    value={inviteData?.email}
                    disabled
                  />
                  <TextField
                    margin="dense"
                    fullWidth
                    type={showPassword ? 'text' : 'password'}
                    label="New password"
                    {...getFieldProps('password')}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleShowPassword} edge="end">
                            <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    error={Boolean(touched.password && errors.password)}
                    helperText={(touched.password && errors.password) || ' '}
                  />
                  <TextField
                    margin="dense"
                    fullWidth
                    type={showPassword ? 'text' : 'password'}
                    label="Confirm password"
                    {...getFieldProps('confirmPassword')}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleShowPassword} edge="end">
                            <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                    helperText={(touched.confirmPassword && errors.confirmPassword) || ' '}
                  />
                  <LoadingButton
                    margin="dense"
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                    disabled={!formik.dirty || !formik.isValid}
                  >
                    Submit
                  </LoadingButton>
                </Stack>
              )}
              {formik.status && (
                <Typography sx={{ mt: 3 }}>
                  <strong>{formik.status}</strong>
                </Typography>
              )}
            </Form>
          </FormikProvider>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
