import { NavLink, Outlet, useLocation, useMatch } from 'react-router-dom';
import { TabContext, TabList } from '@mui/lab';
import { Tab } from '@mui/material';

import { USER_ROLES } from '~constants/auth';

import { useAuth } from '~hooks/useAuth';

import { ScrollView } from '~components/ScrollView';

export function ESUDetails() {
  const match = useMatch('/dashboard/esus/:esuId/:tab');
  const queryString = useLocation().search;
  const getUrl = (tab: string) => tab + queryString;
  const currentTab = match?.params.tab || 'diagnostics';
  const { user } = useAuth();
  const roles = user?.organizationsByRole || {};
  return (
    <>
      <TabContext value={currentTab}>
        <TabList
          sx={{
            marginLeft: 1,
          }}
        >
          {USER_ROLES.EMS_DIAGNOSTIC_VIEW in roles && (
            <Tab
              value="diagnostics"
              label="Diagnostics"
              component={NavLink}
              to={getUrl('diagnostics')}
            />
          )}
          {(USER_ROLES.EDIT_EMS_SCHEDULE in roles || USER_ROLES.EMS_DIAGNOSTIC_RUN in roles) && (
            <Tab value="control" label="Control" component={NavLink} to={getUrl('control')} />
          )}
          {USER_ROLES.OPERATOR_VIEW in roles && (
            <Tab value="schedule" label="Schedule" component={NavLink} to={getUrl('schedule')} />
          )}
          {USER_ROLES.DEBUG_EMS in roles && (
            <Tab value="events" label="Events" component={NavLink} to={getUrl('events')} />
          )}
        </TabList>
      </TabContext>
      <ScrollView>
        <Outlet />
      </ScrollView>
    </>
  );
}
