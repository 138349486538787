import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';

import { useRegisterBreadcrumbs } from '~hooks/useBreadcrumbs';

import { PageLayout } from '~layouts/PageLayout';

import { TABS } from '~pages/v2/fleets/constants';
import { useFleetsNavigation } from '~pages/v2/fleets/hooks/useFleetsNavigation';
import { createFleetPath } from '~pages/v2/fleets/utils/createFleetPath';

import { Tabs } from '~components/Tabs';

const TABS_HEIGHT = 48;

export function Fleets() {
  const { tab } = useFleetsNavigation();

  useRegisterBreadcrumbs('fleets', 'Fleets', createFleetPath());

  return (
    <PageLayout title="Fleets">
      <Tabs tabs={TABS} tab={tab} pathname={createFleetPath()} />
      <Box height={`calc(100% - ${TABS_HEIGHT}px)`} overflow="auto">
        <Box p={2} display="flex" flexDirection="column" gap={2}>
          <Outlet />
        </Box>
      </Box>
    </PageLayout>
  );
}
