import { useCallback, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { ErrorMessage, Form, Formik } from 'formik';
import * as Yup from 'yup';
import Decimal from 'decimal.js';

import { asCactosError, http } from '~http';

import { currencies } from '~constants/currencies';

import { useOrganizationList } from '~hooks/useOrganizationList';
import { useMeteringGroupList } from '~hooks/useMeteringGroupList';

import { ErrorContainer } from '~components/ErrorContainer';

type Props = {
  open: boolean;
  onClose: () => void;
};

type AutocompleteOption = {
  id: string;
  label: string;
};

const timeZones = Intl.supportedValuesOf('timeZone');

const decimalNumberRegex = /^-?\d+(\.\d+)?$/;

const CreateGroupSchema = Yup.object().shape({
  name: Yup.string().min(1, 'Name is too short').max(100, 'Name is too long').required('Required'),
  organizationId: Yup.string().required('Required'),
  fcrNRevenueShare: Yup.string()
    .matches(decimalNumberRegex, 'Value must be a number')
    .test('is-percent', 'Value must be a positive percentage', (value) => {
      if (!value) return true;
      return (
        new Decimal(value).greaterThanOrEqualTo(0) && new Decimal(value).lessThanOrEqualTo(100)
      );
    })
    .required('Required'),
  latitude: Yup.string()
    .matches(decimalNumberRegex, 'Value must be a number')
    .test('is-latitude', 'Value must be between -90 and 90', (value) => {
      if (!value) return true;
      return (
        new Decimal(value).greaterThanOrEqualTo(-90) && new Decimal(value).lessThanOrEqualTo(90)
      );
    })
    .required('Required'),
  longitude: Yup.string()
    .matches(decimalNumberRegex, 'Value must be a number')
    .test('is-longitude', 'Value must be between -180 and 180', (value) => {
      if (!value) return true;
      return (
        new Decimal(value).greaterThanOrEqualTo(-180) && new Decimal(value).lessThanOrEqualTo(180)
      );
    })
    .required('Required'),
  currency: Yup.string().oneOf(currencies, 'Invalid currency').required('Required'),
  timeZone: Yup.string().oneOf(timeZones, 'Invalid timezone').required('Required'),
});

export function GroupCreateModal({ open, onClose }: Props) {
  const { data: organizations, error: organizationsError } = useOrganizationList();
  const { mutate } = useMeteringGroupList();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleClose = useCallback(() => {
    setError(null);
    onClose();
  }, [onClose]);

  const handleSubmit = useCallback(
    async (values: Yup.InferType<typeof CreateGroupSchema>) => {
      if (isLoading) return;

      setIsLoading(true);
      setError(null);

      try {
        await http.post(`/v1/metering_group`, {
          name: values.name,
          organization_id: values.organizationId,
          fcr_n_revenue_share: new Decimal(values.fcrNRevenueShare).div(100).toString(),
          location: {
            latitude: new Decimal(values.latitude).toString(),
            longitude: new Decimal(values.longitude).toString(),
          },
          currency: values.currency,
          time_zone: values.timeZone,
        });
        await mutate();

        handleClose();
      } catch (error: unknown) {
        setError(asCactosError(error).message);
      }

      setIsLoading(false);
    },
    [isLoading, mutate, handleClose],
  );

  if (organizationsError) return null;

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Create Metering Group</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{
            name: '',
            organizationId: '',
            fcrNRevenueShare: '',
            latitude: '',
            longitude: '',
            currency: 'EUR',
            timeZone: 'Europe/Helsinki',
          }}
          onSubmit={async (values, { setSubmitting }) => {
            await handleSubmit(values);
            setSubmitting(false);
          }}
          validationSchema={CreateGroupSchema}
        >
          {({ values, handleChange, handleBlur, setFieldValue }) => (
            <Form id="create-metering-group-form">
              <TextField
                autoFocus
                fullWidth
                required
                margin="dense"
                id="name"
                name="name"
                label="Name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
              />
              <ErrorMessage name="name" component={ErrorContainer} />
              <Box marginTop={2}>
                <Autocomplete
                  fullWidth
                  autoHighlight
                  value={
                    values.organizationId
                      ? {
                          id: values.organizationId,
                          label:
                            organizations?.find((o) => o.id === values.organizationId)
                              ?.human_name ?? '',
                        }
                      : null
                  }
                  onChange={(_event, value: AutocompleteOption | null) => {
                    setFieldValue('organizationId', value?.id ?? '');
                  }}
                  options={
                    organizations?.map((organization) => ({
                      label: organization.human_name,
                      id: organization.id,
                    })) ?? []
                  }
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  renderInput={(params) => <TextField {...params} label="Organization" />}
                />
                <ErrorMessage name="organizationId" component={ErrorContainer} />
              </Box>
              <Box marginTop={2}>
                <TextField
                  fullWidth
                  required
                  type="number"
                  margin="dense"
                  id="fcrNRevenueShare"
                  name="fcrNRevenueShare"
                  label="FCR-N Revenue Share"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                  }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.fcrNRevenueShare}
                />
                <ErrorMessage name="fcrNRevenueShare" component={ErrorContainer} />
              </Box>
              <Box marginTop={2} display="flex" gap={2}>
                <Box sx={{ width: '100%' }}>
                  <TextField
                    fullWidth
                    required
                    type="number"
                    margin="dense"
                    id="latitude"
                    name="latitude"
                    label="Latitude"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.latitude}
                  />
                  <ErrorMessage name="latitude" component={ErrorContainer} />
                </Box>
                <Box sx={{ width: '100%' }}>
                  <TextField
                    fullWidth
                    required
                    type="number"
                    margin="dense"
                    id="longitude"
                    name="longitude"
                    label="Longitude"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.longitude}
                  />
                  <ErrorMessage name="longitude" component={ErrorContainer} />
                </Box>
              </Box>
              <Box marginTop={2} display="flex" gap={2}>
                <Box sx={{ width: '100%' }}>
                  <FormControl fullWidth margin="dense">
                    <InputLabel>Currency</InputLabel>
                    <Select
                      fullWidth
                      required
                      margin="dense"
                      id="currency"
                      name="currency"
                      label="Currency"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.currency}
                    >
                      {currencies.map((currency) => (
                        <MenuItem key={currency} value={currency}>
                          {currency}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <ErrorMessage name="currency" component={ErrorContainer} />
                </Box>
                <Box sx={{ width: '100%' }}>
                  <FormControl fullWidth margin="dense">
                    <InputLabel>Time Zone</InputLabel>
                    <Select
                      fullWidth
                      required
                      margin="dense"
                      id="timeZone"
                      name="timeZone"
                      label="Time Zone"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.timeZone}
                    >
                      {timeZones.map((timeZone) => (
                        <MenuItem key={timeZone} value={timeZone}>
                          {timeZone}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <ErrorMessage name="timeZone" component={ErrorContainer} />
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
        {!!error && (
          <Box marginTop={2}>
            <ErrorContainer>{error}</ErrorContainer>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <LoadingButton type="submit" form="create-metering-group-form" loading={isLoading}>
          Create
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
