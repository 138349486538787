import { type PropsWithChildren } from 'react';
import { Navigate, useLocation, useMatch } from 'react-router-dom';

import { useIsRoutePermitted } from '~hooks/useIsRoutePermitted';

import { NotFound } from '~pages/NotFound';

// Make sure that the user has the correct role to view the page
export function RoleBouncer({ children }: PropsWithChildren) {
  const { pathname } = useLocation();
  const isRoutePermitted = useIsRoutePermitted(pathname);
  const tabMatch = useMatch('/v2/:section/:id/:tab');

  if (!isRoutePermitted) {
    // If within an unpermitted tab, redirect to the section index page
    // This is useful when the user role has changed
    // and they do not have access to the tab anymore
    // They might have access to another tab instead
    if (tabMatch?.params.tab) {
      const { section, id } = tabMatch.params;

      return <Navigate replace to={`/v2/${section}/${id}`} />;
    }

    return <NotFound />;
  }

  return children;
}
