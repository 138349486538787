import { useMemo } from 'react';
import useSWR from 'swr';

import { fetcher } from '~http';

import { type DateTimeRange } from '~utils/time';

type EventResponse = {
  events: [
    // RFC3339
    timestamp: string,
    // event name according to ems-server, or if the server doesn't
    // know the name it passes the id through as a string
    event_name: string,
    // event parameter, it can not be easily explained
    event_param: number,
    // event name according to ems-firmware, or last the index is
    // missing if the firmware doesn't know the name
    event_firmware_name?: string,
  ][];
  executed_start: string;
  executed_end: string;
  first_ping_timestamp?: string;
  last_ping_timestamp?: string;
  number_of_events_in_result: number;
  result_did_not_include_all_of_queried_range: boolean;
  result_range_end: string;
  invalid_events?: any[];
};

export type ESUEventFilterType = { select: string[] } | { ignore: string[] };

export const useESUEvents = (
  esuID: string | null,
  range: DateTimeRange,
  filter?: ESUEventFilterType,
) => {
  const f = filter as { select?: string[]; ignore?: string[] } | undefined;
  const selectFilter = (f?.select?.length ?? 0) > 0 ? f!.select!.join(',') : null;
  const ignoreFilter = (f?.ignore?.length ?? 0) > 0 ? f!.ignore!.join(',') : null;

  const key =
    esuID != null
      ? `/v1/ems/${esuID}/event_log?start=${range.start.toISOString()}&end=${range.end.toISOString()}` +
        (selectFilter ? `&events=${selectFilter}` : '') +
        (ignoreFilter ? `&ignore_events=${ignoreFilter}` : '')
      : null;

  const {
    data: response,
    error,
    isLoading,
  } = useSWR<EventResponse>(key, { fetcher, revalidateOnFocus: false });

  const data = useMemo(
    () =>
      response != null
        ? {
            events: response.events.map(([timestamp, serverName, param, firmwareName]) => ({
              time: new Date(timestamp),
              name: serverName,
              firmwareName,
              param,
            })),
            resultsRangeEnd: new Date(response.result_range_end),
            resultIsIncomplete: response.result_did_not_include_all_of_queried_range,
            firstPingTimestamp:
              response.first_ping_timestamp != null
                ? new Date(response.first_ping_timestamp)
                : undefined,
            lastPingTimestamp:
              response.last_ping_timestamp != null
                ? new Date(response.last_ping_timestamp)
                : undefined,
          }
        : undefined,
    [response],
  );

  return { data, error, isLoading };
};
