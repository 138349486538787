import dayjs from 'dayjs';
import * as _ from 'lodash-es';

import { formatLocalTime } from '~utils/time';
import { checkInt, checkIntOrThrow } from '~utils/parsing';
import { type Row } from '~utils/timeseries';

const formatDate = (value: dayjs.Dayjs | string, isCurrent: boolean) => {
  const time = dayjs(value);
  const formattedTime = formatLocalTime(time);
  if (isCurrent) {
    return <strong>{formattedTime}</strong>;
  }
  return time > dayjs() ? formattedTime : <i>{formattedTime}</i>;
};

const formatPercent = (value: string | number) => `${value} %`;
const formatPower = (value: number) => `${value / 1000} kW`;
const formatOptionalPower = (value: number | null) => (value != null ? formatPower(value) : '');
const formatCurrent = (value: string | number) => `${value} A`;

type ScheduleRowKey = keyof ScheduleRow;

export type ColumnDetail<T> = {
  id: keyof T;
  label: string;
  formatter: (value: any) => string;
};

type CommonScheduleColumnDetail = ColumnDetail<ScheduleRow> & {
  id: ScheduleRowKey; // Enables indexing ScheduleRow with the id
  unit: string;
};

export const SCHEDULE_TIMESERIES_COLUMNS = [
  'mode',
  'min_target_soc',
  'max_target_soc',
  'charge_power',
  'discharge_power',
  'max_fcrn_charge',
  'max_fcrn_discharge',
  'peak_shaving_threshold',
  'max_peak_shaving_output',
  'fcrd_up_allocated_cap',
  'fcrd_down_allocated_cap',
] as const;

export const SCHEDULE_COLUMNS: CommonScheduleColumnDetail[] = [
  {
    id: 'mode',
    label: 'Mode',
    formatter: (value: string) => value,
    unit: '',
  },
  {
    id: 'min_target_soc',
    label: 'Min SOC',
    unit: '%',
    formatter: formatPercent,
  },
  {
    id: 'max_target_soc',
    label: 'Max SOC',
    unit: '%',
    formatter: formatPercent,
  },
  {
    id: 'charge_power',
    label: 'Charge power',
    unit: 'W',
    formatter: formatPower,
  },
  {
    id: 'discharge_power',
    label: 'Discharge power',
    unit: 'W',
    formatter: formatPower,
  },
  {
    id: 'max_fcrn_charge',
    label: 'Max FCR-N charge',
    unit: 'W',
    formatter: formatPower,
  },
  {
    id: 'max_fcrn_discharge',
    label: 'Max FCR-N discharge',
    unit: 'W',
    formatter: formatPower,
  },
  {
    id: 'fcrd_up_allocated_cap',
    label: 'FCR-D up allocated capacity',
    unit: 'W',
    formatter: formatOptionalPower,
  },
  {
    id: 'fcrd_down_allocated_cap',
    label: 'FCR-D down allocated capacity',
    unit: 'W',
    formatter: formatOptionalPower,
  },
  {
    id: 'peak_shaving_threshold',
    label: 'Peak shaving threshold',
    unit: 'A',
    formatter: formatCurrent,
  },
  {
    id: 'max_peak_shaving_output',
    label: 'Max peak shaving output',
    unit: 'W',
    formatter: formatPower,
  },
];

export const CUSTOMER_SCHEDULE_COLUMNS: CommonScheduleColumnDetail[] = SCHEDULE_COLUMNS.filter(
  (detail) =>
    detail.id !== 'mode' &&
    detail.id !== 'fcrd_up_allocated_cap' &&
    detail.id !== 'fcrd_down_allocated_cap',
);

export const SCHEDULE_CSV_COLUMNS = [
  {
    id: 'from_time',
    label: 'Start time',
    formatter: formatDate,
  },
  ...SCHEDULE_COLUMNS,
];

// Schedule row, from schedule_history timeseries
export type ScheduleRow = {
  mode: string | null;
  min_target_soc: number;
  max_target_soc: number;
  charge_power: number;
  discharge_power: number;
  max_fcrn_charge: number;
  max_fcrn_discharge: number;
  peak_shaving_threshold: number;
  max_peak_shaving_output: number;
  fcrd_up_allocated_cap: number;
  fcrd_down_allocated_cap: number;
};

export type ScheduleRowWithTime = ScheduleRow & {
  time: dayjs.Dayjs;
};

export function toScheduleRow(formValues: any): ScheduleRow {
  return {
    mode: formValues.mode,
    min_target_soc: checkIntOrThrow(formValues.min_target_soc),
    max_target_soc: checkIntOrThrow(formValues.max_target_soc),
    charge_power: checkIntOrThrow(formValues.charge_power),
    discharge_power: checkIntOrThrow(formValues.discharge_power),
    max_fcrn_charge: checkIntOrThrow(formValues.max_fcrn_charge),
    max_fcrn_discharge: checkIntOrThrow(formValues.max_fcrn_discharge),
    peak_shaving_threshold: checkIntOrThrow(formValues.peak_shaving_threshold),
    max_peak_shaving_output: checkIntOrThrow(formValues.max_peak_shaving_output),
    fcrd_up_allocated_cap: checkInt(formValues.fcrd_up_allocated_cap) ?? 0,
    fcrd_down_allocated_cap: checkInt(formValues.fcrd_down_allocated_cap) ?? 0,
  };
}

export function toScheduleRowWithTime(formValues: any): ScheduleRowWithTime {
  return { ...toScheduleRow(formValues), time: dayjs(formValues.time) };
}

export type LatestScheduleResponse = {
  schedule: ScheduleRowWithTime[];
  created_by?: string;
  detail?: string;
};

export function toLatestScheduleResponse(responseBody: any): LatestScheduleResponse {
  if (Array.isArray(responseBody?.schedule?.data)) {
    return {
      schedule: responseBody.schedule.data.map(toScheduleRowWithTime),
      created_by: responseBody?.created_by?.toString() ?? undefined,
      detail: responseBody?.detail?.toString() ?? undefined,
    };
  }
  if (Array.isArray(responseBody?.data)) {
    return {
      schedule: responseBody.data.map(toScheduleRowWithTime),
      created_by: undefined,
      detail: undefined,
    };
  }
  throw new Error(`Unexpected response shape: ${JSON.stringify(responseBody)}`);
}

export const TIME_COLUMN = {
  id: 'time',
  label: 'Start time',
  formatter: formatDate,
};

export function asOfIndex(asOf: dayjs.Dayjs, schedule: ScheduleRowWithTime[]) {
  return _.findLastIndex(schedule, (row) => row.time.isSameOrBefore(asOf));
}

export function keepAsOf(asOf: dayjs.Dayjs, schedule: ScheduleRowWithTime[]) {
  return _.dropWhile(schedule, (_, idx) => idx < asOfIndex(asOf, schedule));
}

export interface ScheduleControl {
  time: dayjs.Dayjs;
  duration: number;
}

export type TempControl = {
  time: dayjs.Dayjs;
  duration: number;
  maxPower: number;
};

type TempControlDetail = ColumnDetail<TempControl> & { id: keyof TempControl };

export const TEMP_CONTROL_DETAILS: TempControlDetail[] = [
  {
    id: 'maxPower',
    label: 'Maximum power',
    formatter: formatPower,
  },
];

export type EnergyManagement = {
  time: dayjs.Dayjs;
  duration: number;
  aemActive: boolean;
  fRef: number;
  nemMultiplier: number;
  nemPower: number;
  remainingEndurance: number;
};

type EnergyManagementDetail = ColumnDetail<EnergyManagement> & { id: keyof EnergyManagement };

export const ENERGY_MANAGEMENT_DETAILS: EnergyManagementDetail[] = [
  {
    id: 'aemActive',
    label: 'AEM active',
    formatter: (x) => x.toString(),
  },
  {
    id: 'fRef',
    label: 'f_ref',
    formatter: (x: number) => `${x.toFixed(3)} Hz`,
  },
  {
    id: 'nemPower',
    label: 'NEM power',
    formatter: formatPower,
  },
  {
    id: 'nemMultiplier',
    label: 'NEM multiplier',
    formatter: (x: number) => x.toFixed(3),
  },
  {
    id: 'remainingEndurance',
    label: 'Remaining endurance',
    formatter: (x: number) => `${x.toFixed(1)} min`,
  },
];

export type ManualControl = ScheduleRowWithTime & { duration: number };

export type ActiveControls = {
  temp?: TempControl;
  manual?: ManualControl;
  energyManagement?: EnergyManagement;
};

export function isControlActiveAt(control: ScheduleControl, at: dayjs.Dayjs) {
  return (
    control.time.isSameOrBefore(at) && at.isBefore(control.time.add(control.duration, 'seconds'))
  );
}

export function toTempControl(row: Row): TempControl | undefined {
  try {
    const time = dayjs(row.time.toString());
    const duration = checkIntOrThrow(row.duration);
    if (duration === 0) {
      return undefined;
    }
    const maxPower = checkIntOrThrow(row.max_power);
    if (time !== undefined) {
      return { time, duration, maxPower };
    }
  } catch (e) {
    throw new Error(`Unable to parse temperature control from ${JSON.stringify(row)}: ${e}`);
  }
  throw new Error('Not a temperature control');
}

export function toManualControl(row: Row): ManualControl | undefined {
  try {
    const duration = checkIntOrThrow(row.duration);
    if (duration === 0) {
      return undefined;
    }
    const scheduleRow = toScheduleRowWithTime(row);
    return { ...scheduleRow, duration };
  } catch (e) {
    throw new Error(`Unable to parse manual schedule control from ${JSON.stringify(row)}: ${e}`);
  }
}

export function toEnergyManagement(row: Row): EnergyManagement | undefined {
  try {
    const time = dayjs(row.time.toString());
    const duration = checkIntOrThrow(row.duration);
    if (duration === 0) {
      return undefined;
    }
    const aemActive = checkIntOrThrow(row.aem_active) !== 0;
    const fRef = Number(row.f_ref);
    const nemPower = checkIntOrThrow(row.nem_power);
    const nemMultiplier = Number(row.nem_multiplier);
    const remainingEndurance = Number(row.remaining_endurance);
    if (time !== undefined) {
      return { time, duration, aemActive, fRef, nemPower, nemMultiplier, remainingEndurance };
    }
  } catch (e) {
    throw new Error(`Unable to parse SoC control from ${JSON.stringify(row)}: ${e}`);
  }
  throw new Error('Not a temperature control');
}
