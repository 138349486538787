const numeric = /^-?[0-9]+$/;
export function checkInt(value: any): number | undefined {
  let result: number;
  switch (typeof value) {
    case 'string':
      if (!numeric.test(value)) {
        return undefined;
      }
      result = parseInt(value, 10);
      if (Number.isNaN(result)) {
        return undefined;
      }
      break;
    case 'number':
      if (Math.round(value) !== value) {
        return undefined;
      }
      result = value;
      break;
    default:
      return undefined;
  }
  if (!Number.isSafeInteger(result)) {
    return undefined;
  }
  if (result === 0) {
    /// Convert -0 to 0
    return 0;
  }

  return result;
}

export function checkIntOrThrow(value: any): number {
  const result = checkInt(value);
  if (result === undefined) {
    throw new RangeError(`Not an integer: ${value}`);
  }
  return result;
}

const integralDecimal = /^[+-]?[0-9]+\.0+$/;
export function checkIntegralDecimal(value: any): number | undefined {
  if (typeof value === 'string') {
    // Compared to checkInt, allow zero decimals in string
    if (integralDecimal.test(value)) {
      value = Number(value);
    }
  }
  return checkInt(value);
}
