import { type DateTimeRange } from '~utils/time';
import { getAggregageQueryTimeStepSeconds } from '~utils/timeseries';

import { createAggregateQuery, useTimeseriesAggregate } from '~hooks/timeseries/queries';
import { type ResourceID } from '~hooks/timeseries/types';

export function useSiteGridMeterData(meteringGroupId: string, range: DateTimeRange) {
  const meteringGroupResourceId: ResourceID = `metering_group:${meteringGroupId}`;
  const timeStepSeconds = getAggregageQueryTimeStepSeconds(range);
  const { data, isLoading, error } = useTimeseriesAggregate(
    createAggregateQuery({
      resources: meteringGroupResourceId != null ? [meteringGroupResourceId] : [],
      columns: {
        grid_meter: [
          'grid_active_power:mean',
          'grid_active_power:min',
          'grid_active_power:max',
          'current_a:mean',
          'current_b:mean',
          'current_c:mean',
        ],
      },
      start: range.start,
      end: range.end,
      step: `${timeStepSeconds}s`,
    }),
  );
  return {
    data:
      meteringGroupResourceId != null ? data?.[meteringGroupResourceId].grid_meter ?? null : null,
    isLoading,
    error,
  };
}
