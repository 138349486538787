import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Alert,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import useSWR from 'swr';
import dayjs from 'dayjs';

import { type Region } from '~types';

import { asCactosError, fetcher } from '~http';

import { type DateTimeRange, currentTimeZone, formatDateTimeNoTimeZone } from '~utils/time';

import { type ResourceID, useTimeseriesLatest } from '~hooks/timeseries';
import { createLatestQuery, createRangeQuery, useTimeseriesRange } from '~hooks/timeseries/queries';
import { useCurrentBidsAndAllocations } from '~hooks/useCurrentBidsAndAllocations';

import { BidsAndAllocations } from '~pages/v2/fleets/components/BidsAndAllocations';

import { FCRGraph } from '~components/charts/FCRGraph';
import { Scrollbar } from '~components/Scrollbar';

import { StyledToaster } from '../../../components/StyledToaster';
import { BidUpload } from './BidUpload';
import { BidUploadForm } from './BidUploadForm';

const BIDDING_DOMAIN = '10YFI-1--------U';
const ALLOCATION_TZ = 'CET';

export function FCR() {
  const [bidUploadTab, setBidUploadTab] = useState<'form' | 'csv'>('form');

  const [range, setRange] = useState<DateTimeRange>(getTimeRange());

  useEffect(() => {
    const interval = setInterval(() => {
      setRange(getTimeRange());
    }, 60_000);
    return () => clearInterval(interval);
  }, []);

  const { data: reserveObjects = [], error: reserveObjectsError } = useSWR<Region[]>(
    '/v1/region/fcr',
    { fetcher },
  );

  const { data: rangeQueryResult, mutate: refetchRangeQuery } = useCurrentBidsAndAllocations(
    BIDDING_DOMAIN,
    range,
  );

  const refetchBidsAndAllocations: () => void = useCallback(() => {
    refetchRangeQuery();
  }, [refetchRangeQuery]);

  const dayAheadPrices = rangeQueryResult?.[`region:${BIDDING_DOMAIN}`]?.entsoe_day_ahead_price;

  return (
    <Container maxWidth="xl">
      <Typography variant="h3">FCR-N</Typography>
      <Stack spacing={2} mt={2} mb={2} direction="column">
        {reserveObjectsError != null && (
          <Alert severity="error">
            Failed to load list of reserve objects: {asCactosError(reserveObjectsError).message}
          </Alert>
        )}

        <FCRGraph biddingDomain={BIDDING_DOMAIN} reserveObjects={reserveObjects} />

        <CurrentStatusCard reserveObjects={reserveObjects} />

        <MonthlyAllocationsCard />

        <Card>
          <CardHeader title="Upload bids" />
          <CardContent>
            <TabContext value={bidUploadTab}>
              <TabList onChange={(_, newTabValue) => setBidUploadTab(newTabValue)}>
                <Tab label="Upload form" value="form" />
                <Tab label="Upload CSV" value="csv" />
              </TabList>
              <TabPanel value="csv">
                <BidUpload biddingDomain={BIDDING_DOMAIN} updateBids={refetchBidsAndAllocations} />
              </TabPanel>
              <TabPanel value="form">
                <BidUploadForm
                  biddingDomain={BIDDING_DOMAIN}
                  updateBids={refetchBidsAndAllocations}
                  dayAheadPrices={dayAheadPrices ?? []}
                />
              </TabPanel>
            </TabContext>
          </CardContent>
        </Card>

        <BidsAndAllocations />
      </Stack>

      <StyledToaster />
    </Container>
  );
}

export const CurrentStatusCard = ({ reserveObjects }: { reserveObjects: Region[] }) => {
  const {
    data: allocationResult,
    isLoading: loadingAllocation,
    error: allocationError,
  } = useTimeseriesLatest(
    createLatestQuery({
      resources: [`region:${BIDDING_DOMAIN}`],
      columns: { fcr_allocation: ['quantity'] },
      atTime: 'now',
      start: { hours: 1 },
    }),
  );

  const currentAllocation = allocationResult?.[`region:${BIDDING_DOMAIN}`].fcr_allocation;

  const {
    data: activationsResult,
    isLoading: loadingActivations,
    error: activationsError,
  } = useTimeseriesLatest(
    createLatestQuery({
      resources: reserveObjects.map(({ id }) => `region:${id}` as ResourceID),
      columns: { fcr_reporting_region: ['maintained_capacity'] },
      atTime: 'now',
      start: { hours: 1 },
    }),
  );

  const allActivations = reserveObjects
    .map(({ id }) => activationsResult?.[`region:${id}`]?.fcr_reporting_region?.maintained_capacity)
    .filter((capacity): capacity is number => Number.isFinite(capacity));
  const totalActivationCapacity =
    allActivations.length > 0 ? allActivations.reduce((acc, num) => acc + num, 0) : null;

  const isLoading = loadingAllocation || loadingActivations;

  return (
    <Card>
      <CardHeader
        title={
          <>Latest operation status {isLoading && <CircularProgress size={16} sx={{ ml: 1 }} />}</>
        }
      />
      <CardContent>
        {allocationError != null && (
          <Alert severity="error" sx={{ mb: 2 }}>
            Error loading allocation data: {asCactosError(allocationError).message}
          </Alert>
        )}
        {activationsError != null && (
          <Alert severity="error" sx={{ mb: 2 }}>
            Error loading activation data: {asCactosError(activationsError).message}
          </Alert>
        )}
        <Scrollbar sx={{}}>
          <TableContainer sx={{ minWidth: 900 }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>Associated resource</TableCell>
                  <TableCell>
                    <div>Time</div>
                    <div>({currentTimeZone()})</div>
                  </TableCell>
                  <TableCell>Quantity</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Latest allocation</TableCell>
                  <TableCell>{BIDDING_DOMAIN}</TableCell>
                  <TableCell>
                    {currentAllocation != null
                      ? formatDateTimeNoTimeZone(currentAllocation.time)
                      : 'N/A'}
                  </TableCell>
                  <TableCell>
                    {currentAllocation != null ? `${currentAllocation.quantity} MW` : 'N/A'}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Total reported activation capacity</TableCell>
                  <TableCell>{BIDDING_DOMAIN}</TableCell>
                  <TableCell>-</TableCell>
                  <TableCell>
                    {totalActivationCapacity != null
                      ? `${totalActivationCapacity / 1_000_000} MW`
                      : 'N/A'}
                  </TableCell>
                </TableRow>

                {reserveObjects.map((reserveObject) => {
                  const row =
                    activationsResult?.[`region:${reserveObject.id}`]?.fcr_reporting_region;
                  return (
                    <TableRow key={reserveObject.id}>
                      <TableCell>Reported activation capacity</TableCell>
                      <TableCell>
                        {reserveObject.id} ({reserveObject.name})
                      </TableCell>
                      <TableCell>
                        {row != null ? formatDateTimeNoTimeZone(row.time) : 'N/A'}
                      </TableCell>
                      <TableCell>
                        {row?.maintained_capacity != null
                          ? `${row.maintained_capacity / 1_000_000} MW`
                          : 'N/A'}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
      </CardContent>
    </Card>
  );
};

export const MonthlyAllocationsCard = () => {
  const [currentTimeMs, setCurrentTimeMs] = useState(new Date().getTime());

  useEffect(() => {
    const interval = setInterval(() => setCurrentTimeMs(new Date().getTime()), 60_000);
    return () => clearInterval(interval);
  }, []);

  const [start, end] = useMemo(() => {
    const now = dayjs(currentTimeMs).tz('CET');
    const start = dayjs(now).startOf('month').subtract(3, 'months').utc().toDate();
    const end = dayjs(now).endOf('month').utc().toDate();
    return [start, end];
  }, [currentTimeMs]);

  const { data, isLoading, error } = useTimeseriesRange(
    createRangeQuery({
      resources: [`region:${BIDDING_DOMAIN}`],
      columns: { fcr_allocation: ['quantity', 'price'] },
      start,
      end,
    }),
  );

  const allocationsByMonth: { [month: string]: number } = useMemo(() => {
    const rows = data?.[`region:${BIDDING_DOMAIN}`]?.fcr_allocation;
    if (rows == null) return {};

    return rows.reduce((acc: { [month: string]: number }, row) => {
      const value = (parseFiniteFloat(row.quantity) ?? NaN) * (parseFiniteFloat(row.price) ?? NaN);
      if (!Number.isFinite(value) || value === 0) return acc;
      const month = dayjs(row.time).tz(ALLOCATION_TZ).format('MMMM YYYY');
      return { ...acc, [month]: (acc[month] ?? 0) + value };
    }, {});
  }, [data]);

  return (
    <Card>
      <CardHeader
        title={
          <>
            Monthly allocation values {isLoading && <CircularProgress size={16} sx={{ ml: 1 }} />}
          </>
        }
      />
      <CardContent>
        {error != null && (
          <Alert severity="error" sx={{ mb: 2 }}>
            Error loading allocation data: {asCactosError(error).message}
          </Alert>
        )}
        <TableContainer sx={{ maxWidth: 500 }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Month</TableCell>
                <TableCell align="right">Value</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(allocationsByMonth).map(([month, value]) => (
                <TableRow key={month}>
                  <TableCell>{month}</TableCell>
                  <TableCell align="right">{value.toFixed(2)}</TableCell>
                  <TableCell>EUR</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

const getTimeRange = (): DateTimeRange => {
  const start = dayjs().tz(ALLOCATION_TZ).startOf('day');
  let end = start.utc().add(48, 'hours').tz(ALLOCATION_TZ);
  const startUTCOffset = start.utcOffset();
  const endUTCOffset = end.utcOffset();
  if (startUTCOffset !== endUTCOffset) {
    // If the there's a DST change between start and end, we need to adjust the end time.
    // When DST starts, the day is 23 hours long, so we need to subtract an hour.
    // When DST ends, the day is 25 hours long, so we need to add an extra hour.
    end = end.add(startUTCOffset - endUTCOffset, 'minutes');
  }
  return { start: start.toDate(), end: end.toDate() };
};

const parseFiniteFloat = (value: string | null | undefined): number | null => {
  if (value == null) return null;
  const parsed = parseFloat(value);
  return Number.isFinite(parsed) ? parsed : null;
};
