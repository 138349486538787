import { type CSSProperties } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  alpha,
  useTheme,
} from '@mui/material';

import { useIsRoutePermitted } from '~hooks/useIsRoutePermitted';

import { type SidebarConfigItem } from '~layouts/AppLayout/sidebarConfig';

type SidebarItemProps = {
  item: SidebarConfigItem;
};

export function SidebarItem({ item }: SidebarItemProps) {
  const finalPath = `/v2${item.path}`;
  const { pathname } = useLocation();
  const theme = useTheme();
  const isRoutePermitted = useIsRoutePermitted(finalPath);

  if (!isRoutePermitted) return null;

  const isActive = pathname.startsWith(finalPath);
  const listItemStyle: CSSProperties = {
    cursor: 'pointer',
    color: theme.palette.text.secondary,
  };
  const listItemActiveStyle: CSSProperties = {
    color: theme.palette.primary.main,
    backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
    transition: 'background-color 300ms',
  };

  return (
    <Link to={finalPath} style={{ color: 'inherit', textDecoration: 'none' }}>
      <ListItem
        disablePadding
        dense
        sx={{
          ...listItemStyle,
          ...(isActive && listItemActiveStyle),
        }}
      >
        <ListItemButton>
          {!!item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
          <ListItemText primary={item.label} />
        </ListItemButton>
      </ListItem>
    </Link>
  );
}
