import { useMemo } from 'react';

import { type DateTimeRange } from '~utils/time';

import { createRangeQuery, useTimeseriesRange } from '~hooks/timeseries/queries';
import { type ResourceID, type Row } from '~hooks/timeseries/types';
import { useMeteringGroupList } from '~hooks/useMeteringGroupList';

export function useSitePeakshavingThreshold(meteringGroupId: string, range: DateTimeRange) {
  const { data: meteringGroups } = useMeteringGroupList();

  const meteringGroup = useMemo(
    () => meteringGroups.find((group) => group.id === meteringGroupId),
    [meteringGroups, meteringGroupId],
  );

  // TODO: There's currently no time series to get the peak shaving threshold by metering group
  // so we pick an arbitrary ESU and fetch its peak_shaving_threshold instead.
  const esuResourceId: ResourceID | null = meteringGroup?.esus[0]?.resource_id ?? null;

  const { data, isLoading, error } = useTimeseriesRange(
    createRangeQuery({
      resources: esuResourceId != null ? [esuResourceId] : [],
      columns: {
        schedule_history: ['peak_shaving_threshold'],
      },
      start: range.start,
      end: range.end,
      fillStart: true,
      fillEnd: true,
    }),
  );

  const scheduleHistory =
    esuResourceId != null ? data?.[esuResourceId].schedule_history ?? null : null;

  const extendedHistory = useMemo(() => {
    if (scheduleHistory == null || scheduleHistory.length === 0) {
      return scheduleHistory;
    }
    const now = new Date();
    const lastTime = new Date(scheduleHistory[scheduleHistory.length - 1].time);
    if (lastTime >= range.end || lastTime >= now) {
      return scheduleHistory;
    }
    // assume the last configuration is still valid, add a data point to make the line extend to the end
    const timeStamp = Math.min(range.end.getTime(), now.getTime());
    return (
      scheduleHistory as Pick<Row<'schedule_history'>, 'time' | 'peak_shaving_threshold'>[]
    ).concat([
      {
        time: new Date(timeStamp).toISOString(),
        peak_shaving_threshold: scheduleHistory[scheduleHistory.length - 1].peak_shaving_threshold,
      },
    ]);
  }, [scheduleHistory, range.end]);

  return {
    data: scheduleHistory,
    extendedData: extendedHistory,
    isLoading,
    error,
  };
}
