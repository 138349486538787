import { useCallback, useMemo, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { ErrorMessage, Form, Formik } from 'formik';
import * as Yup from 'yup';

import { asCactosError, http } from '~http';

import { useCurrentEdgeControllerId } from '~hooks/useCurrentEdgeControllerId';
import { useEdgeController } from '~hooks/useEdgeController';
import { useOrganizationList } from '~hooks/useOrganizationList';

import { filterValidControllerTransferOrganizations } from '~pages/fleet/controllers/utils';

import { ErrorContainer } from '~components/ErrorContainer';

type Props = {
  open: boolean;
  onClose: () => void;
};

type AutocompleteOption = {
  id: string;
  label: string;
};

type Organization = {
  name: string;
  human_name: string;
};

const TransferControllerSchema = Yup.object().shape({
  organizationId: Yup.string().required('Required'),
});

export function EdgeControllerTransferModal({ open, onClose }: Props) {
  const id = useCurrentEdgeControllerId();
  const { data: organizations } = useOrganizationList();
  const { data: edgeController, mutate } = useEdgeController(id);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const validOrganizations = useMemo(
    () => filterValidControllerTransferOrganizations(organizations),
    [organizations],
  );

  const handleClose = useCallback(() => {
    setError(null);
    onClose();
  }, [onClose]);

  const handleSubmit = useCallback(
    async (organizationId: string) => {
      if (isLoading) return;

      setIsLoading(true);
      setError(null);

      try {
        await http.put(`/v1/edge_controller/${id}/organization`, { id: organizationId });
        await mutate();

        handleClose();
      } catch (error: unknown) {
        setError(asCactosError(error).message);
      }

      setIsLoading(false);
    },
    [isLoading, id, mutate, handleClose],
  );

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Transfer controller to another organization</DialogTitle>
      <DialogContent>
        <Box pt={1}>
          <Formik
            initialValues={{ organizationId: '' }}
            onSubmit={async (values, { setSubmitting }) => {
              await handleSubmit(values.organizationId);
              setSubmitting(false);
            }}
            validationSchema={TransferControllerSchema}
          >
            {({ values, setFieldValue }) => (
              <>
                <Form id="transfer-controller-form">
                  <Autocomplete
                    fullWidth
                    autoHighlight
                    value={
                      values.organizationId
                        ? {
                            id: values.organizationId,
                            label:
                              validOrganizations.find((o) => o.id === values.organizationId)
                                ?.human_name ?? '',
                          }
                        : null
                    }
                    onChange={(_event, value: AutocompleteOption | null) => {
                      setFieldValue('organizationId', value?.id ?? '');
                    }}
                    options={
                      validOrganizations.map((organization) => ({
                        label: organization.human_name,
                        id: organization.id,
                      })) ?? []
                    }
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    renderInput={(params) => <TextField {...params} label="Organization" />}
                  />
                  <ErrorMessage name="organizationId" component={ErrorContainer} />
                </Form>
                {!!error && (
                  <Box marginTop={2} color="red">
                    <ErrorContainer>{error}</ErrorContainer>
                  </Box>
                )}
                <EdgeControllerTransferModalName controllerName={edgeController?.name} />
                <EdgeControllerTransferModalChangesPreview
                  currentOrganization={edgeController?.organization}
                  nextOrganization={organizations?.find((o) => o.id === values.organizationId)}
                />
              </>
            )}
          </Formik>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <LoadingButton type="submit" form="transfer-controller-form" loading={isLoading}>
          Transfer
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

function EdgeControllerTransferModalName({ controllerName }: { controllerName?: string }) {
  if (!controllerName) return null;

  return (
    <Box
      sx={{
        marginTop: 2,
        paddingTop: 1,
        paddingBottom: 1,
        paddingLeft: 2,
        paddingRight: 2,
        borderRadius: 1,
        background: 'rgba(0, 0, 0, 0.2)',
      }}
      display="flex"
      flexDirection="column"
      gap={0.5}
    >
      <Typography variant="caption" sx={{ color: 'text.secondary' }}>
        Controller name:
      </Typography>
      <Typography variant="body2">{controllerName}</Typography>
    </Box>
  );
}

function EdgeControllerTransferModalChangesPreview({
  currentOrganization,
  nextOrganization,
}: {
  currentOrganization?: Organization;
  nextOrganization?: Organization;
}) {
  if (!(currentOrganization && nextOrganization)) return null;
  if (currentOrganization.name === nextOrganization?.name) return null;

  return (
    <Box
      sx={{
        marginTop: 2,
        paddingTop: 1,
        paddingBottom: 1,
        paddingLeft: 2,
        paddingRight: 2,
        borderRadius: 1,
        background: 'rgba(0, 0, 0, 0.2)',
      }}
      display="flex"
      flexDirection="column"
      gap={0.5}
    >
      <Typography variant="caption" sx={{ color: 'text.secondary' }}>
        Preview of changes to the controller:
      </Typography>
      <Divider />
      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
        Organization:
      </Typography>
      <Typography variant="body2">
        {currentOrganization.human_name}
        <Box component="span" mx={1}>
          →
        </Box>
        {nextOrganization?.human_name ?? ''}
      </Typography>
    </Box>
  );
}
