import { Link } from 'react-router-dom';
import { Box } from '@mui/material';

import cactosSymbol from '~assets/cactos-symbol.svg';

type Props = {
  size?: number;
};

export function Logo({ size = 40 }: Props) {
  return (
    <Link to="/">
      <Box component="img" src={cactosSymbol} sx={{ width: size, height: size }} />
    </Link>
  );
}
