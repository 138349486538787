import { addMinutes, differenceInMinutes, subMinutes } from 'date-fns';

import { type DateTimeRange } from '~utils/time';
import { getAggregageQueryTimeStepSeconds } from '~utils/timeseries';

import {
  createAggregateQuery,
  createRangeQuery,
  useTimeseriesAggregate,
  useTimeseriesRange,
} from '~hooks/timeseries/queries';
import { type ResourceID } from '~hooks/timeseries/types';

export function useESUChartData(resourceId: ResourceID | null, range: DateTimeRange) {
  const shouldAggregateOverTime = differenceInMinutes(range.end, range.start) > 120;
  const aggregateTimeStepSeconds = getAggregageQueryTimeStepSeconds(range);
  const resources = resourceId != null ? [resourceId] : [];
  const {
    data: aggregateData,
    isLoading: loadingAggregateData,
    error: aggregateDataError,
  } = useTimeseriesAggregate(
    createAggregateQuery({
      resources: shouldAggregateOverTime ? resources : [],
      columns: {
        esu_status: ['soc:mean'],
        esu_power_control: [
          'esu_realized_power:mean',
          'esu_realized_power:min',
          'esu_realized_power:max',
        ],
      },
      start: range.start,
      end: range.end,
      step: `${aggregateTimeStepSeconds}s`,
    }),
  );

  const {
    data: rawData,
    isLoading: loadingRawData,
    error: rawDataError,
  } = useTimeseriesRange(
    createRangeQuery({
      resources: shouldAggregateOverTime ? [] : resources,
      columns: {
        esu_status: ['soc'],
        esu_power_control: ['esu_realized_power'],
      },
      start: range.start,
      end: range.end,
      fillStart: subMinutes(range.start, 1),
      fillEnd: addMinutes(range.end, 1),
    }),
  );

  if (shouldAggregateOverTime) {
    const data = resourceId != null ? aggregateData?.[resourceId] : null;
    return {
      data: {
        isAggregatedOverTime: true,
        esu_status: data?.esu_status ?? null,
        esu_power_control: data?.esu_power_control ?? null,
      },
      isLoading: loadingAggregateData,
      error: aggregateDataError,
    };
  } else {
    const data = resourceId != null ? rawData?.[resourceId] : null;
    return {
      data: {
        isAggregatedOverTime: false,
        esu_status: data?.esu_status ?? null,
        esu_power_control: data?.esu_power_control ?? null,
      },
      isLoading: loadingRawData,
      error: rawDataError,
    };
  }
}
