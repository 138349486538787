import { type ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Alert,
  Box,
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import Decimal from 'decimal.js';
import { toast } from 'react-hot-toast';
import {
  type CellChange,
  type CellStyle,
  type Column,
  ReactGrid,
  type Row,
  type TextCell,
} from '@silevis/reactgrid';

import './reactgrid-style-dark.css';
import { asCactosError, http } from '../../../http';
import { currentTimeZone, formatDateTimeNoTimeZone } from '../../../utils/time';
import { useTotalFcrnCapacity } from '../../../hooks/useTotalFcrnCapacity';

type BidUploadFormProps = {
  biddingDomain: string;
  updateBids: () => void;
  dayAheadPrices: { time: string; amount: string | null }[];
};

type BidRow = {
  start: string;
  quantity: string;
  price: string;
};

const MINIMUM_PRICE = 5;

const GRID_COLUMNS: (Column & { columnId: keyof BidRow | 'spot' })[] = [
  { columnId: 'start', width: 180 },
  { columnId: 'spot', width: 90 },
  { columnId: 'quantity', width: 90 },
  { columnId: 'price', width: 90 },
];

const HISTORY_CELL_STYLE: CellStyle = {
  color: 'rgba(255, 255, 255, 0.5)',
  border: {
    bottom: {
      width: '2px',
      style: 'dashed',
    },
  },
};

export function BidUploadForm(props: BidUploadFormProps) {
  const { biddingDomain, updateBids } = props;

  const [selectedQuantity, setSelectedQuantity] = useState<string | null>(null);
  const [price, setPrice] = useState('14');

  const {
    data: totalFcrnCapacity,
    error: totalFcrnCapacityError,
    isLoading,
  } = useTotalFcrnCapacity();

  const defaultQuantity = useMemo(
    () =>
      totalFcrnCapacity ? new Decimal(totalFcrnCapacity).toFixed(1, Decimal.ROUND_DOWN) : '0.0',
    [totalFcrnCapacity],
  );

  const quantityOptions = useMemo<ReactNode>(
    () =>
      getQuantityOptions(totalFcrnCapacity).map((value) => (
        <MenuItem key={value} value={value}>
          {value} MW
        </MenuItem>
      )),
    [totalFcrnCapacity],
  );

  const [bidRows, setBidRows] = useState<BidRow[]>(
    getHours().map((start) => ({ start, quantity: defaultQuantity, price })),
  );

  const uploadBids = useCallback(async () => {
    const formdata = new FormData();
    const name = 'file';
    formdata.append('name', name);
    formdata.append('file', constructCsvFile(name, bidRows));
    try {
      await http.post(`/v1/fcr/bid/${biddingDomain}`, formdata);
      toast.success('Bids uploaded!', { duration: 5_000 });
    } catch (ex) {
      toast.error(`Bid upload failed: ${asCactosError(ex).message}`, { duration: 5_000 });
    } finally {
      updateBids();
    }
  }, [biddingDomain, bidRows, updateBids]);

  useEffect(() => {
    setBidRows((prevRows) =>
      prevRows.map((row) => ({ ...row, quantity: selectedQuantity ?? defaultQuantity })),
    );
  }, [selectedQuantity, defaultQuantity]);

  useEffect(() => {
    setBidRows((prevRows) => prevRows.map((row) => ({ ...row, price })));
  }, [price]);

  const [rowsValid, validationErrors] = validateBidRows(bidRows);

  const previousHour = dayjs(bidRows[0].start).subtract(1, 'hour').toISOString();
  const headerRows: Row[] = [
    {
      rowId: 'header1',
      cells: [
        { type: 'header', text: 'Start time' },
        { type: 'header', text: 'Spot' },
        { type: 'header', text: 'Quantity' },
        { type: 'header', text: 'Price' },
      ],
    },
    {
      rowId: 'header2',
      cells: [
        { type: 'header', text: currentTimeZone() },
        { type: 'header', text: 'EUR/MWh' },
        { type: 'header', text: 'MW' },
        { type: 'header', text: 'EUR/MW' },
      ],
    },
    {
      rowId: 'previous',
      cells: [
        { type: 'header', text: formatDateTimeNoTimeZone(previousHour), style: HISTORY_CELL_STYLE },
        {
          type: 'text',
          nonEditable: true,
          text: getSpotPrice(props.dayAheadPrices, previousHour),
          style: HISTORY_CELL_STYLE,
        },
        { type: 'header', text: '', style: HISTORY_CELL_STYLE },
        { type: 'header', text: '', style: HISTORY_CELL_STYLE },
      ],
    },
  ];

  const gridRows: Row[] = bidRows.map((row, idx) => ({
    rowId: idx,
    cells: [
      { type: 'header', text: formatDateTimeNoTimeZone(row.start) },
      { type: 'text', nonEditable: true, text: getSpotPrice(props.dayAheadPrices, row.start) },
      { type: 'text', text: row.quantity },
      { type: 'text', text: row.price },
    ],
  }));

  return (
    <Box>
      <Typography variant="h6" gutterBottom sx={{ mb: 2 }}>
        Bids to upload
      </Typography>

      {totalFcrnCapacityError && (
        <Alert severity="error">
          Error loading FCRN capacity: {totalFcrnCapacityError.message}
        </Alert>
      )}

      <Box display="flex" gap={1} rowGap={2} my={2} flexWrap="wrap">
        <FormControl>
          <InputLabel id="quantity-select-label">Quantity</InputLabel>
          <Select
            labelId="quantity-select-label"
            value={selectedQuantity ?? defaultQuantity}
            label="Quantity"
            onChange={(e) => setSelectedQuantity(e.target.value)}
            disabled={isLoading || totalFcrnCapacityError != null}
            sx={{ width: 160 }}
          >
            {quantityOptions}
          </Select>
        </FormControl>
        <TextField
          label="Price"
          value={price}
          inputProps={{
            inputMode: 'numeric',
            pattern: '^[0-9]+(\\.[0-9]+)?$',
          }}
          onChange={(e) => setPrice(e.target.value)}
          InputProps={{
            endAdornment: <InputAdornment position="end">EUR/MW</InputAdornment>,
          }}
          sx={{ width: 160 }}
        />
      </Box>

      <Box mb={2}>
        <ReactGrid
          columns={GRID_COLUMNS}
          rows={[...headerRows, ...gridRows]}
          onCellsChanged={(changes) =>
            setBidRows((prevRows) =>
              newBidsWithChanges(prevRows, changes as CellChange<TextCell>[]),
            )
          }
          enableRangeSelection
          enableColumnSelection
          enableRowSelection
          enableFillHandle
        />
      </Box>

      {validationErrors.length > 0 && (
        <Box>
          {validationErrors.map((error) => (
            <Alert key={error} severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          ))}
        </Box>
      )}

      <Box display="flex" gap={2}>
        <Button component="label" variant="contained" disabled={!rowsValid} onClick={uploadBids}>
          Upload Bids
        </Button>
        <Button
          component="label"
          variant="text"
          disabled={!rowsValid}
          onClick={() => downloadCsv(bidRows)}
        >
          Download CSV
        </Button>
      </Box>
    </Box>
  );
}

function getSpotPrice(dayAheadPrices: { time: string; amount: string | null }[], start: string) {
  const spotRow = dayAheadPrices.find((p) => dayjs(p.time).isSame(start));
  return spotRow?.amount ?? '';
}

function getHours(): string[] {
  // Bids are done per one full CET calendar day
  const start = dayjs.utc().add(24, 'hours').tz('CET').startOf('day');
  let end = start.utc().add(24, 'hours').tz('CET');
  const startUtcOffset = start.utcOffset();
  const endUtcOffset = end.utcOffset();
  if (startUtcOffset !== endUtcOffset) {
    // If the there's a DST change between start and end, we need to adjust the end time.
    // When DST starts, the day is 23 hours long, so we need to subtract an hour.
    // When DST ends, the day is 25 hours long, so we need to add an extra hour.
    end = end.add(startUtcOffset - endUtcOffset, 'minutes');
  }
  const hours: string[] = [];
  let hour = start.utc();
  while (hour.isBefore(end)) {
    hours.push(hour.toISOString());
    hour = hour.add(1, 'hour');
  }
  return hours;
}

export function getQuantityOptions(totalFcrnCapacity: string | undefined): string[] {
  const options = ['0.0'];
  const max = new Decimal(totalFcrnCapacity ?? '0.0').toDecimalPlaces(1, Decimal.ROUND_HALF_UP);
  let value = Decimal.max('0.1', max.sub('0.5'));
  while (value.lte(max)) {
    options.push(value.toFixed(1));
    value = value.add('0.1');
  }
  return options;
}

function newBidsWithChanges(prevBids: BidRow[], changes: CellChange<TextCell>[]): BidRow[] {
  const newBids = [...prevBids];
  changes.forEach((change) => {
    const index = change.rowId;
    const columnName = change.columnId;
    if (
      typeof index === 'number' &&
      0 <= index &&
      index < newBids.length &&
      (columnName === 'quantity' || columnName === 'price')
    ) {
      let value = change.newCell.text.trim();
      if (/^\d+,\d+$/.test(value)) {
        value = value.replace(',', '.');
      }
      newBids[index][columnName] = value;
    }
  });
  return newBids;
}

const DECIMAL_REGEX = /^-?[0-9]+(\.[0-9]+)?$/;
const isValidDecimal = (value: string): boolean => DECIMAL_REGEX.test(value);

function validateBidRows(rows: BidRow[]): [rowsValid: boolean, validationErrors: string[]] {
  const errors: string[] = [];
  rows.forEach((row) => {
    if (!Number.isFinite(parseFloat(row.quantity)) || !isValidDecimal(row.quantity)) {
      errors.push(
        `Invalid quantity "${row.quantity}". ` +
          (isValidDecimal(row.quantity.replace(',', '.'))
            ? 'A period (".") must be used as the decimal separator.'
            : 'The quantity must be a number.'),
      );
    }
    const price = parseFloat(row.price);
    if (!Number.isFinite(price) || !isValidDecimal(row.price)) {
      errors.push(
        `Invalid price "${row.price}". ` +
          (isValidDecimal(row.price.replace(',', '.'))
            ? 'A period (".") must be used as the decimal separator.'
            : 'The price must be a number.'),
      );
    } else if (price < MINIMUM_PRICE) {
      errors.push(`Price of ${price} is too low. The minimum is ${MINIMUM_PRICE}.`);
    }
  });
  return [errors.length === 0, Array.from(new Set(errors))];
}

function constructCsvString(rows: BidRow[]): string {
  const csvRows: string[] = [];
  csvRows.push('start,quantity,price');
  rows.forEach((row: BidRow) => {
    csvRows.push([row.start, row.quantity, row.price].join(','));
  });
  return csvRows.join('\n');
}

function constructCsvFile(name: string, rows: BidRow[]): File {
  return new File([new Blob([constructCsvString(rows)])], name);
}

function downloadCsv(bidRows: BidRow[]) {
  const link = document.createElement('a');
  link.id = 'download-link';
  // Element 10 should be for the representative date
  link.download = `FCR-N-bids-${dayjs(bidRows[10].start).format('YYYY-MM-DD')}.csv`;
  link.href = `data:text/plain;charset=utf-8,${encodeURIComponent(constructCsvString(bidRows))}`;
  link.click();
  link.remove();
}
