import { useCallback, useMemo, useState } from 'react';
import { DateTimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { Box, FormLabel, ToggleButton, ToggleButtonGroup } from '@mui/material';
import dayjs from 'dayjs';

import type { WizardStepProps } from '~pages/settings/groups/pricing/types';
import { TIME_ZONE } from '~pages/settings/groups/pricing/constants';

const OPTIONS = {
  today: 'Today',
  yesterday: 'Yesterday',
  startOfWeek: 'Start of week',
  startOfMonth: 'Start of this month',
  startOfLastMonth: 'Start of last month',
};

type Option = keyof typeof OPTIONS;

export function StepStartTime({ formik }: WizardStepProps) {
  const [startTime, setStartTime] = useState<Date | null>(formik.values.startTime);

  const handleOptionChange = useCallback(
    (option: Option) => {
      switch (option) {
        case 'today': {
          const startTime = dayjs.utc().tz(TIME_ZONE).startOf('day').toDate();

          formik.setFieldValue('startTime', startTime);
          setStartTime(startTime);
          break;
        }
        case 'yesterday': {
          const startTime = dayjs.utc().tz(TIME_ZONE).subtract(1, 'day').startOf('day').toDate();

          formik.setFieldValue('startTime', startTime);
          setStartTime(startTime);
          break;
        }
        case 'startOfWeek': {
          const startTime = dayjs.utc().tz(TIME_ZONE).startOf('isoWeek').toDate();

          formik.setFieldValue('startTime', startTime);
          setStartTime(startTime);
          break;
        }
        case 'startOfMonth': {
          const startTime = dayjs.utc().tz(TIME_ZONE).startOf('month').toDate();

          formik.setFieldValue('startTime', startTime);
          setStartTime(startTime);
          break;
        }
        case 'startOfLastMonth': {
          const startTime = dayjs
            .utc()
            .tz(TIME_ZONE)
            .subtract(1, 'month')
            .startOf('month')
            .toDate();

          formik.setFieldValue('startTime', startTime);
          setStartTime(startTime);
          break;
        }
        default: {
          const startTime = dayjs.utc().tz(TIME_ZONE).add(1, 'day').startOf('day').toDate();

          formik.setFieldValue('startTime', startTime);
          setStartTime(startTime);
          break;
        }
      }
    },
    [formik],
  );

  const selectedOptions = useMemo<Option[]>(() => {
    const now = dayjs.utc().tz(TIME_ZONE).startOf('day');
    const dStartTime = dayjs(formik.values.startTime).tz(TIME_ZONE);
    const values: Option[] = [];

    if (dStartTime.isSame(now)) {
      values.push('today');
    }
    if (dStartTime.isSame(now.subtract(1, 'day'))) {
      values.push('yesterday');
    }
    if (dStartTime.isSame(now.startOf('isoWeek'))) {
      values.push('startOfWeek');
    }
    if (dStartTime.isSame(now.startOf('month'))) {
      values.push('startOfMonth');
    }
    if (dStartTime.isSame(now.subtract(1, 'month').startOf('month'))) {
      values.push('startOfLastMonth');
    }

    return values;
  }, [formik.values.startTime]);

  const handleChange = useCallback(
    (date: Date | null) => {
      setStartTime(date);

      if (dayjs(date).isValid()) {
        formik.setFieldValue('startTime', date);
      }
    },
    [formik],
  );

  return (
    <>
      <FormLabel component="legend">Start time</FormLabel>

      <Box mt={2}>
        <ToggleButtonGroup value={selectedOptions} color="primary">
          {Object.entries(OPTIONS).map(([value, label]) => (
            <ToggleButton
              key={value}
              value={value}
              onClick={() => handleOptionChange(value as Option)}
              sx={{ textTransform: 'none' }}
            >
              {label}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </Box>

      <Box mt={2}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateTimePicker
            value={startTime}
            onChange={handleChange}
            ampm={false}
            format="yyyy-MM-dd HH:mm"
            timezone={TIME_ZONE}
          />
        </LocalizationProvider>
      </Box>
    </>
  );
}
