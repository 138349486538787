import { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';

import { APPBAR_DESKTOP, APPBAR_MOBILE, DashboardNavbar } from '~layouts/dashboard/DashboardNavbar';
import { DashboardSidebar } from '~layouts/dashboard/DashboardSidebar';

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    paddingTop: APPBAR_DESKTOP,
  },
}));

export function DashboardLayout() {
  const [open, setOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setOpen(false); // Close the drawer when location changes
  }, [location]);

  return (
    <RootStyle>
      <DashboardNavbar onToggleSidebar={() => setOpen((open) => !open)} />
      <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
      <MainStyle>
        <Outlet />
      </MainStyle>
    </RootStyle>
  );
}
