import { Button } from '@mui/material';
import { useState } from 'react';

import { USER_ROLES } from '~constants/auth';

import { useRegisterBreadcrumbs } from '~hooks/useBreadcrumbs';
import { useUserHasRole } from '~hooks/useUserHasRole';

import { PageLayout } from '~layouts/PageLayout';

import { GroupCreateModal } from '~pages/settings/groups/GroupCreateModal';
import { SitesList } from '~pages/v2/sites/components/SitesList';
import { createSitePath } from '~pages/v2/sites/utils/createSitePath';

export function Sites() {
  useRegisterBreadcrumbs('sites', 'Sites', createSitePath());

  const [isCreateMeteringGroupModalOpen, setIsCreateMeteringGroupModalOpen] = useState(false);

  const canCreateNewGroup = useUserHasRole(USER_ROLES.EDIT_EMS);

  return (
    <>
      <PageLayout
        title="Sites"
        actions={
          canCreateNewGroup && (
            <Button onClick={() => setIsCreateMeteringGroupModalOpen(true)}>Create Site</Button>
          )
        }
      >
        <SitesList />
      </PageLayout>
      <GroupCreateModal
        open={isCreateMeteringGroupModalOpen}
        onClose={() => setIsCreateMeteringGroupModalOpen(false)}
      />
    </>
  );
}
