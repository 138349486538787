import { Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormikContext } from 'formik';

export function SubmitButton() {
  const { isSubmitting } = useFormikContext();
  return (
    <LoadingButton
      size="large"
      type="submit"
      variant="contained"
      loading={isSubmitting}
      sx={{ marginRight: '1rem', marginBottom: '1rem', flex: 1 }}
    >
      Save configuration
    </LoadingButton>
  );
}

export function ResetButton() {
  const { resetForm } = useFormikContext();
  return (
    <Button
      size="large"
      variant="outlined"
      onClick={() => resetForm()}
      sx={{ marginRight: '1rem', marginBottom: '1rem', flex: 1 }}
    >
      Reset to initial values
    </Button>
  );
}
