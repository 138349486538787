import * as _ from 'lodash-es';

import { KINDS } from './constants';
import { parseTimedValue } from './TimedValue';

class ValidationError {
  constructor(message) {
    this.message = message;
  }
}

export function configurationToFormValues(details, emsOptimizerConfiguration) {
  const formValues = {};
  Object.entries(details).forEach(([key, detail]) => {
    const alternatives = [emsOptimizerConfiguration[key], detail.default, ''];
    formValues[key] = _.first(_.dropWhile(alternatives, (v) => v === null || v === undefined));
    if (detail.kind === KINDS.BOOLEAN) {
      // We get booleans as the defaults and numbers from timeseries, convert both to boolean
      formValues[key] = _.every([formValues[key]]);
    }
  });
  return formValues;
}

export function formValuesToConfiguration(details, values) {
  const configuration = {};
  Object.entries(details).forEach(([key, detail]) => {
    const formValue = values[key];
    const kind = detail.kind || KINDS.DECIMAL;
    if (kind === KINDS.DECIMAL) {
      configuration[key] = formValue.toString();
    } else if (kind === KINDS.INTEGER) {
      configuration[key] = _.toNumber(formValue);
    } else if (kind === KINDS.BOOLEAN) {
      configuration[key] = formValue ? 1 : 0;
    } else {
      configuration[key] = formValue;
    }
  });
  return configuration;
}

export function validateConfiguration(fieldDetails, values) {
  const errors = {};
  Object.entries(fieldDetails).forEach(([key, detail]) => {
    const value = values[key];
    try {
      validateValue(value, detail);

      const crossValidation = detail.cross_validation;
      if (crossValidation !== undefined) {
        crossValidate(crossValidation, key, values, fieldDetails);
      }
    } catch (error) {
      if (error instanceof ValidationError) {
        errors[key] = error.message;
      } else {
        console.error('Unexpected validation error', error);
        errors[key] = `Validator failed. Please report the issue to Cactos.`;
      }
    }
  });
  if (!_.isEmpty(errors)) {
    console.error(errors);
  }
  return errors;
}

function checkRange(range, minValue, maxValue) {
  if (minValue < range[0]) {
    throw new ValidationError(`Under range [${range[0]} - ${range[1]}]`);
  }

  if (maxValue > range[1]) {
    throw new ValidationError(`Over range [${range[0]} - ${range[1]}]`);
  }
}

function parseMinMaxValue(value, kind) {
  if (kind === KINDS.DECIMAL) {
    if (value === '') {
      throw new ValidationError(`Must enter a number.`);
    }
    const numericValue = _.toNumber(value);
    if (!Number.isFinite(numericValue)) {
      throw new ValidationError(`Must enter a number.`);
    }
    return [numericValue, numericValue];
  }
  if (kind === KINDS.TIMED_NUMBER) {
    try {
      const timedValues = parseTimedValue(value);
      return [_.min(timedValues.map(_.min)), _.max(timedValues.map(_.max))];
    } catch (error) {
      throw new ValidationError(error.toString());
    }
  }
  if (kind === KINDS.INTEGER) {
    const numericValue = _.toNumber(value);
    if (!_.isInteger(numericValue)) {
      throw new ValidationError(`Must be an integer, got '${value}'`);
    }
    return [numericValue, numericValue];
  }
  throw Error(`Unknown kind ${kind}`);
}

function validateValue(value, detail) {
  const kind = detail.kind || KINDS.DECIMAL;
  if (kind === KINDS.BOOLEAN) {
    if (value !== true && value !== false) {
      throw new ValidationError(`Value must be true or false, was ${value}`);
    }
    return undefined;
  }
  const [minValue, maxValue] = parseMinMaxValue(value, kind);

  const { range } = detail;
  if (range !== undefined) {
    checkRange(range, minValue, maxValue);
  }

  return undefined;
}

function crossValidate(crossValidation, key, values, fieldDetails) {
  Object.entries(crossValidation).forEach(([operation, crossCheckKeys]) => {
    crossCheckKeys.forEach((crossCheckKey) => {
      const left = _.toNumber(values[key]);
      const right = _.toNumber(values[crossCheckKey]);
      if (operation === 'lte') {
        if (!(left <= right)) {
          throw new ValidationError(
            `Value must be less than or equal to ${fieldDetails[crossCheckKey].label}`,
          );
        }
      } else if (operation === 'gte') {
        if (!(left >= right)) {
          throw new ValidationError(
            `Value must be greater than or equal to ${fieldDetails[crossCheckKey].label}`,
          );
        }
      } else if (operation === 'eq') {
        if (left !== right) {
          throw new ValidationError(`Value must be equal to ${fieldDetails[crossCheckKey].label}`);
        }
      } else {
        console.error(`Unknown cross-validation operation ${operation}`);
      }
    });
  });
}
