import { useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Box } from '@mui/material';

import { http } from '~http';

import { useAbortController } from '~hooks/useAbortController';
import { useMeteringGroupContext } from '~hooks/useMeteringGroupContext';

import type { Pricing as PricingType } from '~pages/settings/groups/pricing/types';
import { PricingTable } from '~pages/settings/groups/pricing/PricingTable';

import { Page } from '~components/Page';

export function Pricing() {
  const { id: meteringGroupId } = useMeteringGroupContext();
  const [pricings, setPricings] = useState<PricingType[]>([]);

  const getSignal = useAbortController();

  const refetchPricings = useCallback(async () => {
    const now = dayjs.utc();
    const query = {
      resource: `metering_group:${meteringGroupId}`,
      start: now.subtract(5, 'years').toISOString().replace('Z', ''),
      end: now.add(5, 'years').toISOString().replace('Z', ''),
    };
    const response = await http.get('/v1/pricing', {
      params: query,
      signal: getSignal(),
    });
    const pricings: PricingType[] = response.data ?? [];
    const historicalData = pricings.filter((p) => dayjs.utc(p.start_time) < now);
    const currentPricing = historicalData.slice(-1)[0];

    if (currentPricing) currentPricing.current = true;

    setPricings(pricings);
  }, [meteringGroupId, getSignal]);

  useEffect(() => {
    if (!meteringGroupId) return;

    refetchPricings();
  }, [meteringGroupId, refetchPricings]);

  return (
    <Page title="Electricity Pricing">
      <Box paddingX={3} paddingY={2}>
        <PricingTable pricings={pricings} refetchPricings={refetchPricings} />
      </Box>
    </Page>
  );
}
