import { useCallback, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';

import type { User } from '~types';

import { http } from '~http';

import { useAuth } from '~hooks/useAuth';
import { useLocalStorage } from '~hooks/useLocalStorage';

import { SIDEBAR_WIDTH } from '~layouts/AppLayout/Sidebar';

import { Iconify } from '~components/Iconify';

export function AccountMenu() {
  const { logout } = useAuth();
  const [userData] = useLocalStorage<User | null>('user', null);

  const [menuAnchorElement, setMenuAnchorElement] = useState<null | HTMLElement>(null);

  const handleLogout = useCallback(async () => {
    await http.get('/v1/logout', { responseType: 'text' });
    logout();
  }, [logout]);

  return (
    <>
      <List disablePadding>
        <ListItem
          disablePadding
          onClick={(event) => setMenuAnchorElement(event.currentTarget)}
          sx={{ color: 'text.secondary' }}
        >
          <ListItemButton>
            <ListItemIcon>
              <Iconify fontSize={24} icon="mdi:user" />
            </ListItemIcon>
            <ListItemText
              primary={userData?.email ?? ''}
              primaryTypographyProps={{ noWrap: true, variant: 'body2' }}
            />
          </ListItemButton>
        </ListItem>
      </List>
      <Menu
        open={Boolean(menuAnchorElement)}
        anchorEl={menuAnchorElement}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: SIDEBAR_WIDTH + 16,
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onClose={() => setMenuAnchorElement(null)}
        onClick={() => setMenuAnchorElement(null)}
        sx={{ maxWidth: 220 }}
      >
        <MenuItem to="/v2/user" component={RouterLink} sx={{ gap: 1.5, fontSize: 14 }}>
          <Iconify fontSize={16} icon="mdi:cog" />
          Settings
        </MenuItem>
        <MenuItem onClick={handleLogout} sx={{ gap: 1.5, fontSize: 14 }}>
          <Iconify fontSize={16} icon="mdi:logout" />
          Logout
        </MenuItem>
      </Menu>
    </>
  );
}
