import { Card, Typography, styled } from '@mui/material';
import { type ReactNode } from 'react';

interface CardProps {
  size?: 'small' | 'medium';
}

const Root = styled(Card)<CardProps>(({ size, theme }) => ({
  height: '100%',
  minHeight: size === 'small' ? '132px' : '240px',
  boxShadow: 'none',
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '1rem',
  flexWrap: 'wrap',
}));

export function DashboardCard(props: { children?: ReactNode; size?: 'small' | 'medium' }) {
  const { size, children } = props;
  return <Root size={size}>{children ?? null}</Root>;
}

export function DashboardCardHeader(props: { children: ReactNode }) {
  return (
    <Typography variant="h3" color="primary">
      {props.children}
    </Typography>
  );
}
