import { Box, LinearProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigation } from 'react-router-dom';

import { LOADING_BAR_Z_INDEX } from '~theme/index';

import { usePrevious } from '~hooks/usePrevious';

// A loading bar displayed when a route transition takes time to load
// Typically because of a loader in the route configuration
export function LoadingBar() {
  const { state } = useNavigation();
  const previousState = usePrevious(state);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (previousState !== 'loading' && state === 'loading') {
      setLoading(true);

      return;
    }

    if (state !== 'loading') {
      setLoading(false);
    }
  }, [previousState, state]);

  return (
    <Box
      position="fixed"
      height={loading ? 4 : 0}
      top={0}
      left={0}
      right={0}
      zIndex={LOADING_BAR_Z_INDEX}
      overflow="hidden"
      sx={{ transition: 'height 300ms ease' }}
    >
      <LinearProgress color="primary" />
    </Box>
  );
}
