import { useCallback, useEffect } from 'react';
import { useMatch, useNavigate } from 'react-router-dom';

import { useLocalStorage } from '~hooks/useLocalStorage';

import type { Tab } from '~pages/v2/fleets/types';
import { ROUTER_MATCH_PATH } from '~pages/v2/fleets/constants';
import { createFleetPath } from '~pages/v2/fleets/utils/createFleetPath';

const DEFAULT_TAB: Tab = 'dashboard';

export function useFleetsNavigation() {
  const navigate = useNavigate();
  const params = useMatch(ROUTER_MATCH_PATH)?.params;

  const [tabFromStorage, setTabFromStorage] = useLocalStorage<Tab>('fleets.tab', DEFAULT_TAB);
  const tab = (params?.tab as Tab) || tabFromStorage;

  const navigateToFleet = useCallback(
    (tab: Tab = '') => {
      navigate(createFleetPath(tab));
    },
    [navigate],
  );

  useEffect(() => {
    setTabFromStorage(tab);
  }, [tab, setTabFromStorage]);

  return {
    tab,
    navigateToFleet,
  };
}
