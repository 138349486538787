import type React from 'react';
import { useCallback, useMemo, useState } from 'react';
import { Alert, Box, CircularProgress, useTheme } from '@mui/material';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { curveLinear, curveStepAfter } from '@visx/curve';
import { Grid } from '@visx/grid';
import { Group } from '@visx/group';
import { ParentSize } from '@visx/responsive';
import { scaleLinear, scaleTime } from '@visx/scale';
import { AreaClosed, Line, LinePath } from '@visx/shape';
import { TooltipWithBounds, useTooltip } from '@visx/tooltip';
import { localPoint } from '@visx/event';
import { bisector, extent } from 'd3-array';
import { addSeconds } from 'date-fns';

import { asCactosError } from '~http';

import { formatRange } from '~utils/time';

import {
  ChartLegend,
  TOOLTIP_STYLES,
  formatAxisDate,
  formatTooltipDate,
  getContinuousAxisNumTicks,
  useCommonChartStyles,
} from './utils';

type DataPoint = {
  startTime: Date;
  durationSeconds: number;
  endTime: Date;
  isFirstRowOfPartialBalanceInterval: boolean;
  isSecondRowOfPartialBalanceInterval: boolean;
  // price graph
  importPrice: number;
  exportPrice: number;
  // energy graph
  estimatedStoredEnergyStart: number;
  socBracketMinStart: number;
  socBracketMaxStart: number;
  estimatedStoredEnergyEnd: number;
  socBracketMinEnd: number;
  socBracketMaxEnd: number;
  energyCapacity: number; // used as y axis max
  // power graph
  facilityPower: number;
  fcrnPower: number;
  esuPower: number;
  esuPowerBracketImport: number;
  esuPowerBracketExport: number;
  // only for the first row of a partial balance interval:
  meanGridPower: number;
  // only for the second row of a partial balance interval:
  netShavingTargetEnergy: number;
  netShavingTargetPower: number;
};

type ChartElementID =
  // price graph
  | 'import-price'
  | 'export-price'
  // energy graph
  | 'estimated-stored-energy'
  | 'soc-bracket'
  // power graph
  | 'facility-power'
  | 'fcrn-power'
  | 'esu-power'
  | 'esu-power-bracket'
  | 'mean-grid-power'
  | 'net-shaving-target-power';

const CHART_ELEMENT_GROUP_CHART_DISPLAY_NAMES: Record<ChartElementID, string> = {
  'import-price': 'Buy price',
  'export-price': 'Sell price',
  'estimated-stored-energy': 'Estimated total stored energy',
  'soc-bracket': 'SoC bracket',
  'facility-power': 'Facility power',
  'fcrn-power': 'Total FCR-N power',
  'esu-power': 'Total ESU power',
  'esu-power-bracket': 'Total ESU power bracket',
  'mean-grid-power': 'Mean grid power',
  'net-shaving-target-power': 'Net shaving target power',
};

const CHART_ELEMENT_ESU_CHART_DISPLAY_NAMES: Record<ChartElementID, string> = {
  ...CHART_ELEMENT_GROUP_CHART_DISPLAY_NAMES,
  'estimated-stored-energy': 'Estimated SoC',
  'fcrn-power': 'FCR-N power',
  'esu-power': 'ESU power',
  'esu-power-bracket': 'ESU power bracket',
};

const CHART_MARGIN = { top: 20, left: 45, right: 20, bottom: 30 };

const PRICE_GRAPH_HEIGHT = 140;
const ENERGY_GRAPH_HEIGHT = 200;
const POWER_GRAPH_HEIGHT = 250;
const GRAPH_GAP = 25;

const TOTAL_GRAPH_HEIGHT =
  CHART_MARGIN.top +
  PRICE_GRAPH_HEIGHT +
  GRAPH_GAP +
  ENERGY_GRAPH_HEIGHT +
  GRAPH_GAP +
  POWER_GRAPH_HEIGHT +
  CHART_MARGIN.bottom;

const LEGEND_BOX_MARGIN_TOP = 8;
const LEGEND_BOX_MARGIN_RIGHT = 10;

const OPACITY = {
  line: 1,
  area: 0.4,
};

const OPACITY_UNHIGHLIGHTED = {
  line: 0.25,
  area: 0.1,
};

export type OptimizerResultGraphVariant = 'group' | 'esu';

export type OptimizerResultGraphProps = {
  variant: OptimizerResultGraphVariant;
  groupData:
    | {
        time: string;
        duration: number | null;
        import_price: number | null;
        export_price: number | null;
        start_energy_total: number | null;
        end_energy_total: number | null;
        end_energy_low_limit: number | null;
        end_energy_high_limit: number | null;
        energy_capacity_total: number | null;
        fcrn_allocated_cap_total: number | null;
        esu_total_net_energy: number | null;
        constant_power_net: number | null;
        esu_import_energy_max: number | null;
        esu_export_energy_max: number | null;
        facility_total_net_energy: number | null;
        grid_total_net_energy: number | null;
      }[]
    | null;
  esuData?:
    | {
        time: string;
        start_energy: number | null;
        end_energy: number | null;
        end_energy_low_limit: number | null;
        end_energy_high_limit: number | null;
        energy_capacity: number | null;
        fcrn_allocated_cap: number | null;
        constant_power: number | null;
        esu_import_energy_max: number | null;
        esu_export_energy_max: number | null;
      }[]
    | null;
  isLoading?: boolean;
  error?: any;
};

export const OptimizerResultGraph = ({
  groupData,
  esuData,
  variant,
  isLoading = false,
  error = null,
}: OptimizerResultGraphProps) => {
  const priceGraphLegendItems = useLegendItems(['import-price', 'export-price'], variant);
  const energyGraphLegendItems = useLegendItems(
    ['estimated-stored-energy', 'soc-bracket'],
    variant,
  );
  const powerGraphLegendItems = useLegendItems(
    variant === 'group'
      ? [
          'facility-power',
          'fcrn-power',
          'esu-power',
          'esu-power-bracket',
          'mean-grid-power',
          'net-shaving-target-power',
        ]
      : ['facility-power', 'fcrn-power', 'esu-power', 'esu-power-bracket'],
    variant,
  );

  const [selectedChartElement, setSelectedChartElement] = useState<ChartElementID | null>(null);

  const dataPoints: DataPoint[] = useMemo(() => {
    if (groupData == null) return [];

    const dataPoints: DataPoint[] = groupData.map((row, index) => {
      const time = new Date(row.time);
      const duration = row.duration ?? NaN;
      const endTime = addSeconds(time, Number.isFinite(duration) ? duration : 0);

      const isFirstRowOfPartialBalanceInterval =
        index === 0 && !Number.isFinite(row.start_energy_total);

      // group data may or may not have one more row at the start,
      // but the index relative to the end should stay the same
      const esuRow = esuData?.at(-(groupData.length - index));
      if (
        variant === 'esu' &&
        esuRow != null &&
        time.getTime() !== new Date(esuRow.time).getTime()
      ) {
        throw new Error('Mismatched ESU data');
      }

      const estimatedStoredEnergyStart =
        variant === 'group' ? row.start_energy_total : esuRow?.start_energy;
      const estimatedStoredEnergyEnd =
        variant === 'group' ? row.end_energy_total : esuRow?.end_energy;
      const socBracketMinEnd =
        variant === 'group' ? row.end_energy_low_limit : esuRow?.end_energy_low_limit;
      const socBracketMaxEnd =
        variant === 'group' ? row.end_energy_high_limit : esuRow?.end_energy_high_limit;
      const energyCapacity =
        variant === 'group' ? row.energy_capacity_total : esuRow?.energy_capacity;

      const fcrnPower =
        variant === 'group' ? row.fcrn_allocated_cap_total : esuRow?.fcrn_allocated_cap;
      const esuPower =
        isFirstRowOfPartialBalanceInterval && variant === 'group'
          ? row.esu_total_net_energy
          : variant === 'group'
          ? row.constant_power_net
          : esuRow?.constant_power;
      const esuPowerBracketImport =
        variant === 'group' ? row.esu_import_energy_max : esuRow?.esu_import_energy_max;
      const esuPowerBracketExport =
        variant === 'group' ? row.esu_export_energy_max : esuRow?.esu_export_energy_max;

      return {
        startTime: time,
        durationSeconds: duration,
        endTime,
        isFirstRowOfPartialBalanceInterval,
        isSecondRowOfPartialBalanceInterval: false,
        // price graph
        importPrice: row.import_price ?? NaN,
        exportPrice: row.export_price ?? NaN,
        // energy graph
        estimatedStoredEnergyStart: estimatedStoredEnergyStart ?? NaN,
        estimatedStoredEnergyEnd: estimatedStoredEnergyEnd ?? NaN,
        socBracketMinStart: NaN,
        socBracketMaxStart: NaN,
        socBracketMinEnd: socBracketMinEnd ?? NaN,
        socBracketMaxEnd: socBracketMaxEnd ?? NaN,
        energyCapacity: energyCapacity ?? NaN,
        // power graph
        facilityPower: (row.facility_total_net_energy ?? NaN) * (3600 / duration),
        fcrnPower: fcrnPower ?? NaN,
        esuPower: esuPower ?? NaN,
        esuPowerBracketImport: (esuPowerBracketImport ?? NaN) * (3600 / duration),
        esuPowerBracketExport: -(esuPowerBracketExport ?? NaN) * (3600 / duration),
        meanGridPower:
          isFirstRowOfPartialBalanceInterval && variant === 'group'
            ? (row.grid_total_net_energy ?? NaN) * (3600 / duration)
            : NaN,
        netShavingTargetEnergy: NaN,
        netShavingTargetPower: NaN,
      };
    });

    dataPoints.forEach((dataPoint, index) => {
      const previous = dataPoints[index - 1];
      dataPoint.socBracketMinStart = previous?.socBracketMinEnd ?? NaN;
      dataPoint.socBracketMaxStart = previous?.socBracketMaxEnd ?? NaN;
    });

    // at least two defined data points are needed to draw the line
    if (dataPoints.length >= 2 && dataPoints[0].isFirstRowOfPartialBalanceInterval) {
      dataPoints[1].meanGridPower = dataPoints[0].meanGridPower;
    }

    // create net shaving target
    if (
      variant === 'group' &&
      groupData.length >= 2 &&
      dataPoints.length >= 3 &&
      dataPoints[0].isFirstRowOfPartialBalanceInterval
    ) {
      dataPoints[1].isSecondRowOfPartialBalanceInterval = true;

      // what the grid net energy would if the ESU did nothing
      const forecastedGridNetEnergy =
        (groupData[0].facility_total_net_energy ?? NaN) +
        (groupData[1].facility_total_net_energy ?? NaN) +
        (groupData[0].esu_total_net_energy ?? NaN);

      const netShavingTargetEnergy = -forecastedGridNetEnergy;
      const netShavingTargetPower =
        netShavingTargetEnergy * (3600 / (groupData[1].duration ?? NaN));

      dataPoints[1].netShavingTargetEnergy = netShavingTargetEnergy;
      dataPoints[1].netShavingTargetPower = netShavingTargetPower;
      dataPoints[2].netShavingTargetEnergy = netShavingTargetEnergy;
      dataPoints[2].netShavingTargetPower = netShavingTargetPower;
    }

    // add a zero duration data point at the end to make sure the line is drawn to the end of the last balance interval
    const lastDataPoint = dataPoints.at(-1);
    if (lastDataPoint !== undefined && Number.isFinite(lastDataPoint.durationSeconds)) {
      dataPoints.push({
        ...lastDataPoint,
        startTime: lastDataPoint.endTime,
        durationSeconds: 0,
        isFirstRowOfPartialBalanceInterval: false,
        isSecondRowOfPartialBalanceInterval: false,
        estimatedStoredEnergyStart: lastDataPoint.estimatedStoredEnergyEnd,
        socBracketMinStart: lastDataPoint.socBracketMinEnd,
        socBracketMaxStart: lastDataPoint.socBracketMaxEnd,
        estimatedStoredEnergyEnd: NaN,
        socBracketMinEnd: NaN,
        socBracketMaxEnd: NaN,
      });
    }

    return dataPoints;
  }, [groupData, esuData, variant]);

  return (
    <Box padding={1}>
      {error != null && (
        <Alert severity="error" sx={{ ml: 1, mr: 2, mb: 2 }}>
          Error loading chart data: {asCactosError(error).message}
        </Alert>
      )}
      <ParentSize debounceTime={100}>
        {({ width }) => {
          const legendStyles: React.CSSProperties = {
            position: 'absolute',
            right: CHART_MARGIN.right + LEGEND_BOX_MARGIN_RIGHT,
            width: (width - CHART_MARGIN.left - CHART_MARGIN.right) / 2,
            pointerEvents: 'none',
          };

          return (
            <div style={{ position: 'relative' }}>
              <div
                style={{
                  ...legendStyles,
                  top: CHART_MARGIN.top + LEGEND_BOX_MARGIN_TOP,
                }}
              >
                <ChartLegend
                  items={priceGraphLegendItems}
                  selectedItemID={selectedChartElement}
                  onSelectedItemChange={setSelectedChartElement as (itemID: string | null) => void}
                />
              </div>
              <div
                style={{
                  ...legendStyles,
                  top: CHART_MARGIN.top + PRICE_GRAPH_HEIGHT + GRAPH_GAP + LEGEND_BOX_MARGIN_TOP,
                }}
              >
                <ChartLegend
                  items={energyGraphLegendItems}
                  selectedItemID={selectedChartElement}
                  onSelectedItemChange={setSelectedChartElement as (itemID: string | null) => void}
                />
              </div>
              <div
                style={{
                  ...legendStyles,
                  top:
                    CHART_MARGIN.top +
                    PRICE_GRAPH_HEIGHT +
                    GRAPH_GAP +
                    ENERGY_GRAPH_HEIGHT +
                    GRAPH_GAP +
                    LEGEND_BOX_MARGIN_TOP,
                }}
              >
                <ChartLegend
                  items={powerGraphLegendItems}
                  selectedItemID={selectedChartElement}
                  onSelectedItemChange={setSelectedChartElement as (itemID: string | null) => void}
                />
              </div>

              {isLoading && (
                <div
                  style={{
                    position: 'absolute',
                    top: '35%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    background: 'rgba(80, 80, 80, 0.5)',
                    borderRadius: '8px',
                    padding: '6px',
                  }}
                >
                  <p style={{ fontSize: '18px' }}>Data loading</p>{' '}
                  <CircularProgress size="18px" sx={{ ml: 1 }} />
                </div>
              )}

              <Graph
                width={width}
                data={dataPoints}
                variant={variant}
                highlightedChartElement={selectedChartElement}
              />
            </div>
          );
        }}
      </ParentSize>
    </Box>
  );
};

type GraphProps = {
  width: number;
  data: DataPoint[];
  variant: OptimizerResultGraphVariant;
  highlightedChartElement: ChartElementID | null;
};

const Graph = ({ width, data, variant, highlightedChartElement }: GraphProps) => {
  const {
    axisColor,
    axisBottomTickLabelProps,
    axisLeftTickLabelProps,
    gridColor,
    gridStrokeDasharray,
    highlightColor,
  } = useCommonChartStyles();

  const chartElementColors = useChartElementColors();

  const theme = useTheme();
  const negativePriceAreaColor = theme.palette.chart.validity.bad;
  const beforeCalculationAreaColor = theme.palette.chart.gray;
  const beforeCalculationAreaOpacity = 0.1;

  const {
    tooltipData,
    tooltipLeft = 0,
    tooltipTop = 0,
    tooltipOpen,
    showTooltip,
    hideTooltip,
  } = useTooltip<{ time: Date; dataPoint: DataPoint | null }>();

  const innerWidth = width - CHART_MARGIN.left - CHART_MARGIN.right;

  const numTimeTicks = getContinuousAxisNumTicks(width);

  const [timeScale, priceGraphYScale, energyGraphYScale, powerGraphYScale] = useMemo(() => {
    const timeScale = scaleTime<number>({
      range: [0, innerWidth],
      domain: data.length > 0 ? [data[0].startTime, data.at(-1)!.startTime] : undefined,
    });

    const priceValues = data.flatMap((dataPoint) => [dataPoint.importPrice, dataPoint.exportPrice]);
    const [priceMin, priceMax] = extent(priceValues);
    const priceGraphYScale = scaleLinear<number>({
      range: [PRICE_GRAPH_HEIGHT, 0],
      domain:
        priceMin != null && priceMax != null ? [priceMin - 0.001, priceMax + 0.001] : [0, 0.1],
      nice: true,
    });

    const energyValues = data.flatMap((dataPoint) => [
      // the start values are the ones actually plotted on the graph
      dataPoint.estimatedStoredEnergyStart,
      dataPoint.socBracketMinStart,
      dataPoint.socBracketMaxStart,
    ]);
    const [energyMin, energyMax] = extent(energyValues);
    const energyCapacityValues = data
      .map((dataPoint) => dataPoint.energyCapacity)
      .filter(Number.isFinite);
    const energyCapacityMax =
      energyCapacityValues.length > 0 ? Math.max(...energyCapacityValues) : null;
    const energyGraphYScale = scaleLinear<number>({
      range: [ENERGY_GRAPH_HEIGHT, 0],
      domain:
        energyMin != null && energyMax != null
          ? [energyMin - 1, Math.max(energyMax + 1, energyCapacityMax ?? 0)]
          : [0, 100],
      nice: true,
    });

    const powerValues = data.flatMap((dataPoint) => [
      -dataPoint.facilityPower, // flipped in graph
      // FCR-N power mirrored against x-axis in the graph
      dataPoint.fcrnPower,
      -dataPoint.fcrnPower,
      dataPoint.esuPower,
      dataPoint.esuPowerBracketImport,
      dataPoint.esuPowerBracketExport,
      -dataPoint.meanGridPower, // flipped in graph
      // net shaving target power is not here,
      // if it goes outside the graph domain the line will not be draw
    ]);
    const [powerMin, powerMax] = extent(powerValues);
    const powerGraphYScale = scaleLinear<number>({
      range: [POWER_GRAPH_HEIGHT, 0],
      domain: powerMin != null && powerMax != null ? [powerMin - 1, powerMax + 1] : [-10, 10],
      nice: true,
    });

    return [timeScale, priceGraphYScale, energyGraphYScale, powerGraphYScale];
  }, [data, innerWidth]);

  const handleTooltip = useCallback(
    (event: React.TouchEvent<SVGRectElement> | React.MouseEvent<SVGRectElement>): void => {
      const { x, y } = localPoint(event) || { x: 0, y: 0 };
      const dateUnderCursor = timeScale.invert(x - CHART_MARGIN.left);
      const index = bisector((d: DataPoint) => d.startTime).left(data, dateUnderCursor);
      showTooltip({
        tooltipData: {
          time: dateUnderCursor,
          dataPoint:
            data[index]?.startTime.getTime() === dateUnderCursor.getTime()
              ? data[index]
              : 0 < index && index < data.length
              ? data[index - 1]
              : null,
        },
        tooltipLeft: x,
        tooltipTop: y,
      });
    },
    [data, showTooltip, timeScale],
  );

  if (innerWidth <= 10) return null;

  return (
    <div>
      <svg width={width} height={TOTAL_GRAPH_HEIGHT}>
        <Group top={CHART_MARGIN.top} left={CHART_MARGIN.left}>
          {data[0]?.isFirstRowOfPartialBalanceInterval && (
            <rect
              x={0}
              y={0}
              width={timeScale(data[0].endTime)}
              height={PRICE_GRAPH_HEIGHT}
              fill={beforeCalculationAreaColor}
              opacity={beforeCalculationAreaOpacity}
            />
          )}
          {priceGraphYScale.domain()[0] < 0 &&
            // highlight the negative price area if visible in the graph
            (() => {
              const y0 = Math.max(priceGraphYScale(0), priceGraphYScale.range()[1]);
              const y1 = priceGraphYScale.range()[0];
              return (
                <rect
                  x={0}
                  y={y0}
                  width={innerWidth}
                  height={y1 - y0}
                  fill={negativePriceAreaColor}
                  opacity={0.15}
                />
              );
            })()}

          <Grid
            xScale={timeScale}
            numTicksColumns={numTimeTicks}
            yScale={priceGraphYScale}
            numTicksRows={8}
            width={innerWidth}
            height={PRICE_GRAPH_HEIGHT}
            stroke={gridColor}
            strokeDasharray={gridStrokeDasharray}
          />
          <Line from={{ x: 0, y: 0 }} to={{ x: innerWidth, y: 0 }} stroke={axisColor} />
          <Line
            from={{ x: innerWidth, y: 0 }}
            to={{ x: innerWidth, y: PRICE_GRAPH_HEIGHT }}
            stroke={axisColor}
          />
          <AxisLeft
            scale={priceGraphYScale}
            numTicks={8}
            stroke={axisColor}
            tickStroke={axisColor}
            tickLabelProps={axisLeftTickLabelProps}
          />
          <AxisBottom
            top={PRICE_GRAPH_HEIGHT}
            scale={timeScale}
            stroke={axisColor}
            tickStroke={axisColor}
            numTicks={numTimeTicks}
            tickFormat={() => ''}
          />

          <LinePath
            data={data}
            defined={(dataPoint) => Number.isFinite(dataPoint.importPrice)}
            x={(dataPoint) => timeScale(dataPoint.startTime)}
            y={(dataPoint) => priceGraphYScale(dataPoint.importPrice)}
            curve={curveStepAfter}
            stroke={chartElementColors['import-price']}
            strokeWidth={1}
            opacity={getOpacity('line', 'import-price', highlightedChartElement)}
          />
          <LinePath
            data={data}
            defined={(dataPoint) => Number.isFinite(dataPoint.exportPrice)}
            x={(dataPoint) => timeScale(dataPoint.startTime)}
            y={(dataPoint) => priceGraphYScale(dataPoint.exportPrice)}
            curve={curveStepAfter}
            stroke={chartElementColors['export-price']}
            strokeWidth={1}
            opacity={getOpacity('line', 'export-price', highlightedChartElement)}
          />
        </Group>

        <Group top={CHART_MARGIN.top + PRICE_GRAPH_HEIGHT + GRAPH_GAP} left={CHART_MARGIN.left}>
          {data[0]?.isFirstRowOfPartialBalanceInterval && (
            <rect
              x={0}
              y={0}
              width={timeScale(data[0].endTime)}
              height={ENERGY_GRAPH_HEIGHT}
              fill={beforeCalculationAreaColor}
              opacity={beforeCalculationAreaOpacity}
            />
          )}

          <Grid
            xScale={timeScale}
            numTicksColumns={numTimeTicks}
            yScale={energyGraphYScale}
            width={innerWidth}
            height={ENERGY_GRAPH_HEIGHT}
            stroke={gridColor}
            strokeDasharray={gridStrokeDasharray}
          />
          <Line from={{ x: 0, y: 0 }} to={{ x: innerWidth, y: 0 }} stroke={axisColor} />
          <Line
            from={{ x: innerWidth, y: 0 }}
            to={{ x: innerWidth, y: ENERGY_GRAPH_HEIGHT }}
            stroke={axisColor}
          />
          <AxisLeft
            scale={energyGraphYScale}
            stroke={axisColor}
            tickStroke={axisColor}
            tickLabelProps={axisLeftTickLabelProps}
          />
          <AxisBottom
            top={ENERGY_GRAPH_HEIGHT}
            scale={timeScale}
            stroke={axisColor}
            tickStroke={axisColor}
            numTicks={numTimeTicks}
            tickFormat={() => ''}
          />

          <LinePath
            data={data}
            defined={(dataPoint) => Number.isFinite(dataPoint.estimatedStoredEnergyStart)}
            x={(dataPoint) => timeScale(dataPoint.startTime)}
            y={(dataPoint) => energyGraphYScale(dataPoint.estimatedStoredEnergyStart)}
            curve={curveLinear}
            stroke={chartElementColors['estimated-stored-energy']}
            strokeWidth={1}
            opacity={getOpacity('line', 'estimated-stored-energy', highlightedChartElement)}
          />
          <LinePath
            data={data}
            defined={(dataPoint) => Number.isFinite(dataPoint.socBracketMinStart)}
            x={(dataPoint) => timeScale(dataPoint.startTime)}
            y={(dataPoint) => energyGraphYScale(dataPoint.socBracketMinStart)}
            curve={curveLinear}
            stroke={chartElementColors['soc-bracket']}
            strokeWidth={1}
            strokeDasharray="10,4"
            opacity={getOpacity('line', 'soc-bracket', highlightedChartElement)}
          />
          <LinePath
            data={data}
            defined={(dataPoint) => Number.isFinite(dataPoint.socBracketMaxStart)}
            x={(dataPoint) => timeScale(dataPoint.startTime)}
            y={(dataPoint) => energyGraphYScale(dataPoint.socBracketMaxStart)}
            curve={curveLinear}
            stroke={chartElementColors['soc-bracket']}
            strokeWidth={1}
            strokeDasharray="10,4"
            opacity={getOpacity('line', 'soc-bracket', highlightedChartElement)}
          />
        </Group>

        <Group
          top={CHART_MARGIN.top + PRICE_GRAPH_HEIGHT + GRAPH_GAP + ENERGY_GRAPH_HEIGHT + GRAPH_GAP}
          left={CHART_MARGIN.left}
        >
          {data[0]?.isFirstRowOfPartialBalanceInterval && (
            <rect
              x={0}
              y={0}
              width={timeScale(data[0].endTime)}
              height={POWER_GRAPH_HEIGHT}
              fill={beforeCalculationAreaColor}
              opacity={beforeCalculationAreaOpacity}
            />
          )}

          <Grid
            xScale={timeScale}
            numTicksColumns={numTimeTicks}
            yScale={powerGraphYScale}
            width={innerWidth}
            height={POWER_GRAPH_HEIGHT}
            stroke={gridColor}
            strokeDasharray={gridStrokeDasharray}
          />
          <Line from={{ x: 0, y: 0 }} to={{ x: innerWidth, y: 0 }} stroke={axisColor} />
          <Line
            from={{ x: innerWidth, y: 0 }}
            to={{ x: innerWidth, y: POWER_GRAPH_HEIGHT }}
            stroke={axisColor}
          />
          <AxisLeft
            scale={powerGraphYScale}
            stroke={axisColor}
            tickStroke={axisColor}
            tickLabelProps={axisLeftTickLabelProps}
          />
          <AxisBottom
            top={POWER_GRAPH_HEIGHT}
            scale={timeScale}
            stroke={axisColor}
            tickStroke={axisColor}
            numTicks={numTimeTicks}
            tickFormat={(date) => formatAxisDate(date as Date)}
            tickLabelProps={{ ...axisBottomTickLabelProps, dy: '0em' }}
          />

          <AreaClosed
            data={data}
            defined={(dataPoint) => Number.isFinite(dataPoint.fcrnPower)}
            x={(dataPoint) => timeScale(dataPoint.startTime)}
            y0={(dataPoint) => powerGraphYScale(-dataPoint.fcrnPower)}
            y1={(dataPoint) => powerGraphYScale(dataPoint.fcrnPower)}
            yScale={powerGraphYScale}
            curve={curveStepAfter}
            fill={chartElementColors['fcrn-power']}
            opacity={getOpacity('area', 'fcrn-power', highlightedChartElement)}
          />
          <AreaClosed
            data={data}
            defined={(dataPoint) => Number.isFinite(dataPoint.esuPower)}
            x={(dataPoint) => timeScale(dataPoint.startTime)}
            y0={() => powerGraphYScale(0)}
            y1={(dataPoint) => powerGraphYScale(dataPoint.esuPower)}
            yScale={powerGraphYScale}
            curve={curveStepAfter}
            fill={chartElementColors['esu-power']}
            opacity={getOpacity('area', 'esu-power', highlightedChartElement)}
          />

          <Line
            from={{ x: 0, y: powerGraphYScale(0) }}
            to={{ x: innerWidth, y: powerGraphYScale(0) }}
            stroke={axisColor}
            strokeWidth={1}
            strokeDasharray="4,2"
          />

          <LinePath
            data={data}
            defined={(dataPoint) => Number.isFinite(dataPoint.facilityPower)}
            x={(dataPoint) => timeScale(dataPoint.startTime)}
            y={(dataPoint) => powerGraphYScale(-dataPoint.facilityPower)}
            curve={curveStepAfter}
            stroke={chartElementColors['facility-power']}
            strokeWidth={1}
            opacity={getOpacity('line', 'facility-power', highlightedChartElement)}
          />
          <LinePath
            data={data}
            defined={(dataPoint) => Number.isFinite(dataPoint.esuPowerBracketExport)}
            x={(dataPoint) => timeScale(dataPoint.startTime)}
            y={(dataPoint) => powerGraphYScale(dataPoint.esuPowerBracketExport)}
            curve={curveStepAfter}
            stroke={chartElementColors['esu-power-bracket']}
            strokeWidth={1}
            opacity={getOpacity('line', 'esu-power-bracket', highlightedChartElement)}
          />
          <LinePath
            data={data}
            defined={(dataPoint) => Number.isFinite(dataPoint.esuPowerBracketImport)}
            x={(dataPoint) => timeScale(dataPoint.startTime)}
            y={(dataPoint) => powerGraphYScale(dataPoint.esuPowerBracketImport)}
            curve={curveStepAfter}
            stroke={chartElementColors['esu-power-bracket']}
            strokeWidth={1}
            opacity={getOpacity('line', 'esu-power-bracket', highlightedChartElement)}
          />
          <LinePath
            data={data}
            defined={(dataPoint) => Number.isFinite(dataPoint.meanGridPower)}
            x={(dataPoint) => timeScale(dataPoint.startTime)}
            y={(dataPoint) => powerGraphYScale(-dataPoint.meanGridPower)}
            curve={curveStepAfter}
            stroke={chartElementColors['mean-grid-power']}
            strokeWidth={1}
            opacity={getOpacity('line', 'mean-grid-power', highlightedChartElement)}
          />
          <LinePath
            data={data}
            defined={(dataPoint) =>
              Number.isFinite(dataPoint.netShavingTargetPower) &&
              powerGraphYScale.domain()[0] <= dataPoint.netShavingTargetPower &&
              dataPoint.netShavingTargetPower <= powerGraphYScale.domain()[1]
            }
            x={(dataPoint) => timeScale(dataPoint.startTime)}
            y={(dataPoint) => powerGraphYScale(dataPoint.netShavingTargetPower)}
            curve={curveStepAfter}
            stroke={chartElementColors['net-shaving-target-power']}
            strokeWidth={1}
            opacity={getOpacity('line', 'net-shaving-target-power', highlightedChartElement)}
          />
        </Group>

        {tooltipOpen &&
          tooltipData?.dataPoint &&
          tooltipData.dataPoint.startTime < tooltipData.dataPoint.endTime && (
            <rect
              y={CHART_MARGIN.top}
              height={TOTAL_GRAPH_HEIGHT - CHART_MARGIN.top - CHART_MARGIN.bottom}
              x={CHART_MARGIN.left + timeScale(tooltipData.dataPoint.startTime)}
              width={
                timeScale(tooltipData.dataPoint.endTime) -
                timeScale(tooltipData.dataPoint.startTime)
              }
              fill={highlightColor}
              opacity={0.1}
            />
          )}

        <rect
          x={CHART_MARGIN.left}
          y={CHART_MARGIN.top}
          width={innerWidth}
          height={TOTAL_GRAPH_HEIGHT - CHART_MARGIN.top - CHART_MARGIN.bottom}
          fill="transparent"
          onTouchStart={handleTooltip}
          onTouchMove={handleTooltip}
          onMouseMove={handleTooltip}
          onMouseLeave={hideTooltip}
        />
      </svg>

      {tooltipOpen && tooltipData && (
        <TooltipWithBounds
          key={`tooltip-${tooltipTop}-${tooltipLeft}-${tooltipData.time.getTime()}`}
          top={tooltipTop}
          left={tooltipLeft}
          style={TOOLTIP_STYLES}
        >
          <div style={{ color: highlightColor }}>
            <strong>
              {tooltipData.dataPoint
                ? formatRange({
                    start: tooltipData.dataPoint.startTime,
                    end: tooltipData.dataPoint.endTime,
                  })
                : formatTooltipDate(tooltipData.time)}
            </strong>
          </div>
          {getTooltipItems(tooltipData.dataPoint, variant).map((item, index) =>
            item === 'divider' ? (
              <hr key={`divider-${index}`} style={{ border: '1px solid white', margin: '4px 0' }} />
            ) : (
              <div key={item.id} style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ marginRight: 3 }}>
                  <svg width="10" height="10">
                    <g transform="translate(5, 5)">
                      <circle r="5" fill={chartElementColors[item.id]} />
                    </g>
                  </svg>
                </div>
                {variant === 'group'
                  ? CHART_ELEMENT_GROUP_CHART_DISPLAY_NAMES[item.id]
                  : CHART_ELEMENT_ESU_CHART_DISPLAY_NAMES[item.id]}
                : {item.value}
              </div>
            ),
          )}
        </TooltipWithBounds>
      )}
    </div>
  );
};

const getTooltipItems = (
  dataPoint: DataPoint | null,
  variant: OptimizerResultGraphVariant,
): ({ id: ChartElementID; value: string } | 'divider')[] => {
  const items: ({ id: ChartElementID; value: string } | 'divider')[] = [
    {
      id: 'import-price',
      value: Number.isFinite(dataPoint?.importPrice)
        ? dataPoint!.importPrice.toFixed(4) + ' €/kWh'
        : '-',
    },
    {
      id: 'export-price',
      value: Number.isFinite(dataPoint?.exportPrice)
        ? dataPoint!.exportPrice.toFixed(4) + ' €/kWh'
        : '-',
    },
    'divider',
    {
      id: 'estimated-stored-energy',
      value:
        Number.isFinite(dataPoint?.estimatedStoredEnergyStart) &&
        Number.isFinite(dataPoint?.estimatedStoredEnergyEnd)
          ? dataPoint!.estimatedStoredEnergyStart.toFixed(2) +
            ' kWh \u2192 ' +
            dataPoint!.estimatedStoredEnergyEnd.toFixed(2) +
            ' kWh'
          : '-',
    },
    {
      id: 'soc-bracket',
      value:
        Number.isFinite(dataPoint?.socBracketMinStart) &&
        Number.isFinite(dataPoint?.socBracketMaxStart) &&
        Number.isFinite(dataPoint?.socBracketMinEnd) &&
        Number.isFinite(dataPoint?.socBracketMaxEnd)
          ? `${dataPoint!.socBracketMinStart.toFixed(2)}\u2013` +
            `${dataPoint!.socBracketMaxStart.toFixed(2)} kWh \u2192 ` +
            `${dataPoint!.socBracketMinEnd.toFixed(2)}\u2013` +
            `${dataPoint!.socBracketMaxEnd.toFixed(2)} kWh`
          : Number.isFinite(dataPoint?.socBracketMinEnd) &&
            Number.isFinite(dataPoint?.socBracketMaxEnd)
          ? `${dataPoint!.socBracketMinEnd.toFixed(2)}\u2013` +
            `${dataPoint!.socBracketMaxEnd.toFixed(2)} kWh (end)`
          : '-',
    },
    'divider',
    {
      id: 'facility-power',
      value: Number.isFinite(dataPoint?.facilityPower)
        ? dataPoint!.facilityPower.toFixed(2) + ' kW'
        : '-',
    },
    {
      id: 'fcrn-power',
      value: Number.isFinite(dataPoint?.fcrnPower) ? dataPoint!.fcrnPower.toFixed(2) + ' kW' : '-',
    },
    {
      id: 'esu-power',
      value: Number.isFinite(dataPoint?.esuPower) ? dataPoint!.esuPower.toFixed(2) + ' kW' : '-',
    },
    {
      id: 'esu-power-bracket',
      value:
        Number.isFinite(dataPoint?.esuPowerBracketExport) &&
        Number.isFinite(dataPoint?.esuPowerBracketImport)
          ? `${dataPoint!.esuPowerBracketExport.toFixed(2)}\u2013` +
            `${dataPoint!.esuPowerBracketImport.toFixed(2)} kW`
          : '-',
    },
  ];

  if (variant === 'group' && dataPoint?.isFirstRowOfPartialBalanceInterval) {
    items.push({
      id: 'mean-grid-power',
      value: Number.isFinite(dataPoint.meanGridPower)
        ? dataPoint.meanGridPower.toFixed(2) + ' kW'
        : '-',
    });
  }

  if (variant === 'group' && dataPoint?.isSecondRowOfPartialBalanceInterval) {
    items.push({
      id: 'net-shaving-target-power',
      value:
        dataPoint.netShavingTargetPower.toFixed(2) +
        ' kW (' +
        dataPoint.netShavingTargetEnergy.toFixed(2) +
        ' kWh over ' +
        (dataPoint.durationSeconds / 60).toFixed(0) +
        ' min)',
    });
  }

  return items;
};

const useChartElementColors = () => {
  const theme = useTheme();
  const colors: Record<ChartElementID, string> = {
    'import-price': theme.palette.chart.categorical10[7],
    'export-price': theme.palette.chart.categorical10[0],
    'estimated-stored-energy': theme.palette.chart.categorical10[0],
    'soc-bracket': theme.palette.chart.categorical10[8],
    'facility-power': theme.palette.chart.categorical10[7],
    'fcrn-power': theme.palette.chart.categorical10[1],
    'esu-power': theme.palette.chart.categorical10[0],
    'esu-power-bracket': theme.palette.chart.categorical10[3],
    'mean-grid-power': theme.palette.chart.categorical10[4],
    'net-shaving-target-power': theme.palette.chart.categorical10[6],
  };
  return colors;
};

const useLegendItems = (ids: ChartElementID[], variant: OptimizerResultGraphVariant) => {
  const chartElementColors = useChartElementColors();
  const displayNames =
    variant === 'group'
      ? CHART_ELEMENT_GROUP_CHART_DISPLAY_NAMES
      : CHART_ELEMENT_ESU_CHART_DISPLAY_NAMES;
  return ids.map((id) => ({
    id,
    displayName: displayNames[id],
    color: chartElementColors[id],
  }));
};

const getOpacity = (
  type: 'line' | 'area',
  id: ChartElementID,
  highlightedChartElement: ChartElementID | null,
) => {
  if (highlightedChartElement === null || highlightedChartElement === id) return OPACITY[type];
  return OPACITY_UNHIGHLIGHTED[type];
};
