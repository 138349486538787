import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AppBar, Box, Divider, Drawer, IconButton, List, Toolbar } from '@mui/material';

import { useResponsive } from '~hooks/useResponsive';

import { sidebarConfig } from '~layouts/AppLayout/sidebarConfig';
import { AccountMenu } from '~layouts/AppLayout/AccountMenu';
import { SidebarItem } from '~layouts/AppLayout/SidebarItem';

import { Logo } from '~components/Logo';
import { Iconify } from '~components/Iconify';

export const SIDEBAR_WIDTH = 200;
export const SIDEBAR_MOBILE_HEIGHT = 48;
export const SIDEBAR_LOGO_HEIGHT = 72;
export const SIDEBAR_USER_HEIGHT = 46;

export function Sidebar() {
  const isDesktop = useResponsive('up', 'lg');
  const location = useLocation();

  const [open, setOpen] = useState(false);

  const sidebarItems = useMemo(
    () => (
      <>
        <Box overflow="auto" flexGrow={1}>
          <List disablePadding>
            {sidebarConfig.map((item, i) => (
              <SidebarItem key={i} item={item} />
            ))}
          </List>
        </Box>
        <Divider />
        <AccountMenu />
      </>
    ),
    [],
  );

  useEffect(() => {
    // Close the drawer when location changes
    setOpen(false);
  }, [location]);

  return (
    <>
      {!isDesktop && (
        <>
          <AppBar>
            <Toolbar variant="dense">
              <IconButton onClick={() => setOpen((x) => !x)}>
                <Iconify icon="mdi:menu" fontSize={24} color="grey.600" />
              </IconButton>
              <Box flexGrow={1} display="flex" justifyContent="center">
                <Logo size={32} />
              </Box>
              <Box width={40} />
            </Toolbar>
          </AppBar>
          <Drawer
            open={open}
            onClose={() => setOpen(false)}
            PaperProps={{
              sx: {
                width: SIDEBAR_WIDTH,
                maxWidth: '100vw',
                paddingTop: `${SIDEBAR_MOBILE_HEIGHT}px`,
              },
            }}
          >
            {sidebarItems}
          </Drawer>
        </>
      )}
      {isDesktop && (
        <Box width={SIDEBAR_WIDTH}>
          <Drawer
            open
            variant="persistent"
            PaperProps={{
              sx: {
                width: SIDEBAR_WIDTH,
              },
            }}
          >
            <Box p={2}>
              <Logo />
            </Box>
            <Divider />
            {sidebarItems}
          </Drawer>
        </Box>
      )}
    </>
  );
}
