import {
  Card,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import styled from '@emotion/styled';
import React, { useState } from 'react';

import { formatDateTime } from '~utils/time';

import {
  type QueryResultItem,
  type TimeSeriesName,
  type TimeSeriesQuery,
} from '~hooks/timeseries/types';

import { type TimeseriesSchemas } from './TimeSeriesExplorer';

const DEFAULT_ROWS_SHOWN = 100;

export type ResultCardProps = {
  result: QueryResultItem;
  query: TimeSeriesQuery | null;
  schemas: TimeseriesSchemas;
  displayNameForResource: Map<string, string>;
};

export const ResultCard = React.memo(
  ({ result, query, schemas, displayNameForResource }: ResultCardProps) => {
    const [showAll, setShowAll] = useState(false);
    const columns = getExpectedColumns(result.resource, result.series, query, schemas);

    const rows = showAll ? result.data : result.data.slice(0, DEFAULT_ROWS_SHOWN);

    return (
      <Card>
        <CardHeader
          title={
            (displayNameForResource.get(result.resource) ?? result.resource) + ' ' + result.series
          }
        />
        <TableContainer sx={{ minWidth: 800, paddingLeft: 1, paddingRight: 1, paddingBottom: 1 }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                {['time', ...columns].map((column) => (
                  <HeaderCell key={column}>
                    {column
                      .split('_')
                      .flatMap((part, index, all) =>
                        index < all.length - 1 ? [part + '_', <wbr key={index} />] : [part],
                      )}
                  </HeaderCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody sx={{ minWidth: 800 }}>
              {rows.map((row) => (
                <TableRow key={row.time ?? ''}>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    {formatDateTime(row.time as string)}
                  </TableCell>
                  {columns.map((column) => (
                    <ContentCell
                      key={column}
                      sx={column === 'received_timestamp' ? { whiteSpace: 'nowrap' } : undefined}
                    >
                      {(() => {
                        const presicion = 4;
                        const value = row[column];
                        if (column === 'received_timestamp') return formatDateTime(value as string);
                        if (value == null)
                          return (
                            <Typography color="GrayText">
                              <i>null</i>
                            </Typography>
                          );
                        if (typeof value === 'number')
                          // up to presicion, but without extra zeros
                          return (Number(value.toPrecision(presicion)) * 1).toString();
                        return value.toString();
                      })()}
                    </ContentCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {result.data.length > DEFAULT_ROWS_SHOWN && (
          <Typography
            sx={{ textAlign: 'center', cursor: 'pointer', color: 'primary', pb: 1 }}
            onClick={() => setShowAll((prev) => !prev)}
          >
            {showAll ? 'Show less' : `Show all ${result.data.length} rows`}
          </Typography>
        )}
      </Card>
    );
  },
);

const ContentCell = styled(TableCell)({
  width: '90px',
  textAlign: 'center',
});

const HeaderCell = styled(TableCell)({
  width: '90px',
  writingMode: 'vertical-rl',
  maxHeight: '120px',
  transform: 'rotate(180deg)',
});

const getExpectedColumns = (
  resource: string,
  series: string,
  query: TimeSeriesQuery | null,
  schemas: TimeseriesSchemas,
): readonly string[] => {
  if (query == null) return [];
  const resourceType = resource.split(':')[0];
  const columns = query.columns[series as TimeSeriesName] ?? [];
  if (columns.includes('*')) {
    const allColumns = Object.keys(schemas[resourceType]?.[series]?.columns ?? {});
    const set = new Set([...columns, ...allColumns]);
    set.delete('*');
    return Array.from(set);
  }
  return columns;
};
