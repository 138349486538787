import { Events } from '~pages/fleet/esus/Events';

import { ScrollView } from '~components/ScrollView';

export function ControllerEvents() {
  return (
    <ScrollView>
      <Events />
    </ScrollView>
  );
}
