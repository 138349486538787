import { useEffect, useState } from 'react';
import { formatDistanceToNow } from 'date-fns';

export function RelativeTime({ date }: { date: string }) {
  const [, forceUpdate] = useState(0);

  // Update every 10 seconds
  useEffect(() => {
    const intervalId = setInterval(() => {
      forceUpdate((n) => n + 1);
    }, 10_000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return <time dateTime={date}>{formatDistanceToNow(date, { addSuffix: true })}</time>;
}
