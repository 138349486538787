import { Box, Chip } from '@mui/material';

import { type ESUCurrentMode } from '~types';

import { Iconify } from '~components/Iconify';
import { DashboardCard, DashboardCardHeader } from '~components/DashboardCard';

type EMSStatusCardProps = {
  esuMode: ESUCurrentMode | undefined;
};

export function EMSStatusCard(props: EMSStatusCardProps) {
  const { esuMode } = props;

  if (esuMode == null || esuMode.mode === 'invalid_data') {
    return (
      <DashboardCard size="small">
        <DashboardCardHeader>Status</DashboardCardHeader>
        <Box display="flex" gap="1rem" flexWrap="wrap">
          <Chip sx={{ minWidth: 100 }} />
          <Chip sx={{ minWidth: 100 }} />
          <Chip sx={{ minWidth: 100 }} />
          <Chip sx={{ minWidth: 100 }} />
        </Box>
      </DashboardCard>
    );
  }

  const networkOk: boolean = esuMode.mode !== 'no_recent_data';
  const gridOk: boolean | null = esuMode.mode === 'no_recent_data' ? null : esuMode.grid_connected;
  const powerOn: boolean | null = (() => {
    switch (esuMode.mode) {
      case 'operating':
        return true;
      case 'powered_off':
        return false;
      case 'no_recent_data':
      default:
        return null;
    }
  })();
  const [status, statusIcon]: [string, string] | [null, null] = (() => {
    if (esuMode.mode !== 'operating') return [null, null];
    if (esuMode.fcr_n.active) return ['FCR-N', 'mdi:sine-wave'];
    if (esuMode.peak_shaving) return ['Peak Shaving', 'mdi:graph-bell-curve'];
    if (esuMode.load_shifting) return ['Load Shifting', 'mdi:battery-charging-50'];
    return ['Idle', 'mdi:check'];
  })();

  return (
    <DashboardCard size="small">
      <DashboardCardHeader>Status</DashboardCardHeader>
      <Box display="flex" gap="1rem" flexWrap="wrap">
        <Chip
          color={networkOk ? 'primary' : 'error'}
          icon={<Iconify icon={networkOk ? 'mdi:signal' : 'mdi:signal-off'} />}
          label={networkOk ? 'Cellular OK' : 'Cellular Disconnected'}
        />
        {gridOk != null && (
          <Chip
            color={gridOk == null ? 'default' : gridOk ? 'primary' : 'error'}
            icon={
              <Iconify icon={gridOk ? 'mdi:transmission-tower' : 'mdi:transmission-tower-off'} />
            }
            label={gridOk ? 'Grid OK' : 'Grid Down'}
          />
        )}
        {powerOn != null && (
          <Chip
            color={powerOn ? 'primary' : 'error'}
            icon={<Iconify icon={powerOn ? 'mdi:electric-switch-closed' : 'mdi:electric-switch'} />}
            label={powerOn ? 'Power On' : 'Power Off'}
          />
        )}
        {status != null && (
          <Chip color="primary" icon={<Iconify icon={statusIcon} />} label={status} />
        )}
      </Box>
    </DashboardCard>
  );
}
