import { type ESUCurrentMode, type ESUWithController, type FleetFilter } from '~types';

import { useTimeseriesLatest } from '~hooks/timeseries';
import { createLatestQuery } from '~hooks/timeseries/queries';
import { useESUList } from '~hooks/useESUList';
import { useESUCurrentMode } from '~hooks/useESUCurrentMode';
import { type FaultEventWithESU, useESUFaults } from '~hooks/useESUFaults';

export type ESUOperationDataRow = ESUWithController & {
  current_mode?: ESUCurrentMode;
  faults?: FaultEventWithESU[];
  bms_cell_voltages?: {
    time: string;
    'max(*)': number | null;
    'min(*)': number | null;
  };
  bms_temperatures?: {
    time: string;
    'max(*)': number | null;
    'min(*)': number | null;
  };
  esu_status?: {
    time: string;
    soc: number | null;
  };
  esu_power_control?: {
    time: string;
    aem_active: number | null;
    cbps_power: number | null;
    constant_power: number | null;
    esu_requested_power: number | null;
    fcrn_allocated_cap: number | null;
    fcrn_maintained_cap: number | null;
    internal_throttling: number | null;
  };
};
export type ESUOperationData = {
  data: ESUOperationDataRow[] | undefined;
  error: any;
  isLoading: boolean;
};

export const CURRENT_FAULTS_RANGE_MINUTES = 5;

export function useESUOperationData(fleetFilter: FleetFilter): ESUOperationData {
  const { data: esus, error: esuListError, isLoading: esuListLoading } = useESUList(fleetFilter);

  const resources = esus?.map((esu) => esu.resource_id) ?? [];

  const { data: esuModeData, error: esuModeError, isLoading: esuModeLoading } = useESUCurrentMode();

  const query = createLatestQuery({
    resources,
    columns: {
      bms_cell_voltages: ['max(*)', 'min(*)'],
      bms_temperatures: ['max(*)', 'min(*)'],
      esu_status: ['soc'],
      esu_power_control: [
        'aem_active',
        'cbps_power',
        'constant_power',
        'esu_requested_power',
        'fcrn_allocated_cap',
        'fcrn_maintained_cap',
        'internal_throttling',
      ],
    },
    start: { minutes: 10 },
  });
  const {
    data: timeseriesData,
    error: timeseriesError,
    isLoading: timeseriesLoading,
  } = useTimeseriesLatest(query, {
    refreshInterval: 60_000,
    revalidateOnFocus: false,
  });

  const {
    data: esuFaultsData,
    error: esuFaultsError,
    isLoading: esuFaultsLoading,
  } = useESUFaults(CURRENT_FAULTS_RANGE_MINUTES, fleetFilter);

  const mergedData = esus?.map((esu) => {
    const esuData = timeseriesData?.[esu.resource_id];
    const esuMode = esuModeData?.[esu.id];
    const faults = esuFaultsData?.faults.filter((fault) => fault.ems_id === esu.id);
    return {
      ...esu,
      ...esuData,
      current_mode: esuMode,
      faults,
    };
  });

  return {
    data: mergedData,
    error: esuListError ?? esuModeError ?? timeseriesError ?? esuFaultsError,
    isLoading: esuListLoading || timeseriesLoading || esuModeLoading || esuFaultsLoading,
  };
}
