import { Container, Grid, Typography } from '@mui/material';

import { useCurrentESUId } from '~hooks/useCurrentESUId';
import { useSingleESUCurrentMode } from '~hooks/useESUCurrentMode';
import { createLatestQuery, useTimeseriesLatest } from '~hooks/timeseries/queries';
import { useESU } from '~hooks/useESUList';

import { Page } from '~components/Page';

import { DashboardCharts } from './DashboardCharts';
import {
  EMSBatteryCard,
  EMSChargeCard,
  EMSGridFrequencyCard,
  EMSPowerCard,
  EMSStatusCard,
  FCRNRevenueEstimateCard,
} from './cards';

export function DashboardApp() {
  const esuId = useCurrentESUId();
  const { data: esu } = useESU(esuId);

  const { data: esuMode } = useSingleESUCurrentMode(esuId);

  const { data: queryResult, isLoading: esuDataLoading } = useTimeseriesLatest(
    createLatestQuery({
      resources: esu ? [esu.resource_id] : [],
      columns: {
        bms_cell_voltages: ['max(*)', 'min(*)'],
        bms_temperatures: ['max(*)'],
        esu_status: ['soc'],
        esu_diagnostics_power: ['bms_battery_pack_voltage'],
        esu_power_control: ['esu_realized_power', 'control_grid_f'],
      },
      start: { minutes: 10 },
    }),
    {
      refreshInterval: 10_000,
      revalidateOnFocus: true,
    },
  );

  const esuData = esu != null ? queryResult?.[esu.resource_id] : undefined;

  return (
    <Page title="Cactos Dashboard">
      <Container maxWidth="xl">
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} lg={4}>
            <EMSStatusCard esuMode={esuMode} />
          </Grid>

          <Grid item xs={12} sm={6} lg={4}>
            <EMSPowerCard
              powerWatts={esuData?.esu_power_control?.esu_realized_power}
              isLoading={esuDataLoading}
            />
          </Grid>

          <Grid item xs={12} sm={6} lg={4}>
            <EMSChargeCard socPercentage={esuData?.esu_status?.soc} isLoading={esuDataLoading} />
          </Grid>

          <Grid item xs={12} sm={6} lg={4}>
            <EMSBatteryCard data={esuData} isLoading={esuDataLoading} />
          </Grid>

          <Grid item xs={12} sm={6} lg={4}>
            <FCRNRevenueEstimateCard
              groupId={esu?.edge_controller.metering_group.id ?? undefined}
            />
          </Grid>

          <Grid item xs={12} sm={6} lg={4}>
            <EMSGridFrequencyCard
              frequencyMilliHz={esuData?.esu_power_control?.control_grid_f}
              isLoading={esuDataLoading}
            />
          </Grid>
        </Grid>
        <Typography variant="h3" sx={{ mt: 5, mb: 5 }}>
          {esu?.name && `${esu?.name}: `}Historical Data
        </Typography>
        <DashboardCharts />
      </Container>
    </Page>
  );
}
