import { useMemo } from 'react';

import { SCHEDULE_TIMESERIES_COLUMNS, toScheduleRowWithTime } from '~constants/schedule';

import { useESU } from '~hooks/useESUList';
import { createRangeQuery, useTimeseriesRange } from '~hooks/timeseries/queries';

export function useScheduleHistory(esuId: string) {
  const { data: esu, error: esuError, isLoading: isLoadingEsu } = useESU(esuId);
  const {
    data: scheduleHistoryResult,
    error: error,
    isLoading: isLoading,
    mutate,
  } = useTimeseriesRange(
    createRangeQuery({
      resources: esu ? [esu.resource_id] : [],
      columns: {
        schedule_history: SCHEDULE_TIMESERIES_COLUMNS,
      },
      start: { hours: 24 },
      end: 'now',
    }),
    { refreshInterval: 60_000, revalidateOnFocus: false },
  );
  const scheduleHistory = useMemo(() => {
    if (esu == null || scheduleHistoryResult == null) {
      return [];
    }
    return (
      scheduleHistoryResult[esu.resource_id]?.schedule_history.map(toScheduleRowWithTime) ?? []
    );
  }, [esu, scheduleHistoryResult]);

  return {
    data: scheduleHistory,
    error: esuError || error,
    isLoading: isLoadingEsu || isLoading,
    mutate,
  };
}
