import { type PropsWithChildren, useCallback, useMemo, useState } from 'react';

import { isRoutePermitted } from '~utils/isRoutePermitted';

import { useAuth } from '~hooks/useAuth';
import {
  type Breadcrumb,
  type BreadcrumbType,
  BreadcrumbsContext,
  type BreadcrumbsContextType,
} from '~hooks/useBreadcrumbs';

export function BreadcrumbsProvider({ children }: PropsWithChildren) {
  const { user } = useAuth();
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([]);

  const registerBreadcrumbs = useCallback(
    (type: BreadcrumbType, label: string | null, path: string) => {
      // null label does not register the breadcrumb
      // Empty string label shows "..." (breadcrumb data loading)
      if (label === null) return () => {};

      // TODO remove this line on UI v2 merge
      const finalPath = path.startsWith('/v2') ? path : `/v2${path}`;

      if (!isRoutePermitted(user, finalPath)) return () => {};

      setBreadcrumbs((previousBreadcrumbs) =>
        previousBreadcrumbs.some((breadcrumb) => breadcrumb.path === finalPath)
          ? previousBreadcrumbs
          : [...previousBreadcrumbs, { type, label, path: finalPath }].sort(sortBreadcrumbs),
      );

      return () => {
        setBreadcrumbs((previousBreadcrumbs) =>
          previousBreadcrumbs.filter((breadcrumb) => breadcrumb.path !== finalPath),
        );
      };
    },
    [user],
  );

  const breadcrumbsContext = useMemo<BreadcrumbsContextType>(
    () => ({
      breadcrumbs,
      setBreadcrumbs,
      registerBreadcrumbs,
    }),
    [breadcrumbs, registerBreadcrumbs],
  );

  return (
    <BreadcrumbsContext.Provider value={breadcrumbsContext}>{children}</BreadcrumbsContext.Provider>
  );
}

const breadcrumbTypeSortOrder: BreadcrumbType[] = [
  'other',
  'fleets',
  'organizations',
  'sites',
  'controllers',
  'esus',
];

function sortBreadcrumbs(a: Breadcrumb, b: Breadcrumb) {
  if (a.type !== b.type) {
    return breadcrumbTypeSortOrder.indexOf(a.type) - breadcrumbTypeSortOrder.indexOf(b.type);
  }

  return a.path.localeCompare(b.path);
}
