import { Box, Card, CircularProgress, Tooltip } from '@mui/material';
import { ErrorOutline } from '@mui/icons-material';
import { ParentSize } from '@visx/responsive';
import { scaleSequential } from 'd3-scale';
import { interpolateRdYlBu } from 'd3-scale-chromatic';

import { DashboardCardHeader } from '../DashboardCard';
import { HistogramBarChart, type UnitInfo } from './HistogramBarChart';

type FleetTemperatureHistogramProps = {
  title: string;
  data: Map<number, UnitInfo[]>;
  isLoading?: boolean;
  error?: any;
  height?: number;
};

export function FleetTemperatureHistogram({
  title,
  data,
  isLoading = false,
  error = null,
  height = 300,
}: FleetTemperatureHistogramProps) {
  const barColorScale = scaleSequential(interpolateRdYlBu).domain([45, 4]).clamp(true);

  return (
    <Card sx={{ padding: 2 }}>
      <DashboardCardHeader>
        {title}
        {isLoading && <CircularProgress size={15} sx={{ ml: 1.5, mb: 0.35 }} />}
        {error != null && (
          <Tooltip title="Error loading fleet temperature data." placement="right">
            <ErrorOutline color="error" fontSize="small" sx={{ ml: 1.2 }} />
          </Tooltip>
        )}
      </DashboardCardHeader>
      <Box sx={{ p: 1, pb: 1, alignSelf: 'stretch' }}>
        <ParentSize debounceTime={100}>
          {({ width }) => (
            <HistogramBarChart
              width={width}
              height={height}
              data={data}
              barColoring={(key) => barColorScale(key)}
              formatTooltipLabel={(key) => `${key} °C`}
              formatBottomAxisLabel={(key) => `${key}°C`}
              minBottomAxisTickGap={40}
            />
          )}
        </ParentSize>
      </Box>
    </Card>
  );
}
