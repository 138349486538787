import { useTheme } from '@mui/material';
import { Toaster } from 'react-hot-toast';

export const StyledToaster = () => {
  const theme = useTheme();
  return (
    <Toaster
      position="bottom-right"
      toastOptions={{
        success: {
          style: {
            background: theme.palette.success.dark,
            color: theme.palette.text.primary,
          },
          iconTheme: {
            primary: theme.palette.text.primary,
            secondary: theme.palette.success.dark,
          },
        },
        error: {
          style: {
            background: theme.palette.error.dark,
            color: theme.palette.text.primary,
          },
          iconTheme: {
            primary: theme.palette.text.primary,
            secondary: theme.palette.error.dark,
          },
        },
      }}
    />
  );
};
