import { Outlet } from 'react-router-dom';

import { useRegisterBreadcrumbs } from '~hooks/useBreadcrumbs';
import { useESU } from '~hooks/useESUList';

import { TABS } from '~pages/v2/esus/constants';
import { useESUsNavigation } from '~pages/v2/esus/hooks/useESUsNavigation';
import { useESUsHierarchyBreadcrumbs } from '~pages/v2/esus/hooks/useESUsHierarchyBreadcrumbs';
import { createESUPath } from '~pages/v2/esus/utils/createESUPath';

import { Tabs } from '~components/Tabs';

export function ESU() {
  const { esuId, tab } = useESUsNavigation();

  const { data: esu } = useESU(esuId);

  useESUsHierarchyBreadcrumbs();
  useRegisterBreadcrumbs('esus', esu?.name ?? '', createESUPath(esuId));

  return (
    <>
      <Tabs tabs={TABS} tab={tab} pathname={createESUPath(esuId)} />
      <Outlet />
    </>
  );
}
