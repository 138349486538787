import useSWR, { preload } from 'swr';

import type { FetchHookData, Region } from '~types';

import { fetcher } from '~http';

export async function preloadReserveObjects() {
  try {
    return await preload('/v1/region/fcr', fetcher);
  } catch {
    return null;
  }
}

export function useReserveObjects(): FetchHookData<Region[], false> {
  const { data, error, isLoading, mutate } = useSWR<Region[]>('/v1/region/fcr', {
    refreshInterval: 60_000,
    revalidateOnFocus: false,
    fetcher,
  });

  return {
    data: data ?? [],
    error,
    isLoading,
    mutate,
  };
}
