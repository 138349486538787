import type React from 'react';
import { useMemo } from 'react';
import { useTheme } from '@mui/material';
import { defaultStyles } from '@visx/tooltip';
import { type TickLabelProps } from '@visx/axis';
import { format } from 'date-fns';

export const TOOLTIP_STYLES: React.CSSProperties = {
  ...defaultStyles,
  minWidth: 60,
  backgroundColor: 'rgba(0,0,0,0.9)',
  color: 'white',
};

export type ChartLegendProps = {
  items: {
    id: string;
    displayName: string;
    color: string;
  }[];
  selectedItemID?: string | null;
  onSelectedItemChange?: (itemID: string | null) => void;
};

export const ChartLegend = ({
  items,
  selectedItemID = null,
  onSelectedItemChange: setSelectedItem,
}: ChartLegendProps) => {
  const theme = useTheme();

  return (
    <div
      style={{
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'flex-end',
        fontSize: '12px',
      }}
    >
      {items.map((item) => (
        <div
          key={item.id}
          style={{
            pointerEvents: 'auto',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            padding: '1px 2px',
            margin: '0px 10px 0px 0px',
            borderRadius: '6px',
            backgroundColor: selectedItemID === item.id ? theme.palette.grey[700] : 'transparent',
          }}
          onMouseEnter={() => setSelectedItem?.(item.id)}
          onMouseLeave={() => setSelectedItem?.(null)}
        >
          <div style={{ display: 'flex', margin: '2px 4px 2px 0px' }}>
            <svg width="15" height="15">
              <g transform="translate(7.5, 7.5)">
                <circle r="7.5" fill={item.color} />
              </g>
            </svg>
          </div>
          <div
            style={{
              justifyContent: 'left',
              display: 'flex',
              flex: '1 1 0%',
              margin: '0px',
            }}
          >
            {item.displayName}
          </div>
        </div>
      ))}
    </div>
  );
};

export const formatAxisDate = (date: Date): string => {
  if (date.getHours() === 0 && date.getMinutes() === 0 && date.getSeconds() === 0) {
    return format(date, 'MMM d');
  } else if (date.getSeconds() === 0) {
    return format(date, 'HH:mm');
  } else {
    return format(date, ':ss');
  }
};

export const formatTooltipDate = (date: Date): string => format(date, 'yyyy-MM-dd HH:mm:ss');

export const useCommonChartStyles = () => {
  const theme = useTheme();

  const highlightColor: string = theme.palette.primary.main;
  const elementColor1: string = theme.palette.grey[300];
  const elementColor2: string = theme.palette.grey[600];
  const axisColor: string = theme.palette.grey[500];
  const gridColor: string = theme.palette.grey[800];

  const styles = useMemo(() => {
    const axisBottomTickLabelProps: TickLabelProps<any> = {
      fill: axisColor,
      fontSize: 11,
      textAnchor: 'middle',
      dy: '-0.3em',
    };
    const axisLeftTickLabelProps: TickLabelProps<any> = {
      fill: axisColor,
      fontSize: 11,
      textAnchor: 'end',
      dy: '0.33em',
    };

    const gridStrokeDasharray = '2,4';

    return {
      highlightColor,
      elementColor1,
      elementColor2,
      gridColor,
      gridStrokeDasharray,
      axisColor,
      axisLeftTickLabelProps,
      axisBottomTickLabelProps,
    };
  }, [highlightColor, elementColor1, elementColor2, axisColor, gridColor]);

  return styles;
};

// based on the length of labels created by formatAxisDate
const DEFAULT_MIN_TICK_GAP = 80;

export const getContinuousAxisNumTicks = (
  width: number,
  minTickGap: number = DEFAULT_MIN_TICK_GAP,
): number => {
  // if there's lots of space, we don't have to completely fill it up with ticks
  const emptySpace = 0.6 * Math.max(width - 350, 0);
  return Math.ceil((width - emptySpace) / minTickGap);
};

export const getDiscreteAxisNumTicks = (
  width: number,
  nOfItems: number,
  minTickGap: number,
): number => {
  for (const numTicks of geometricSeries(nOfItems, 1 / 2)) {
    if (numTicks * minTickGap < width) return Math.ceil(numTicks);
  }
  throw new Error("Looked through an infinite series, and still couldn't find a solution.");
};

function* geometricSeries(start: number, ratio: number): Generator<number> {
  let current = start;
  while (true) {
    yield current;
    current *= ratio;
  }
}
