import { memo } from 'react';
import { Box, Card, CardHeader, CircularProgress, Stack, Typography } from '@mui/material';
import { ParentSize } from '@visx/responsive';
import { color } from 'd3-color';
import { scaleSequential } from 'd3-scale';
import { interpolateViridis } from 'd3-scale-chromatic';

import { formatDateTime } from '~utils/time';

import { type BatteryInfo } from '~pages/fleet/diagnostics/useDiagnosticsData';

import { RelativeTime } from '../RelativeTime';
import { GroupedBarChart } from './GroupedBarChart';

interface ESUCellVoltageGraphProps {
  bmsCellVoltages: Record<string, number | null> | undefined;
  isLoading: boolean;
  batteryInfo: BatteryInfo;
  height?: number;
  title: string;
}

function ESUCellVoltageGraphComponent(props: ESUCellVoltageGraphProps) {
  const { isLoading, batteryInfo, height, title } = props;
  const cellVoltages = props.bmsCellVoltages ?? {};

  const nOfCells = batteryInfo.cell_count;
  const cellIndices = [...Array(nOfCells).keys()];
  const voltageArray = cellIndices.map((index) => (cellVoltages[`col_${index}`] ?? NaN) / 1000);

  const dataTime = cellVoltages?.time?.toString() ?? undefined;

  const domain = [batteryInfo.nominal_cell_voltage_max, batteryInfo.nominal_cell_voltage_min];
  const barColorScale = scaleSequential(interpolateViridis).domain(domain).clamp(true);

  return (
    <Stack>
      <Card>
        <CardHeader
          title={
            <Typography gutterBottom variant="h6" component="h4">
              {title}
              {isLoading && <CircularProgress size={12} sx={{ mt: 1, ml: 1 }} />}
            </Typography>
          }
          subheader={
            dataTime ? (
              <>
                {formatDateTime(dataTime)} (
                <RelativeTime date={dataTime} />)
              </>
            ) : (
              'Loading...'
            )
          }
        />
        <Box sx={{ p: 3, pb: 1 }} dir="ltr">
          <ParentSize debounceTime={100}>
            {({ width }) => (
              <GroupedBarChart
                width={width}
                height={height ?? 300}
                data={voltageArray}
                groupSize={batteryInfo.cells_per_module}
                yAxisLimitGranularity={1}
                barColoring={(groupIndex, barIndex, value) =>
                  color(barColorScale(value))!.brighter(3).toString()
                }
                tooltipUpperText={(data) => `Module ${data.groupIndex} cell ${data.barIndex}`}
                tooltipLowerText={(data) => `${data.value.toFixed(3)} V`}
              />
            )}
          </ParentSize>
        </Box>
      </Card>
    </Stack>
  );
}

export const ESUCellVoltageGraph = memo(ESUCellVoltageGraphComponent);
