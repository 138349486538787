import Decimal from 'decimal.js';
import * as Yup from 'yup';

import { validateEffectivePeriod } from '~pages/settings/groups/pricing/utils/effectivePeriod';

const testIsNonNegative = (field: string) => ({
  name: 'isNonNegativeDecimal',
  message: `${field} must be a positive number`,
  test: (value: any) => {
    if (value === undefined) return false;
    try {
      return new Decimal(value).greaterThanOrEqualTo(0);
    } catch {
      return false;
    }
  },
});

const testIsBetween0And1 = (field: string) => ({
  name: 'isBetween0And1',
  message: `${field} must be between 0 and 1`,
  test: (value: any) => {
    if (value === undefined) return false;
    try {
      const x = new Decimal(value);

      return x.greaterThanOrEqualTo(0) && x.lessThanOrEqualTo(1);
    } catch {
      return false;
    }
  },
});

export const PricingWizardSchema = Yup.object({
  startTime: Yup.date().required('Start time is required'),
  purchaseModel: Yup.string().oneOf(['spotMargin', 'fixed']).required('Purchase model is required'),
  purchaseSpotFactor: Yup.string().when('purchaseModel', {
    is: 'spotMargin',
    then: (schema) =>
      schema
        .required('Purchase spot factor is required')
        .test(testIsNonNegative('Purchase spot factor'))
        .test(testIsBetween0And1('Purchase spot factor')),
  }),
  purchaseSpotMargin: Yup.string().when('purchaseModel', {
    is: 'spotMargin',
    then: (schema) =>
      schema
        .required('Purchase spot margin is required')
        .test(testIsNonNegative('Purchase spot margin')),
  }),
  purchaseFixedPrice: Yup.string().when('purchaseModel', {
    is: 'fixed',
    then: (schema) =>
      schema
        .required('Purchase fixed price is required')
        .test(testIsNonNegative('Purchase fixed price')),
  }),
  purchaseTransferModel: Yup.string()
    .oneOf(['fixed', 'variable'])
    .required('Purchase transfer is required'),
  purchaseTransferFixedPrice: Yup.string().when('purchaseTransferModel', {
    is: 'fixed',
    then: (schema) =>
      schema
        .required('Purchase transfer fixed price is required')
        .test(testIsNonNegative('Purchase transfer fixed price')),
  }),
  purchaseTransferHigher: Yup.string().when('purchaseTransferModel', {
    is: 'variable',
    then: (schema) =>
      schema.required('Transfer higher is required').test(testIsNonNegative('Transfer higher')),
  }),
  purchaseTransferLower: Yup.string().when('purchaseTransferModel', {
    is: 'variable',
    then: (schema) =>
      schema
        .required('Transfer higher is required')
        .test(testIsNonNegative('Transfer higher'))
        .test({
          name: 'purchaseTransferLower higher than purchaseTransferHigher',
          message: 'Transfer lower must be less than or equal to transfer higher',
          test: (value, context) => {
            const purchaseTransferHigher = context.parent.purchaseTransferHigher;

            return new Decimal(value ?? '0').lessThanOrEqualTo(purchaseTransferHigher ?? '0');
          },
        }),
  }),
  purchaseEffectivePeriod: Yup.string().when('purchaseTransferModel', {
    is: 'variable',
    then: (schema) =>
      schema.test({
        name: 'purchaseEffectivePeriod',
        message: 'Effective period is required',
        test: (value) => !!value && validateEffectivePeriod(value),
      }),
  }),
  purchaseHasEnergyTax: Yup.boolean().required('Has energy tax is required'),
  purchaseEnergyTax: Yup.string()
    .when('purchaseHasEnergyTax', {
      is: true,
      then: (schema) => schema.required('Energy tax is required'),
    })
    .test(testIsNonNegative('Energy tax')),
  purchaseHasStockpileFee: Yup.boolean().required('Has stockpile fee is required'),
  purchaseStockpileFee: Yup.string()
    .when('purchaseHasStockpileFee', {
      is: true,
      then: (schema) => schema.required('Stockpile fee is required'),
    })
    .test(testIsNonNegative('Stockpile fee')),
  purchaseHasCustomFee: Yup.boolean().required('Has custom fee is required'),
  purchaseCustomFee: Yup.string()
    .when('purchaseHasCustomFee', {
      is: true,
      then: (schema) => schema.required('Custom fee is required'),
    })
    .test(testIsNonNegative('Custom fee')),
  purchaseCustomFeeDescription: Yup.string(),
  purchaseHasVat: Yup.boolean().required('Has VAT is required'),
  salesModel: Yup.string().oneOf(['spotMargin', 'fixed']).required('Sales model is required'),
  salesSpotFactor: Yup.string().when('salesModel', {
    is: 'spotMargin',
    then: (schema) =>
      schema
        .required('Sales spot factor is required')
        .test(testIsNonNegative('Sales spot factor'))
        .test(testIsBetween0And1('Sales spot factor')),
  }),
  salesSpotMargin: Yup.string().when('salesModel', {
    is: 'spotMargin',
    then: (schema) =>
      schema.required('Sales spot margin is required').test(testIsNonNegative('Sales spot margin')),
  }),
  salesFixedPrice: Yup.string().when('salesModel', {
    is: 'fixed',
    then: (schema) =>
      schema.required('Sales fixed price is required').test(testIsNonNegative('Sales fixed price')),
  }),
  salesTransferModel: Yup.string()
    .oneOf(['noTransfer', 'fixed'])
    .required('Sales transfer model is required'),
  salesTransferFixedPrice: Yup.string().when('salesTransferModel', {
    is: 'fixed',
    then: (schema) =>
      schema
        .required('Sales transfer fixed price is required')
        .test(testIsNonNegative('Sales transfer fixed price')),
  }),
  salesEnergyDescription: Yup.string(),
  salesTransferDescription: Yup.string(),
  salesHasVat: Yup.boolean().required('Has VAT is required'),
});

export type PricingWizardSchemaType = Yup.InferType<typeof PricingWizardSchema>;
