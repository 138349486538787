export const USER_ROLES = {
  VIEW_EMS: 'view_ems',
  EDIT_EMS: 'edit_ems',
  EDIT_EMS_SCHEDULE: 'edit_ems_schedule',
  CREATE_EMS: 'create_ems',
  VIEW_REPORT: 'view_report',
  DEBUG_EMS: 'debug_ems',
  FCR_BIDDING: 'fcr_bidding',
  ADMIN: 'admin',
  OPERATOR_VIEW: 'operator_view',
  VIEW_OPTIMIZER_RESULT: 'view_optimizer_result',
  VIEW_ORGANIZATION: 'view_organization',
  VIEW_PRICING: 'view_pricing',
  OPTIMIZER_SYSTEM_CONFIGURATION: 'optimizer_system_configuration',
  EMS_DIAGNOSTIC_VIEW: 'ems_diagnostic_view',
  EMS_DIAGNOSTIC_RUN: 'ems_diagnostic_run',
  FCR_PREQUALIFICATION_TESTING: 'fcr_prequalification_testing',
  VIEW_TOPOLOGY: 'view_topology',
  EDIT_TOPOLOGY: 'edit_topology',
  USER: 'user',
  CREATE_ORGANIZATION: 'create_organization',
} as const;

export const ROOT_ORGANIZATION = '/';
