import { Button } from '@mui/material';
import { useState } from 'react';

import { USER_ROLES } from '~constants/auth';

import { useRegisterBreadcrumbs } from '~hooks/useBreadcrumbs';
import { useUserHasRootRole } from '~hooks/useUserHasRootRole';

import { PageLayout } from '~layouts/PageLayout';

import { OrganizationCreateModal } from '~pages/settings/organizations/OrganizationCreateModal';
import { OrganizationsList } from '~pages/v2/organizations/components/OrganizationsList';
import { createOrganizationPath } from '~pages/v2/organizations/utils/createOrganizationPath';

export function Organizations() {
  useRegisterBreadcrumbs('organizations', 'Organizations', createOrganizationPath());

  const [isCreateOrganizationModalOpen, setIsCreateOrganizationModalOpen] = useState(false);

  const userCanCreateOrganization = useUserHasRootRole(USER_ROLES.CREATE_ORGANIZATION);

  return (
    <>
      <PageLayout
        title="Organizations"
        actions={
          userCanCreateOrganization && (
            <Button onClick={() => setIsCreateOrganizationModalOpen(true)}>
              Create organization
            </Button>
          )
        }
      >
        <OrganizationsList />
      </PageLayout>
      <OrganizationCreateModal
        open={isCreateOrganizationModalOpen}
        onClose={() => setIsCreateOrganizationModalOpen(false)}
      />
    </>
  );
}
