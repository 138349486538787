import useSWR from 'swr';

import { type ESUCurrentMode } from '~types';

import { fetcher, http } from '~http';

type ESUCurrentModeResponse = { [esu_id: string]: ESUCurrentMode };

export function useSingleESUCurrentMode(esuId: string): {
  data: ESUCurrentMode | undefined;
  error: Error | undefined;
  isLoading: boolean;
} {
  const { data, error, isLoading } = useSWR<ESUCurrentModeResponse>(
    `/v1/esu_current_mode?ids=${esuId}`,
    { refreshInterval: 60_000, revalidateOnFocus: false, fetcher },
  );
  return { data: data?.[esuId], error, isLoading };
}

export function useESUCurrentMode() {
  const { data, error, isLoading } = useSWR<ESUCurrentModeResponse>('/v1/esu_current_mode', {
    refreshInterval: 60_000,
    revalidateOnFocus: false,
    fetcher: fetchESUCurrentMode,
  });
  return { data, error, isLoading };
}

async function fetchESUCurrentMode(url: string) {
  const response = await http.get(url, { timeout: 30_000 });
  return response.data;
}
