import axios, { type AxiosRequestConfig } from 'axios';

export const BASE_URL = new URL(
  import.meta.env.VITE_API_URL || '/api/',
  window.location.origin,
).toString();

export type CactosError = {
  message: string;
};

export function asCactosError(e: unknown): CactosError {
  if (axios.isAxiosError(e)) {
    let data = e.response?.data;
    if (typeof data === 'string') {
      try {
        // If the request was for text/plain, the response won't automatically be parsed as JSON,
        // but the error message might still be in JSON format.
        // Try to parse it as JSON to extract the error message.
        data = JSON.parse(data);
      } catch (e) {
        // Ignore
      }
    }
    if (typeof data === 'object' && data !== null && 'message' in data) {
      console.warn(`Error ${e.status}: ${data.message}`);
      return data as CactosError;
    } else if (typeof data === 'string') {
      console.warn(`Error ${e.status}: ${data}`);
      return { message: data };
    }
    if (e.code === 'ETIMEDOUT') {
      console.warn('Request timed out', e);
      return { message: e.message };
    }
    console.error('Unable to parse error response');
    return { message: 'Unable to parse error response' };
  }
  console.error(`Expected timeout or cactos error object in Axios error, found instead: ${e}`);
  return { message: `Unknown error: ${e}` };
}

const axiosConfig: AxiosRequestConfig = {
  baseURL: BASE_URL,
  headers: {
    'Content-type': 'application/json',
  },
  timeout: 5000,
  responseType: 'json',
  transitional: {
    silentJSONParsing: false,
    forcedJSONParsing: false,
    clarifyTimeoutError: true,
  },
};

const http = axios.create(axiosConfig);

http.interceptors.response.use(
  (response: any) => response,
  (error: any) => {
    if (error?.response?.status === 401 && !window.location.href.endsWith('/login')) {
      window.location.href = '/login';
    } else {
      throw error;
    }
  },
);

const plainHttp = axios.create(axiosConfig);

export { plainHttp, http };

export async function fetcher(url: string) {
  const response = await http.get(url);
  return response.data;
}
