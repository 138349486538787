import { type ReactNode } from 'react';

import { Iconify } from '~components/Iconify';

export type SidebarConfigItem = {
  label: string;
  icon?: ReactNode;
  path?: string;
};

const getIcon = (icon: string) => <Iconify icon={icon} fontSize={20} />;

export const sidebarConfig: SidebarConfigItem[] = [
  {
    label: 'Fleets',
    icon: getIcon('mdi:sitemap'),
    path: '/fleets',
  },
  {
    label: 'Organizations',
    icon: getIcon('mdi:office-building'),
    path: '/organizations',
  },
  {
    label: 'Sites',
    icon: getIcon('mdi:home-lightning-bolt-outline'),
    path: '/sites',
  },
  {
    label: 'Controllers',
    icon: getIcon('mdi:server-network-outline'),
    path: '/controllers',
  },
  {
    label: 'ESUs',
    icon: getIcon('mdi:battery-charging-50'),
    path: '/esus',
  },
  {
    label: 'Optimizer',
    icon: getIcon('mdi:math-integral-box'),
    path: '/optimizer',
  },
  {
    label: 'Explorer',
    icon: getIcon('mdi:toy-brick-search'),
    path: '/explorer',
  },
];
