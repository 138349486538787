import { useCallback, useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { Alert, Box } from '@mui/material';
import * as _ from 'lodash-es';
import axios from 'axios';

import { asCactosError, http } from '~http';

import { formatLocalTime } from '~utils/time';

import { useMeteringGroupContext } from '~hooks/useMeteringGroupContext';

import { Page } from '~components/Page';

import {
  ConfigurationFields,
  ResetButton,
  SubmitButton,
  configurationToFormValues,
  formValuesToConfiguration,
  validateConfiguration,
} from '~optimizer/index';

export function GroupOptimizerConfiguration() {
  const { id: currentEmsGroup, name } = useMeteringGroupContext();
  const [optimizerConfigurationHolder, setOptimizerConfigurationHolder] = useState({
    status: 'Loading optimizer configuration',
    data: null,
  });
  const [fieldDetailsHolder, setFieldDetailsHolder] = useState({
    status: 'Loading user interface',
    data: null,
  });
  const [saveStatus, setSaveStatus] = useState(null); // Shown if saving fails
  const [reloadRequest, setReloadRequest] = useState(null); // Set to trigger re-fetching of configuration from server

  useEffect(() => {
    async function fetchFieldDetails() {
      try {
        const response = await http.get(
          `/v1/metering_group/${currentEmsGroup}/optimizer_configuration_fields`,
        );
        setFieldDetailsHolder({ status: 'Done', data: _.keyBy(response.data, (el) => el.field) });
      } catch (ex) {
        setFieldDetailsHolder({
          status: 'Failed to fetch field details, cannot edit configuration.',
          data: null,
        });
      }
    }
    fetchFieldDetails();
  }, [currentEmsGroup, setFieldDetailsHolder]);

  useEffect(() => {
    if (fieldDetailsHolder.data === null) {
      // Need field details so that we know which fields to fetch
      return;
    }
    const fields = Object.entries(fieldDetailsHolder.data).map(
      ([field]) => `group_optimizer_configuration.${field}`,
    );
    async function fetchOptimizerConfigurationTimeseries() {
      try {
        const response = await http.get('/v2/timeseries', {
          params: {
            resources: `metering_group:${currentEmsGroup}`,
            columns: fields.join(','),
            latest: true,
          },
        });
        const configuration = { ...response.data?.results?.[0]?.data?.[0] };
        setOptimizerConfigurationHolder({ status: 'Done', data: configuration });
      } catch (ex) {
        if (!axios.isCancel(ex)) {
          const error = asCactosError(ex);
          setOptimizerConfigurationHolder({
            status: `Failed to fetch optimizer configuration, cannot edit configuration.  ${error.message}`,
            data: null,
          });
        }
      }
    }
    fetchOptimizerConfigurationTimeseries();
  }, [currentEmsGroup, fieldDetailsHolder, setOptimizerConfigurationHolder, reloadRequest]);

  const saveConfiguration = useCallback(
    (values, actions) => {
      async function postConfiguration() {
        try {
          const configuration = formValuesToConfiguration(fieldDetailsHolder.data, values);
          await http.post(
            `/v1/metering_group/${currentEmsGroup}/optimizer_configuration`,
            { configuration },
            { responseType: 'text' },
          );
          actions.setSubmitting(false);
          setReloadRequest(new Date());
          setSaveStatus(null);
          actions.resetForm();
        } catch (ex) {
          actions.setSubmitting(false);
          if (!axios.isCancel(ex)) {
            const error = asCactosError(ex);
            console.error(`Could not save configuration, ${error.message}`);
            setSaveStatus(`Could not save configuration, ${error.message}`);
          }
        }
      }
      postConfiguration();
    },
    [fieldDetailsHolder, currentEmsGroup, setSaveStatus],
  );

  useEffect(() => {
    // Reset save status text if group changes
    setSaveStatus(null);
  }, [setSaveStatus, currentEmsGroup]);

  const validateValues = useCallback(
    (values) => validateConfiguration(fieldDetailsHolder.data, values),
    [fieldDetailsHolder],
  );

  if (_.isEmpty(fieldDetailsHolder.data) || optimizerConfigurationHolder.data === null) {
    return (
      <>
        <p>Loading form: {fieldDetailsHolder.status}</p>
        <p>Loading configuration: {optimizerConfigurationHolder.status}</p>
      </>
    );
  }

  const initialValues = configurationToFormValues(
    fieldDetailsHolder.data,
    optimizerConfigurationHolder.data,
  );
  const initialErrors = validateConfiguration(fieldDetailsHolder.data, initialValues);
  return (
    <Page title={`${name} Config`}>
      <Box paddingX={3} paddingY={2}>
        <Formik
          initialValues={initialValues}
          initialErrors={optimizerConfigurationHolder.data.time === undefined ? initialErrors : {}}
          initial
          enableReinitialize
          onSubmit={saveConfiguration}
          validate={validateValues}
          highlightInitialErrors
        >
          <Form autoComplete="off">
            {optimizerConfigurationHolder.data.time !== undefined && (
              <p>Last edited {formatLocalTime(optimizerConfigurationHolder.data.time)}</p>
            )}
            {optimizerConfigurationHolder.data.time === undefined && (
              <Alert severity="info">
                This is the initial configuration. Installation-specific values must be entered.
              </Alert>
            )}
            <ConfigurationFields fieldDetails={fieldDetailsHolder.data} highlightInitialErrors />
            {saveStatus !== null && <p>{saveStatus}</p>}
            <Box width={390} display="flex" marginTop="1rem">
              <SubmitButton />
            </Box>
            <Box width={390} display="flex">
              <ResetButton />
            </Box>
          </Form>
        </Formik>
      </Box>
    </Page>
  );
}
