import { useEffect, useState } from 'react';
import { FormControlLabel, InputAdornment, Switch, TextField, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';

import { KINDS } from '~optimizer/constants';
import { TimedValuePreview } from '~optimizer/TimedValuePreview';

function groupDetails(details) {
  const result = {};
  Object.entries(details).forEach(([key, detail]) => {
    const groupLabel = detail.group;
    if (result[groupLabel] === undefined) {
      result[groupLabel] = {};
    }
    result[groupLabel][key] = detail;
  });
  return result;
}

function numberLabel(detail) {
  return `${detail.label} [${detail.range[0]} - ${detail.range[1]}]`;
}

function integerLabel(detail) {
  return `${detail.label} (integer))`;
}

export function ConfigurationFields(props) {
  const { fieldDetails } = props;
  const { setFieldTouched, errors, values, initialValues, getFieldProps } = useFormikContext();
  const fields = [];

  const [previewedTimedValue, setPreviewedTimedValue] = useState();
  const [anchorEl, setAnchorEl] = useState();
  const [currentKey, setCurrentKey] = useState(null);

  function showTimedValuePopup(element, key) {
    try {
      setCurrentKey(key);
      setPreviewedTimedValue(values[key]);
      setAnchorEl(element);
    } catch (error) {
      // Validation failed, quite possibly nothing was entered yet.
    }
  }

  function hideTimedValuePopup() {
    setCurrentKey(null);
    setPreviewedTimedValue(null);
    setAnchorEl(null);
  }

  useEffect(() => {
    // Keep updating previewed timed value when one of the timed value fields is hovered
    if (currentKey != null) {
      try {
        setPreviewedTimedValue(values[currentKey]);
      } catch (error) {
        // Validation failed, quite possibly nothing was entered yet.
      }
    }
  }, [values, currentKey]);

  const fieldDetailsByGroup = groupDetails(fieldDetails);
  const FIELD_MIN_WIDTH = '38ch';
  Object.entries(fieldDetailsByGroup).forEach(([groupLabel, groupFields]) => {
    fields.push(
      <Typography key={groupLabel} variant="h5" mb="1rem">
        {groupLabel}
      </Typography>,
    );
    Object.entries(groupFields).forEach(([key, detail]) => {
      const kind = detail.kind || KINDS.DECIMAL;
      if (kind === KINDS.DECIMAL) {
        fields.push(
          <TextField
            key={key}
            label={numberLabel(detail)}
            variant="outlined"
            {...getFieldProps(key)}
            InputProps={{
              inputMode: 'numeric',
              pattern: '[0-9.]*',
              endAdornment: <InputAdornment position="end">{detail.unit}</InputAdornment>,
            }}
            error={Boolean(errors[key])}
            helperText={
              errors[key] ||
              (values[key] !== initialValues[key] && `Changed from ${initialValues[key]}`)
            }
            sx={{
              marginRight: '1rem',
              marginBottom: '1rem',
              width: '23%',
              minWidth: FIELD_MIN_WIDTH,
            }}
          />,
        );
      } else if (kind === KINDS.TIMED_NUMBER) {
        fields.push(
          <TextField
            key={key}
            type="text"
            width="16em"
            label={numberLabel(detail)}
            {...getFieldProps(key)}
            InputProps={{
              endAdornment: <InputAdornment position="end">{detail.unit}</InputAdornment>,
            }}
            error={Boolean(errors[key])}
            helperText={
              errors[key] ||
              (values[key] !== initialValues[key] && `Changed from ${initialValues[key]}`)
            }
            sx={{
              marginRight: '1rem',
              marginBottom: '1rem',
              width: '23%',
              minWidth: FIELD_MIN_WIDTH,
            }}
            onFocus={(e) => showTimedValuePopup(e.target, key)}
            onBlur={() => {
              hideTimedValuePopup();
              setFieldTouched(key);
            }}
          />,
        );
      } else if (kind === KINDS.INTEGER) {
        fields.push(
          <TextField
            key={key}
            label={integerLabel(detail)}
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            variant="outlined"
            {...getFieldProps(key)}
            error={Boolean(errors[key])}
            helperText={
              errors[key] ||
              (values[key] !== initialValues[key] && `Changed from ${initialValues[key]}`)
            }
            sx={{
              marginRight: '1rem',
              marginBottom: '1rem',
              width: '23%',
              minWidth: FIELD_MIN_WIDTH,
            }}
          />,
        );
      } else if (kind === KINDS.BOOLEAN) {
        fields.push(
          <FormControlLabel
            key={key}
            control={<Switch {...getFieldProps(key)} checked={values[key]} />}
            label={detail.label}
            labelPlacement="start"
            sx={{
              margin: 0,
              marginBottom: '1rem',
              width: '23%',
              minWidth: FIELD_MIN_WIDTH,
            }}
          />,
        );
      } else {
        console.error(`Unexpected kind ${kind}`);
      }
    });
  });
  fields.push(
    <TimedValuePreview
      key="timed-value-preview"
      timedValue={previewedTimedValue}
      anchorEl={anchorEl}
    />,
  );
  return fields;
}

ConfigurationFields.propTypes = {
  fieldDetails: PropTypes.object,
};
