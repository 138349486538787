import { useCallback, useEffect } from 'react';
import { useMatch, useNavigate, useParams } from 'react-router-dom';

import { useLocalStorage } from '~hooks/useLocalStorage';

import type { Tab } from '~pages/v2/sites/types';
import { ROUTER_MATCH_PATH } from '~pages/v2/sites/constants';
import { createSitePath } from '~pages/v2/sites/utils/createSitePath';

const DEFAULT_TAB: Tab = 'dashboard';

export function useSitesNavigation() {
  const navigate = useNavigate();
  const { meteringGroupId = '' } = useParams();
  const params = useMatch(ROUTER_MATCH_PATH)?.params;

  const [tabFromStorage, setTabFromStorage] = useLocalStorage<Tab>('sites.tab', DEFAULT_TAB);
  const tab: Tab = (params?.tab as Tab) || tabFromStorage;

  const navigateToSite = useCallback(
    (meteringGroupId = '', tab: Tab = '') => {
      navigate(createSitePath(meteringGroupId, tab));
    },
    [navigate],
  );

  useEffect(() => {
    setTabFromStorage(tab);
  }, [tab, setTabFromStorage]);

  return {
    meteringGroupId,
    tab,
    navigateToSite,
  };
}
