import { Box } from '@mui/material';

import { FCRNRevenueEstimateCard } from '~pages/dashboard/cards';
import { SiteDashboardCharts } from '~pages/v2/sites/components/SiteDashboardCharts';
import { useSitesNavigation } from '~pages/v2/sites/hooks/useSitesNavigation';

import { ScrollView } from '~components/ScrollView';

export function SiteDashboard() {
  const { meteringGroupId } = useSitesNavigation();

  return (
    <ScrollView>
      <Box p={2}>
        <Box display="flex" mb={2}>
          <FCRNRevenueEstimateCard groupId={meteringGroupId} />
        </Box>
        <SiteDashboardCharts />
      </Box>
    </ScrollView>
  );
}
