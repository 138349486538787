import { NavLink, Outlet, useMatch } from 'react-router-dom';
import { TabContext, TabList } from '@mui/lab';
import { Tab } from '@mui/material';

import { USER_ROLES } from '~constants/auth';

import { useAuth } from '~hooks/useAuth';

import { ScrollView } from '~components/ScrollView';

export function GroupSettings() {
  const match = useMatch('/settings/groups/:groupId/:tab');
  const currentTab = match?.params.tab || 'config';
  const { user } = useAuth();
  const roles = user?.organizationsByRole || {};
  return (
    <>
      <TabContext value={currentTab}>
        <TabList
          sx={{
            marginLeft: 1,
          }}
        >
          {USER_ROLES.OPERATOR_VIEW in roles && (
            <Tab value="config" label="Config" component={NavLink} to="config" />
          )}
          {USER_ROLES.OPERATOR_VIEW in roles && (
            <Tab value="members" label="ESUs" component={NavLink} to="members" />
          )}
          {USER_ROLES.VIEW_TOPOLOGY in roles && (
            <Tab value="topology" label="Topology" component={NavLink} to="topology" />
          )}
          {USER_ROLES.VIEW_PRICING in roles && (
            <Tab value="pricing" label="Pricing" component={NavLink} to="pricing" />
          )}
        </TabList>
      </TabContext>
      {match?.params.tab === 'topology' ? (
        <Outlet />
      ) : (
        <ScrollView>
          <Outlet />
        </ScrollView>
      )}
    </>
  );
}
